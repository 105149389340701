import React, {Component} from 'react';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {Form, FormControlChangeType, IFormConfig, IMultiselectOption, Loader, LoaderType, Translation} from 'jobhunter-common-web';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {IAddRelocationCalendarEventPayload} from '../../../../../api/addRelocationCalendarEventAPI';
import {WithTranslation, withTranslation} from 'react-i18next';
import {addRelocationEventFormConfig} from './addRelocationEventFormConfig';
import {changeIsRelocationEventAdded} from '../../../../../store/reducers/relocationDetailsPageSlice';
import {CalendarIconType} from '../../../../../model/calendarEventModel';
import moment from 'moment';

interface IAddRelocationEventProps extends WithTranslation {
    readonly isModalOpen: boolean;
    readonly toggleModal: () => void;
    readonly addRelocationEvent: (payload: IAddRelocationCalendarEventPayload) => void;
    readonly isRelocationEventAdded: boolean;
    readonly error: string | null;
    readonly changeIsRelocationEventAdded: typeof changeIsRelocationEventAdded;
}

interface IAddRelocationEventState {
    value: any;
    formConfig: typeof IFormConfig | null;
    isLoading: boolean;
}

class AddRelocationEvent extends Component<IAddRelocationEventProps, IAddRelocationEventState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IAddRelocationEventProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: null,
            isLoading: false,
        };
    }

    componentDidMount(): void {
        this.setFormConfig();

        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    tap((data: any) => this.setState({value: data.value}))
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: IAddRelocationEventProps, prevState: IAddRelocationEventState): void {
        if (this.props.isRelocationEventAdded !== prevProps.isRelocationEventAdded && this.props.isRelocationEventAdded) {
            this.props.toggleModal();
        }

        if (this.props.error !== prevProps.error && this.props.error) {
            this.setState({isLoading: false});
        }
    }

    componentWillUnmount() {
        this.props.changeIsRelocationEventAdded(false);
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <Modal isOpen={this.props.isModalOpen} toggle={() => this.props.toggleModal()}>
                <ModalHeader toggle={() => this.props.toggleModal()}>
                    <Translation text="employer.positions.addPosition.title" />
                </ModalHeader>
                <ModalBody>
                    {this.state.formConfig && (
                        <Form
                            config={this.state.formConfig}
                            onValueStateChange={this.onValueStateChange}
                            value={this.state.value}
                            submitForm={() => this.addNewEvent()}
                            controlName={'addRelocationEventForm'}
                        />
                    )}
                </ModalBody>
                <Loader type={LoaderType.Local} showLoader={this.state.isLoading} />
            </Modal>
        );
    }

    private setFormConfig = () => {
        const types: typeof IMultiselectOption[] | [] = this.renderEventTypes(),
            formConfig = addRelocationEventFormConfig(types);

        this.setState({formConfig});
    };

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private addNewEvent = () => {
        this.setState({isLoading: true});

        const value = this.state.value,
            payload: IAddRelocationCalendarEventPayload = {
                startsAt: moment(value.date).toISOString(),
                endsAt: moment(value.date).add(1, 'hours').toISOString(),
                title: value.title,
                description: value.description,
                iconType: value.type,
            };
        this.props.addRelocationEvent(payload);
    };

    private renderEventTypes = () => {
        const {t} = this.props,
            eventTypes = [
                {
                    label: t('relocationView.calendar.eventTypes.warning'),
                    value: CalendarIconType.WARNING,
                },
                {
                    label: t('relocationView.calendar.eventTypes.alert'),
                    value: CalendarIconType.ALERT,
                },
                {
                    label: t('relocationView.calendar.eventTypes.info'),
                    value: CalendarIconType.INFO,
                },
            ];

        return eventTypes;
    };
}

export default withTranslation()(AddRelocationEvent);
