import {IModelCity, IModelSeniority, IModelCountry, IOrganizationDetails, IFileOutput} from 'jobhunter-common-web';
import {IModelApiObject} from './base';
import {IOfferItem} from './offerDataModel';
import {IModelDictionaryDatum} from 'jobhunter-common-web';

export enum PositionStatus {
    ACTIVE = 'active',
    CLOSED = 'closed',
}

export interface IModelBasePosition extends IModelApiObject {
    readonly city: typeof IModelCity;
    readonly createdAt: string;
    readonly maximumSalary: string;
    readonly minimumSalary: string;
    readonly seniority: typeof IModelSeniority;
    readonly name: string;
    readonly id: string;
    readonly description: string;
}

export interface IModelPositionsInformation extends IModelApiObject, IModelBasePosition {
    readonly offersCount: number;
    readonly status: PositionStatus;
}

export interface IModelPosition extends IModelApiObject, IModelBasePosition {
    readonly soughtPositions: ISoughtPosition[] | [];
}

export interface ISoughtPosition extends IModelApiObject {
    readonly id: string;
    readonly organization: IOrganization;
    readonly offers: IOffersPosition[];
}

export interface IOffersPosition extends IModelApiObject {
    readonly id: string;
    readonly offer: IOfferItem;
    readonly organization: IOrganization;
}

export interface IOrganization extends IModelApiObject {
    readonly id: string;
    readonly organizationCompany: IOrganizationCompany;
    readonly organizationDetails: typeof IOrganizationDetails;
}

export interface IOrganizationCompany extends IModelApiObject {
    readonly name: string;
    readonly address: string;
    readonly city: string;
    readonly phone: string;
    readonly vatNumber: string;
    readonly country: typeof IModelCountry;
    readonly avatar: typeof IFileOutput;
    readonly description: string;
}

export interface IModelPositionRelatedHr extends IModelApiObject {
    readonly address: string;
    readonly avatar: typeof IFileOutput;
    readonly city: string;
    readonly country: typeof IModelDictionaryDatum;
    readonly description: string;
    readonly name: string;
    readonly phone: string;
    readonly vatNumber: string | null;
    readonly hrDepartmentId: string;
}

export interface IUpdatePositionPayload {
    readonly name: string;
    readonly description: string;
    readonly minimumSalary: string;
    readonly maximumSalary: string;
    readonly cityId: string;
    readonly seniorityId: string;
}
