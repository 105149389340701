import React, {Component} from 'react';
import LayoutWrapper from '../../../LayoutWrapper';
import {Button, Card, CardBody, CardFooter, CardHeader} from 'reactstrap';
import {
    DateComponent,
    Translation,
    WithLocation,
    withLocation,
    WithNavigate,
    withNavigation,
    authTokenSelector,
    UserRole,
    authUserRolesSelector,
    Price,
} from 'jobhunter-common-web';
import {Edit3, Trash2} from 'react-feather';
import {WithTranslation, withTranslation} from 'react-i18next';
import styles from './styles.module.scss';
import DeletePosition from './DeletePosition';
import OffersList from './OffersList';
import {RootState} from '../../../../store/reducers';
import {connect} from 'react-redux';
import {IModelPosition} from '../../../../model/positionsDataModel';
import {positionDetailsSelector, isPositionDetailsLoadingSelector} from '../../../../store/selectors/positionDetailsSelectors';
import {
    fetchPositionDetails,
    fetchPositionRelatedHr,
    resetToInitialPositionDetailsState,
} from '../../../../store/reducers/positionDetailsPageSlice';
import ButtonWithTooltip from './ButtonWithTooltip';
import PositionRelatedRecruiters from './PositionRelatedRecruiters';
import ConfirmInvitation from './ConfirmInvitation';

declare type WithLocationProps = typeof WithLocation;
declare type WithNavigationProps = typeof WithNavigate;

interface IConnectedPositionDetailsProps {
    readonly userRoles: typeof UserRole | null;
    readonly position: IModelPosition | null;
    readonly isPositionLoading: boolean;
    readonly fetchPositionDetails: typeof fetchPositionDetails;
    readonly resetToInitialPositionDetailsState: typeof resetToInitialPositionDetailsState;
    readonly fetchPositionRelatedHr: typeof fetchPositionRelatedHr;
}

interface IExternalPositionDetailsProps {}

interface IPositionDetailsProps
    extends IConnectedPositionDetailsProps,
        IExternalPositionDetailsProps,
        WithTranslation,
        WithLocationProps,
        WithNavigationProps {}

interface IPositionDetailsState {
    isDeleteModalShown: boolean;
    isInvitationModalVisible: boolean;
    selectedRecruiterId: string | null;
}

class PositionDetails extends Component<IPositionDetailsProps, IPositionDetailsState> {
    constructor(props: IPositionDetailsProps) {
        super(props);

        this.state = {
            isDeleteModalShown: false,
            isInvitationModalVisible: false,
            selectedRecruiterId: null,
        };
    }

    componentDidMount(): void {
        if (this.props.location && this.props.location.pathname) {
            const positionId = this.props.location.pathname.split('/').pop();
            this.props.fetchPositionDetails(positionId);

            if (!this.isOrganizationHeadHunter()) {
                this.props.fetchPositionRelatedHr(positionId);
            }
        }
    }

    componentWillUnmount() {
        this.props.resetToInitialPositionDetailsState();
    }

    render() {
        const position = this.props.position;

        return (
            <>
                <LayoutWrapper>
                    <div className={styles.positionDetails}>
                        <div className="panel-section-header">
                            <Translation text="employer.positions.positionDetails.title" />
                        </div>
                        <Card className="position-details-card">
                            <CardHeader className={styles.cardHeader}>
                                <div className={styles.positionHeader}>
                                    <div className={styles.title}>
                                        {this.isOrganizationHeadHunter() ? (
                                            <p>
                                                {position?.soughtPositions && position?.soughtPositions.length ? (
                                                    <span className={styles.companyName}>
                                                        {position?.soughtPositions[0]?.organization?.organizationCompany?.name}
                                                    </span>
                                                ) : null}
                                                {position?.name}
                                            </p>
                                        ) : (
                                            <p>{position?.name}</p>
                                        )}
                                        <p className={styles.date}>
                                            {position && position.createdAt ? <DateComponent date={position.createdAt} /> : '---'}
                                        </p>
                                    </div>
                                    <div>
                                        {this.isOrganizationHeadHunter() ? (
                                            <Button
                                                color="primary"
                                                className="add-offer-button"
                                                onClick={() => this.redirectToAddNewOffer(position)}>
                                                <Translation text="employer.positions.addOffer" />
                                            </Button>
                                        ) : (
                                            <>
                                                <ButtonWithTooltip
                                                    buttonStyles={`${styles.button} edit-position-button`}
                                                    onButtonClick={() => this.redirectToEditPosition(position?.id)}
                                                    iconComponent={<Edit3 id={`edit`} size={24} />}
                                                    tooltipTarget="edit"
                                                    tooltipText="employer.positions.positionDetails.tooltips.edit"
                                                />

                                                <ButtonWithTooltip
                                                    buttonStyles={`${styles.button} remove-position-button`}
                                                    onButtonClick={() => this.removePosition()}
                                                    iconComponent={<Trash2 id={`delete`} size={24} />}
                                                    tooltipTarget="delete"
                                                    tooltipText="employer.positions.positionDetails.tooltips.delete"
                                                />

                                                {this.isOrganizationAdmin() ? (
                                                    <Button
                                                        color="primary"
                                                        className="add-offer-button"
                                                        onClick={() => this.redirectToAddNewOffer(position)}>
                                                        <Translation text="employer.positions.addOffer" />
                                                    </Button>
                                                ) : null}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody className={styles.cardBody}>
                                <div className={styles.description}>{position?.description}</div>
                            </CardBody>
                            <CardFooter className={styles.cardFooter}>
                                <div className={styles.positionFooter}>
                                    <div className={styles.label}>
                                        <p>
                                            <Translation text="employer.positions.positionDetails.location" />
                                        </p>
                                        <p className={styles.value}>
                                            <Translation text={position?.city.name} />
                                        </p>
                                    </div>
                                    <div className={styles.label}>
                                        <p>
                                            <Translation text="employer.positions.positionDetails.seniority" />
                                        </p>
                                        <p className={styles.value}>
                                            <Translation text={position?.seniority.name} />
                                        </p>
                                    </div>
                                    <div className={styles.label}>
                                        <p>
                                            <Translation text="employer.positions.positionDetails.salary" />
                                        </p>
                                        <p className={styles.value}>
                                            <Price price={{amount: position?.minimumSalary}} /> -{' '}
                                            <Price price={{amount: position?.maximumSalary}} />
                                        </p>
                                    </div>
                                </div>
                            </CardFooter>
                        </Card>

                        <Card className="position-offers-card">
                            <CardHeader>
                                <div className={styles.offersHeader}>
                                    <div className={styles.title}>
                                        <p>
                                            <Translation text="employer.positions.positionDetails.offers.title" />
                                        </p>
                                    </div>
                                    <div>
                                        {this.isOrganizationHeadHunter() ? null : (
                                            <PositionRelatedRecruiters onRecruiterChange={this.toggleInvitationModal} />
                                        )}
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <OffersList
                                    offersList={this.props.position?.soughtPositions}
                                    isOrganizationHeadhunter={this.isOrganizationHeadHunter()}
                                />
                            </CardBody>
                        </Card>
                    </div>
                </LayoutWrapper>

                {this.state.isDeleteModalShown ? (
                    <DeletePosition
                        isModalOpen={this.state.isDeleteModalShown}
                        toggleModal={this.removePosition}
                        position={this.props.position}
                    />
                ) : null}

                {this.state.isInvitationModalVisible ? (
                    <ConfirmInvitation
                        isModalOpen={this.state.isInvitationModalVisible}
                        toggleModal={this.toggleInvitationModal}
                        positionId={this.props.position?.id}
                        hrDepartmentId={this.state.selectedRecruiterId}
                    />
                ) : null}
            </>
        );
    }

    private removePosition = () => {
        this.setState({isDeleteModalShown: !this.state.isDeleteModalShown});
    };

    private isOrganizationHeadHunter = (): boolean => {
        return this.props.userRoles.includes(UserRole.HEADHUNTER);
    };

    private isOrganizationAdmin = (): boolean => {
        return this.props.userRoles.includes(UserRole.ORGANIZATION_ADMIN);
    };

    private toggleInvitationModal = (recruiterId?: string) => {
        this.setState({
            selectedRecruiterId: recruiterId ? recruiterId : null,
            isInvitationModalVisible: !this.state.isInvitationModalVisible,
        });
    };

    private redirectToEditPosition = (positionId: string | undefined) => {
        if (!positionId) {
            return;
        }

        this.props.navigate(`/panel/employer/positions/edit/${positionId}`);
    };

    private redirectToAddNewOffer = (item: IModelPosition | null) => {
        const soughtPositionId = item?.soughtPositions[0]?.id;
        this.props.navigate(`/panel/human_resources/offers/add-offer`, {state: {soughtPositionId: soughtPositionId}});
    };
}

export default connect(
    (state: RootState) => ({
        userRoles: authUserRolesSelector(state),
        authToken: authTokenSelector(state),
        position: positionDetailsSelector(state),
        isPositionLoading: isPositionDetailsLoadingSelector(state),
    }),
    {fetchPositionDetails, resetToInitialPositionDetailsState, fetchPositionRelatedHr}
)(withTranslation()(withLocation(withNavigation(PositionDetails))));
