import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IPositionDetailsState} from '../reducers/positionDetailsPageSlice';

export const selectPositionDetailsPage = (state: RootState) => {
    return state.positionDetailsPageSlice;
};

export const positionDetailsSelector = createSelector([selectPositionDetailsPage], (state: IPositionDetailsState) => state.positionDetails);

export const isPositionDetailsInitializedSelector = createSelector(
    [selectPositionDetailsPage],
    (state: IPositionDetailsState) => state.isPositionDetailsInitialized
);

export const isPositionDetailsLoadingSelector = createSelector(
    [selectPositionDetailsPage],
    (state: IPositionDetailsState) => state.isPositionDetailsLoading
);

export const positionDetailsErrorSelector = createSelector(
    [selectPositionDetailsPage],
    (state: IPositionDetailsState) => state.positionDetailsError
);

export const positionRelatedHrListSelector = createSelector(
    [selectPositionDetailsPage],
    (state: IPositionDetailsState) => state.positionRelatedHrList
);

export const isHRAddedSelector = createSelector([selectPositionDetailsPage], (state: IPositionDetailsState) => state.isHRAdded);
