import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {Translation} from 'jobhunter-common-web';
import {IModelPositionsInformation, PositionStatus} from '../../../../../model/positionsDataModel';
import {connect} from 'react-redux';
import {changeIsPositionStatusUpdated, changePositionStatus} from '../../../../../store/reducers/positionsPageSlice';
import {RootState} from '../../../../../store/reducers';
import {isPositionStatusUpdatedSelector} from '../../../../../store/selectors/positionSelectors';

interface IConnectedChangePositionStatusProps {
    readonly isPositionStatusUpdated: boolean;
    readonly changePositionStatus: typeof changePositionStatus;
    readonly changeIsPositionStatusUpdated: typeof changeIsPositionStatusUpdated;
}

interface IExternalChangePositionStatusProps {
    isModalOpen: boolean;
    toggleModal: () => void;
    position: IModelPositionsInformation | null;
}

interface IChangePositionStatusProps extends IConnectedChangePositionStatusProps, IExternalChangePositionStatusProps {}

interface IChangePositionStatusState {
    isLoading: boolean;
}

class ChangePositionStatus extends Component<IChangePositionStatusProps, IChangePositionStatusState> {
    constructor(props: IChangePositionStatusProps) {
        super(props);

        this.state = {
            isLoading: false,
        };
    }

    componentDidUpdate(prevProps: Readonly<IChangePositionStatusProps>) {
        if (this.props.isPositionStatusUpdated !== prevProps.isPositionStatusUpdated && this.props.isPositionStatusUpdated) {
            this.props.toggleModal();
        }
    }

    componentWillUnmount() {
        this.props.changeIsPositionStatusUpdated(false);
    }

    render() {
        const isPositionActive = this.props.position?.status === PositionStatus.ACTIVE;

        return (
            <Modal isOpen={this.props.isModalOpen} toggle={() => this.props.toggleModal()}>
                <ModalHeader toggle={() => this.props.toggleModal()}>
                    <p>
                        {isPositionActive ? (
                            <Translation text="employer.positions.closePosition.title" />
                        ) : (
                            <Translation text="employer.positions.openPosition.title" />
                        )}
                    </p>
                </ModalHeader>
                <ModalBody>
                    <div className="modal-description text-start">
                        <p>
                            {isPositionActive ? (
                                <Translation text="employer.positions.closePosition.description" />
                            ) : (
                                <Translation text="employer.positions.openPosition.description" />
                            )}
                        </p>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" className="close-modal-button" outline onClick={() => this.props.toggleModal()}>
                        <Translation text="buttons.no" />
                    </Button>

                    <Button color="primary" className="change-position-status-button" onClick={() => this.changePositionStatus()}>
                        <Translation text="buttons.yes" />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    private changePositionStatus = () => {
        if (null === this.props.position) {
            return;
        }
        this.props.changePositionStatus(this.props.position.id, this.props.position.status);
    };
}

export default connect((state: RootState) => ({isPositionStatusUpdated: isPositionStatusUpdatedSelector(state)}), {
    changePositionStatus,
    changeIsPositionStatusUpdated,
})(ChangePositionStatus);
