import {isNotNullOrUndefined, WithLocation, withLocation, WizardComponent, authUserRolesSelector, UserRole} from 'jobhunter-common-web';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import LayoutWrapper from '../LayoutWrapper';
import {ChevronRight, CreditCard, Home, Shield, User} from 'react-feather';
import Company from './EmployerProfileSteps/Company';
import Verification from './EmployerProfileSteps/Verification';
import CompanyDetails from './EmployerProfileSteps/CompanyDetails';
import PersonalInformation from './EmployerProfileSteps/Personal';
import {fetchEmployerPageData, resetToInitialEmployerPageState, updateEmployerPage} from '../../store/reducers/employerPageSlice';
import {employerPageLoadingSelector} from '../../store/selectors/employerPageSelectors';
import {RootState} from '../../store/reducers';

declare type WithLocationProps = typeof WithLocation;

interface IEmployerProps extends WithLocationProps {
    readonly fetchEmployerPageData: typeof fetchEmployerPageData;
    readonly updateEmployerPage: typeof updateEmployerPage;
    readonly resetToInitialEmployerPageState: typeof resetToInitialEmployerPageState;
    readonly authUserRoles: any;
}

interface IEmployerState {
    activeTab: number;
    stepper: any;
}

class Employer extends Component<IEmployerProps, IEmployerState> {
    private readonly elementRef: any;
    constructor(props: IEmployerProps) {
        super(props);

        this.state = {
            activeTab: 0,
            stepper: null,
        };
        this.elementRef = React.createRef();
    }

    componentDidMount(): void {
        this.props.fetchEmployerPageData();

        if (isNotNullOrUndefined(this.props.location) && isNotNullOrUndefined(this.props.location.state)) {
            this.setState({activeTab: this.props.location.state?.activeTab});
        }
    }

    componentWillUnmount() {
        this.props.resetToInitialEmployerPageState();
    }

    render() {
        const isAdminAccount = this.props.authUserRoles?.some((userRole: typeof UserRole) => userRole === UserRole.ORGANIZATION_ADMIN);
        const steps = [
            {
                id: 'personal_information',
                title: 'dashboard.profile.personalInformation.title',
                className: 'personal-step-button',
                // subtitle: 'Enter personal information',
                icon: <Shield size={18} />,
                content: <PersonalInformation stepper={this.state.stepper} isAdminAccount={isAdminAccount} />,
            },
            {
                id: 'company_information',
                title: 'dashboard.profile.companyInformation.title',
                className: 'company-info-step-button',
                // subtitle: 'Enter Company information',
                icon: <User size={18} />,
                content: <Company stepper={this.state.stepper} />,
            },
            {
                id: 'verification',
                title: 'dashboard.profile.verification.title',
                className: 'verification-step-button',
                // subtitle: 'Add company verification documents',
                icon: <Home size={18} />,
                content: <Verification stepper={this.state.stepper} isAdminAccount={isAdminAccount} />,
            },
            {
                id: 'company_details',
                title: 'dashboard.profile.companyDetails.title',
                className: 'company-details-step-button',
                // subtitle: 'Add company details information',
                icon: <CreditCard size={18} />,
                content: <CompanyDetails stepper={this.state.stepper} isAdminAccount={isAdminAccount} />,
            },
        ];

        if (this.props.authUserRoles && !isAdminAccount) {
            steps.splice(1, 1);
        }
        return (
            <LayoutWrapper>
                <div className="modern-vertical-wizard">
                    <WizardComponent
                        type="modern-vertical"
                        className="employer-wizard"
                        ref={this.elementRef}
                        steps={steps}
                        options={{linear: false}}
                        separator={<ChevronRight size={17} />}
                        instance={(el: any) => this.setState({stepper: el})}
                        activeTab={this.state.activeTab}
                    />
                </div>
            </LayoutWrapper>
        );
    }
}

export default connect(
    (state: RootState) => ({
        isLoading: employerPageLoadingSelector(state),
        authUserRoles: authUserRolesSelector(state),
    }),
    {
        updateEmployerPage,
        fetchEmployerPageData,
        resetToInitialEmployerPageState,
    }
)(withLocation(Employer));
