import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IOffersPageState} from '../reducers/offersPageSlice';

export const selectOffersPage = (state: RootState): IOffersPageState => {
    return state.offersPage;
};

export const offersSelector = createSelector([selectOffersPage], (state: IOffersPageState) => state.offers);

export const offersMetadataSelector = createSelector([selectOffersPage], (state: IOffersPageState) => state.offersMetadata);

export const offersPaginationSelector = createSelector([selectOffersPage], (state: IOffersPageState) => state.pagination);

export const isOffersPageLoadingSelector = createSelector([selectOffersPage], (state: IOffersPageState) => state.isOffersPageLoading);

export const isOffersPageInitializedSelector = createSelector(
    [selectOffersPage],
    (state: IOffersPageState) => state.isOffersPageInitialized
);

export const isOfferActionProcessingSelector = createSelector(
    [selectOffersPage],
    (state: IOffersPageState) => state.isOfferActionProcessing
);

export const isOfferActionCompleteSelector = createSelector([selectOffersPage], (state: IOffersPageState) => state.isOfferActionComplete);

export const offerFiltersSelector = createSelector([selectOffersPage], (state: IOffersPageState) => state.offersFilters);

export const offersErrorSelector = createSelector([selectOffersPage], (state: IOffersPageState) => state.offersError);
