import {Observable} from 'rxjs';
import {jobHunterAPI, RestQueryParams} from 'jobhunter-common-web';
import {IUpdatePositionPayload} from '../model/positionsDataModel';

export function updatePositionAPI(authToken: string | null, payload: IUpdatePositionPayload, positionId: string): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return jobHunterAPI.put(`api/positions/${positionId}`, payload, new RestQueryParams(), headers);
}
