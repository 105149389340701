import {PayloadAction} from '@reduxjs/toolkit';
import {
    addAlert,
    AlertType,
    authAccountIdSelector,
    authTokenSelector,
    mapResponseAccountToOrganizationFullInfo,
    setAccountState,
} from 'jobhunter-common-web';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {of} from 'rxjs';
import {catchError, mergeMap, switchMap} from 'rxjs/operators';
import {getAccountDataAPI} from '../../api/getAccountData';
import {IEmployerPageUpdatePayload, updateCompanyAccountDataAPI} from '../../api/updateCompanyAccountData';
import {IUpdateAccountPayload} from '../../api/updateFullAccountData';
import {RootState} from '../reducers';
import {
    changeEmployerError,
    changeIsEmployerLoading,
    fetchEmployerPageData,
    ICompanyDetails,
    ICompanyInformation,
    IEmployerData,
    IEmployerInformation,
    IOrganizationVerificationDocuments,
    setEmployerData,
    submitCompanyDetails,
    submitCompanyInformation,
    submitPersonalInformation,
    submitVerificationFiles,
    updateEmployerPage,
} from '../reducers/employerPageSlice';
import {employerPageSelector} from '../selectors/employerPageSelectors';

export interface IEmployerUpdatePayload {
    employerInformation: IEmployerInformation | null;
    companyInformation: ICompanyInformation | null;
    verificationFiles: IOrganizationVerificationDocuments | null;
    companyDetails: ICompanyDetails | null;
}

const enum EmployerPagePayloadType {
    EMPLOYER_INFORMATION = 'EMPLOYER_INFORMATION',
    COMPANY_INFORMATION = 'COMPANY_INFORMATION',
    VERIFICATION_FILES = 'VERIFICATION_FILES',
    COMPANY_DETAILS = 'COMPANY_DETAILS',
    GENERAL = 'GENERAL',
}

const updateEmployerPageEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(updateEmployerPage.type),
        switchMap((action: PayloadAction<any>): any => {
            return updateAccountData(state$, action.payload, EmployerPagePayloadType.GENERAL);
        }),
        catchError((error) => {
            return of(addAlert({message: getErrorMessage(error), type: AlertType.WARNING}), changeIsEmployerLoading(false));
        })
    );

const updateCompanyInformationEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(submitCompanyInformation.type),
        switchMap((action: PayloadAction<any>): any => {
            return updateAccountData(state$, action.payload, EmployerPagePayloadType.COMPANY_INFORMATION);
        }),
        catchError((error) => {
            return of(addAlert({message: getErrorMessage(error), type: AlertType.WARNING}), changeIsEmployerLoading(false));
        })
    );

const updatePersonalInformationEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(submitPersonalInformation.type),
        switchMap((action: PayloadAction<any>): any => {
            return updateAccountData(state$, action.payload, EmployerPagePayloadType.EMPLOYER_INFORMATION);
        }),
        catchError((error) => {
            return of(addAlert({message: getErrorMessage(error), type: AlertType.WARNING}), changeIsEmployerLoading(false));
        })
    );

const updateCompanyDetailsEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(submitCompanyDetails.type),
        switchMap((action: PayloadAction<any>): any => {
            return updateAccountData(state$, action.payload, EmployerPagePayloadType.COMPANY_DETAILS);
        }),
        catchError((error) => {
            return of(addAlert({message: getErrorMessage(error), type: AlertType.WARNING}), changeIsEmployerLoading(false));
        })
    );

const updateVerificationFilesEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(submitVerificationFiles.type),
        switchMap((action: PayloadAction<any>): any => {
            return updateAccountData(state$, action.payload, EmployerPagePayloadType.VERIFICATION_FILES);
        }),
        catchError((error) => {
            return of(addAlert({message: getErrorMessage(error), type: AlertType.WARNING}), changeIsEmployerLoading(false));
        })
    );

const setEmployerDataEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(fetchEmployerPageData.type),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value),
                accountId = authAccountIdSelector(state$.value);
            if (accountId === null) {
                return of();
            }

            return getAccountDataAPI(authToken, accountId).pipe(
                mergeMap((resp: any) => {
                    const employerData = mapDataFromServerToEmployerData(resp),
                        account = {
                            candidateFullInfo: null,
                            organizationFullInfo: mapResponseAccountToOrganizationFullInfo(resp),
                        };
                    return of(setEmployerData(employerData), changeIsEmployerLoading(false), setAccountState(account));
                })
            );
        }),
        catchError((error) => {
            return of(addAlert({message: getErrorMessage(error), type: AlertType.WARNING}), changeIsEmployerLoading(false));
        })
    );

const updateAccountData = (state$: StateObservable<RootState>, payload: IUpdateAccountPayload, payloadType: EmployerPagePayloadType) => {
    const authToken = authTokenSelector(state$.value),
        accountId = authAccountIdSelector(state$.value);
    if (accountId === null) {
        return;
    }
    changeIsEmployerLoading(true);
    const convertedPayload = mapDataToAccountPayload(payload, state$, payloadType);
    return updateCompanyAccountDataAPI(authToken, accountId, convertedPayload).pipe(
        switchMap((response: any) => {
            const employerData = mapDataFromServerToEmployerData(response),
                account = {
                    candidateFullInfo: null,
                    organizationFullInfo: mapResponseAccountToOrganizationFullInfo(response),
                };
            return of(
                addAlert({message: `alerts.employer.uploadSuccess.${payloadType.toLowerCase()}`, type: AlertType.SUCCESS}),
                setEmployerData(employerData),
                changeIsEmployerLoading(false),
                setAccountState(account)
            );
        }),
        catchError((error: any) => {
            return of(...fetchListErrorActions(error, changeEmployerError, changeIsEmployerLoading));
        })
    );
};

// update state without sending update to server
// update general info
// update preferences
// send update account to server

const mapDataToAccountPayload = (
    payload: {[key: string]: any},
    state: StateObservable<RootState>,
    payloadType: EmployerPagePayloadType
): any => {
    const employerPageInfo = employerPageSelector(state.value),
        employerInformation = employerPageInfo.employerInformation,
        companyInformation = employerPageInfo.companyInformation,
        verificationFiles = employerPageInfo.verificationFiles,
        companyDetails = employerPageInfo.companyDetails,
        verificationFilesIdArray = verificationFiles?.files ? verificationFiles.files.map((file: any) => file.file.id) : [],
        citiesIdArray = companyDetails?.cities ? companyDetails.cities.map((city: any) => (city.id ? city.id : city)) : [],
        companyTypesIdArray = companyDetails?.companyTypes
            ? companyDetails.companyTypes.map((companyType: any) => (companyType.id ? companyType.id : companyType))
            : [],
        industriesIdArray =
            companyDetails && companyDetails.industries
                ? companyDetails.industries.map((industry: any) => (industry.id ? industry.id : industry))
                : [];

    const convertedPayload: IEmployerPageUpdatePayload = {
        organization: {
            organization: {
                name: companyInformation?.name ? companyInformation.name : null,
                mobile: companyInformation?.mobile ? companyInformation.mobile : null,
                address: companyInformation?.address ? companyInformation.address : null,
                cityName: companyInformation?.cityName ? companyInformation.cityName : null,
                countryId: companyInformation?.country?.id ? companyInformation.country.id : null,
                avatar: companyInformation?.avatar?.id ? companyInformation.avatar.id : null,
                about: companyInformation?.about ? companyInformation.about : null,
                organizationVerificationFiles: verificationFilesIdArray,
            },
            organizationDetails: {
                cities: citiesIdArray,
                companyTypes: companyTypesIdArray,
                industries: industriesIdArray,
                organizationSizeId: companyDetails?.organizationSize?.id ? companyDetails.organizationSize.id : '',
            },
        },
        firstName: employerInformation?.firstName ? employerInformation.firstName : null,
        lastName: employerInformation?.lastName ? employerInformation.lastName : null,
        phone: employerInformation?.phone ? employerInformation.phone : null,
        birthDate: employerInformation?.birthDate ? employerInformation.birthDate : null,
        cityName: employerInformation?.city ? employerInformation.city : null,
        countryId: employerInformation?.country?.id ? employerInformation.country.id : null,
        about: null,
        avatar: employerInformation?.avatar?.id ? employerInformation.avatar.id : null,
    };

    switch (payloadType) {
        case EmployerPagePayloadType.COMPANY_INFORMATION:
            convertedPayload.organization.organization.name = payload.name;
            convertedPayload.organization.organization.mobile = payload.mobile;
            convertedPayload.organization.organization.address = payload.address;
            convertedPayload.organization.organization.cityName = payload.cityName;
            convertedPayload.organization.organization.countryId =
                payload.country && payload.country.id ? payload.country.id : payload.country;
            convertedPayload.organization.organization.avatar = payload.avatar ? payload.avatar : convertedPayload.avatar;

            break;
        case EmployerPagePayloadType.COMPANY_DETAILS:
            const organizationDetailsPayload = {
                cities: payload.cities ? payload.cities : [],
                companyTypes: payload.companyTypes ? payload.companyTypes : [],
                industries: payload.industries ? payload.industries : [],
                organizationSizeId: payload.organizationSize ? payload.organizationSize : [],
            };
            convertedPayload.organization.organizationDetails = organizationDetailsPayload;

            break;
        case EmployerPagePayloadType.VERIFICATION_FILES:
            const verificationFilesIdArray = payload ? payload.map((file: any) => file.file.id) : [];
            convertedPayload.organization.organization.organizationVerificationFiles = verificationFilesIdArray;

            break;
        case EmployerPagePayloadType.EMPLOYER_INFORMATION:
            convertedPayload.firstName = payload.firstName ? payload.firstName : convertedPayload.firstName;
            convertedPayload.lastName = payload.lastName ? payload.lastName : convertedPayload.lastName;
            convertedPayload.phone = payload.phone ? payload.phone : convertedPayload.phone;
            convertedPayload.birthDate = payload.birthDate ? payload.birthDate : convertedPayload.birthDate;
            convertedPayload.cityName = payload.cityName ? payload.cityName : convertedPayload.cityName;
            convertedPayload.countryId = payload.country ? payload.country : convertedPayload.countryId;
            convertedPayload.about = payload.description ? payload.description : convertedPayload.about;
            convertedPayload.avatar = payload.avatar ? payload.avatar : convertedPayload.avatar;

            break;
        default:
            return;
    }
    return convertedPayload;
};

const mapDataFromServerToEmployerData = (account: {[key: string]: any}): IEmployerData => {
    const organizationFullInfo = account.organizationFullInfo,
        organizationUser = account.organizationFullInfo.user,
        organizationCompanyInfo = organizationFullInfo.organization.organizationCompany,
        organizationDetails = organizationFullInfo.organization.organizationDetails;

    const employerInformation: IEmployerInformation = {
            firstName: organizationUser?.firstName ? organizationUser.firstName : null,
            lastName: organizationUser?.lastName ? organizationUser.lastName : null,
            phone: organizationUser?.phone ? organizationUser.phone : null,
            city: organizationUser?.cityName ? organizationUser.cityName : null,
            country: organizationUser?.country ? organizationUser.country : null,
            email: organizationUser?.email ? organizationUser.email : null,
            avatar: organizationUser?.avatar ? organizationUser.avatar : null,
            id: organizationUser?.id ? organizationUser.id : null,
            birthDate: organizationUser?.birthDate ? organizationUser.birthDate : null,
        },
        companyInformation: ICompanyInformation = {
            address: organizationCompanyInfo ? organizationCompanyInfo.address : null,
            avatar: organizationCompanyInfo ? organizationCompanyInfo.avatar : null,
            cityName: organizationCompanyInfo ? organizationCompanyInfo.city : null,
            country: organizationCompanyInfo ? organizationCompanyInfo.country : null,
            about: organizationCompanyInfo ? organizationCompanyInfo.description : null,
            name: organizationCompanyInfo ? organizationCompanyInfo.name : null,
            mobile: organizationCompanyInfo ? organizationCompanyInfo.phone : null,
            // vatNumber: organizationCompanyInfo ? organizationCompanyInfo.vatNumber : null,
        },
        verificationFiles: IOrganizationVerificationDocuments = organizationFullInfo.verification
            ? organizationFullInfo.verification
            : null,
        companyDetails: ICompanyDetails = {
            cities: organizationDetails ? organizationDetails.cities : null,
            companyTypes: organizationDetails ? organizationDetails.companyTypes : null,
            industries: organizationDetails ? organizationDetails.industries : null,
            organizationSize: organizationDetails ? organizationDetails.organizationSize : null,
        };
    const employerDataPayload: IEmployerData = {
        employerInformation: employerInformation,
        companyInformation: companyInformation,
        verificationFiles: verificationFiles,
        companyDetails: companyDetails,
    };
    return employerDataPayload;
};

export const fetchListErrorActions = (error: any, setSliceError: any, setSliceIsLoading: any): any[] => {
    return [
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
        setSliceError(getErrorMessage(error)),
        setSliceIsLoading(false),
    ];
};

export const getErrorMessage = (error: any) => {
    let errorMessage;
    if (error.response && error.response.message) {
        errorMessage = error.response.message;
    } else if (error.response && error.response['hydra:description']) {
        errorMessage = error.response['hydra:description'];
    } else {
        errorMessage = 'Something went wrong. Please try again later.';
    }

    return errorMessage;
};

const employerPageEpic = combineEpics(
    updateEmployerPageEpic,
    updatePersonalInformationEpic,
    updateCompanyDetailsEpic,
    updateCompanyInformationEpic,
    updateVerificationFilesEpic,
    setEmployerDataEpic
);

export default employerPageEpic;
