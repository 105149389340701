import {
    FormControlType,
    IFormConfig,
    InputType,
    ValidationRules,
    collectionInputDataMapper,
    collectionOutputDataMapper,
    MultiSelectType,
    IMultiselectOption,
    FormButtonType,
} from 'jobhunter-common-web';

export const addRelocationEventFormConfig: typeof IFormConfig = (
    eventTypes: typeof IMultiselectOption[] | [],
    dismissModal: () => void,
    value?: any
) => ({
    controlType: 'form',
    class: 'default-form',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'title',
            controlType: 'group',
            class: 'row',
            controls: {
                title: {
                    controlType: 'control',
                    defaultValue: value ? value.name : '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [],
                    placeholder: 'relocationView.calendar.addEvent.form.placeholders.title',
                    isLabelHidden: false,
                    label: 'relocationView.calendar.addEvent.form.labels.title',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12 form-control',
                },
            },
        },
        {
            key: 'description',
            controlType: 'group',
            class: 'row',
            controls: {
                description: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.RICHTEXT,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}, {name: ValidationRules.MAX_LENGTH, params: {length: 2000}}],
                    placeholder: 'relocationView.calendar.addEvent.form.placeholders.description',
                    label: 'relocationView.calendar.addEvent.form.labels.description',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12 form-control',
                },
            },
        },
        {
            key: 'date_type',
            controlType: 'group',
            class: 'row',
            controls: {
                date: {
                    controlType: 'control',
                    defaultValue: '',
                    additionalStyles: '',
                    formControlType: FormControlType.DATE,
                    validationRules: [
                        {
                            name: ValidationRules.IS_DATE_GTE_THAN,
                            params: {
                                valueAccessor: (data: any) => data,
                                compareAccessor: () => new Date(),
                            },
                        },
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'relocationView.calendar.addEvent.form.placeholders.date',
                    isLabelHidden: false,
                    label: 'relocationView.calendar.addEvent.form.labels.date',
                    // maxDate: new Date(),
                    type: InputType.DATE,
                    dateFormat: 'dd/MM/yyyy HH:mm',
                    // minDate: new Date(1920, 1, 1),
                    // showYearDropdown: true,
                    // showMonthDropdown: true,
                    openToDate: new Date(),
                    hostClass: 'col-xl-6 form-control required',
                    inputStyles: 'date-time-picker',
                    isTimePicker: true,
                },
                type: {
                    controlType: 'control',
                    defaultValue: value ? value.city : '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    multiselectOptions: eventTypes ? eventTypes : [],
                    validationRules: [
                        // {name: ValidationRules.IS_REQUIRED}
                    ],
                    placeholder: 'relocationView.calendar.addEvent.form.labels.type',
                    isLabelHidden: false,
                    label: 'relocationView.calendar.addEvent.form.placeholders.type',
                    hostClass: 'col-xl-6 form-control',
                    inputDataMapper: collectionInputDataMapper,
                    outputDataMapper: collectionOutputDataMapper,
                },
            },
        },
        {
            key: 'submit',
            controlType: 'group',
            class: 'row justify-content-end',
            controls: {
                closeModalButton: {
                    buttonName: 'previous',
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.BUTTON,
                    inputStyles: 'btn-prev btn-outline-primary d-flex align-items-center',
                    hostClass: 'mb-0 w-auto',
                    defaultContainerStyles: '',
                    containerStyles: '',
                    defaultValue: null,
                    btnText: 'buttons.cancel',
                    buttonDisabled: false,
                    customClickHandler: () => {
                        return dismissModal();
                    },
                },
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    inputStyles: 'btn-primary',
                    defaultContainerStyles: '',
                    hostClass: 'mb-0 mt-1 align-items-end w-auto',
                    containerStyles: '',
                    defaultValue: null,
                    isLabelHidden: true,
                    btnText: 'buttons.add',
                    buttonDisabled: (mappedOutputValue: any, isFormValid: boolean) => {
                        return !isFormValid;
                    },
                },
            },
        },
    ],
});
