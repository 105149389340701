import {PayloadAction} from '@reduxjs/toolkit';
import {
    addAlert,
    AlertType,
    authTokenSelector,
    flattenObj,
    getErrorMessage,
    getMetadataDetails,
    RestQueryParams,
} from 'jobhunter-common-web';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {addServiceAPI} from '../../api/addServiceAPI';
import {deleteServiceAPI} from '../../api/deleteServiceAPI';
import {getUserServicesAPI} from '../../api/getUserServicesAPI';
import {RootState} from '../reducers';
import {
    addService,
    changeServiceAdded,
    changeServiceRemoved,
    changeServicesPageLoading,
    changeServicesPagination,
    deleteService,
    fetchServicesList,
    IAddService,
    IDeleteService,
    IFetchServiceList,
    setServicesList,
    setServicesMetadata,
} from '../reducers/myServicesSlice';
import {servicesPaginationSelector} from '../selectors/myServicesSelectors';

const fetchServicesListEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getServices(action$, state$, fetchServicesList);
};

const fetchServicesListOnPaginationChangeEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getServices(action$, state$, changeServicesPagination);
};

const addNewServiceEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(addService.type),
        switchMap((action: PayloadAction<IAddService>): any => {
            const authToken = authTokenSelector(state$.value);
            return addServiceAPI(authToken, action.payload.service).pipe(
                switchMap(() => {
                    const actions = successActions([changeServiceAdded(true)], 'create');
                    actions.push(fetchServicesList(null));
                    return of(...actions);
                }),
                catchError((error) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

const removeServiceEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(deleteService.type),
        switchMap((action: PayloadAction<IDeleteService>): any => {
            const authToken = authTokenSelector(state$.value);
            return deleteServiceAPI(authToken, action.payload.serviceId).pipe(
                switchMap(() => {
                    const actions = successActions([changeServiceRemoved(true)], 'delete');
                    actions.push(fetchServicesList(null));
                    return of(...actions);
                }),
                catchError((error) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

const getServices = (action$: Observable<any>, state$: StateObservable<RootState>, actionType: any) => {
    return action$.pipe(
        ofType(actionType),
        switchMap((action: PayloadAction<IFetchServiceList>): any => {
            const authToken = authTokenSelector(state$.value),
                paginationParams = servicesPaginationSelector(state$.value),
                pagination = flattenObj(paginationParams);
            let queryParams = new RestQueryParams(pagination).add('order[createdAt]', 'desc');
            if (action.payload.params) {
                const params = action.payload.params;
                Object.keys(params).forEach((key) => {
                    return (queryParams = queryParams.add(String(key), String(params[key])));
                });
            }

            return getUserServicesAPI(authToken, queryParams).pipe(
                switchMap((resp: any) => {
                    const metadata = getMetadataDetails(resp['hydra:view']),
                        actions = successActions([setServicesList(resp[`hydra:member`]), setServicesMetadata(metadata)]);
                    return of(...actions);
                }),
                catchError((error) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

const successActions = (changeSliceList?: any[], actionType?: string): any[] => {
    const actions = [changeServicesPageLoading(false)];
    if (changeSliceList) {
        return actions.concat(changeSliceList);
    }
    if (actionType) actions.push(addAlert({message: `alerts.services.success.${actionType}`, type: AlertType.SUCCESS}));
    return actions;
};

const errorActions = (error: any): any[] => {
    return [changeServicesPageLoading(false), addAlert({message: getErrorMessage(error), type: AlertType.WARNING})];
};

const myServicesEpic = combineEpics(fetchServicesListEpic, fetchServicesListOnPaginationChangeEpic, addNewServiceEpic, removeServiceEpic);

export default myServicesEpic;
