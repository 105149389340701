import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {Translation} from 'jobhunter-common-web';
import styles from './styles.module.scss';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {isOfferApplicationCandidateRejectedSelector} from '../../../../store/selectors/dashboardSelectors';

interface IConnectedRejectApplicantProps {
    readonly isCandidateRejected: boolean;
}

interface IExternalRejectApplicantProps {
    isModalOpen: boolean;
    toggleModal: () => void;
    rejectCandidate: () => void;
}

interface IRejectApplicantProps extends IConnectedRejectApplicantProps, IExternalRejectApplicantProps {}

interface IRejectApplicantState {
    isLoading: boolean;
}

class RejectApplicantModal extends Component<IRejectApplicantProps, IRejectApplicantState> {
    constructor(props: IRejectApplicantProps) {
        super(props);

        this.state = {
            isLoading: false,
        };
    }

    componentDidUpdate(prevProps: IRejectApplicantProps): void {
        if (this.props.isCandidateRejected !== prevProps.isCandidateRejected && this.props.isCandidateRejected) {
            this.props.toggleModal();
        }
    }

    render() {
        return (
            <Modal isOpen={this.props.isModalOpen} toggle={() => this.props.toggleModal()}>
                <ModalHeader toggle={() => this.props.toggleModal()}>
                    <p className={styles.warningHeader}>
                        <Translation text="dashboard.offerApplications.rejectApplicantModal.title" />
                    </p>
                </ModalHeader>
                <ModalBody>
                    <div className={styles.modalContent}>
                        <p>
                            <Translation text="dashboard.offerApplications.rejectApplicantModal.description" />
                        </p>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" className="close-modal-button" outline onClick={() => this.props.toggleModal()}>
                        <Translation text="buttons.no" />
                    </Button>
                    <Button color="primary" className="reject-aplicant-button" onClick={() => this.props.rejectCandidate()}>
                        <Translation text="buttons.yes" />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default connect((state: RootState) => ({
    isCandidateRejected: isOfferApplicationCandidateRejectedSelector(state),
}))(RejectApplicantModal);
