import {createSelector} from '@reduxjs/toolkit';
import {IEmployerState} from '../reducers/employerPageSlice';

export const selectEmployerPage = (state: any): IEmployerState => {
    return state.employerPage;
};

export const employerPageSelector = createSelector([selectEmployerPage], (state: IEmployerState) => state);
export const employerInformationSelector = createSelector([selectEmployerPage], (state: IEmployerState) => state.employerInformation);
export const companyInformationSelector = createSelector([selectEmployerPage], (state: IEmployerState) => state.companyInformation);
export const verificationFilesSelector = createSelector([selectEmployerPage], (state: IEmployerState) => state.verificationFiles);
export const companyDetailsSelector = createSelector([selectEmployerPage], (state: IEmployerState) => state.companyDetails);

export const employerPageLoadingSelector = createSelector([selectEmployerPage], (state: IEmployerState) => state.isEmployerLoading);

export const employerPageInitializedSelector = createSelector([selectEmployerPage], (state: IEmployerState) => state.isEmployerInitialized);

export const employerPageErrorSelector = createSelector([selectEmployerPage], (state: IEmployerState) => state.employerError);
