import {Observable} from 'rxjs';
import {jobHunterAPI, RestQueryParams} from 'jobhunter-common-web';

export interface IUpdateBillingInformationPayload {
    companyName: string;
    email: string;
    taxId: number;
    mobile: number;
    address: string;
    state: string;
    city: string | null;
    zip: number;
    countryId: number;
}

export function updateBillingInfoAPI(
    authToken: string | null,
    payload: IUpdateBillingInformationPayload,
    accountId: string
): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return jobHunterAPI.put(`api/accounts/${accountId}/change_billing_info`, payload, new RestQueryParams(), headers);
}
