import {
    authTokenSelector,
    Form,
    FormControlChangeType,
    IFormConfig,
    IOrganizationFullInfo,
    organizationFullInfoSelector,
    Translation,
    verificationFileTypesSelector,
    addVerificationFileAPI,
} from 'jobhunter-common-web';
import {Component} from 'react';
import {connect} from 'react-redux';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {IModelDictionaryDatum} from '../../../../../model/dictionaryDatum';
import {RootState} from '../../../../../store/reducers';
import {fetchEmployerPageData} from '../../../../../store/reducers/employerPageSlice';
import {addVerificationDocumentFormConfig} from './addVerificationDocumentFormConfig';

interface IConnectedVerificationDocumentProps {
    readonly authToken: string | null;
    readonly fetchEmployerPageData: typeof fetchEmployerPageData;
    readonly verificationFileTypes: IModelDictionaryDatum[] | null;
    readonly organizationFullInfo: typeof IOrganizationFullInfo | null;
}

interface IExternalAddVerificationDocumentProps {
    isModalOpen: boolean;
    toggleModal: () => void;
}

interface IAddVerificationDocumentProps extends IConnectedVerificationDocumentProps, IExternalAddVerificationDocumentProps {}

interface IAddVerificationDocumentState {
    value: any;
    formConfig: typeof IFormConfig | null;
    isLoading: boolean;
}

class AddVerificationDocument extends Component<IAddVerificationDocumentProps, IAddVerificationDocumentState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IAddVerificationDocumentProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: null,
            isLoading: false,
        };
    }

    componentDidMount(): void {
        this.setFormConfig();
        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    tap((data: any) => this.setState({value: data.value}))
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: Readonly<IAddVerificationDocumentProps>) {
        if (this.props.verificationFileTypes !== prevProps.verificationFileTypes) {
            this.setFormConfig();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <Modal contentClassName="add-verification-file-modal" isOpen={this.props.isModalOpen} toggle={() => this.props.toggleModal()}>
                <ModalHeader toggle={() => this.props.toggleModal()}>
                    <Translation text="employer.verification.addDocument.title" />
                </ModalHeader>
                <ModalBody>
                    <p className="modal-description">
                        <Translation text="employer.verification.addDocument.description" />
                    </p>
                    {this.state.formConfig && (
                        <Form
                            config={this.state.formConfig}
                            onValueStateChange={this.onValueStateChange}
                            value={this.state.value}
                            controlName={'addVerificationForm'}
                            onButtonClicked={this.addVerificationDocument}
                        />
                    )}
                </ModalBody>
            </Modal>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private setFormConfig = () => {
        const verificationFileTypes = this.props.verificationFileTypes ? this.props.verificationFileTypes : [];

        this.setState({
            formConfig: addVerificationDocumentFormConfig(verificationFileTypes),
        });
    };

    private addVerificationDocument = () => {
        const verificationFile = this.state.value.document,
            organizationId = this.props.organizationFullInfo.id,
            verificationFileTypeId = this.state.value.documentType;

        if (verificationFile && verificationFile.length && organizationId && verificationFileTypeId && this.props.authToken) {
            const formData = new FormData();
            formData.append('file', verificationFile[0].blobFile);
            formData.append('organizationId', organizationId);
            formData.append('typeId', verificationFileTypeId);

            this.setState({isLoading: true});
            this.subscriptions.push(
                addVerificationFileAPI(this.props.authToken, formData)
                    .pipe(
                        tap(() => {
                            this.props.fetchEmployerPageData();
                            this.props.toggleModal();
                        })
                    )
                    .subscribe()
            );
        }
    };
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        verificationFileTypes: verificationFileTypesSelector(state),
        organizationFullInfo: organizationFullInfoSelector(state),
    }),
    {
        fetchEmployerPageData,
    }
)(AddVerificationDocument);
