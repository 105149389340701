import {PayloadAction} from '@reduxjs/toolkit';
import {
    addAlert,
    AlertType,
    authTokenSelector,
    flattenObj,
    getErrorMessage,
    getMetadataDetails,
    RestQueryParams,
} from 'jobhunter-common-web';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {getPurchasedServicesAPI} from '../../api/getPurchasedServicesAPI';
import {RootState} from '../reducers';
import {
    changePurchasedServicesPageLoading,
    changePurchasedServicesPagination,
    fetchPurchasedServicesList,
    IFetchPurchasedServiceList,
    setPurchasedServicesList,
    setPurchasedServicesMetadata,
} from '../reducers/purchasedServicesSlice';
import {purchasedServicesPaginationSelector} from '../selectors/purchasedServicesSelectors';

const fetchPurchasedServicesListEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getPurchasedServices(action$, state$, fetchPurchasedServicesList);
};

const fetchServicesListOnPaginationChangeEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getPurchasedServices(action$, state$, changePurchasedServicesPagination);
};

const getPurchasedServices = (action$: Observable<any>, state$: StateObservable<RootState>, actionType: any) => {
    return action$.pipe(
        ofType(actionType),
        switchMap((action: PayloadAction<IFetchPurchasedServiceList>): any => {
            const authToken = authTokenSelector(state$.value),
                paginationParams = purchasedServicesPaginationSelector(state$.value),
                pagination = flattenObj(paginationParams);
            let queryParams = new RestQueryParams(pagination).add('order[createdAt]', 'desc');
            if (action.payload.params) {
                const params = action.payload.params;
                Object.keys(params).forEach((key) => {
                    return (queryParams = queryParams.add(String(key), String(params[key])));
                });
            }
            return getPurchasedServicesAPI(authToken, queryParams).pipe(
                switchMap((resp: any) => {
                    const metadata = getMetadataDetails(resp['hydra:view']);
                    const actions = successActions([
                        setPurchasedServicesList(resp[`hydra:member`]),
                        setPurchasedServicesMetadata(metadata),
                    ]);
                    return of(...actions);
                }),
                catchError((error) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

const successActions = (changeSliceList?: any[], actionType?: string): any[] => {
    const actions = [changePurchasedServicesPageLoading(false)];
    if (changeSliceList) {
        return actions.concat(changeSliceList);
    }
    if (actionType) actions.push(addAlert({message: `alerts.services.success.${actionType}`, type: AlertType.SUCCESS}));
    return actions;
};

const errorActions = (error: any): any[] => {
    return [changePurchasedServicesPageLoading(false), addAlert({message: getErrorMessage(error), type: AlertType.WARNING})];
};

const purchasedServicesEpic = combineEpics(fetchPurchasedServicesListEpic, fetchServicesListOnPaginationChangeEpic);

export default purchasedServicesEpic;
