import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelPosition, IModelPositionRelatedHr, IUpdatePositionPayload} from '../../model/positionsDataModel';

export interface IPositionDetailsError {
    positionDetailsError: string | null;
}

export interface IPositionDetailsIsLoading {
    isPositionDetailsLoading: boolean;
}

export interface IPositionDetailsIsInitialized {
    isPositionDetailsInitialized: boolean;
}

export interface ISetPositionDetails {
    positionDetails: IModelPosition[] | null;
}

export interface IFetchPosition {
    positionId: string;
}

export interface ISetPositionRelatedHrList {
    hrList: IModelPositionRelatedHr[];
}

export interface IUpdatePosition {
    positionId: string;
    updatePayload: IUpdatePositionPayload;
}

export interface IChangeIsPositionRemoved {
    isPositionRemoved: boolean;
}

export interface IAddHRToPosition {
    positionId: string;
    hrDepartmentId: string;
}

export interface IChangeIsHRAdded {
    isHRAdded: boolean;
}
export interface IPositionDetailsState {
    positionDetails: IModelPosition[] | null;
    positionRelatedHrList: IModelPositionRelatedHr[] | null;
    isPositionDetailsLoading: boolean;
    isPositionDetailsInitialized: boolean;
    isPositionRemoved: boolean;
    positionDetailsError: string | null;
    isHRAdded: boolean;
}

const initialState: IPositionDetailsState = {
    positionDetails: null,
    positionRelatedHrList: null,
    isPositionDetailsInitialized: false,
    isPositionDetailsLoading: false,
    isPositionRemoved: false,
    positionDetailsError: null,
    isHRAdded: false,
};

const positionDetailsPageSlice = createSlice({
    name: 'positionDetails',
    initialState: initialState,
    reducers: {
        changeIsHRAdded: {
            reducer: (state: IPositionDetailsState, action: PayloadAction<IChangeIsHRAdded>) => {
                return {
                    ...state,
                    isHRAdded: action.payload.isHRAdded,
                };
            },
            prepare: (isHRAdded: boolean) => {
                return {
                    payload: {
                        isHRAdded: isHRAdded,
                    },
                };
            },
        },
        addHRToPosition: {
            reducer: (state: IPositionDetailsState) => {
                return {
                    ...state,
                };
            },
            prepare: (positionId: string | null | undefined, hrDepartmentId: string | null) => {
                return {
                    payload: {
                        positionId: positionId,
                        hrDepartmentId: hrDepartmentId,
                    },
                };
            },
        },
        setPositionDetails: {
            reducer: (state: IPositionDetailsState, action: PayloadAction<ISetPositionDetails>) => {
                return {
                    ...state,
                    positionDetails: action.payload.positionDetails,
                };
            },
            prepare: (positionDetails: IModelPosition[] | null) => {
                return {
                    payload: {
                        positionDetails: positionDetails,
                    },
                };
            },
        },
        changeIsPositionDetailsInitialized: {
            reducer: (state: IPositionDetailsState, action: PayloadAction<IPositionDetailsIsInitialized>) => {
                return {
                    ...state,
                    isPositionDetailsInitialized: action.payload.isPositionDetailsInitialized,
                };
            },
            prepare: (isPositionDetailsInitialized: boolean) => {
                return {
                    payload: {
                        isPositionDetailsInitialized: isPositionDetailsInitialized,
                    },
                };
            },
        },
        changeIsPositionDetailsLoading: {
            reducer: (state: IPositionDetailsState, action: PayloadAction<IPositionDetailsIsLoading>) => {
                return {
                    ...state,
                    isPositionDetailsLoading: action.payload.isPositionDetailsLoading,
                };
            },
            prepare: (isPositionDetailsLoading: boolean) => {
                return {
                    payload: {
                        isPositionDetailsLoading: isPositionDetailsLoading,
                    },
                };
            },
        },
        fetchPositionDetails: {
            reducer: (state: IPositionDetailsState) => {
                return {
                    ...state,
                    isPositionDetailsLoading: true,
                };
            },
            prepare: (positionId: string) => {
                return {
                    payload: {positionId: positionId},
                };
            },
        },
        changePositionDetailsPageError: {
            reducer: (state: IPositionDetailsState, action: PayloadAction<IPositionDetailsError>) => {
                return {
                    ...state,
                    positionDetailsError: action.payload.positionDetailsError,
                };
            },
            prepare: (positionDetailsError: string | null) => {
                return {
                    payload: {
                        positionDetailsError: positionDetailsError,
                    },
                };
            },
        },
        fetchPositionRelatedHr: {
            reducer: (state: IPositionDetailsState) => {
                return {
                    ...state,
                };
            },
            prepare: (positionId: string) => {
                return {
                    payload: {
                        positionId: positionId,
                    },
                };
            },
        },
        setPositionRelatedHr: {
            reducer: (state: IPositionDetailsState, action: PayloadAction<ISetPositionRelatedHrList>) => {
                return {
                    ...state,
                    positionRelatedHrList: action.payload.hrList,
                };
            },
            prepare: (hrList: IModelPositionRelatedHr[]) => {
                return {
                    payload: {
                        hrList: hrList,
                    },
                };
            },
        },
        acceptPositionRelatedHr: {
            reducer: (state: IPositionDetailsState) => {
                return {
                    ...state,
                };
            },
            prepare: (positionId: string) => {
                return {
                    payload: {
                        positionId: positionId,
                    },
                };
            },
        },
        removePosition: {
            reducer: (state: IPositionDetailsState) => {
                return {
                    ...state,
                };
            },
            prepare: (positionId: string) => {
                return {
                    payload: {
                        positionId: positionId,
                    },
                };
            },
        },
        changeIsPositionRemoved: {
            reducer: (state: IPositionDetailsState, action: PayloadAction<IChangeIsPositionRemoved>) => {
                return {
                    ...state,
                    isPositionRemoved: action.payload.isPositionRemoved,
                };
            },
            prepare: (isPositionRemoved: boolean) => {
                return {
                    payload: {
                        isPositionRemoved: isPositionRemoved,
                    },
                };
            },
        },
        updatePosition: {
            reducer: (state: IPositionDetailsState) => {
                return {
                    ...state,
                    isPositionDetailsLoading: true,
                };
            },
            prepare: (positionId: string, updatePayload: IUpdatePositionPayload) => {
                return {
                    payload: {
                        positionId: positionId,
                        updatePayload: updatePayload,
                    },
                };
            },
        },
        resetToInitialPositionDetailsState: () => {
            return {
                ...initialState,
            };
        },
    },
});
export const {
    setPositionDetails,
    changeIsPositionDetailsInitialized,
    changeIsPositionDetailsLoading,
    changePositionDetailsPageError,
    fetchPositionDetails,
    fetchPositionRelatedHr,
    setPositionRelatedHr,
    acceptPositionRelatedHr,
    removePosition,
    changeIsPositionRemoved,
    updatePosition,
    resetToInitialPositionDetailsState,
    changeIsHRAdded,
    addHRToPosition,
} = positionDetailsPageSlice.actions;

export default positionDetailsPageSlice.reducer;
