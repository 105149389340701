import {DateComponent, IModelSubscription, Loader, LoaderType, Price, Translation} from 'jobhunter-common-web';
import React, {Component} from 'react';
import {Badge, Table} from 'reactstrap';
import {PaymentStatus} from '../../../../model/offerDataModel';
import {ISubscriptionsList} from '../../../../store/reducers/billingPageSlice';
import styles from './styles.module.scss';

interface IExternalPurchasedSubscriptionsProps {
    billings: ISubscriptionsList | null;
    isLoading: boolean;
}

interface IPurchasedSubscriptionsProps extends IExternalPurchasedSubscriptionsProps {}

interface IPurchasedSubscriptionsState {}

class PurchasedSubscriptions extends Component<IPurchasedSubscriptionsProps, IPurchasedSubscriptionsState> {
    render() {
        return (
            <div className={styles.subscriptionsContainer}>
                <div className="panel-section-header">
                    <Translation text="billings.title" />
                </div>
                <Table responsive className="purchased-subscriptions-table">
                    <thead>
                        <tr>
                            <th>
                                <Translation text="billings.table.plan" />
                            </th>
                            <th>
                                <Translation text="billings.table.accountingPeriod" />
                            </th>
                            <th>
                                <Translation text="billings.table.issueDate" />
                            </th>
                            <th>
                                <Translation text="billings.table.paymentDate" />
                            </th>
                            <th>
                                <Translation text="billings.table.cost" />
                            </th>
                            <th>
                                <Translation text="billings.table.status" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderSubscriptionTableRows(this.props.billings?.current, true)}
                        {this.renderSubscriptionTableRows(this.props.billings?.purchased)}
                    </tbody>
                </Table>

                <Loader type={LoaderType.Local} showLoader={this.props.isLoading} />
            </div>
        );
    }

    private renderSubscriptionTableRows(item: any, current?: boolean) {
        if ((!item || item.length === 0) && current) {
            return;
        }

        if ((!item || item.length === 0) && !current) {
            return (
                <tr key={'no-data'}>
                    <td colSpan={6}>
                        <Translation text="billings.table.noPlans" />
                    </td>
                </tr>
            );
        }

        return item.map((item: typeof IModelSubscription) => {
            const status = item.payment.paidAt ? PaymentStatus.PAID : PaymentStatus.NOT_PAID,
                statusTranslationKey = status === PaymentStatus.PAID ? 'billings.table.statusPaid' : 'billings.table.statusNotPaid';
            return (
                <tr key={item.id}>
                    <td className="fw-bold">
                        <Translation text={item.name} />
                        {current === true ? (
                            <Badge color="light-success ms-1" pill>
                                <div className="align-middle">
                                    <Translation text="billings.table.currentPlan" />
                                </div>
                            </Badge>
                        ) : null}
                    </td>
                    <td>
                        {item.activeFrom !== null ? (
                            <p>
                                <DateComponent date={item.activeFrom} /> -
                                <DateComponent date={item.activeTo} />
                            </p>
                        ) : (
                            '---'
                        )}
                    </td>
                    <td>{item.payment.paidAt !== null ? <DateComponent date={item.payment.paidAt} /> : '---'}</td>
                    <td>{item.payment.paidAt !== null ? <DateComponent date={item.payment.paidAt} /> : '---'}</td>
                    <td className={styles.price}>
                        <Price price={{amount: item.payment.grossValue}} />
                    </td>
                    <td>
                        <Badge color={status === PaymentStatus.PAID ? 'light-success' : 'light-danger'} pill>
                            <span className="align-middle">
                                <Translation text={statusTranslationKey} />
                            </span>
                        </Badge>
                    </td>
                </tr>
            );
        });
    }
}

export default PurchasedSubscriptions;
