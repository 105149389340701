import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IEmployeesPageState} from '../reducers/employeesPageSlice';

export const selectEmployeesPage = (state: RootState) => {
    return state.employeesPage;
};

export const externalEmployeesListSelector = createSelector(
    [selectEmployeesPage],
    (state: IEmployeesPageState) => state.externalEmployeesList
);

export const employeesMetadataSelector = createSelector([selectEmployeesPage], (state: IEmployeesPageState) => state.employeesMetadata);

export const employeesPaginationSelector = createSelector([selectEmployeesPage], (state: IEmployeesPageState) => state.pagination);

export const isEmployeesPageInitializedSelector = createSelector(
    [selectEmployeesPage],
    (state: IEmployeesPageState) => state.isEmployeesPageInitialized
);

export const isExternalEmployeesListLoadingSelector = createSelector(
    [selectEmployeesPage],
    (state: IEmployeesPageState) => state.isExternalEmployeesListLoading
);

export const isEmployeeAddedSelector = createSelector([selectEmployeesPage], (state: IEmployeesPageState) => state.isEmployeeAdded);

export const employeesPageErrorSelector = createSelector([selectEmployeesPage], (state: IEmployeesPageState) => state.employeesPageError);
export const isEmployeeDeletedSelector = createSelector([selectEmployeesPage], (state: IEmployeesPageState) => state.isEmployeeDeleted);
