import {
    IFileOutput,
    IModelCity,
    IModelDictionaryDatum,
    IModelSeniority,
    IUser,
    ICandidateFullInfo,
    IOrganizationFullInfo,
} from 'jobhunter-common-web';
import {IModelApiObject} from './base';
import {ICandidateAccount} from '../store/reducers/dashboardPageSlice';

export enum OfferStatus {
    ACTIVE = 'active',
    CLOSED = 'closed',
    CANCELLED = 'cancelled',
    COMPLETED = 'completed',
    DRAFT = 'draft',
    PENDING = 'pending',
    APPLIED = 'applied',
    SCHEDULED = 'scheduled',
}

export enum PaymentStatus {
    PAID = 'paid',
    NOT_PAID = 'not paid',
}

export interface IOfferItem extends IModelApiObject {
    readonly candidatesCount: number;
    readonly closedAt: string | null;
    readonly jobBoards: {[key: string]: any} | [];
    readonly offer: IOffer;
    readonly requestedJobBoards: string[];
    readonly shouldBeCompletedAt: string | null;
    readonly status: OfferStatus;
}

export interface IOffer extends IModelApiObject {
    readonly id: string;
    readonly name: string;
    readonly avatar: typeof IFileOutput;
    readonly seniority: typeof IModelSeniority;
    readonly city: typeof IModelCity;
    readonly minimumSalary: string;
    readonly maximumSalary: string;
    readonly publishAt: string;
    readonly workTypes: typeof IModelDictionaryDatum[];
    readonly relocation: boolean;
    readonly industries: typeof IModelDictionaryDatum[];
    readonly companyTypes: typeof IModelDictionaryDatum[];
    readonly contractTypes: typeof IModelDictionaryDatum[];
    readonly employmentTypes: typeof IModelDictionaryDatum[];
    readonly organizationSize: typeof IModelDictionaryDatum;
    readonly positionName: string | null;
    readonly positionId: string | null;
    readonly soughtPositionId: string | null;
    readonly preferenceTags: typeof IModelDictionaryDatum[];
}

export interface IApplicationCandidate extends IModelApiObject {
    readonly account: ICandidateAccount;
    readonly id: string;
}

export interface IChatContact extends IModelApiObject {
    readonly candidateFullInfo: typeof ICandidateFullInfo;
    readonly organizationFullInfo: typeof IOrganizationFullInfo;
}

export interface IApplicationOffer extends IModelApiObject {
    readonly applicationId: string;
    readonly candidate: IApplicationCandidate;
    readonly consultation: IConsultation | null;
    readonly isHired: boolean;
    readonly name: string;
    readonly offerId: string;
    readonly positionName: string;
    readonly offerStatus: string;
    readonly offerOwnerOrganizationId: string | null;
    readonly positionOwnerOrganizationId: string | null;
    readonly relocationStatus: string | null;
    readonly chatContact: IChatContact;
    readonly hiredAt: string | null;
    readonly positionCompanyName: string | null;
}

export interface IConsultation {
    readonly id: string;
    readonly startsAt: string;
    readonly endsAt: string;
    readonly participants: IConsultationParticipant[];
    readonly agoraChannel: string;
    readonly status: string;
}

declare type IModelUser = typeof IUser;

export interface IConsultationParticipantAccount extends IModelUser {
    readonly email: string;
}

export interface IConsultationParticipant {
    readonly account: IConsultationParticipantAccount;
    readonly agoraToken: string;
    readonly email: string;
    readonly id: string;
}
