import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {Loader, LoaderType, Translation} from 'jobhunter-common-web';
import {connect} from 'react-redux';
import {cancelOffer, changeIsOfferActionComplete, closeOffer, deleteOffer} from '../../../../store/reducers/offersPageSlice';
import {isOfferActionCompleteSelector, isOfferActionProcessingSelector} from '../../../../store/selectors/offersPageSelectors';
import {RootState} from '../../../../store/reducers';
import {IOfferItem} from '../../../../model/offerDataModel';

export enum CloseOfferModalType {
    REMOVE_OFFER = 'remove-offer',
    CLOSE_OFFER = 'close-offer',
    CANCEL_OFFER = 'cancel-offer',
}

interface IConnectedCloseOfferModalProps {
    readonly isLoading: boolean;
    readonly isComplete: boolean;
    readonly cancelOffer: typeof cancelOffer;
    readonly closeOffer: typeof closeOffer;
    readonly deleteOffer: typeof deleteOffer;
    readonly changeIsOfferActionComplete: typeof changeIsOfferActionComplete;
}

interface IExternalCloseOfferModalProps {
    isModalOpen: boolean;
    toggleModal: () => void;
    type: CloseOfferModalType;
    offer: IOfferItem | null;
}

interface ICloseOfferModalProps extends IConnectedCloseOfferModalProps, IExternalCloseOfferModalProps {}

interface ICloseOfferModalState {}

class CloseOfferModal extends Component<ICloseOfferModalProps, ICloseOfferModalState> {
    componentDidUpdate(prevProps: Readonly<ICloseOfferModalProps>) {
        if (this.props.isComplete !== prevProps.isComplete && this.props.isComplete) {
            this.props.toggleModal();
        }
    }

    componentWillUnmount() {
        this.props.changeIsOfferActionComplete(false);
    }

    render() {
        const offerHasApplication =
            null !== this.props.offer && this.props.offer.candidatesCount > 0 && this.props.type !== CloseOfferModalType.CLOSE_OFFER;

        return (
            <Modal isOpen={this.props.isModalOpen} toggle={() => this.props.toggleModal()}>
                <ModalHeader toggle={() => this.props.toggleModal()}>{this.renderModalTitle(offerHasApplication)}</ModalHeader>
                <ModalBody>{this.renderModalDescription(offerHasApplication)}</ModalBody>
                <ModalFooter>{this.renderActionButtons(offerHasApplication)}</ModalFooter>

                <Loader type={LoaderType.Local} showLoader={this.props.isLoading} />
            </Modal>
        );
    }

    private renderModalTitle = (hasApplications: boolean) => {
        const hasApplicationsTitle =
                this.props.type === CloseOfferModalType.CLOSE_OFFER
                    ? 'humanResources.offers.closeOfferNonAvailable.title'
                    : 'humanResources.offers.deleteOfferNonAvailable.title',
            title = hasApplications
                ? hasApplicationsTitle
                : this.props.type === CloseOfferModalType.REMOVE_OFFER
                ? 'humanResources.offers.deleteOffer.title'
                : 'humanResources.offers.closeOffer.title';

        return <Translation text={title} />;
    };

    private renderModalDescription = (hasApplications: boolean) => {
        const hasApplicationsDescription =
                this.props.type === CloseOfferModalType.CLOSE_OFFER
                    ? 'humanResources.offers.closeOfferNonAvailable.description'
                    : 'humanResources.offers.deleteOfferNonAvailable.description',
            description = hasApplications
                ? hasApplicationsDescription
                : this.props.type === CloseOfferModalType.REMOVE_OFFER
                ? 'humanResources.offers.deleteOffer.description'
                : 'humanResources.offers.closeOffer.description';

        return <Translation text={description} />;
    };

    private renderActionButtons = (hasApplications: boolean) => {
        if (hasApplications && this.props.type !== CloseOfferModalType.CLOSE_OFFER) {
            return (
                <Button color="primary" className="close-offer-button" onClick={() => this.props.toggleModal()}>
                    <Translation text="buttons.ok" />
                </Button>
            );
        }

        return (
            <>
                <Button color="secondary" className="close-modal-button" outline onClick={() => this.props.toggleModal()}>
                    <Translation text="buttons.cancel" />
                </Button>

                <Button
                    color="primary"
                    onClick={() => {
                        return this.props.type === CloseOfferModalType.CLOSE_OFFER
                            ? this.closeOffer()
                            : this.props.type === CloseOfferModalType.REMOVE_OFFER
                            ? this.deleteOffer()
                            : this.cancelOffer();
                    }}>
                    {this.props.type === CloseOfferModalType.CLOSE_OFFER ? (
                        <Translation text="buttons.close" />
                    ) : (
                        <Translation text="buttons.delete" />
                    )}
                </Button>
            </>
        );
    };

    private cancelOffer = () => {
        if (null === this.props.offer) {
            return;
        }

        this.props.cancelOffer(this.props.offer.offer.id);
    };

    private closeOffer = () => {
        if (null === this.props.offer) {
            return;
        }

        this.props.closeOffer(this.props.offer.offer.id);
    };

    private deleteOffer = () => {
        if (null === this.props.offer) {
            return;
        }

        this.props.deleteOffer(this.props.offer.offer.id);
    };
}

export default connect(
    (state: RootState) => ({
        isLoading: isOfferActionProcessingSelector(state),
        isComplete: isOfferActionCompleteSelector(state),
    }),
    {
        cancelOffer,
        closeOffer,
        deleteOffer,
        changeIsOfferActionComplete,
    }
)(CloseOfferModal);
