import {
    FormButtonType,
    FormControlType,
    IFormConfig,
    InputType,
    multiCollectionInputDataMapper,
    multiCollectionOutputDataMapper,
    MultiSelectType,
    IMultiselectOption,
    ValidationRules,
} from 'jobhunter-common-web';

export const addServiceFormConfig: typeof IFormConfig = (
    countryList: typeof IMultiselectOption[] | [],
    serviceTypes: typeof IMultiselectOption[] | [],
    value?: any
) => ({
    controlType: 'form',
    class: 'default-form add-service-form',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'title',
            controlType: 'group',
            class: 'row',
            controls: {
                title: {
                    controlType: 'control',
                    defaultValue: value ? value.title : '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: 'marketplace.services.addService.form.placeholders.title',
                    isLabelHidden: false,
                    label: 'marketplace.services.addService.form.labels.title',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12 form-control required big-input',
                },
            },
        },
        {
            key: 'type',
            controlType: 'group',
            class: 'row',
            controls: {
                // location: {
                //     controlType: 'control',
                //     defaultValue: value && value.country ? value.country : '',
                //     formControlType: FormControlType.AUTOCOMPLETE,
                //     multiselectType: MultiSelectType.SINGLE,
                //     multiselectOptions: countryList ? countryList : [],
                //     validationRules: [{name: ValidationRules.IS_REQUIRED}],
                //     placeholder: 'marketplace.services.addService.form.placeholders.location',
                //     label: 'marketplace.services.addService.form.labels.location',
                //     hostClass: 'col-xl-12 form-control required big-single-select',
                //     inputDataMapper: collectionInputDataMapper,
                //     outputDataMapper: collectionOutputDataMapper,
                //     selectedOption: value && value.country ? countryList.find((country: any) => country.value === value.country) : null,
                // },
                type: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.DEFAULT,
                    multiselectOptions: serviceTypes ? serviceTypes : [],
                    validationRules: [],
                    placeholder: 'employer.companyDetails.form.placeholders.select',
                    label: 'employer.companyDetails.form.labels.serviceType',
                    hostClass: 'col-xl-12 form-control required',
                    inputDataMapper: multiCollectionInputDataMapper,
                    outputDataMapper: multiCollectionOutputDataMapper,
                },
            },
        },
        {
            key: 'description',
            controlType: 'group',
            class: 'row',
            controls: {
                description: {
                    controlType: 'control',
                    defaultValue: value ? value.longDescription : '',
                    formControlType: FormControlType.TEXTAREA,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}, {name: ValidationRules.MAX_LENGTH, params: {length: 2000}}],
                    placeholder: 'marketplace.services.addService.form.placeholders.description',
                    isLabelHidden: false,
                    rows: 5,
                    maxLength: 2000,
                    label: 'marketplace.services.addService.form.labels.description',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12 form-control required',
                },
            },
        },
        {
            key: 'price_details',
            controlType: 'group',
            class: 'row',
            controls: {
                netPrice: {
                    controlType: 'control',
                    defaultValue: value ? value.netPrice : '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [{name: ValidationRules.IS_POSITIVE_NUMBER_OR_ZERO}],
                    placeholder: 'marketplace.services.addService.form.placeholders.netPrice',
                    isLabelHidden: false,
                    label: 'marketplace.services.addService.form.labels.netPrice',
                    minValue: 0,
                    stepValue: 100,
                    type: InputType.NUMBER,
                    hostClass: 'col-xl-3 form-control required',
                    isDefaultValueController: true,
                },
                taxRate: {
                    controlType: 'control',
                    defaultValue: value ? value.taxRate : '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [{name: ValidationRules.IS_POSITIVE_NUMBER_OR_ZERO}],
                    placeholder: 'marketplace.services.addService.form.placeholders.taxRate',
                    isLabelHidden: false,
                    minValue: 0,
                    maxValue: 100,
                    stepValue: 10,
                    label: 'marketplace.services.addService.form.labels.taxRate',
                    type: InputType.NUMBER,
                    hostClass: 'col-xl-3 form-control required',
                    isDefaultValueController: true,
                },
                grossPrice: {
                    controlType: 'control',
                    defaultValue: value ? value.grossPrice : '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: 'marketplace.services.addService.form.labels.grossPrice',
                    isLabelHidden: false,
                    disabled: true,
                    label: 'marketplace.services.addService.form.labels.grossPrice',
                    type: InputType.NUMBER,
                    hostClass: 'col-xl-3 form-control',
                },
            },
        },
        {
            key: 'onlyForHiredUsers',
            controlType: 'group',
            class: 'row',
            controls: {
                onlyForHiredUsers: {
                    controlType: 'control',
                    defaultValue: value?.onlyForHiredUsers,
                    formControlType: FormControlType.SWITCH,
                    validationRules: [],
                    placeholder: '',
                    label: 'marketplace.services.addService.form.labels.hiredEmployeeNeeded',
                    hostClass: 'col-xl-2 form-control switch-inline-container',
                },
            },
        },
        {
            controlType: 'group',
            key: 'add_service_modal_buttons',
            class: 'row justify-content-end',
            controls: {
                previousButton: {
                    buttonName: 'cancel',
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.BUTTON,
                    inputStyles: 'btn-prev btn-outline-primary d-flex align-items-center',
                    hostClass: 'mb-0 w-auto',
                    disabled: false,
                    defaultContainerStyles: '',
                    containerStyles: '',
                    defaultValue: null,
                    btnText: 'buttons.cancel',
                    buttonDisabled: () => {
                        return false;
                    },
                },
                submitButton: {
                    buttonName: 'submit',
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    inputStyles: 'btn-submit btn-primary d-flex align-items-center',
                    hostClass: 'mb-0 align-items-end w-auto',
                    defaultContainerStyles: '',
                    containerStyles: '',
                    defaultValue: null,
                    btnText: 'buttons.save',
                    buttonDisabled: (mappedOutputValue: any, isFormValid: boolean) => {
                        return !isFormValid;
                    },
                },
            },
        },
    ],
});
