import {
    accountSelector,
    authTokenSelector,
    deepCloneObject,
    downloadVerificationFileAPI,
    IModelDictionaryDatum,
    isSameValue,
    Loader,
    LoaderType,
    Translation,
    verificationFileTypesSelector,
} from 'jobhunter-common-web';
import {Component} from 'react';
import {ArrowLeft, Trash2, Save} from 'react-feather';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Button} from 'reactstrap';
import {Subscription} from 'rxjs';
import {RootState} from '../../../../store/reducers';
import {
    IOrganizationVerificationDocuments,
    IVerificationFile,
    setEmployerData,
    submitVerificationFiles,
} from '../../../../store/reducers/employerPageSlice';
import {employerPageLoadingSelector, verificationFilesSelector} from '../../../../store/selectors/employerPageSelectors';
import AddVerificationDocument from './AddVerificationDocument';
import RemoveVerificationDocument from './RemoveVerificationDocument';

interface IConnectedVerificationProps {
    readonly verificationFileTypes: typeof IModelDictionaryDatum[] | null;
    readonly authToken: string | null;
    readonly account: any;
    readonly verificationFiles: IOrganizationVerificationDocuments | null;
    readonly isLoading: boolean;
    readonly setEmployerData: typeof setEmployerData;
    readonly submitVerificationFiles: typeof submitVerificationFiles;
}

interface IExternalVerificationProps {
    readonly stepper: any;
    readonly isAdminAccount: boolean;
}

interface IVerificationProps extends IConnectedVerificationProps, IExternalVerificationProps, WithTranslation, WithTranslation {}

interface IVerificationState {
    isModalVisible: boolean;
    selectedVerificationFile: IVerificationFile | null;
    verificationFiles: File[] | null;
    documentsLeft: number;
}

class Verification extends Component<IVerificationProps, IVerificationState> {
    private subscriptions: Subscription[] = [];

    constructor(props: IVerificationProps) {
        super(props);

        this.state = {
            isModalVisible: false,
            selectedVerificationFile: null,
            verificationFiles: null,
            documentsLeft: 10,
        };
    }

    componentDidMount(): void {
        if (this.props.verificationFiles) {
            this.setState({documentsLeft: 10 - this.props.verificationFiles.files.length});
        }
    }

    componentDidUpdate(prevProps: Readonly<IVerificationProps>): void {
        if (!isSameValue(this.props.verificationFiles, prevProps.verificationFiles) && this.props.verificationFiles) {
            this.setState({documentsLeft: 10 - this.props.verificationFiles.files.length});
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <>
                <section className="wizard-content">
                    <div className="personal-information-section">
                        <div className="header">
                            <div className="mb-3">
                                <h2 className="title">
                                    <Translation text="employer.verification.title" />
                                </h2>
                                <p className="sub-title">
                                    <Translation
                                        text={`employer.verification.${this.props.isAdminAccount ? 'description' : 'nonAdminDescription'}`}
                                    />
                                </p>
                                <span className={`${!this.props.isAdminAccount ? 'd-none' : ''} fw-bold`}>
                                    <Translation
                                        text="employer.verification.documentsLeft"
                                        config={{documentsLeft: this.state.documentsLeft}}
                                    />
                                </span>
                            </div>
                            <div>
                                <Button
                                    color="primary"
                                    className="add-verification-file-button"
                                    disabled={this.state.documentsLeft === 0 || !this.props.isAdminAccount}
                                    onClick={() => this.toggleAddDocument()}>
                                    <Translation text="buttons.add" />
                                </Button>
                            </div>
                        </div>

                        <div>
                            <h3 className="mb-2">
                                <Translation text="employer.verification.files" />
                            </h3>
                            {this.props.verificationFiles && this.props.verificationFiles.files.length ? (
                                <ol>
                                    {this.props.verificationFiles.files.map((document: IVerificationFile, index: number) => {
                                        return this.renderDocumentDetails(document, index);
                                    })}
                                </ol>
                            ) : (
                                <p className="no-options">
                                    <Translation text="employer.verification.uploadInformation" />
                                </p>
                            )}
                        </div>
                    </div>

                    <div className="wizard-actions">
                        <Button color="primary" className="btn-prev verification-prev-button" onClick={() => this.props.stepper.previous()}>
                            <ArrowLeft size={14} className="align-middle me-sm-25 me-0" />
                            <span className="align-middle d-sm-inline-block d-none">
                                <Translation text="buttons.previous" />
                            </span>
                        </Button>
                        <Button
                            color="primary"
                            className="btn-next verification-next-button"
                            disabled={this.state.documentsLeft === 10}
                            onClick={() => this.props.stepper.next()}>
                            <Save size={14} className="align-middle me-25" />
                            <span className="align-middle d-sm-inline-block d-none">
                                <Translation text="buttons.save" />
                            </span>
                        </Button>
                    </div>
                </section>

                {this.state.isModalVisible ? (
                    <AddVerificationDocument isModalOpen={this.state.isModalVisible} toggleModal={this.toggleAddDocument} />
                ) : null}
                {this.state.selectedVerificationFile ? (
                    <RemoveVerificationDocument
                        isModalOpen={!!this.state.selectedVerificationFile}
                        selectedVerificationDocument={this.state.selectedVerificationFile}
                        toggleRemoveModal={this.closeRemoveVerificationFileModal}
                        removeVerificationDocument={this.removeVerificationDocument}
                    />
                ) : null}
                <Loader type={LoaderType.Local} showLoader={this.props.isLoading} />
            </>
        );
    }

    private toggleAddDocument = () => {
        this.setState({isModalVisible: !this.state.isModalVisible});
    };

    private removeVerificationDocument = (verificationDocumentId: string) => {
        let verificationFiles = deepCloneObject(this.props.verificationFiles);
        verificationFiles = verificationFiles.files.filter(
            (verificationFile: IVerificationFile) => verificationFile.file.id !== verificationDocumentId
        );
        this.props.submitVerificationFiles(verificationFiles);

        this.setState({selectedVerificationFile: null});
    };

    private closeRemoveVerificationFileModal = () => {
        return this.setState({selectedVerificationFile: null});
    };

    private downloadVerificationFile = (verificationFileId: string) => {
        this.subscriptions.push(downloadVerificationFileAPI(this.props.authToken, verificationFileId).subscribe());
    };

    private renderDocumentDetails = (document: IVerificationFile, index: number) => {
        return (
            <li key={`${document.file.id}_${index}`} className="mb-1">
                <div className="d-flex align-items-center justify-content-between">
                    <p>
                        <span
                            className={`${this.props.isAdminAccount ? 'link-primary cursor-pointer' : ''}`}
                            onClick={() => (this.props.isAdminAccount ? this.downloadVerificationFile(document.file.id) : null)}>
                            {document.file.originalFileName ? document.file.originalFileName : document.fileName}
                        </span>{' '}
                        - <Translation text={document.type.name} />
                    </p>
                    <Button
                        color="flat-primary"
                        className={`${!this.props.isAdminAccount ? 'd-none' : ''} btn-fixed-border remove-verification-file-button`}
                        onClick={() => this.removeDocument(document)}>
                        <Trash2 size={20} />
                    </Button>
                </div>
            </li>
        );
    };

    private removeDocument = (verificationFile: IVerificationFile) => {
        this.setState({selectedVerificationFile: verificationFile});
    };
}

export default withTranslation()(
    connect(
        (state: RootState) => ({
            authToken: authTokenSelector(state),
            account: accountSelector(state),
            verificationFiles: verificationFilesSelector(state),
            verificationFileTypes: verificationFileTypesSelector(state),
            isLoading: employerPageLoadingSelector(state),
        }),
        {
            submitVerificationFiles,
            setEmployerData,
        }
    )(Verification)
);
