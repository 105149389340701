const pl = {
    dashboard: {
        bestMatch: {
            title: 'Best match',
            table: {
                headers: {
                    project: 'Project',
                    city: 'City',
                    salary: 'Salary',
                    publication: 'Publication',
                },
            },
        },
        applications: {
            title: 'Your applications',
            table: {
                headers: {
                    project: 'Project',
                    city: 'City',
                    salary: 'Salary',
                    status: 'Status',
                },
            },
        },
        profile: {
            title: 'Profile',
        },
        calendar: {
            title: 'Calendar',
            eventCalendar: {
                title: 'Events',
                noEvents: 'There are no events',
            },
        },
    },
    profile: {
        personal: {
            title: 'Personal',
            description: 'Enter information',
            personalInformation: 'Personal Information',
            enterInformation: 'Enter your information',
            form: {
                placeholders: {
                    firstName: 'First name',
                    lastName: 'Last name',
                    phoneNumber: 'Phone number',
                    birthDate: 'Birth date',
                    city: 'City',
                    country: 'Country',
                    description: 'Write something about you',
                },
                labels: {
                    firstName: 'First name',
                    lastName: 'Last name',
                    phoneNumber: 'Phone number',
                    birthDate: 'Birth date',
                    city: 'City',
                    country: 'Country',
                    description: 'About me',
                    relocation: 'Relocation available:',
                    remote: 'Only remote:',
                },
                buttons: {
                    upload: 'Upload',
                    change: 'Change',
                    remove: 'Remove',
                },
            },
        },
        career: {
            title: 'Career',
            description: 'Lorem ipsum',
            careerTimeline: 'Career timeline:',
            noCareer: 'You have not yet added any jobs. Select "Add new" and update your virtual CV.',
            noTechnologySkills: 'Add programming skills and select their level.  We will then suggest job opportunities to you.',
            noLanguages: 'Add the languages you know and select their level.',
            socialLinks: {
                placeholders: {
                    github: 'http://github.com/',
                    linkedin: 'http://linkedin.com',
                    portfolio: 'http://portfolio.com',
                },
            },
            addLanguage: {
                title: 'Add yout language skills',
                description: 'Lorem ipsum',
                form: {
                    labels: {
                        language: 'Language',
                        level: 'Level',
                    },
                },
            },
            addCareer: {
                title: 'Add your job',
                description: 'Lorem ipsum',
                form: {
                    labels: {
                        company: 'Company name',
                        position: 'Position',
                        startDate: 'Start date',
                        endDate: 'End date',
                        isWorking: 'Currently working here',
                    },
                },
            },
            addTechnology: {
                title: 'Add your technology skills',
                description: 'Choose a programming language and the tools and frameworks you are know',
                form: {
                    labels: {
                        technology: 'Technology',
                        tools: 'Tools and frameworks',
                    },
                    placeholders: {
                        select: 'Write or select',
                    },
                },
            },
        },
        skillTest: {
            title: 'Skill test',
            description: 'Lorem ipsum',
            testSkills: 'Test your skills with ...',
            technologySkills: 'Technology skills:',
            languageSkills: 'Language skills:',
        },
        preferences: {
            title: 'Preferences',
            description: 'Lorem ipsum',
            addPreferences: {
                description: 'Enter your information',
                job: 'Job:',
                form: {
                    labels: {
                        technology: 'Technology',
                        location: 'Location',
                        companyType: 'Company type',
                        industry: 'Industry',
                        contractType: 'Contract type',
                        onlyRemote: 'Only remote',
                        partTimeRemote: 'Part time remote',
                        onlyStationary: 'Only stationary',
                        fullTime: 'Full time',
                        partTime: 'Part time',
                        onlyRelocation: 'Only relocation',
                        salary: 'Salary min.',
                    },
                    placeholders: {
                        select: 'Select',
                    },
                },
            },
        },
    },
    auth: {
        authFooter: {
            srTitle: 'Stopka strony zawierająca dane kontaktowe, nawigację po stronie i zapisy do newslettera',
            addressColumn: {
                title: 'Educat Sp. z o.o.',
                address1: 'ul. Zygmunta Krasińskiego 2',
                address2: '20-709 Lublin',
                nip: 'NIP: 9662131616',
                regon: 'REGON  12',
            },
            contactColumn: {
                title: 'Kontakt',
                correspondenceAddressTitle: 'Adres korespondencyjny:',
                address1: 'ul. Zygmunta Krasińskiego 2',
                address2: '20-709 Lublin',
            },
            linksColumn: {
                title: 'JobHunter',
                aboutUs: 'O nas',
                career: 'Kariera',
                cooperation: 'Współpraca',
                contact: 'Kontakt',
                policies: 'Regulamin i polityka prywatności',
                safePayments: 'Bezpieczne płatności',
            },
            newsletterColumn: {
                title: 'Bądź na bieżąco',
                subscribeInfo: 'Subskrybuj nasz newsletter',
                newsletterLabelSR: 'Wpisz swój adres email aby otrzymac newsletter',
                newsletterSubmitButtonSR: 'Przycisk zatwierdzania formularza subskrypcji Newslettera',
            },
            copyright: 'JobHunter {{date}} © Wszystkie prawa zastrzeżone.',
            socialIcons: {
                facebook: 'Link prowadzący do profilu na Facebooku',
                instagram: 'Link prowadzący do profilu na Instagramie',
                linkedin: 'Link prowadzący do profilu na Linkedin',
                youtube: 'Link prowadzący do kanalu Youtube',
            },
        },
        formControls: {
            placeholder: 'Wpisz tutaj',
            email: 'Email',
            password: 'Hasło',
            newPassword: 'Nowe hasło',
            repeatPassword: 'Powtórz nowe hasło',
            registrationTermsAgreement: ``,
            phone: 'Telefon',
            firstName: 'Imię',
            lastName: 'Nazwisko',
            registrationTerms: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempr. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempr.`,
        },
        register: {
            title: 'Zarejestruj się',
            subtitle:
                'Platforma EduCat to  miejsce ułatwiające proces przygotowania do studiów. Wypełnij poniższy formularz, aby założyć swój profil.',
            haveAccount: 'Masz już konto w naszym serwisie? ',
            logIn: 'Zaloguj się.',
            registerWith: {
                title: 'lub zarejestruj się bezpośrednio za pomocą swojego konta na:',
                facebook: 'Zarejestruj się przez Facebook',
                google: 'Zarejestruj się przez Google',
            },
        },
        confirmRegistration: {
            title: 'Potwierdź rejestrację',
            subtitle: 'Aby zakończyć rejestrację nacisnij przycisk poniżej.',
        },
        login: {
            title: 'Zaloguj się',
            subtitle: '',
            haveAccount: 'Nie masz jeszcze konta w naszym serwisie? ',
            register: 'Zarejestruj się.',
            resetPassword: 'Nie pamiętasz hasła?',
            loginWith: {
                title: 'lub zaloguj się bezpośrednio za pomocą swojego konta na:',
                facebook: 'Zaloguj się przez Facebook',
                google: 'Zaloguj się przez Google',
            },
        },
        resetPassword: {
            title: 'Zresetuj hasło',
            subtitle:
                'Nie martw się, że hasło Ci umknęło :) Wpisz adres email, który został użyty przy rejestracji. Wyślemy na niego dalsze instrukcje.',
        },
        newPassword: {
            title: 'Zmień hasło',
            subtitle: 'Wpisz nowe hasło i kontynuuj przygodę na Platformie EduCat',
        },
        alert: {
            loginSuccess: 'Logowanie zakończone pomyślnie.',
            loginFailed: 'Niepoprawny login lub hasło. Spróbuj jeszcze raz.',
            logout: 'Zostałeś wylogowany.',
            registrationSuccess: 'Dane rejestracyjne wysłane prawidłowo, potwierdź rejestrację klikając w link podany w emailu.',
            confirmRegistrationSuccess: 'Rejestracja została potwierdzona, zaloguj się za pomocą maila i swojego hasła.',
            sendResetPasswordMailSuccess: 'Na twój adres email wysłaliśmy wiadomość z linkiem resetującym hasło.',
            confirmResetPasswordSuccess: 'Twoje hasło zostało zmienione, możesz zalogować się za jego pomocą.',
            authenticationError: 'Musisz być zalogowany aby mieć dostęp do danych.',
        },
    },
    settings: {
        changePassword: {
            title: 'Profile Details',
            form: {
                labels: {
                    currentPassword: 'Current password',
                    newPassword: 'New password',
                    confirmPassword: 'Confirm new password',
                },
                placeholders: {
                    currentPassword: 'Enter current password',
                    newPassword: 'Enter new password',
                    confirmPassword: 'Confirm your new password',
                },
            },
            passwordRequirements: {
                title: 'Password requirements:',
                length: 'Minimum 8 characters long - the more, the better',
                uppercase: 'At least one lowercase character',
                characters: 'At least one number, symbol, or whitespace character',
            },
        },
    },
    paymentCard: {
        form: {
            labels: {
                cardholderName: 'Cardholder name',
                cardNumber: 'Card number',
            },
            placeholders: {
                cardholderName: 'Cardholder name',
            },
        },
    },
    offers: {
        title: 'Find your new job',
        description: 'Find job in Poland',
        tabs: {
            offers: 'Offers',
            myApplications: 'My applications',
            myRecruitment: 'My recruitment',
            favourite: 'Favourites',
        },
        form: {
            placeholders: {
                search: 'Search',
            },
        },
        filters: {
            moreFilters: 'More filters',
            lessFilters: 'Less filters',
            form: {
                placeholders: {
                    select: 'Select',
                },
                labels: {
                    technology: 'Technology',
                    seniority: 'Seniority',
                    minSalary: 'Min. salary EUR',
                    bestMatch: 'Best match',
                    contactType: 'Contract type',
                    industry: 'Industry',
                    companyType: 'Company type',
                    jobType: 'Job type',
                    employmentType: 'Employment type',
                    relocation: 'Relocation',
                },
            },
        },
        table: {
            project: 'Project',
            seniority: 'Seniority',
            salary: 'Salary',
            remote: 'Remote',
            status: 'Status',
            actions: 'Actions',
            tooltips: {
                observe: 'Observe',
                apply: 'Apply',
                details: 'Details',
                share: 'Share',
            },
        },
        offerView: {
            labels: {
                seniority: 'Seniority',
                employees: 'Employees',
                relocation: 'Relocation',
                salary: 'Salary',
                industry: 'Industry',
                companyType: 'Company type',
                contractType: 'Contract type',
                employmentType: 'Employment type',
                jobType: 'Job type',
            },
            description: {
                technology: 'Tech stack',
                jobDescription: 'Job description',
                responsibilities: 'Your responsibilities',
                benefits: 'What we offer',
            },
            applicationForm: {
                title: 'Apply',
                description: 'Lorem ipsum',
                labels: {
                    applicationAgreement: 'I confirm that I want to apply for this job.',
                    dataProcessingAgreement: 'I agree to the processing of my personal data for recruitment procedures',
                },
            },
        },
    },
    marketplace: {
        title: 'Find a service',
        description: 'find the service that fits your needs',
        form: {
            labels: {
                serviceType: 'Service type',
                salaryRange: 'Salary range',
            },
        },
        serviceDetails: {
            by: 'by',
            readMore: 'Read more',
            readLess: 'Read less',
        },
        buyService: {
            title: 'Payment',
            description: 'Add card for further billing',
            billingSummary: 'Billing summary',
        },
        purchaseComplete: {
            title: 'Thanks for purchase!',
            invoice: 'Invoice #',
            serviceName: 'Service name',
            subTotal: 'Subtotal',
            tax: 'Tax',
            total: 'Total',
            details: 'Your details',
            serviceTitle: 'Service title',
            companyName: 'Company name',
            noticeError: 'Notice something wrong?',
            report: 'let us know',
        },
    },
    services: {
        title: 'My services',
        table: {
            headers: {
                invoice: 'Invoice',
                service: 'Service',
                info: 'Info',
                serviceDescription: 'Service description',
                cost: 'Cost',
                status: 'Status',
                actions: 'Actions',
            },
            tooltips: {
                buyAgain: 'Buy again',
                details: 'Details',
            },
        },
    },
    videoChat: {
        consultationStart: 'Meeting starts at ',
        consultationTimeZone: 'Time zone: Eastern European Time',
        wailTillConsultationStarts: 'Wait for the HR specialist to let you in.',
        noAccess: 'You cannot join the consultation.',
        contactAdmin: 'Check URL or contact admin.',
        consultationEnded: 'Meeting is already over',
        rescheduleConsultation: 'In case you have missed the meeting, please contact the admin.',
        settings: {
            title: 'Settings',
            select: 'Select',
            selectCamera: 'Choose a camera',
            selectSpeaker: 'Choose a speaker',
            selectMicrophone: 'Choose a microphone',
            testMicrophone: 'Say something to test a microphone',
            poor: 'poor',
            excellent: 'excellent',
            saveChanges: 'Save preferences',
        },
        chat: {
            errorMessage: 'Nie udało się wysłać wiadomości',
            placeholder: 'Write a message',
            noMessages: 'You do not have any messages',
            status: {
                online: 'Online',
            },
        },
    },
    buttons: {
        add: 'Add',
        addNew: 'Add new',
        upload: 'Upload',
        more: 'Zobacz więcej',
        login: 'Zaloguj się',
        saveChanges: 'Save changes',
        discard: 'Discard',
        apply: 'Apply',
        buy: 'Buy',
        readLess: 'Read less',
        readMore: 'Read more',
        close: 'Close',
        join: 'Join',
        next: 'Next',
        previous: 'Previous',
    },
    formValidation: {
        errors: {
            minLength: 'Wartość jest zbyt krótka',
            isEmailValid: 'Adres email jest nieprawidłowy',
            isRequired: 'Pole wymagane',
            isCheckedTrue: 'Musi być zaznaczone',
            isTrue: 'Musi być zaznaczone',
            isPasswordSame: 'Hasła nie mogą być różne',
            isStartDateValid: 'Data musi być w przyszłości',
            isStartDateGreaterOrEvenValid: 'Data musi być w przyszłości lub dziś',
            isEndDateValid: 'Data powrotu musi być po dacie wylotu',
            isNumber: 'Wartość musi być liczbą',
            isPositiveNumberOrZero: 'Value should be greater than 0',
        },
    },
    footer: {
        copyright: `JobHunter {{date}} © Wszelkie prawa zastrzeżone`,
    },
    chat: {
        noUserListProvided: 'Nie udało się pobrać listy znajomych',
        noNodeConnection: 'Nie udało się nawiązać połączenia z serverem czatu. Czat chwilowo niedostępny',
        noConnectionReasons: 'Prawdopodobne przyczyny:',
        already_online: 'Ktoś inny używa tego konta.',
        expired_token: 'Token jest nieważny. Przeloguj się by uzyskać nowy token.',
        not_authorized: 'Nie posiadasz uprawnień do korzystania z czatu. Skontaktuj się z administratorem.',
        no_token: 'Brak tokenu. Przeloguj się by uzyskać owy token.',
        'websocket error': 'Niespodziewany błąd. Spróbuj ponownie później.',
    },
    maintenance: {
        pageNotFound: 'Błąd - nie znaleziono strony',
    },
    db: {
        seniority: {
            novice: 'Novice',
            trainee: 'Trainee',
            junior: 'Junior',
            middle: 'Mid',
            senior: 'Senior',
            architect: 'Architect',
        },
        technology: {
            javascript: 'JavaScript',
            php: 'PHP',
            java: 'Java',
            cpp: 'C++',
            csharp: 'C#',
            python: 'Python',
            html: 'HTML',
            html5: 'HTML5',
            ruby: 'Ruby',
            scala: 'Scala',
            golang: 'Go',
            sql: 'SQL',
            rust: 'Rust',
        },
        technology_tool: {
            laravel: 'Laravel',
            reactjs: 'Reactjs',
            yii: 'Yii',
            angularjs: 'Angular',
            angular: 'Angular',
            symfony: 'Symfony',
            doctrine: 'Doctrine',
            phalcon: 'Phalcon',
            spring: 'Spring',
            hibernate: 'Hibernate',
            javalin: 'Javalin',
            dotnet: '.NET',
            xamarin: 'Xamarin',
            infernet: 'Infer.NET',
            vuejs: 'Vue.js',
            gatsby: 'Gatsby',
            django: 'Django',
            flask: 'Flask',
            framework7: 'Framework7',
            kendoui: 'Kendo UI',
            rubyonrails: 'Ruby on Rails',
            sinatra: 'Sinatra',
            hanami: 'Hanami',
            grape: 'Grape',
            ramaze: 'Ramaze',
            slick: 'Slick',
            playframework: 'Play Framework',
            akka: 'Akka',
            gin: 'Gin',
            echo: 'Echo',
            kit: 'Kit',
            fasthttp: 'Fasthttp',
            aeron: 'Aeron',
            tensorflow: 'TensorFlow',
            devart: 'Devart',
            dbvisualizer: 'DbVisualizer',
            dbschema: 'DbSchema',
            manageengine: 'ManageEngine',
            tauri: 'Tauri',
            yew: 'Yew',
            rocket: 'Rocket',
            juniper: 'Juniper',
            dioxus: 'Dioxus',
        },
        language: {
            de: 'German',
            en: 'English',
            pl: 'Polish',
        },
        language_level: {
            a1: 'A1',
            a2: 'A2',
            b1: 'B1',
            b2: 'B2',
            c1: 'C1',
            c2: 'C2',
        },
        contract_type: {
            employment: 'Employment',
            freelance: 'Freelance',
            b2b: 'B2B',
        },
        company_type: {
            support: 'Support',
            hoster: 'Hoster',
            softwarehouse: 'Softwarehouse',
            agency: 'Agency',
            integrator: 'Integrator',
            startup: 'Startup',
            interactive_agency: 'Interactive Agency',
            team_leasing_company: 'Team Leasing Company',
            body_leasing_company: 'Body Leasing Company',
            own_it_department: 'Own IT department',
        },
        work_type: {
            partially_remote: 'Partially remote',
            stationary: 'Stationary',
            remote: 'Remote',
        },
        industry: {
            government: 'Government',
            finance: 'Finance',
            it: 'IT',
            education: 'Education',
            healthcare: 'Healthcare',
            hardware: 'Hardware',
            software: 'Software',
            saas: 'Software as a Service',
            platforms: 'Platforms',
            systems_integrator: 'Systems Integrator',
            business_process_outsourcing: 'Business Process Outsourcing',
            infrastructure: 'Infrastructure',
            data_centers: 'Data Centers',
            telecom: 'Telecom',
            it_consulting: 'IT Consulting',
            creative_services: 'Creative Services',
            information_security: 'Information Security',
            data_services: 'Data Services',
            ai: 'Artificial Intelligence',
            robotics: 'Robotics',
            gaming: 'Gaming',
            media: 'Media',
            simulation: 'Simulation',
            research_n_development: 'Research & Development',
            ecommerce: 'Ecommerce',
            energy: 'Energy',
            marketing: 'Marketing',
            market_research: 'Market Research',
            competitive_intelligence: 'Competitive Intelligence',
            public_services: 'Public Services',
            smart_cities: 'Smart Cities',
            building_n_home_automation: 'Building & Home Automation',
            immersive_environments: 'Immersive Environments',
            sharing_economy: 'Sharing Economy',
            consumer_products: 'Consumer Products',
            mass_customization: 'Mass Customization',
            consumer_services: 'Consumer Services',
            business_services: 'Business Services',
            reputation_systems: 'Reputation Systems',
            architecture: 'Architecture',
            agriculture: 'Agriculture',
            automotive: 'Automotive',
            others: 'Others',
        },
        employment_type: {
            part_time: 'Part time',
            full_time: 'Full time',
        },
        city: {
            warsaw: 'Warsaw',
            poznan: 'Poznan',
            wroclaw: 'Wroclaw',
            krakow: 'Krakow',
        },
        preference_tag: {
            clubs_high: 'Clubs high',
            nature_high: 'Nature high',
            education_medium: 'Education medium',
            'holidays_26-30': 'Holidays 26-30',
            climate_hot: 'Climate hot',
            pace_of_life_slow: 'Pace of life slow',
            climate_dry: 'Climate dry',
            climate_forest: 'Climate forest',
            climate_mountains: 'Climate mountains',
            nature_medium: 'Nature medium',
            'holidays_21-25': 'Holidays 21-25',
            climate_humid: 'Climate humid',
            holidays_11_15: 'Holidays 11-15',
            education_low: 'Education low',
            nature_low: 'Nature low',
            holidays_no_more_than_10: 'Holidays no more than 10',
            religion_buddhism: 'Religion buddhism',
            climate_snow: 'Climate snow',
            religion_judaism: 'Religion judaism',
            holidays_31_or_more: 'Holidays 31 or more',
            religion_muslim: 'Religion muslim',
            religion_hindu: 'Religion hindu',
            clubs_medium: 'Clubs medium',
            climate_cold: 'Climate cold',
            education_high: 'Education high',
            pace_of_life_medium: 'Pace of life medium',
            religion_taoist: 'Religion taoist',
            religion_protestant: 'Religion protestant',
            'holidays_16-20': 'Holidays 16-20',
            climate_temperate: 'Climate temperate',
            pace_of_life_fast: 'Pace of life fast',
            religion_catholic: 'Religion catholic',
            clubs_low: 'Clubs low',
        },
        country: {
            AF: 'Afghanistan',
            AL: 'Albania',
            DZ: 'Algeria',
            AS: 'American Samoa',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarctica',
            AG: 'Antigua and Barbuda',
            AR: 'Argentina',
            AM: 'Armenia',
            AW: 'Aruba',
            AU: 'Australia',
            AT: 'Austria',
            AZ: 'Azerbaijan',
            BS: 'Bahamas (the)',
            BH: 'Bahrain',
            BD: 'Bangladesh',
            BB: 'Barbados',
            BY: 'Belarus',
            BE: 'Belgium',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermuda',
            BT: 'Bhutan',
            BO: 'Bolivia (Plurinational State of)',
            BQ: 'Bonaire, Sint Eustatius and Saba',
            BA: 'Bosnia and Herzegovina',
            BW: 'Botswana',
            BV: 'Bouvet Island',
            BR: 'Brazil',
            IO: 'British Indian Ocean Territory (the)',
            BN: 'Brunei Darussalam',
            BG: 'Bulgaria',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            CV: 'Cabo Verde',
            KH: 'Cambodia',
            CM: 'Cameroon',
            CA: 'Canada',
            KY: 'Cayman Islands (the)',
            CF: 'Central African Republic (the)',
            TD: 'Chad',
            CL: 'Chile',
            CN: 'China',
            CX: 'Christmas Island',
            CC: 'Cocos (Keeling) Islands (the)',
            CO: 'Colombia',
            KM: 'Comoros (the)',
            CD: 'Congo (the Democratic Republic of the)',
            CG: 'Congo (the)',
            CK: 'Cook Islands (the)',
            CR: 'Costa Rica',
            HR: 'Croatia',
            CU: 'Cuba',
            CW: 'Curaçao',
            CY: 'Cyprus',
            CZ: 'Czechia',
            CI: "Côte d'Ivoire",
            DK: 'Denmark',
            DJ: 'Djibouti',
            DM: 'Dominica',
            DO: 'Dominican Republic (the)',
            EC: 'Ecuador',
            EG: 'Egypt',
            SV: 'El Salvador',
            GQ: 'Equatorial Guinea',
            ER: 'Eritrea',
            EE: 'Estonia',
            SZ: 'Eswatini',
            ET: 'Ethiopia',
            FK: 'Falkland Islands (the) [Malvinas]',
            FO: 'Faroe Islands (the)',
            FJ: 'Fiji',
            FI: 'Finland',
            FR: 'France',
            GF: 'French Guiana',
            PF: 'French Polynesia',
            TF: 'French Southern Territories (the)',
            GA: 'Gabon',
            GM: 'Gambia (the)',
            GE: 'Georgia',
            DE: 'Germany',
            GH: 'Ghana',
            GI: 'Gibraltar',
            GR: 'Greece',
            GL: 'Greenland',
            GD: 'Grenada',
            GP: 'Guadeloupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guinea',
            GW: 'Guinea-Bissau',
            GY: 'Guyana',
            HT: 'Haiti',
            HM: 'Heard Island and McDonald Islands',
            VA: 'Holy See (the)',
            HN: 'Honduras',
            HK: 'Hong Kong',
            HU: 'Hungary',
            IS: 'Iceland',
            IN: 'India',
            ID: 'Indonesia',
            IR: 'Iran (Islamic Republic of)',
            IQ: 'Iraq',
            IE: 'Ireland',
            IM: 'Isle of Man',
            IL: 'Israel',
            IT: 'Italy',
            JM: 'Jamaica',
            JP: 'Japan',
            JE: 'Jersey',
            JO: 'Jordan',
            KZ: 'Kazakhstan',
            KE: 'Kenya',
            KI: 'Kiribati',
            KP: "Korea (the Democratic People's Republic of)",
            KR: 'Korea (the Republic of)',
            KW: 'Kuwait',
            KG: 'Kyrgyzstan',
            LA: "Lao People's Democratic Republic (the)",
            LV: 'Latvia',
            LB: 'Lebanon',
            LS: 'Lesotho',
            LR: 'Liberia',
            LY: 'Libya',
            LI: 'Liechtenstein',
            LT: 'Lithuania',
            LU: 'Luxembourg',
            MO: 'Macao',
            MG: 'Madagascar',
            MW: 'Malawi',
            MY: 'Malaysia',
            MV: 'Maldives',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Marshall Islands (the)',
            MQ: 'Martinique',
            MR: 'Mauritania',
            MU: 'Mauritius',
            YT: 'Mayotte',
            MX: 'Mexico',
            FM: 'Micronesia (Federated States of)',
            MD: 'Moldova (the Republic of)',
            MC: 'Monaco',
            MN: 'Mongolia',
            ME: 'Montenegro',
            MS: 'Montserrat',
            MA: 'Morocco',
            MZ: 'Mozambique',
            MM: 'Myanmar',
            NA: 'Namibia',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Netherlands (the)',
            NC: 'New Caledonia',
            NZ: 'New Zealand',
            NI: 'Nicaragua',
            NE: 'Niger (the)',
            NG: 'Nigeria',
            NU: 'Niue',
            NF: 'Norfolk Island',
            MP: 'Northern Mariana Islands (the)',
            NO: 'Norway',
            OM: 'Oman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'Palestine, State of',
            PA: 'Panama',
            PG: 'Papua New Guinea',
            PY: 'Paraguay',
            PE: 'Peru',
            PH: 'Philippines (the)',
            PN: 'Pitcairn',
            PL: 'Poland',
            PT: 'Portugal',
            PR: 'Puerto Rico',
            QA: 'Qatar',
            MK: 'Republic of North Macedonia',
            RO: 'Romania',
            RU: 'Russian Federation (the)',
            RW: 'Rwanda',
            RE: 'Réunion',
            BL: 'Saint Barthélemy',
            SH: 'Saint Helena, Ascension and Tristan da Cunha',
            KN: 'Saint Kitts and Nevis',
            LC: 'Saint Lucia',
            MF: 'Saint Martin (French part)',
            PM: 'Saint Pierre and Miquelon',
            VC: 'Saint Vincent and the Grenadines',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Sao Tome and Principe',
            SA: 'Saudi Arabia',
            SN: 'Senegal',
            RS: 'Serbia',
            SC: 'Seychelles',
            SL: 'Sierra Leone',
            SG: 'Singapore',
            SX: 'Sint Maarten (Dutch part)',
            SK: 'Slovakia',
            SI: 'Slovenia',
            SB: 'Solomon Islands',
            SO: 'Somalia',
            ZA: 'South Africa',
            GS: 'South Georgia and the South Sandwich Islands',
            SS: 'South Sudan',
            ES: 'Spain',
            LK: 'Sri Lanka',
            SD: 'Sudan (the)',
            SR: 'Suriname',
            SJ: 'Svalbard and Jan Mayen',
            SE: 'Sweden',
            CH: 'Switzerland',
            SY: 'Syrian Arab Republic',
            TW: 'Taiwan (Province of China)',
            TJ: 'Tajikistan',
            TZ: 'Tanzania, United Republic of',
            TH: 'Thailand',
            TL: 'Timor-Leste',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad and Tobago',
            TN: 'Tunisia',
            TR: 'Turkey',
            TM: 'Turkmenistan',
            TC: 'Turks and Caicos Islands (the)',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ukraine',
            AE: 'United Arab Emirates (the)',
            GB: 'United Kingdom of Great Britain and Northern Ireland (the)',
            UM: 'United States Minor Outlying Islands (the)',
            US: 'United States of America (the)',
            UY: 'Uruguay',
            UZ: 'Uzbekistan',
            VU: 'Vanuatu',
            VE: 'Venezuela (Bolivarian Republic of)',
            VN: 'Viet Nam',
            VG: 'Virgin Islands (British)',
            VI: 'Virgin Islands (U.S.)',
            WF: 'Wallis and Futuna',
            EH: 'Western Sahara',
            YE: 'Yemen',
            ZM: 'Zambia',
            ZW: 'Zimbabwe',
            AX: 'Åland Islands',
        },
        service_type: {
            language: 'Language',
            relocation: 'Relocation',
            family: 'Family',
            housing: 'Housing',
            buddy: 'Buddy',
        },
        organization_size: {
            tiny: 'Tiny',
            small: 'Small',
            medium: 'Medium',
            large: 'Large',
            huge: 'Huge',
        },
    },
    accessibilityMenu: {
        title: 'Accessibility Menu',
        openAccessibilityMenu: 'Open accessibility menu',
        closeAccessibilityMenu: 'Close accessibility menu',
        contrastMode: {
            title: 'Contrast Mode {{mode}}',
            toggleButton: 'Toggle contrast mode',
            on: 'on',
            off: 'off',
        },
        fontSize: {
            title: 'Font Size',
            increase: 'Increase Font Size',
            decrease: 'Decrease Font Size',
        },
    },
};

export default pl;
