import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelApiResponseViewObject} from 'jobhunter-common-web';
import {IModelPurchasedService} from '../../model/servicesDataModel';

export interface IFetchPurchasedServiceList {
    params: {[key: string]: any} | null;
}

export interface IPaginationParams {
    page: number;
    itemsPerPage: number;
}

export interface ISetPurchasedServiceList {
    purchasedServicesList: IModelPurchasedService[] | null;
}

export interface ISetPurchasedServicesMetadata {
    purchasedServicesMetadata: typeof IModelApiResponseViewObject | null;
}

export interface IChangePurchasedServicesPagination {
    pagination: IPaginationParams;
}

export interface IPurchasedServicesListLoading {
    isPurchasedServicesListLoading: boolean;
}

export interface IPurchasedServicesListInitialized {
    isPurchasedServicesListInitialized: boolean;
}

export interface IPurchasedServiceListError {
    purchasedServicesListError: string | null;
}

export interface IPurchasedServicesListState {
    purchasedServicesList: IModelPurchasedService[] | null;
    purchasedServicesMetadata: typeof IModelApiResponseViewObject | null;
    pagination: IPaginationParams | null;
    isPurchasedServicesListLoading: boolean;
    isPurchasedServicesListInitialized: boolean;
    purchasedServicesListError: string | null;
}

const initialState: IPurchasedServicesListState = {
    purchasedServicesList: null,
    purchasedServicesMetadata: null,
    pagination: {
        itemsPerPage: 10,
        page: 1,
    },
    isPurchasedServicesListLoading: false,
    isPurchasedServicesListInitialized: false,
    purchasedServicesListError: null,
};

const purchasedServicesPageSlice = createSlice({
    name: 'purchasedServicesList',
    initialState: initialState,
    reducers: {
        setPurchasedServicesList: {
            reducer: (state: IPurchasedServicesListState, action: PayloadAction<ISetPurchasedServiceList>) => {
                return {
                    ...state,
                    purchasedServicesList: action.payload.purchasedServicesList,
                };
            },
            prepare: (purchasedServicesList: IModelPurchasedService[] | null) => {
                return {
                    payload: {
                        purchasedServicesList: purchasedServicesList,
                    },
                };
            },
        },
        setPurchasedServicesMetadata: {
            reducer: (state: IPurchasedServicesListState, action: PayloadAction<ISetPurchasedServicesMetadata>) => {
                return {
                    ...state,
                    purchasedServicesMetadata: action.payload.purchasedServicesMetadata,
                };
            },
            prepare(purchasedServicesMetadata: typeof IModelApiResponseViewObject | null) {
                return {
                    payload: {
                        purchasedServicesMetadata: purchasedServicesMetadata,
                    },
                };
            },
        },
        changePurchasedServicesPagination: {
            reducer: (state: IPurchasedServicesListState, action: PayloadAction<IChangePurchasedServicesPagination>) => {
                return {
                    ...state,
                    isPurchasedServicesListLoading: true,
                    pagination: action.payload.pagination,
                };
            },
            prepare(pagination: IPaginationParams) {
                return {
                    payload: {pagination: pagination},
                };
            },
        },
        changePurchasedServicesPageLoading: {
            reducer: (state: IPurchasedServicesListState, action: PayloadAction<IPurchasedServicesListLoading>) => {
                return {
                    ...state,
                    isPurchasedServicesListLoading: action.payload.isPurchasedServicesListLoading,
                };
            },
            prepare: (isPurchasedServicesListLoading: boolean) => {
                return {
                    payload: {
                        isPurchasedServicesListLoading: isPurchasedServicesListLoading,
                    },
                };
            },
        },
        changePurchasedServicesPageInitialized: {
            reducer: (state: IPurchasedServicesListState, action: PayloadAction<IPurchasedServicesListInitialized>) => {
                return {
                    ...state,
                    isPurchasedServicesListInitialized: action.payload.isPurchasedServicesListInitialized,
                };
            },
            prepare: (isPurchasedServicesListInitialized: boolean) => {
                return {
                    payload: {
                        isPurchasedServicesListInitialized: isPurchasedServicesListInitialized,
                    },
                };
            },
        },
        changePurchasedServicesPageError: {
            reducer: (state: IPurchasedServicesListState, action: PayloadAction<IPurchasedServiceListError>) => {
                return {
                    ...state,
                    purchasedServicesListError: action.payload.purchasedServicesListError,
                };
            },
            prepare: (purchasedServicesListError: any) => {
                return {
                    payload: {
                        purchasedServicesListError: purchasedServicesListError,
                    },
                };
            },
        },
        fetchPurchasedServicesList: {
            reducer: (state: IPurchasedServicesListState) => {
                return {
                    ...state,
                    isPurchasedServicesListLoading: true,
                };
            },
            prepare: (params: {[key: string]: any} | null) => {
                return {
                    payload: {
                        params: params,
                    },
                };
            },
        },
        resetToInitialPurchasedServicePageState: () => {
            return {
                ...initialState,
            };
        },
    },
});
export const {
    setPurchasedServicesList,
    fetchPurchasedServicesList,
    setPurchasedServicesMetadata,
    changePurchasedServicesPagination,
    resetToInitialPurchasedServicePageState,
    changePurchasedServicesPageError,
    changePurchasedServicesPageInitialized,
    changePurchasedServicesPageLoading,
} = purchasedServicesPageSlice.actions;

export default purchasedServicesPageSlice.reducer;
