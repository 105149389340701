import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {Translation} from 'jobhunter-common-web';
import styles from './styles.module.scss';
import {IModelPosition} from '../../../../../model/positionsDataModel';
import {connect} from 'react-redux';
import {removePosition} from '../../../../../store/reducers/positionDetailsPageSlice';

interface IConnectedDeletePositionProps {
    readonly removePosition: typeof removePosition;
}

interface IExternalDeletePositionProps {
    isModalOpen: boolean;
    toggleModal: () => void;
    position: IModelPosition | null;
}

interface IDeletePositionProps extends IConnectedDeletePositionProps, IExternalDeletePositionProps {}

interface IDeletePositionState {
    isLoading: boolean;
}

class DeletePosition extends Component<IDeletePositionProps, IDeletePositionState> {
    constructor(props: IDeletePositionProps) {
        super(props);

        this.state = {
            isLoading: false,
        };
    }

    componentDidMount(): void {}

    render() {
        const positionHasOffers = this.props.position && this.props.position.soughtPositions.length,
            modalTitle = positionHasOffers
                ? 'employer.positions.positionDetails.deletePosition.deleteWarning'
                : 'employer.positions.positionDetails.deletePosition.title',
            modalDescription = positionHasOffers
                ? 'employer.positions.positionDetails.deletePosition.deleteInfo'
                : 'employer.positions.positionDetails.deletePosition.description';

        return (
            <Modal isOpen={this.props.isModalOpen} toggle={() => this.props.toggleModal()}>
                <ModalHeader toggle={() => this.props.toggleModal()}>
                    <p className={positionHasOffers ? `${styles.warningHeader}` : ''}>
                        <Translation text={modalTitle} />
                    </p>
                </ModalHeader>
                <ModalBody>
                    <div className={styles.modalContent}>
                        <p>
                            <Translation text={modalDescription} />
                        </p>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {positionHasOffers ? (
                        <Button color="primary" onClick={() => this.props.toggleModal()}>
                            <Translation text="buttons.ok" />
                        </Button>
                    ) : (
                        <>
                            <Button color="secondary" className="close-modal-button" outline onClick={() => this.props.toggleModal()}>
                                <Translation text="buttons.no" />
                            </Button>
                            <Button color="primary" className="remove-position-button" onClick={() => this.deletePosition()}>
                                <Translation text="buttons.yes" />
                            </Button>
                        </>
                    )}
                </ModalFooter>
            </Modal>
        );
    }

    private deletePosition = () => {
        if (null === this.props.position) {
            return;
        }
        this.props.removePosition(this.props.position?.id);
    };
}

export default connect(() => ({}), {
    removePosition,
})(DeletePosition);
