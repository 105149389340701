import {Observable} from 'rxjs';
import {jobHunterAPI, RestQueryParams} from 'jobhunter-common-web';

export function changeEmployeeRoleAPI(authToken: string | null, employeeId: string, employeeRole: string): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return jobHunterAPI.put(`api/organization_employees/${employeeId}/change_role`, {role: employeeRole}, new RestQueryParams(), headers);
}
