import {Translation, withLocation, WithLocation, Loader, LoaderType, AuthHeader, Toast} from 'jobhunter-common-web';
import React, {Component} from 'react';
import {Button} from 'reactstrap';
import styles from './styles.module.scss';
import {connect} from 'react-redux';
import {isAcceptPageLoadingSelector} from '../../../../store/selectors/hrPageSelectors';
import {acceptInvitation} from '../../../../store/reducers/hrPageSlice';
import {RootState} from '../../../../store/reducers';

declare type WithLocationProps = typeof WithLocation;

interface IConnectedAcceptInvitationProps {
    readonly isLoading: boolean;
    readonly acceptInvitation: typeof acceptInvitation;
}

interface IAcceptInvitationProps extends WithLocationProps, IConnectedAcceptInvitationProps {}

interface IAcceptInvitationState {}

class AcceptInvitation extends Component<IAcceptInvitationProps, IAcceptInvitationState> {
    render() {
        return (
            <>
                <div className="auth-page">
                    <div className={`auth-content role_organization`}>
                        <div className="auth-left-bg">
                            <AuthHeader />
                        </div>
                        <main className="auth-section">
                            <div className={styles.container}>
                                <h1 className={styles.item}>
                                    <Translation text="employer.acceptInvitation" />
                                </h1>
                                <Button
                                    color="primary"
                                    className={`${styles.item} accept-invitation-button`}
                                    onClick={() => this.acceptInvitation()}>
                                    <Translation text="buttons.acceptInvitation" />
                                </Button>
                                <Loader type={LoaderType.Local} showLoader={this.props.isLoading} />
                            </div>
                        </main>
                    </div>
                    <Toast />
                </div>
            </>
        );
    }

    private acceptInvitation = () => {
        const authToken = this.props.location.pathname.split('/').pop();
        return this.props.acceptInvitation(authToken);
    };
}

export default connect(
    (state: RootState) => ({
        isLoading: isAcceptPageLoadingSelector(state),
    }),
    {
        acceptInvitation,
    }
)(withLocation(AcceptInvitation));
