import React, {ChangeEvent, Component} from 'react';
import LayoutWrapper from '../../LayoutWrapper';
import {
    authUserRolesSelector,
    isNotNullOrUndefined,
    ListItemDetails,
    Loader,
    LoaderType,
    Pagination,
    Tooltip,
    Translation,
    UserRole,
    WithNavigate,
    withNavigation,
} from 'jobhunter-common-web';
import {Badge, Button, Card, CardBody, CardTitle, Table} from 'reactstrap';
import {Check, CheckSquare, Edit3, Eye, File, Search, Send, Trash2, Users} from 'react-feather';
import {WithTranslation, withTranslation} from 'react-i18next';
import styles from './styles.module.scss';
import CloseOfferModal, {CloseOfferModalType} from './CloseOfferModal';
import {
    applyOffersFilters,
    changeOffersFilters,
    changeOffersPagination,
    fetchOffers,
    resetToInitialOffersPageState,
} from '../../../store/reducers/offersPageSlice';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {isOffersPageLoadingSelector, offersMetadataSelector, offersSelector} from '../../../store/selectors/offersPageSelectors';
import {IModelApiResponseViewObject} from '../../../model/base';
import RecommendOfferModal from './RecommendOfferModal';
import {IOffer, IOfferItem, OfferStatus} from '../../../model/offerDataModel';
import SwitchFilter from '../../Employer/Positions/SwitchFilter';

export const convertBadgeColor = (status: OfferStatus, type?: string): string => {
    let badgeColor;
    switch (status) {
        case OfferStatus.CLOSED:
            badgeColor = 'secondary';
            break;
        case OfferStatus.CANCELLED:
            badgeColor = 'warning';
            break;
        case OfferStatus.COMPLETED:
            badgeColor = 'primary';
            break;
        case OfferStatus.DRAFT:
            badgeColor = 'info';
            break;
        default:
            badgeColor = 'success';
    }

    return type ? `${type}-${badgeColor}` : badgeColor;
};

declare type WithNavigationProps = typeof WithNavigate;

interface IConnectedOffersProps {
    readonly offers: IOfferItem[] | null;
    readonly offersMetadata: IModelApiResponseViewObject | null;
    readonly isOffersPageLoading: boolean;
    readonly userRoles: typeof UserRole | null;
    readonly fetchOffers: typeof fetchOffers;
    readonly changeOffersPagination: typeof changeOffersPagination;
    readonly resetToInitialOffersPageState: typeof resetToInitialOffersPageState;
    readonly changeOffersFilters: typeof changeOffersFilters;
    readonly applyOffersFilters: typeof applyOffersFilters;
}

interface IOffersProps extends IConnectedOffersProps, WithTranslation, WithNavigationProps {}

interface IOffersState {
    isCloseOfferModalVisible: boolean;
    isRecommendationModalVisible: boolean;
    selectedOffer: IOfferItem | null;
    closeOfferType: CloseOfferModalType;
    isModalVisible: boolean;
    isChecked: boolean;
}

class Offers extends Component<IOffersProps, IOffersState> {
    constructor(props: IOffersProps) {
        super(props);

        this.state = {
            isCloseOfferModalVisible: false,
            isRecommendationModalVisible: false,
            selectedOffer: null,
            closeOfferType: CloseOfferModalType.CLOSE_OFFER,
            isModalVisible: false,
            isChecked: false,
        };
    }

    componentDidMount() {
        this.props.fetchOffers();
    }

    componentWillUnmount() {
        this.props.resetToInitialOffersPageState();
    }

    render() {
        return (
            <LayoutWrapper>
                <div className="panel-section-header d-flex justify-content-between align-items-center flex-wrap">
                    <Translation text="humanResources.offers.title" />

                    <button className="btn btn-primary add-new-offer-button" onClick={() => this.addNewOffer()}>
                        <Translation text="humanResources.offers.addOffer" />
                    </button>
                </div>
                <Card className="offers-card">
                    <CardBody>
                        <CardTitle>
                            <div className="card-header">
                                <div>
                                    <File size={20} />
                                    <Translation text="humanResources.offers.cardTitle" />
                                </div>
                                <SwitchFilter
                                    name={'hasPosition'}
                                    isChecked={this.state.isChecked}
                                    changeFilters={this.changeOfferPositionFilter}
                                    label={'humanResources.offers.filters.hasPosition'}
                                />
                            </div>
                        </CardTitle>
                        <Table responsive className="offers-table">
                            <thead>
                                <tr>
                                    <th>
                                        <Translation text="humanResources.offers.table.offer" />
                                    </th>
                                    <th>
                                        <Translation text="humanResources.offers.table.candidates" />
                                    </th>
                                    <th>
                                        <Translation text="humanResources.offers.table.status" />
                                    </th>
                                    <th>
                                        <Translation text="humanResources.offers.table.position" />
                                    </th>
                                    <th className="text-center">
                                        <Translation text="humanResources.offers.table.actions" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>{this.renderOffersTableRows()}</tbody>
                        </Table>
                    </CardBody>
                </Card>

                <Pagination listMetadata={this.props.offersMetadata} changePage={this.props.changeOffersPagination} />

                {this.state.isCloseOfferModalVisible ? (
                    <CloseOfferModal
                        isModalOpen={this.state.isCloseOfferModalVisible}
                        toggleModal={this.toggleCloseOfferModal}
                        type={this.state.closeOfferType}
                        offer={this.state.selectedOffer}
                    />
                ) : null}

                {this.state.isRecommendationModalVisible ? (
                    <RecommendOfferModal
                        isModalOpen={this.state.isRecommendationModalVisible}
                        toggleModal={this.toggleRecommendationModal}
                        offer={this.state.selectedOffer ? this.state.selectedOffer.offer : null}
                    />
                ) : null}

                <Loader type={LoaderType.Local} showLoader={this.props.isOffersPageLoading} />
            </LayoutWrapper>
        );
    }

    private renderOffersTableRows() {
        const {t} = this.props,
            offers: IOfferItem[] | null = this.props.offers;

        if (null === offers || !offers.length) {
            return (
                <tr key={'no-data'}>
                    <td colSpan={5}>
                        <p>
                            <Translation text="humanResources.offers.table.noData" />
                        </p>
                    </td>
                </tr>
            );
        }

        return offers.map((item: IOfferItem) => {
            return (
                <tr key={`offers_${item.offer.id}`}>
                    <td>
                        <ListItemDetails avatar={item.offer.avatar} name={item.offer.name} />
                    </td>
                    <td>
                        <div className={styles.itemDescription}>
                            {isNotNullOrUndefined(item.candidatesCount) ? (
                                <>
                                    <p className="me-1">{item.candidatesCount}</p>
                                    <Button
                                        color="flat-primary"
                                        className="view-candidate-button"
                                        onClick={() => this.viewCandidateDetails(item.offer)}
                                        disabled={item.candidatesCount === 0}>
                                        <Search id={`details_${item.offer.id}`} size={20} />
                                        <Tooltip
                                            target={`details_${item.offer.id}`}
                                            tooltipText={t('humanResources.offers.tooltips.viewDetails')}
                                        />
                                    </Button>
                                </>
                            ) : null}
                        </div>
                    </td>
                    <td>
                        <Badge color={convertBadgeColor(item.status, 'light')} pill>
                            <span className="align-middle">{item.status}</span>
                        </Badge>
                    </td>
                    <td>
                        <div className={styles.itemDescription}>
                            {item.offer.soughtPositionId ? <Check color="#28C76F" /> : null}

                            {item.offer.soughtPositionId ? (
                                <Button color="flat-primary" className="view-position-button" onClick={() => this.viewPosition(item.offer)}>
                                    <Eye id={`position_${item.offer.id}`} size={20} />
                                    <Tooltip
                                        target={`position_${item.offer.id}`}
                                        tooltipText={t('humanResources.offers.tooltips.positionDetails')}
                                    />
                                </Button>
                            ) : null}
                        </div>
                    </td>
                    <td className="text-center">
                        <Button
                            disabled={item.status !== OfferStatus.ACTIVE}
                            className="recommend-offer-button"
                            color="flat-primary"
                            onClick={() => this.toggleRecommendationModal(item)}>
                            <Send id={`recommend_${item.offer.id}`} size={20} />
                            <Tooltip target={`recommend_${item.offer.id}`} tooltipText={t('humanResources.offers.tooltips.recommend')} />
                        </Button>

                        {this.isOrganizationHeadhunterOrAdmin() ? (
                            <Button
                                color="flat-primary"
                                className="recommended-candidates"
                                onClick={() => this.viewRecommendedCandidates(item.offer.id)}>
                                <Users id={`recommended_candidates_${item.offer.id}`} size={20} />
                                <Tooltip
                                    target={`recommended_candidates_${item.offer.id}`}
                                    tooltipText={t('humanResources.offers.viewRecommendedCandidates')}
                                />
                            </Button>
                        ) : null}

                        <Button
                            color="flat-primary"
                            disabled={
                                item.status === OfferStatus.CLOSED ||
                                item.status === OfferStatus.CANCELLED ||
                                item.status === OfferStatus.COMPLETED
                            }
                            className="edit-offer-button"
                            onClick={() => this.editOffer(item.offer)}>
                            <Edit3 id={`edit_${item.offer.id}`} size={20} />
                            <Tooltip target={`edit_${item.offer.id}`} tooltipText={t('humanResources.offers.tooltips.edit')} />
                        </Button>

                        <Button
                            color="flat-primary"
                            className="remove-offer-button"
                            onClick={() => this.toggleCloseOfferModal(item, CloseOfferModalType.REMOVE_OFFER)}>
                            <Trash2 id={`delete_${item.offer.id}`} size={20} />
                            <Tooltip target={`delete_${item.offer.id}`} tooltipText={t('humanResources.offers.tooltips.delete')} />
                        </Button>

                        <Button color="flat-primary" className="view-offer-button" onClick={() => this.viewOffer(item.offer)}>
                            <Eye id={`view_${item.offer.id}`} size={20} />
                            <Tooltip target={`view_${item.offer.id}`} tooltipText={t('humanResources.offers.tooltips.view')} />
                        </Button>

                        <Button
                            color="flat-primary"
                            className="close-offer-button"
                            disabled={item.status === OfferStatus.CLOSED}
                            onClick={() => this.toggleCloseOfferModal(item, CloseOfferModalType.CLOSE_OFFER)}>
                            <CheckSquare id={`link_${item.offer.id}`} size={20} />
                            <Tooltip target={`link_${item.offer.id}`} tooltipText={t('humanResources.offers.tooltips.closeOffer')} />
                        </Button>
                    </td>
                </tr>
            );
        });
    }

    private changeOfferPositionFilter = (e: ChangeEvent<HTMLInputElement>) => {
        const hasPosition = e.target.checked,
            params = {
                'exists[soughtPosition]': hasPosition.toString(),
            };

        this.props.changeOffersFilters(params);
        this.props.applyOffersFilters();
    };

    private viewPosition = (item: IOffer) => {
        this.props.navigate(`/panel/human_resources/recruitment/${item.soughtPositionId}`);
    };

    private editOffer = (item: IOffer) => {
        this.props.navigate(`/panel/human_resources/edit-offer/${item.id}`);
    };

    private viewCandidateDetails = (item: IOffer) => {
        this.props.navigate(`/panel/human_resources/candidates`, {state: {offerId: item.id}});
    };

    private viewOffer = (item: IOffer) => {
        this.props.navigate(`/panel/human_resources/offers/${item.id}`);
    };

    private addNewOffer = () => {
        this.props.navigate(`/panel/human_resources/offers/add-offer`);
    };

    private viewRecommendedCandidates = (offerId: string) => {
        this.props.navigate(`/panel/human_resources/recommended-candidates/${offerId}`);
    };

    private toggleCloseOfferModal = (item?: IOfferItem, type?: CloseOfferModalType) => {
        this.setState({
            isCloseOfferModalVisible: !this.state.isCloseOfferModalVisible,
            selectedOffer: item ? item : null,
            closeOfferType: type ? type : CloseOfferModalType.CLOSE_OFFER,
        });
    };

    private toggleRecommendationModal = (item?: IOfferItem) => {
        this.setState({
            isRecommendationModalVisible: !this.state.isRecommendationModalVisible,
            selectedOffer: item ? item : null,
        });
    };

    private isOrganizationHeadhunterOrAdmin = (): boolean => {
        return this.props.userRoles.includes(UserRole.ORGANIZATION_ADMIN) || this.props.userRoles.includes(UserRole.HEADHUNTER);
    };
}

export default connect(
    (state: RootState) => ({
        offers: offersSelector(state),
        offersMetadata: offersMetadataSelector(state),
        isOffersPageLoading: isOffersPageLoadingSelector(state),
        userRoles: authUserRolesSelector(state),
    }),
    {
        fetchOffers,
        changeOffersPagination,
        resetToInitialOffersPageState,
        changeOffersFilters,
        applyOffersFilters,
    }
)(withTranslation()(withNavigation(Offers)));
