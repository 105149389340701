import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {Switch, Translation} from 'jobhunter-common-web';
import styles from './styles.module.scss';
import {EmployeeType} from '../../../../model/employeeDataModel';

interface IEmployeeViewProps {
    isModalOpen: boolean;
    toggleModal: (item: {[key: string]: any} | null) => void;
    employee: {[key: string]: any} | null;
}

interface IEmployeeViewState {
    isLoading: boolean;
}

class EmployeeView extends Component<IEmployeeViewProps, IEmployeeViewState> {
    constructor(props: IEmployeeViewProps) {
        super(props);

        this.state = {
            isLoading: false,
        };
    }

    componentDidMount(): void {}

    componentWillUnmount() {}

    render() {
        const item = this.props.employee;
        return (
            <Modal
                isOpen={this.props.isModalOpen}
                toggle={() => {
                    this.props.toggleModal(null);
                }}>
                <ModalHeader toggle={() => this.props.toggleModal(null)}>
                    <p>
                        {item?.organizationFullInfo ? (
                            <span>
                                {item?.organizationFullInfo.user.firstName} {item?.organizationFullInfo.user.lastName}
                            </span>
                        ) : (
                            <span>
                                {item?.firstName} {item?.lastName}
                            </span>
                        )}
                    </p>
                </ModalHeader>
                <ModalBody>
                    <div className={styles.employeeDetails}>
                        <p className={styles.label}>
                            <Translation text="humanResources.employeeView.email" />
                        </p>
                        {/*ToDo check if the organization employee should have email*/}
                        <p className={styles.details}>{item?.organizationFullInfo ? '---' : item?.email}</p>
                        {item?.type === EmployeeType.ORGANIZATION_EMPLOYEE ? (
                            <>
                                <p className={styles.label}>
                                    <Translation text="humanResources.employeeView.status" />
                                </p>
                                <Switch
                                    name={item?.id}
                                    handleChange={(e: any) => {
                                        console.log('name: ', e.target.name, 'checked:', e.target.checked);
                                    }}
                                    checked={item?.active}
                                    disabled={true}
                                />
                            </>
                        ) : null}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" className="close-modal-button" onClick={() => this.props.toggleModal(null)}>
                        <Translation text="buttons.ok" />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default EmployeeView;
