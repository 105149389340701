import {deepCloneObject, formatServerDateToString} from 'jobhunter-common-web';
import {injectable} from 'inversify';
import moment from 'moment';
import {getTimezoneOffset, numberToHourConverter} from '../utils/dateTransformUtils';

export enum WeekDays {
    MO = 'Mon',
    TU = 'Tue',
    WE = 'Wed',
    TH = 'Thu',
    FR = 'Fri',
    SA = 'Sat',
    SU = 'Sun',
}

export const weekDays = [WeekDays.MO, WeekDays.TU, WeekDays.WE, WeekDays.TH, WeekDays.FR, WeekDays.SA, WeekDays.SU];

export interface ICalendarCreationPayload {
    name: string;
    public: boolean;
    price: any;
    slotLength: number | null;
    availableFrom: string | null;
    availableUntil: string | null;
    timezone: string;
    calendarRules: ICalendarRule[];
    clinicEmail: string;
    specialistEmail: string | null;
}

export interface ICalendarGeneratorHourItem {
    value: number | null;
    label: string | null;
    endLabel: string | null;
    isFree: boolean;
}

export interface ITimeSlotItem extends ICalendarGeneratorHourItem {
    dayName: WeekDays;
    dayDateTimeStart: string;
    dayDateTimeEnd: string;
}

export interface calendarWeekDay {
    dayName: WeekDays;
    dayIndex: number;
    dayTimeSlots: ITimeSlotItem[];
}

export interface ICalendar {
    Mo: ITimeSlotItem[];
    Tu: ITimeSlotItem[];
    We: ITimeSlotItem[];
    Th: ITimeSlotItem[];
    Fr: ITimeSlotItem[];
    Sa: ITimeSlotItem[];
    Su: ITimeSlotItem[];
}
export interface ICalendarSettings {
    slotLength: number | null;
    availableFrom: number | null;
    availableUntil: number | null;
}

export interface ICalendarRule {
    startsAt: string;
    endsAt: string;
    interval: string;
    intervalStart: string;
    tags?: string | null;
}

export interface ICalendarSettings {
    slotLength: number | null;
    availableFrom: number | null;
    availableUntil: number | null;
}

@injectable()
class CalendarCreationService {
    // constructor() {
    //     fixInjectedProperties(this);
    // }

    public generateTimeSlotsTable = (calendarSettings: ICalendarSettings): ICalendarGeneratorHourItem[] => {
        const slotsTable: ICalendarGeneratorHourItem[] = [];
        let step: number;
        if (calendarSettings.availableFrom === null || calendarSettings.availableUntil === null || calendarSettings.slotLength === null) {
            return slotsTable;
        }
        for (step = calendarSettings.availableFrom; step < calendarSettings.availableUntil; step = step + calendarSettings.slotLength) {
            const label = numberToHourConverter(step),
                endLabel = numberToHourConverter(step + calendarSettings.slotLength);
            slotsTable.push({value: step, label: label, endLabel: endLabel, isFree: true});
        }
        return slotsTable;
    };

    public generateWeekDaysArray = (timeSlotsTable: ICalendarGeneratorHourItem[], timezone: string): calendarWeekDay[] => {
        const weekDaysArray: calendarWeekDay[] = [];

        weekDays.forEach((weekday: WeekDays, index) => {
            const weekDayDate = new Date(0);
            weekDayDate.setDate(weekDayDate.getDate() - ((weekDayDate.getDay() - (index + 1)) % 7));

            const timeSlotsFromHours: ITimeSlotItem[] = [];

            timeSlotsTable.forEach((dayTimeSlot: ICalendarGeneratorHourItem) => {
                const dateTime = formatServerDateToString(weekDayDate);
                let dateTimeStart = dateTime,
                    dateTimeEnd = dateTime;
                if (dayTimeSlot.label) {
                    dateTimeStart = dateTime + 'T' + dayTimeSlot.label + ':00.00' + timezone;
                    dateTimeStart = moment(dateTimeStart).toISOString();
                }
                if (dayTimeSlot.endLabel) {
                    dateTimeEnd = dateTime + 'T' + dayTimeSlot.endLabel + ':00' + timezone;
                    dateTimeEnd = moment(dateTimeEnd).toISOString();
                }
                const timeSlotFromHours: ITimeSlotItem = {
                    value: dayTimeSlot.value,
                    label: dayTimeSlot.label,
                    endLabel: dayTimeSlot.endLabel,
                    isFree: dayTimeSlot.isFree,
                    dayName: weekday,
                    dayDateTimeStart: dateTimeStart,
                    dayDateTimeEnd: dateTimeEnd,
                };
                return timeSlotsFromHours.push(timeSlotFromHours);
            });
            weekDaysArray.push({dayName: weekday, dayIndex: index, dayTimeSlots: timeSlotsFromHours});
        });

        return weekDaysArray;
    };

    public generateWeekFromCalendarRules = (calendarRules: ICalendarRule[]): any[] => {
        const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            calendarRulesWithWeekday: any = deepCloneObject(calendarRules);
        calendarRulesWithWeekday.forEach(
            (calendarRule: any) => (calendarRule['day'] = weekdays[new Date(calendarRule.intervalStart).getDay()])
        );
        const calendarRulesWeek: any[] = [];
        weekDays.forEach((weekDay: any) => {
            calendarRulesWeek[weekDay] = calendarRulesWithWeekday.filter((calendarRule: any) => calendarRule.day === weekDay);
        });

        return calendarRulesWeek;
    };

    public generateCalendarSettings = (fromServer: boolean, data: any): ICalendarSettings => {
        if (!fromServer) {
            return {
                availableFrom: data.value.availableFrom,
                availableUntil: data.value.availableUntil,
                slotLength: data.value.slotLength,
            };
        }

        const timezoneOffset = getTimezoneOffset(data.timezone);
        const availableFromTimezone = data.availableFrom
                ? moment(new Date(data.availableFrom)).utcOffset(timezoneOffset).format('YYYY-MM-DD HH:mm')
                : null,
            availableUntilTimezone = data.availableFrom
                ? moment(new Date(data.availableUntil)).utcOffset(timezoneOffset).format('YYYY-MM-DD HH:mm')
                : null;
        return {
            slotLength: 1,
            availableFrom: availableFromTimezone ? new Date(availableFromTimezone).getHours() : null,
            availableUntil: availableUntilTimezone ? new Date(availableUntilTimezone).getHours() : null,
        };
    };

    public getMonthAvailableDates = (availableConsultationSlots: any, currentMonth: number): Date[] => {
        const availableDates: Date[] = [],
            selectedYear = Object.keys(availableConsultationSlots as any)[0];
        const selectedMonths = (availableConsultationSlots as any)[selectedYear];
        Object.keys(selectedMonths).forEach((key: any) => {
            if (key === currentMonth.toString()) {
                const selectedMonth = selectedMonths[key];
                Object.keys(selectedMonth).forEach((key: any) => {
                    if (selectedMonth[key]) {
                        const selectedDay = selectedMonth[key],
                            retrievedDays: any[] = [];
                        Object.keys(selectedDay).forEach((day: any) => retrievedDays.push(selectedDay[day]));
                        const isDayAvailable = retrievedDays.some((el: any) => el.is_free);
                        if (isDayAvailable) {
                            return availableDates.push(new Date(`${selectedYear}-${currentMonth}-${key}`));
                        }
                    }
                });

                return availableDates;
            }
        });

        return availableDates;
    };
}

export default CalendarCreationService;
