import React, {Component} from 'react';
import {Button} from 'reactstrap';
import {Tooltip} from 'jobhunter-common-web';
import {withTranslation, WithTranslation} from 'react-i18next';

interface IButtonWithTooltipProps extends WithTranslation {
    buttonStyles?: string;
    buttonColor?: string;
    onButtonClick: () => void;
    iconComponent: any;
    tooltipTarget: string;
    tooltipText: string;
}

class ButtonWithTooltip extends Component<IButtonWithTooltipProps> {
    render() {
        const {t} = this.props;

        return (
            <Button
                className={this.props.buttonStyles ? this.props.buttonStyles : ''}
                color={this.props.buttonColor ? this.props.buttonColor : 'flat-primary'}
                onClick={() => this.props.onButtonClick()}>
                {this.props.iconComponent}
                <Tooltip target={this.props.tooltipTarget} tooltipText={t(this.props.tooltipText)} />
            </Button>
        );
    }
}

export default withTranslation()(ButtonWithTooltip);
