import {
    authTokenSelector,
    changeMenuCollapsed,
    changeMenuHidden,
    ChatContact,
    initLogout,
    isAuthenticatedSelector,
    menuCollapsedSelector,
    menuHiddenSelector,
    NotFound,
    Sidebar,
    Toast,
    Translation,
    usernameSelector,
    getChatContacts,
    chatContactsSelector,
    authAccountIdSelector,
    saveFile,
    sendMessageToOfflineAPI,
    getMessagesFromUserAPI,
    postMessageReadConfirmationAPI,
    organizationFullInfoSelector,
    IOrganisationFullInfo,
    authUserRolesSelector,
    UserRole,
} from 'jobhunter-common-web';
import React from 'react';
import {connect} from 'react-redux';
import {Chat, IWithChatConnection, withChatConnection} from '@solvee/reactjs-text-chat';
import {Route, Routes} from 'react-router-dom';
import {Subscription} from 'rxjs';
import {fixInjectedProperties, lazyInject} from '../../ioc';
import {IAlertManagerService} from '../../service/alertManagerService';
import {RootState} from '../../store/reducers';
import themeConfig from './themeConfig';
import classnames from 'classnames';
import Dashboard from '../Dashboard';
import menu from './menu-items';
import Settings from '../Settings';
import VideoChatHost from '../VideoChatHost';
import HumanResources from '../HumanResources';
import Recruitment from '../HumanResources/Recruitment';
import Candidates from '../HumanResources/Candidates';
import subMenu from './sub-menu-items';
import Employer from '../Employer';
import Positions from '../Employer/Positions';
import CooperatingHumanResources from '../Employer/CooperatingHumanResources';
import Employee from '../HumanResources/Employee';
import Services from '../Marketplace/Services';
import PurchasedServices from '../Marketplace/PurchasedServices';
import PositionDetails from '../Employer/Positions/PositionDetails';
import EditPosition from '../Employer/Positions/EditPosition';
import CandidateView from '../HumanResources/Candidates/CandidateView';
import Billings from '../Settings/Billings';
import Offers from '../HumanResources/Offers';
import OfferView from '../HumanResources/Offers/OfferView';
import EditOffer from '../HumanResources/Offers/EditOffer';
import AddOffer from '../HumanResources/Offers/AddOffer';
import SoughtPositionView from '../HumanResources/Recruitment/SoughtPositionView';
import RelocationList from '../Relocation';
import RelocationView from '../Relocation/RelocationView';
import PreferredCandidates from '../HumanResources/Offers/PreferredCandidates';
import {NavItem, NavLink} from 'reactstrap';
import {Menu} from 'react-feather';

interface IConnectedPanelHostProps {
    readonly organisationAccount: typeof IOrganisationFullInfo;
    readonly username: string;
    readonly initLogout: typeof initLogout;
    readonly menuHidden: boolean;
    readonly menuCollapsed: boolean;
    readonly changeMenuHidden: typeof changeMenuHidden;
    readonly changeMenuCollapsed: typeof changeMenuCollapsed;
    readonly authToken: string;
    readonly authenticated: boolean;
    readonly accountId: string;
    readonly userRoles: typeof UserRole | null;
    readonly getChatContacts: typeof getChatContacts;
    readonly channelsToJoin: typeof ChatContact[];
}

interface IExternalPanelHostProps {
    fullWidthLayout?: any;
}

interface IPanelHostProps extends IConnectedPanelHostProps, IExternalPanelHostProps, IWithChatConnection {}

interface IPanelHostState {
    width: number;
}

class PanelHost extends React.Component<IPanelHostProps, IPanelHostState> {
    private subscription: Subscription | null = null;
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IPanelHostProps) {
        super(props);

        this.state = {
            width: window.innerWidth,
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.props.getChatContacts();

        if (this.state.width <= 768) {
            this.props.changeMenuHidden(true);
        } else {
            this.props.changeMenuHidden(false);
        }

        window.addEventListener('resize', this.updateDimensions);
    }

    componentDidUpdate(prevProps: Readonly<IPanelHostProps>, prevState: Readonly<IPanelHostState>) {
        if (this.state.width !== prevState.width && this.state.width <= 768) {
            this.props.changeMenuHidden(true);
        }

        if (this.state.width !== prevState.width && this.state.width > 768) {
            this.props.changeMenuHidden(false);
        }
    }

    componentWillUnmount() {
        if (null !== this.subscription) {
            this.subscription.unsubscribe();
        }
        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {
        const isHidden = this.props.menuHidden;
        const menuCollapsed = this.props.menuCollapsed;
        const navbarWrapperClasses = {
            floating: 'navbar-floating',
            sticky: 'navbar-sticky',
            static: 'navbar-static',
            hidden: 'navbar-hidden',
        };
        const footerClasses = {
            static: 'footer-static',
            sticky: 'footer-fixed',
            hidden: 'footer-hidden',
        };
        const navbarType = 'floating';
        const footerType = 'static';
        const windowWidth = this.state.width;
        const isHeadhunterOnly = this.isHeadhunterOnly(),
            isStandardEmployee = this.isStandardEmployeeOnly();
        // @ts-ignore
        // @ts-ignore
        // @ts-ignore
        // @ts-ignore
        // @ts-ignore
        return (
            <div className={`${themeConfig.layout.skin}-layout`}>
                <div
                    className={classnames(
                        `wrapper vertical-layout vertical-menu-modern ${navbarWrapperClasses[navbarType] || 'navbar-floating'} ${
                            footerClasses[footerType] || 'footer-static'
                        }`,
                        {
                            'menu-collapsed': (menuCollapsed && !isHidden) || (windowWidth <= 768 && !isHidden),
                            'menu-expanded': !menuCollapsed || windowWidth > 768,
                            'menu-hide': isHidden || windowWidth <= 768,
                            // 'vertical-overlay-menu': menuCollapsed || windowWidth <= 768,
                        }
                    )}
                    {...(isHidden ? {'data-col': '1-column'} : {})}>
                    <nav className="header-navbar d-md-none d-sm-flex">
                        <div className="navbar-container d-flex justify-content-between align-items-center">
                            <ul className="bookmark-wrapper">
                                <NavItem className="mobile-menu me-auto">
                                    <NavLink
                                        className="nav-menu-main menu-toggle is-active"
                                        onClick={() => this.props.changeMenuHidden(!isHidden)}>
                                        <Menu className="ficon" />
                                    </NavLink>
                                </NavItem>
                            </ul>
                        </div>
                    </nav>

                    {!isHidden ? (
                        <Sidebar
                            skin={themeConfig.layout.skin}
                            logoImage={themeConfig.app.appLogoImage}
                            logoMobileImage={themeConfig.app.appLogoMobile}
                            menuData={[menu(isHeadhunterOnly, isStandardEmployee), subMenu(this.onLogout)]}
                            menuCollapsed={menuCollapsed}
                            setMenuCollapsed={() => this.props.changeMenuCollapsed(!menuCollapsed)}
                            currentActiveItem={null}
                        />
                    ) : null}
                    <div className="panel-wrapper">
                        <div
                            className={classnames('sidenav-overlay d-md-none d-sm-flex', {
                                show: !isHidden,
                            })}
                            onClick={() => this.props.changeMenuHidden(!isHidden)}></div>

                        <Routes>
                            <Route path="/dashboard" element={<Dashboard />} key="dashboard" />
                            <Route path="/employer" element={<Employer />} key="employer" />
                            <Route path="/employer/positions" element={<Positions />} key="positions" />
                            <Route path="/employer/positions/:id" element={<PositionDetails />} key="position_details" />
                            <Route path="/employer/positions/edit/:id" element={<EditPosition />} key="edit_position_details" />
                            <Route
                                path="/employer/cooperating_human_resources"
                                element={<CooperatingHumanResources />}
                                key="cooperating_hr"
                            />
                            <Route path="/marketplace/services" element={<Services />} key="services" />
                            <Route path="/marketplace/purchased_services" element={<PurchasedServices />} key="purchased_services" />
                            <Route
                                path="/marketplace/chat"
                                element={
                                    <Chat
                                        avatarUrl={this.props.organisationAccount?.user?.avatar?.fileUrls?.smallThumb}
                                        messagesNumberPerHistoryPage={10}
                                        canSendMessagesToOffline={this.props.canSendMessagesToOffline}
                                        authToken={this.props.authToken}
                                        confirmMessageRead={this.props.confirmMessageRead}
                                        accountId={this.props.accountId}
                                        errors={this.props.errors}
                                        getMessagesFromUser={this.props.getMessagesFromUser}
                                        errorHandler={(e: any) => this.alertManager?.handleApiError(e)}
                                        showOnlineIndicator={true}
                                        hasUnreadMessages={this.props.hasUnreadMessages}
                                        addMessage={this.props.addMessage}
                                        setSelectedChatRoomId={this.props.setSelectedChatRoomId}
                                        selectedChatRoomId={this.props.selectedChatRoomId}
                                        chatContactsList={this.props.allChannels}
                                        peerConnections={this.props.peerConnections}
                                        hasNodeConnection={this.props.hasNodeConnection}
                                        joinChannels={this.props.joinChannels}
                                        allContactsList={this.props.allowedChannels}
                                    />
                                }
                                key="chat"
                            />
                            <Route path="/settings" element={<Settings />} key="settings" />
                            <Route path="/settings/billings" element={<Billings />} key="billings" />
                            <Route path="/human_resources" element={<HumanResources />} key="human_resources" />
                            <Route path="/human_resources/employee" element={<Employee />} key="employee" />
                            <Route path="/human_resources/recruitment" element={<Recruitment />} key="recruitment" />
                            <Route
                                path="/human_resources/recruitment/:sought_position_id"
                                element={<SoughtPositionView />}
                                key="sought-position"
                            />
                            <Route path="/human_resources/offers" element={<Offers />} key="offers" />
                            <Route path="/human_resources/offers/:offer_id" element={<OfferView />} key="offer" />
                            <Route path="/human_resources/edit-offer/:id" element={<EditOffer />} key="edit-offer" />
                            <Route path="/human_resources/offers/add-offer" element={<AddOffer />} key="add-offer" />
                            <Route path="/human_resources/candidates" element={<Candidates />} key="candidates" />
                            <Route path="/human_resources/candidates/:id" element={<CandidateView />} key="candidate-view" />
                            <Route
                                path="/human_resources/recommended-candidates/:id"
                                element={<PreferredCandidates />}
                                key="recommended-candidates"
                            />
                            <Route path="/relocations" element={<RelocationList />} key="relocations" />
                            <Route
                                path="/relocations/:id"
                                element={
                                    <RelocationView
                                        // @ts-ignore MD: używanie importowanego z common routera(?) powoduje, że TS nie widzi komponentu w normalny sposób
                                        avatarUrl={this.props.organisationAccount?.user?.avatar?.fileUrls?.smallThumb}
                                        messagesNumberPerHistoryPage={10}
                                        canSendMessagesToOffline={this.props.canSendMessagesToOffline}
                                        authToken={this.props.authToken}
                                        confirmMessageRead={this.props.confirmMessageRead}
                                        accountId={this.props.accountId}
                                        errors={this.props.errors}
                                        getMessagesFromUser={this.props.getMessagesFromUser}
                                        errorHandler={(e: any) => this.alertManager?.handleApiError(e)}
                                        showOnlineIndicator={true}
                                        hasUnreadMessages={this.props.hasUnreadMessages}
                                        addMessage={this.props.addMessage}
                                        setSelectedChatRoomId={this.props.setSelectedChatRoomId}
                                        selectedChatRoomId={this.props.selectedChatRoomId}
                                        chatContactsList={this.props.allowedChannels}
                                        peerConnections={this.props.peerConnections}
                                        hasNodeConnection={this.props.hasNodeConnection}
                                        joinChannels={this.props.joinChannels}
                                        allContactsList={this.props.allChannels}
                                    />
                                }
                                key="relocation-view"
                            />
                            <Route path="/consultations/:id" element={<VideoChatHost />} key="video-chat" />
                            <Route path="*" element={<NotFound />} key="not-found" />
                        </Routes>
                        <footer
                            className={classnames(`footer footer-light ${footerClasses[footerType] || 'footer-static'}`, {
                                'd-none': footerType !== 'static',
                            })}>
                            <Translation text="footer.copyright" config={{date: new Date().getFullYear()}} />
                        </footer>
                    </div>
                </div>
                <Toast />
            </div>
        );
    }

    private onLogout = () => {
        this.props.initLogout();
        this.alertManager?.logoutSuccess();
    };

    private updateDimensions = () => {
        this.setState({width: window.innerWidth});
    };

    private isHeadhunterOnly = () => {
        return (
            this.props.userRoles.includes(UserRole.HEADHUNTER) &&
            !this.props.userRoles.includes(UserRole.STANDARD_EMPLOYEE) &&
            !this.props.userRoles.includes(UserRole.ORGANIZATION_ADMIN)
        );
    };

    private isStandardEmployeeOnly = () => {
        return (
            this.props.userRoles.includes(UserRole.STANDARD_EMPLOYEE) &&
            !this.props.userRoles.includes(UserRole.HEADHUNTER) &&
            !this.props.userRoles.includes(UserRole.ORGANIZATION_ADMIN)
        );
    };
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        isAuthenticated: isAuthenticatedSelector(state),
        accountId: authAccountIdSelector(state),
        organisationAccount: organizationFullInfoSelector(state),
        username: usernameSelector(state),
        menuCollapsed: menuCollapsedSelector(state),
        menuHidden: menuHiddenSelector(state),
        channelsToJoin: chatContactsSelector(state),
        userRoles: authUserRolesSelector(state),
    }),
    {
        initLogout,
        changeMenuHidden,
        changeMenuCollapsed,
        getChatContacts,
    }
)(
    withChatConnection(PanelHost, {
        webRTCConfig: {
            chatSignalingServerAddress: process.env.REACT_APP_CHAT_SERVER_URL || '',
            serverUrls: [
                {urls: process.env.REACT_APP_CHAT_STUN_SERVER_URL || ''},
                {
                    urls: process.env.REACT_APP_CHAT_TURN_SERVER_URL || '',
                    credential: process.env.REACT_APP_CHAT_TURN_SERVER_PASSWORD || '',
                    username: process.env.REACT_APP_CHAT_TURN_SERVER_USERNAME || '',
                },
            ],
        },
        saveMessagesConfig: {
            saveMessagesOnlyWhenPeerIsOffline: false,
            messagesNumberPerHistoryPage: 10,
            getContacts: getChatContacts,
            saveFile: saveFile,
            sendMessageToOfflineAPI: sendMessageToOfflineAPI,
            getMessagesFromUserAPI: getMessagesFromUserAPI,
            postMessageReadConfirmationAPI: postMessageReadConfirmationAPI,
        },
    })
);
