import {
    IModelApiResponseViewObject,
    IModelDictionaryDatum,
    Loader,
    LoaderType,
    Pagination,
    Price,
    Tooltip,
    Translation,
} from 'jobhunter-common-web';
import React, {Component} from 'react';
import {Search, Trash2} from 'react-feather';
import {WithTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Badge, Button, Card, CardBody, Table} from 'reactstrap';
import {IModelServiceListItem} from '../../../model/servicesDataModel';
import {RootState} from '../../../store/reducers';
import {
    changeServicesPagination,
    deleteService,
    fetchServicesList,
    resetToInitialServicePageState,
} from '../../../store/reducers/myServicesSlice';
import {isServicesPageLoading, servicesListSelector, servicesMetadataSelector} from '../../../store/selectors/myServicesSelectors';
import LayoutWrapper from '../../LayoutWrapper';
import ServiceView from '../PurchasedServices/ServiceView';
import AddService from './AddServiceModal';
import RemoveServiceModal from './RemoveServiceModal';

interface IConnectedServicesProps {
    readonly isLoading: boolean;
    readonly servicesList: IModelServiceListItem[] | null;
    readonly servicesMetadata: typeof IModelApiResponseViewObject | null;
    readonly fetchServicesList: typeof fetchServicesList;
    readonly resetToInitialServicePageState: typeof resetToInitialServicePageState;
    readonly deleteService: typeof deleteService;
    readonly changeServicesPagination: typeof changeServicesPagination;
}

interface IServicesProps extends IConnectedServicesProps, WithTranslation {}

interface IServicesState {
    isAddServiceModalOpen: boolean;
    isViewServiceModalOpen: boolean;
    selectedViewService: IModelServiceListItem | null;
    selectedDeleteService: IModelServiceListItem | null;
}

class Services extends Component<IServicesProps, IServicesState> {
    constructor(props: IServicesProps) {
        super(props);

        this.state = {
            isAddServiceModalOpen: false,
            isViewServiceModalOpen: false,
            selectedViewService: null,
            selectedDeleteService: null,
        };
    }

    componentDidMount(): void {
        this.props.fetchServicesList(null);
    }

    componentWillUnmount() {
        this.props.resetToInitialServicePageState();
    }

    render() {
        return (
            <LayoutWrapper>
                <div className="d-flex justify-content-between align-items-center">
                    <h2 className="panel-section-header">
                        <Translation text="marketplace.services.title" />
                    </h2>
                    <Button color="primary" className="add-service-button" onClick={() => this.toggleAddServiceModal()}>
                        <Translation text="marketplace.services.addService.title" />
                    </Button>
                </div>

                <Card className="services-card">
                    <CardBody>{this.renderServiceListTable()}</CardBody>
                </Card>

                <Pagination listMetadata={this.props.servicesMetadata} itemsPerPage={10} changePage={this.props.changeServicesPagination} />

                {this.state.isAddServiceModalOpen ? (
                    <AddService isModalOpen={this.state.isAddServiceModalOpen} toggleModal={this.toggleAddServiceModal} />
                ) : null}

                {this.state.selectedDeleteService ? (
                    <RemoveServiceModal
                        toggleModal={this.toggleDeleteServiceModal}
                        service={this.state.selectedDeleteService}
                        removeService={this.removeService}
                    />
                ) : null}
                {this.state.selectedViewService ? (
                    <ServiceView toggleModal={this.toggleViewServiceModal} service={this.state.selectedViewService} />
                ) : null}
                <Loader type={LoaderType.Local} showLoader={this.props.isLoading} />
            </LayoutWrapper>
        );
    }

    private renderServiceListTable = () => {
        return (
            <Table striped responsive className="services-table">
                <thead>
                    <tr>
                        <th>
                            <Translation text="marketplace.services.table.headers.service" />
                        </th>
                        <th>
                            <Translation text="marketplace.services.table.headers.type" />
                        </th>
                        <th>
                            <Translation text="marketplace.services.table.headers.description" />
                        </th>
                        <th>
                            <Translation text="marketplace.services.table.headers.price" />
                        </th>
                        <th>
                            <Translation text="marketplace.services.table.headers.status" />
                        </th>
                        <th>
                            <Translation text="marketplace.services.table.headers.actions" />
                        </th>
                    </tr>
                </thead>
                <tbody>{this.renderServicesTableRows()}</tbody>
            </Table>
        );
    };

    private renderServicesTableRows() {
        const services: IModelServiceListItem[] | null = this.props.servicesList,
            {t} = this.props;

        if (!services || services.length === 0) {
            return (
                <tr key={'no-data'}>
                    <td colSpan={6}>
                        <p>
                            <Translation text="marketplace.services.table.noData" />
                        </p>
                    </td>
                </tr>
            );
        }

        return services.map((item: any) => {
            const wasPurchased = item.purchasedCount > 0;
            return (
                <tr key={`matches_${item.id}`}>
                    <td>
                        <p className="fw-bolder">{item.title}</p>
                    </td>
                    <td>{this.renderServiceTypes(item.types)}</td>
                    <td className="text-break">{item.description}</td>
                    <td className="fw-bolder">
                        <Price price={{amount: item.grossPrice}} />
                    </td>
                    <td>{this.renderServiceStatus(item.accepted)}</td>
                    <td>
                        <div className="d-flex">
                            <div id={`delete_${item.id}`}>
                                <Button
                                    color="flat-primary"
                                    className="delete-service-button"
                                    onClick={() => this.toggleDeleteServiceModal(item)}
                                    disabled={wasPurchased}>
                                    <Trash2 size={20} />
                                    <Tooltip
                                        target={`delete_${item.id}`}
                                        tooltipText={t(
                                            `marketplace.services.table.tooltips.${wasPurchased ? 'purchasedCannotDelete' : 'delete'}`
                                        )}
                                    />
                                </Button>
                            </div>
                            <Button color="flat-primary" className="view-service-button" onClick={() => this.toggleViewServiceModal(item)}>
                                <Search id={`edit_${item.id}`} size={20} />
                                <Tooltip target={`edit_${item.id}`} tooltipText={t('marketplace.services.table.tooltips.view')} />
                            </Button>
                        </div>
                    </td>
                </tr>
            );
        });
    }

    private renderServiceTypes = (types: typeof IModelDictionaryDatum) => {
        return types.map((item: typeof IModelDictionaryDatum) => {
            return (
                <Badge color="light-primary" pill className="mb-025" key={item.id}>
                    <span className="align-middle">
                        <Translation text={item.name} />
                    </span>
                </Badge>
            );
        });
    };

    private renderServiceStatus = (status: boolean) => {
        const serviceStatusTranslationKey = status
            ? 'marketplace.services.serviceStatus.accepted'
            : 'marketplace.services.serviceStatus.pending';

        return (
            <Badge color={`${status ? 'light-primary' : 'light-warning'}`} pill>
                <Translation text={serviceStatusTranslationKey} />
            </Badge>
        );
    };

    private toggleAddServiceModal = () => {
        this.setState({
            isAddServiceModalOpen: !this.state.isAddServiceModalOpen,
        });
    };

    private toggleViewServiceModal = (service: IModelServiceListItem | null) => {
        this.setState({
            selectedViewService: service ? service : null,
        });
    };

    private toggleDeleteServiceModal = (service: IModelServiceListItem | null) => {
        this.setState({
            selectedDeleteService: service ? service : null,
        });
    };

    private removeService = (serviceId: string) => {
        this.props.deleteService(serviceId);
    };
}

export default withTranslation()(
    connect(
        (state: RootState) => ({
            isLoading: isServicesPageLoading(state),
            servicesList: servicesListSelector(state),
            servicesMetadata: servicesMetadataSelector(state),
        }),
        {
            fetchServicesList,
            deleteService,
            changeServicesPagination,
            resetToInitialServicePageState,
        }
    )(Services)
);
