import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {catchError, switchMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {
    addAlert,
    AlertType,
    authTokenSelector,
    deepCloneObject,
    flattenObj,
    getErrorMessage,
    getMetadataDetails,
    isNotNullOrUndefined,
    RestQueryParams,
} from 'jobhunter-common-web';
import {getOffersAPI} from '../../api/getOffersAPI';
import {RootState} from '../reducers';
import {
    changeIsOffersPageLoading,
    fetchOffers,
    setOffers,
    changeOffersError,
    setOffersMetadata,
    changeOffersPagination,
    IOfferAction,
    cancelOffer,
    closeOffer,
    recommendOffer,
    changeIsOfferActionComplete,
    changeIsOfferActionProcessing,
    applyOffersFilters,
    deleteOffer,
} from '../reducers/offersPageSlice';
import {offerFiltersSelector, offersPaginationSelector} from '../selectors/offersPageSelectors';
import {PayloadAction} from '@reduxjs/toolkit';
import {cancelOfferAPI} from '../../api/cancelOfferAPI';
import {closeOfferAPI} from '../../api/closeOfferAPI';
import {sendOfferRecommendationAPI} from '../../api/sendOfferRecommendationAPI';
import {deleteOfferAPI} from '../../api/deleteOfferAPI';

const fetchOffersEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getOffers(action$, state$, fetchOffers);
};

const changeOffersPaginationEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getOffers(action$, state$, changeOffersPagination);
};

const cancelOfferEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return offerAction(action$, state$, cancelOffer, 'humanResources.offers.deleteOffer.offerDeleted', cancelOfferAPI);
};

const closeOfferEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return offerAction(action$, state$, closeOffer, 'humanResources.offers.closeOffer.offerClosed', closeOfferAPI);
};

const deleteOfferEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return offerAction(action$, state$, deleteOffer, 'humanResources.offers.closeOffer.offerClosed', deleteOfferAPI);
};

const applyOfferFiltersEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getOffers(action$, state$, applyOffersFilters);
};

const recommendOfferEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return offerAction(
        action$,
        state$,
        recommendOffer,
        'humanResources.offers.recommendOffer.recommendationSent',
        sendOfferRecommendationAPI
    );
};

const getOffers = (action$: Observable<any>, state$: StateObservable<any>, actionType: any) => {
    return action$.pipe(
        ofType(actionType.type),
        switchMap(() => {
            const authToken = authTokenSelector(state$.value),
                paginationParams = offersPaginationSelector(state$.value),
                filters = deepCloneObject(offerFiltersSelector(state$.value)),
                filterObj = {
                    ...filters,
                    ...paginationParams,
                },
                flattened = flattenObj(filterObj),
                queryParams = new RestQueryParams(flattened).add('order[createdAt]', 'desc'); // .add('exists[soughtPosition]', 'false');

            return getOffersAPI(authToken, queryParams).pipe(
                switchMap((resp: any) => {
                    const metadata = getMetadataDetails(resp['hydra:view']),
                        actions = successActions([setOffers(resp['hydra:member']), setOffersMetadata(metadata)]);
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const offerAction = (
    action$: Observable<any>,
    state$: StateObservable<any>,
    actionType: any,
    successMessage: string,
    api: (authToken: string | null, offerId: string, payload: any) => Observable<any>
) => {
    return action$.pipe(
        ofType(actionType.type),
        switchMap((action: PayloadAction<IOfferAction>) => {
            const authToken = authTokenSelector(state$.value),
                offerId = action.payload.offerId,
                payload = isNotNullOrUndefined(action.payload.actionPayload) ? action.payload.actionPayload : {};

            return api(authToken, offerId, payload).pipe(
                switchMap(() => {
                    const actions = [
                        changeIsOfferActionComplete(true),
                        changeIsOfferActionProcessing(false),
                        addAlert({message: successMessage}),
                        fetchOffers(),
                    ];
                    return of(...actions);
                }),
                catchError((error) => of(...offerActionErrorList(error)))
            );
        }),
        catchError((error) => of(...offerActionErrorList(error)))
    );
};

const successActions = (changeSliceList?: any[]): any[] => {
    const actions = [changeIsOffersPageLoading(false)];

    if (changeSliceList) {
        return actions.concat(changeSliceList);
    }
    return actions;
};

const updateListErrorActions = (error: any): any[] => {
    return [
        changeIsOffersPageLoading(false),
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
        changeOffersError(getErrorMessage(error)),
    ];
};

const offerActionErrorList = (error: any): any[] => {
    return [
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
        changeIsOfferActionComplete(true),
        changeIsOfferActionProcessing(false),
    ];
};

const offersEpic = combineEpics(
    fetchOffersEpic,
    changeOffersPaginationEpic,
    closeOfferEpic,
    cancelOfferEpic,
    deleteOfferEpic,
    recommendOfferEpic,
    applyOfferFiltersEpic
);

export default offersEpic;
