import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IAddEmployeePayload} from '../../api/addEmployeeAPI';
import {IModelExternalEmployee} from '../../model/employeeDataModel';
import {IModelApiResponseViewObject} from '../../model/base';
import {IChangePagination, IPaginationParams, ISetMetadata} from './offersPageSlice';

export interface IAddEmployee {
    employee: IAddEmployeePayload;
}

export interface ISetExternalEmployeesList {
    externalEmployeesList: IModelExternalEmployee[] | null;
}

export interface IChangeExternalEmployeesListLoading {
    isExternalEmployeesListLoading: boolean;
}

export interface IChangeEmployeesPageInitialized {
    isEmployeesPageInitialized: boolean;
}

export interface IChangeEmployeeUpdated {
    isEmployeesUpdated: boolean;
}

export interface IChangeEmployeeDeleted {
    isEmployeeDeleted: boolean;
}
export interface IChangeEmployeesPageError {
    employeesPageError: string | null;
}

export interface IEmployeeAction {
    employeeId: string;
    role?: string;
}

export interface IChangeExternalEmployee {
    id: string;
    info: IAddEmployeePayload;
}

export interface IEmployeesPageState {
    readonly externalEmployeesList: IModelExternalEmployee[] | null;
    readonly employeesMetadata: IModelApiResponseViewObject | null;
    readonly pagination: IPaginationParams | null;
    readonly isExternalEmployeesListLoading: boolean;
    readonly isEmployeesPageInitialized: boolean;
    readonly employeesPageError: string | null;
    readonly isEmployeeAdded: boolean;
    readonly isEmployeeDeleted: boolean;
}

const initialState: IEmployeesPageState = {
    externalEmployeesList: null,
    employeesMetadata: null,
    pagination: {
        itemsPerPage: 25,
        page: 1,
    },
    isEmployeesPageInitialized: false,
    isExternalEmployeesListLoading: false,
    isEmployeeAdded: false,
    employeesPageError: null,
    isEmployeeDeleted: false,
};

const employeesPageSlice = createSlice({
    name: 'employeesPage',
    initialState: initialState,
    reducers: {
        changeExternalEmployee: {
            reducer: (state: IEmployeesPageState) => {
                return {
                    ...state,
                    isExternalEmployeesListLoading: true,
                };
            },
            prepare: (id: string, info: IAddEmployeePayload) => {
                return {
                    payload: {
                        id: id,
                        info: info,
                    },
                };
            },
        },
        changeEmployeeRole: {
            reducer: (state: IEmployeesPageState) => {
                return {
                    ...state,
                    isExternalEmployeesListLoading: true,
                };
            },
            prepare: (employeeId: string, role: string) => {
                return {
                    payload: {
                        employeeId: employeeId,
                        role: role,
                    },
                };
            },
        },
        deleteEmployee: {
            reducer: (state: IEmployeesPageState) => {
                return {
                    ...state,
                    isExternalEmployeesListLoading: true,
                };
            },
            prepare: (employeeId: string) => {
                return {
                    payload: {
                        employeeId: employeeId,
                    },
                };
            },
        },
        changeIsEmployeeDeleted: {
            reducer: (state: IEmployeesPageState, action: PayloadAction<IChangeEmployeeDeleted>) => {
                return {
                    ...state,
                    isEmployeeDeleted: action.payload.isEmployeeDeleted,
                };
            },
            prepare: (isEmployeeDeleted: boolean) => {
                return {
                    payload: {
                        isEmployeeDeleted: isEmployeeDeleted,
                    },
                };
            },
        },
        addEmployee: {
            reducer: (state: IEmployeesPageState) => {
                return {
                    ...state,
                    isExternalEmployeesListLoading: true,
                };
            },
            prepare: (employee: any) => {
                return {
                    payload: {
                        employee: employee,
                    },
                };
            },
        },
        changeIsEmployeeAdded: {
            reducer: (state: IEmployeesPageState, action: PayloadAction<IChangeEmployeeUpdated>) => {
                return {
                    ...state,
                    isEmployeeAdded: action.payload.isEmployeesUpdated,
                };
            },
            prepare: (isEmployeesUpdated: boolean) => {
                return {
                    payload: {
                        isEmployeesUpdated: isEmployeesUpdated,
                    },
                };
            },
        },
        changeIsEmployeesPageInitialized: {
            reducer: (state: IEmployeesPageState, action: PayloadAction<IChangeEmployeesPageInitialized>) => {
                return {
                    ...state,
                    isEmployeesPageInitialized: action.payload.isEmployeesPageInitialized,
                };
            },
            prepare: (isEmployeesPageInitialized: boolean) => {
                return {
                    payload: {
                        isEmployeesPageInitialized: isEmployeesPageInitialized,
                    },
                };
            },
        },
        changeIsExternalListLoading: {
            reducer: (state: IEmployeesPageState, action: PayloadAction<IChangeExternalEmployeesListLoading>) => {
                return {
                    ...state,
                    isExternalEmployeesListLoading: action.payload.isExternalEmployeesListLoading,
                };
            },
            prepare: (isExternalEmployeesListLoading: boolean) => {
                return {
                    payload: {
                        isExternalEmployeesListLoading: isExternalEmployeesListLoading,
                    },
                };
            },
        },
        setExternalEmployeesList: {
            reducer: (state: IEmployeesPageState, action: PayloadAction<ISetExternalEmployeesList>) => {
                return {
                    ...state,
                    externalEmployeesList: action.payload.externalEmployeesList,
                };
            },
            prepare: (externalEmployeesList: any) => {
                return {
                    payload: {
                        externalEmployeesList: externalEmployeesList,
                    },
                };
            },
        },
        changeEmployeesPageError: {
            reducer: (state: IEmployeesPageState, action: PayloadAction<IChangeEmployeesPageError>) => {
                return {
                    ...state,
                    employeesPageError: action.payload.employeesPageError,
                };
            },
            prepare: (employeesPageError: any) => {
                return {
                    payload: {
                        employeesPageError: employeesPageError,
                    },
                };
            },
        },
        setEmployeesMetadata: {
            reducer: (state: IEmployeesPageState, action: PayloadAction<ISetMetadata>) => {
                return {
                    ...state,
                    employeesMetadata: action.payload.metadata,
                };
            },
            prepare(metadata: IModelApiResponseViewObject | null) {
                return {
                    payload: {
                        metadata: metadata,
                    },
                };
            },
        },
        changeEmployeesPagination: {
            reducer: (state: IEmployeesPageState, action: PayloadAction<IChangePagination>) => {
                return {
                    ...state,
                    isExternalEmployeesListLoading: true,
                    pagination: action.payload.pagination,
                };
            },
            prepare(pagination: IPaginationParams) {
                return {
                    payload: {pagination: pagination},
                };
            },
        },
        fetchExternalEmployeesList: (state: IEmployeesPageState) => {
            return {
                ...state,
                isExternalEmployeesListLoading: true,
            };
        },
        resetToInitialEmployeesPageState: () => {
            return {
                ...initialState,
            };
        },
    },
});
export const {
    addEmployee,
    fetchExternalEmployeesList,
    resetToInitialEmployeesPageState,
    changeIsEmployeeDeleted,
    changeIsEmployeesPageInitialized,
    changeEmployeeRole,
    deleteEmployee,
    changeIsExternalListLoading,
    setExternalEmployeesList,
    changeEmployeesPageError,
    changeExternalEmployee,
    changeIsEmployeeAdded,
    setEmployeesMetadata,
    changeEmployeesPagination,
} = employeesPageSlice.actions;

export default employeesPageSlice.reducer;
