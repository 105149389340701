import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IRecruitmentPageState} from '../reducers/recruitmentPageSlice';

export const selectRecruitmentPage = (state: RootState) => {
    return state.recruitmentPage;
};

export const recruitmentListSelector = createSelector([selectRecruitmentPage], (state: IRecruitmentPageState) => state.recruitmentList);

export const recruitmentMetadataSelector = createSelector(
    [selectRecruitmentPage],
    (state: IRecruitmentPageState) => state.recruitmentMetadata
);

export const recruitmentPaginationSelector = createSelector([selectRecruitmentPage], (state: IRecruitmentPageState) => state.pagination);

export const isRecruitmentListInitializedSelector = createSelector(
    [selectRecruitmentPage],
    (state: IRecruitmentPageState) => state.isRecruitmentListInitialized
);

export const isRecruitmentListLoadingSelector = createSelector(
    [selectRecruitmentPage],
    (state: IRecruitmentPageState) => state.isRecruitmentListLoading
);

export const recruitmentListErrorSelector = createSelector(
    [selectRecruitmentPage],
    (state: IRecruitmentPageState) => state.recruitmentListError
);
