import {
    IFormConfig,
    FormControlType,
    MultiSelectType,
    collectionInputDataMapper,
    collectionOutputDataMapper,
    multiCollectionInputDataMapper,
    multiCollectionOutputDataMapper,
    IMultiselectOption,
} from 'jobhunter-common-web';

export const candidateFiltersFormConfig = (
    candidatesList?: typeof IMultiselectOption[] | [],
    offersList?: typeof IMultiselectOption[] | [],
    positionsList?: typeof IMultiselectOption[] | []
): typeof IFormConfig => ({
    controlType: 'group',
    class: 'default-form',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'candidates_filters',
            class: 'row',
            controls: {
                candidates: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    multiselectOptions: candidatesList,
                    validationRules: [],
                    placeholder: 'humanResources.dashboard.candidates.form.placeholders.candidates',
                    label: 'humanResources.dashboard.candidates.form.labels.candidates',
                    hostClass: 'col-xl-4',
                    inputDataMapper: collectionInputDataMapper,
                    outputDataMapper: collectionOutputDataMapper,
                },
                offers: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.DEFAULT,
                    multiselectOptions: offersList,
                    validationRules: [],
                    placeholder: 'humanResources.dashboard.candidates.form.placeholders.offer',
                    label: 'humanResources.dashboard.candidates.form.labels.offer',
                    hostClass: 'col-xl-4',
                    inputDataMapper: multiCollectionInputDataMapper,
                    outputDataMapper: multiCollectionOutputDataMapper,
                },
                positions: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    multiselectOptions: positionsList,
                    validationRules: [],
                    placeholder: 'humanResources.dashboard.candidates.form.placeholders.position',
                    label: 'humanResources.dashboard.candidates.form.labels.position',
                    hostClass: 'col-xl-4',
                    inputDataMapper: collectionInputDataMapper,
                    outputDataMapper: collectionOutputDataMapper,
                },
            },
        },
    ],
});
