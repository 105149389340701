import React, {Component} from 'react';
import styles from '../../../Employer/Positions/PositionDetails/styles.module.scss';
import {
    authUserRolesSelector,
    DateComponent,
    Loader,
    LoaderType,
    Price,
    Tooltip,
    Translation,
    UserRole,
    withRouterWrapper,
    WithRouterWrapperProps,
} from 'jobhunter-common-web';
import {Button, Card, CardBody, CardFooter, CardHeader} from 'reactstrap';
import LayoutWrapper from '../../../LayoutWrapper';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {
    downloadOrganizationVerificationFile,
    fetchSoughtPositionDetails,
    resetToInitialSoughtPositionDetailsState,
} from '../../../../store/reducers/soughtPositionDetailsPageSlice';
import {
    isSoughtPositionDetailsLoadingSelector,
    soughtPositionDetailsSelector,
} from '../../../../store/selectors/soughtPositionDetailsSelectors';
import {IModelRecruitment} from '../../../../model/recruitmentDataModel';
import SoughtPositionOffersList from './SoughtPositionOffersList';
import {Download} from 'react-feather';
import {WithTranslation, withTranslation} from 'react-i18next';

declare type WithRouterWrapper = typeof WithRouterWrapperProps;

interface IConnectedSoughtPositionViewProps {
    readonly soughtPositionDetails: IModelRecruitment | null;
    readonly isSoughtPositionDetailsLoading: boolean;
    readonly userRoles: typeof UserRole | null;
    readonly fetchSoughtPositionDetails: typeof fetchSoughtPositionDetails;
    readonly downloadOrganizationVerificationFile: typeof downloadOrganizationVerificationFile;
    readonly resetToInitialSoughtPositionDetailsState: typeof resetToInitialSoughtPositionDetailsState;
}

interface ISoughtPositionViewProps extends IConnectedSoughtPositionViewProps, WithRouterWrapper, WithTranslation {}

class SoughtPositionView extends Component<ISoughtPositionViewProps> {
    componentDidMount() {
        if (this.props.router && this.props.router.params && this.props.router.params.sought_position_id) {
            const soughtPositionId = this.props.router.params.sought_position_id;
            this.props.fetchSoughtPositionDetails(soughtPositionId);
        }
    }

    componentWillUnmount() {
        this.props.resetToInitialSoughtPositionDetailsState();
    }

    render() {
        const position: IModelRecruitment | null = this.props.soughtPositionDetails;

        return (
            <LayoutWrapper>
                <div className={styles.positionDetails}>
                    <div className="panel-section-header">
                        <Translation text="employer.positions.positionDetails.title" />
                    </div>
                    <div className="row">
                        <div className="col-lg-8 col-12">
                            <Card className="position-details-card">
                                <CardHeader className={styles.cardHeader}>
                                    <div className={styles.positionHeader}>
                                        <div className={styles.title}>
                                            <p>
                                                {position?.organizationName ? (
                                                    <span className={styles.companyName}>{position?.organizationName}</span>
                                                ) : null}

                                                {position?.name}
                                            </p>
                                            <p className={styles.date}>
                                                {position && position.createdAt ? <DateComponent date={position.createdAt} /> : '---'}
                                            </p>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className={styles.cardBody}>
                                    <div className={styles.description}>{position?.description}</div>
                                </CardBody>
                                <CardFooter className={styles.cardFooter}>
                                    <div className={styles.positionFooter}>
                                        <div className={styles.label}>
                                            <p>
                                                <Translation text="employer.positions.positionDetails.salary" />
                                            </p>
                                            <p className={styles.value}>
                                                <Price price={{amount: position?.minimumSalary}} /> -{' '}
                                                <Price price={{amount: position?.maximumSalary}} />
                                            </p>
                                        </div>
                                    </div>
                                </CardFooter>
                            </Card>
                        </div>

                        <div className="col-lg-4 col-12">{this.renderEmployerDetails(position)}</div>
                    </div>

                    <Card className="position-offers-card">
                        <CardHeader>
                            <div className={styles.offersHeader}>
                                <div className={styles.title}>
                                    <p>
                                        <Translation text="employer.positions.positionDetails.offers.title" />
                                    </p>
                                </div>

                                {this.isOrganizationAdminOrHeadHunter() ? (
                                    <Button
                                        color="primary"
                                        className="add-offer-button"
                                        onClick={() => this.redirectToAddNewOffer(position)}>
                                        <Translation text="employer.positions.addOffer" />
                                    </Button>
                                ) : null}
                            </div>
                        </CardHeader>
                        <CardBody>
                            <SoughtPositionOffersList
                                recruitment={position}
                                isOrganizationHeadhunter={this.isOrganizationAdminOrHeadHunter()}
                            />
                        </CardBody>
                    </Card>
                </div>
                <Loader type={LoaderType.Local} showLoader={this.props.isSoughtPositionDetailsLoading} />
            </LayoutWrapper>
        );
    }

    private redirectToAddNewOffer = (item: IModelRecruitment | null) => {
        this.props.router.navigate(`/panel/human_resources/offers/add-offer`, {state: {soughtPositionId: item?.id}});
    };

    private isOrganizationAdminOrHeadHunter = (): boolean => {
        return this.props.userRoles.includes(UserRole.HEADHUNTER) || this.props.userRoles.includes(UserRole.ORGANIZATION_ADMIN);
    };

    private renderEmployerDetails = (position: IModelRecruitment | null) => {
        const {t} = this.props;

        return (
            <Card className="employer-details-card">
                <CardHeader className={styles.cardHeader}>
                    <div className={styles.positionHeader}>
                        <div className={styles.title}>
                            <p>
                                <Translation text="employer.positions.employerDetails.title" />
                            </p>
                        </div>
                    </div>
                </CardHeader>
                <CardBody className={styles.cardBody}>
                    <div className={styles.description}>
                        {position?.verificationFiles && position.verificationFiles.length ? (
                            position.verificationFiles.map((file) => {
                                const fileName = file?.file?.originalFileName ? file.file.originalFileName : file.fileName;
                                return (
                                    <div key={file.file.id} className={styles.fileItem}>
                                        <p>
                                            {fileName} - <Translation text={file.type.name} />
                                        </p>

                                        <Button
                                            color="flat-primary"
                                            className="download-file-button"
                                            onClick={() => this.downloadFile(file.file.id)}>
                                            <Download id={`download_${file.file.id}`} size={20} />
                                            <Tooltip
                                                target={`download_${file.file.id}`}
                                                tooltipText={t('relocationView.files.tooltips.download')}
                                            />
                                        </Button>
                                    </div>
                                );
                            })
                        ) : (
                            <Translation text="employer.positions.employerDetails.noFiles" />
                        )}
                    </div>
                </CardBody>
                <CardFooter className={styles.cardFooter}>
                    <div className={styles.positionFooter}>
                        <div className={styles.label}>
                            <p>
                                <Translation text="employer.positions.employerDetails.taxId" />
                            </p>
                            <p className={styles.value}>
                                {position?.taxId ? (
                                    position.taxId
                                ) : (
                                    <span>
                                        <Translation text="employer.positions.employerDetails.noTaxId" />
                                    </span>
                                )}
                            </p>
                        </div>
                    </div>
                </CardFooter>
            </Card>
        );
    };

    private downloadFile = (fileId: string) => {
        this.props.downloadOrganizationVerificationFile(fileId);
    };
}

export default connect(
    (state: RootState) => ({
        soughtPositionDetails: soughtPositionDetailsSelector(state),
        isSoughtPositionDetailsLoading: isSoughtPositionDetailsLoadingSelector(state),
        userRoles: authUserRolesSelector(state),
    }),
    {
        fetchSoughtPositionDetails,
        downloadOrganizationVerificationFile,
        resetToInitialSoughtPositionDetailsState,
    }
)(withRouterWrapper(withTranslation()(SoughtPositionView)));
