import {CreditCard, LogOut, Settings} from 'react-feather';
import React from 'react';
import {ISideMenuGroup, ISideMenuHeader, ISideMenuItem} from 'jobhunter-common-web';

const subMenu = (onLogout?: () => void): (typeof ISideMenuGroup | typeof ISideMenuItem | typeof ISideMenuHeader)[] => [
    {
        id: 'settings',
        title: 'Settings',
        icon: <Settings size={20} />,
        navLink: '/panel/settings',
        navLinkClassname: 'settings-link',
        children: [
            {
                id: 'billings',
                title: 'Billings',
                icon: <CreditCard size={20} />,
                navLink: '/panel/settings/billings',
                navLinkClassname: 'billings-link',
            },
        ],
    },
    {
        id: 'logout',
        title: 'Logout',
        icon: <LogOut size={20} />,
        navLinkClassname: 'logout-button',
        onClick: () => {
            if (onLogout) {
                return onLogout();
            }
        },
    },
];
export default subMenu;
