import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {Translation} from 'jobhunter-common-web';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {isEmployeeDeletedSelector} from '../../../../store/selectors/employeesPageSelectors';
import {deleteEmployee} from '../../../../store/reducers/employeesPageSlice';

interface IExternalDeleteEmployeeProps {
    isModalOpen: boolean;
    toggleModal: (employee: {[key: string]: any} | null) => void;
    employee?: {[key: string]: any} | null;
}
interface IConnectedDeleteEmployeeProps {
    readonly isEmployeeDeleted: boolean;
    readonly deleteEmployee: typeof deleteEmployee;
}
interface IDeleteEmployeeProps extends IConnectedDeleteEmployeeProps, IExternalDeleteEmployeeProps {}

interface IDeleteEmployeeState {
    isLoading: boolean;
}

class DeleteEmployee extends Component<IDeleteEmployeeProps, IDeleteEmployeeState> {
    constructor(props: IDeleteEmployeeProps) {
        super(props);

        this.state = {
            isLoading: false,
        };
    }

    componentDidUpdate(prevProps: IDeleteEmployeeProps): void {
        if (this.props.isEmployeeDeleted !== prevProps.isEmployeeDeleted && this.props.isEmployeeDeleted) {
            this.props.toggleModal(null);
        }
    }

    render() {
        return (
            <Modal isOpen={this.props.isModalOpen} toggle={() => this.props.toggleModal(null)}>
                <ModalHeader toggle={() => this.props.toggleModal(null)}>
                    <Translation text="humanResources.deleteEmployeeModal.title" />
                </ModalHeader>
                <ModalBody>
                    <p className="modal-description">
                        <Translation text="humanResources.deleteEmployeeModal.description" />
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" outline className="close-modal-button" onClick={() => this.props.toggleModal(null)}>
                        <Translation text="buttons.cancel" />
                    </Button>

                    <Button color="primary" className="remove-employee-button" onClick={() => this.deleteEmployee()}>
                        <Translation text="buttons.delete" />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    private deleteEmployee = () => {
        if (this.props.employee === null || this.props.employee === undefined || this.props.employee?.applicationId === null) {
            return;
        }
        this.props.deleteEmployee(this.props.employee?.id);
        this.props.toggleModal(null);
    };
}

export default connect(
    (state: RootState) => ({
        isEmployeeDeleted: isEmployeeDeletedSelector(state),
    }),
    {
        deleteEmployee,
    }
)(DeleteEmployee);
