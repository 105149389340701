import {
    AccountImageHost,
    accountSelector,
    authTokenSelector,
    citiesSelector,
    countriesSelector,
    deepCloneObject,
    Form,
    FormControlChangeType,
    IFileOutput,
    IFormConfig,
    IModelDictionaryDatum,
    convertToMultiselectLabels,
    isSameValue,
    Loader,
    LoaderType,
    Translation,
    sortMultiselectLabels,
} from 'jobhunter-common-web';
import {Component} from 'react';
import {ArrowLeft, Save} from 'react-feather';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {BehaviorSubject, Subscription} from 'rxjs';
import {debounceTime, filter, tap} from 'rxjs/operators';
import {RootState} from '../../../../store/reducers';
import {changeCompanyInformation, ICompanyInformation, submitCompanyInformation} from '../../../../store/reducers/employerPageSlice';
import {companyInformationSelector, employerPageLoadingSelector} from '../../../../store/selectors/employerPageSelectors';
import {companyInformationFormConfig} from './companyInformationFormConfig';

interface IConnectedCompanyProps {
    readonly countries: typeof IModelDictionaryDatum[] | null;
    readonly authToken: string | null;
    readonly account: any;
    readonly companyInformation: ICompanyInformation | null;
    readonly isLoading: boolean;
    readonly changeCompanyInformation: typeof changeCompanyInformation;
    readonly submitCompanyInformation: typeof submitCompanyInformation;
}

interface IExternalCompanyProps {
    stepper: any;
}

interface ICompanyProps extends IConnectedCompanyProps, IExternalCompanyProps, WithTranslation {}

interface ICompanyState {
    value: any;
    formConfig: typeof IFormConfig | null;
}

class Company extends Component<ICompanyProps, ICompanyState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: ICompanyProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: null,
        };
    }

    componentDidMount(): void {
        this.setFormValuesFromState();
        this.setFormConfig();

        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    debounceTime(500),
                    tap((data: any) => this.changeStateCompanyInformation(data.value))
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: Readonly<ICompanyProps>): void {
        if (this.props.companyInformation && this.props.companyInformation !== prevProps.companyInformation) {
            this.setFormValuesFromState();
        }
        if (
            !isSameValue(this.props.countries, prevProps.countries) ||
            (!isSameValue(this.props.companyInformation, prevProps.companyInformation) && this.props.companyInformation)
        ) {
            this.setFormConfig();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        const companyInformationImage =
            this.props.companyInformation && this.props.companyInformation.avatar ? this.props.companyInformation.avatar : null;
        return (
            <section className="wizard-content">
                <div className="personal-information-section">
                    <div className="header">
                        <div>
                            <h2 className="title">
                                <Translation text="employer.company.title" />
                            </h2>
                            <p className="sub-title">
                                <Translation text="employer.company.description" />
                            </p>
                        </div>
                    </div>

                    <div className="image-upload-host">
                        <AccountImageHost
                            externalImage={companyInformationImage}
                            imageUploadAction={(file: typeof IFileOutput) => this.submitCompanyInformation({avatar: file})}
                        />
                    </div>
                </div>

                <div>
                    {this.state.formConfig && (
                        <Form
                            config={this.state.formConfig}
                            onValueStateChange={this.onValueStateChange}
                            value={this.state.value}
                            controlName={'changeCompanyForm'}
                            onButtonClicked={this.goNext}
                        />
                    )}
                </div>
                <Loader type={LoaderType.Local} showLoader={this.props.isLoading} />
            </section>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private goNext = (name: string) => {
        if (name === 'submit') {
            this.submitCompanyInformation(this.state.value);
            return this.props.stepper.next();
        }
        return this.props.stepper.previous();
    };

    private submitCompanyInformation = (data: any) => {
        const payloadData = deepCloneObject(data);
        if (data.avatar) {
            payloadData.avatar = data.avatar.id;
        }
        this.props.submitCompanyInformation(payloadData);
    };

    private changeStateCompanyInformation = (data: ICompanyInformation) => {
        const updateStatePayload: ICompanyInformation = this.mapFormDataToState(deepCloneObject(data));
        this.props.changeCompanyInformation(updateStatePayload);
        this.setState({value: updateStatePayload});
    };

    private setFormConfig = () => {
        const {t} = this.props;
        const countries = this.props.countries ? sortMultiselectLabels(convertToMultiselectLabels(this.props.countries, t)) : [],
            prevButtonIcon = <ArrowLeft size={14} className="align-middle me-sm-25 me-0" />,
            nextButtonIcon = <Save size={14} className="align-middle me-sm-25 me-0" />;

        this.setState({
            formConfig: companyInformationFormConfig(countries, prevButtonIcon, nextButtonIcon),
        });
    };

    private setFormValuesFromState = () => {
        const companyInformation = this.props.companyInformation,
            values = {
                name: companyInformation?.name,
                mobile: companyInformation?.mobile,
                address: companyInformation?.address,
                about: companyInformation?.about,
                cityName: companyInformation?.cityName,
                country:
                    companyInformation && companyInformation.country
                        ? companyInformation.country.id
                            ? companyInformation.country.id
                            : companyInformation.country
                        : null,
            };

        this.setState({value: values});
    };

    private mapFormDataToState(data: any): ICompanyInformation {
        const currentStateData = this.props.companyInformation;
        const updateStatePayload: ICompanyInformation = {
            address: data.address ? data.address : currentStateData?.address,
            avatar: data.avatar ? data.avatar : currentStateData?.avatar,
            cityName: data.cityName ? data.cityName : currentStateData?.cityName,
            country: data.country ? data.country : currentStateData?.country,
            mobile: data.mobile ? data.mobile : currentStateData?.mobile,
            about: data.about ? data.about : currentStateData?.about,
            name: data.name ? data.name : currentStateData?.name,
        };
        return updateStatePayload;
    }
}

export default withTranslation()(
    connect(
        (state: RootState) => ({
            authToken: authTokenSelector(state),
            account: accountSelector(state),
            companyInformation: companyInformationSelector(state),
            cities: citiesSelector(state),
            countries: countriesSelector(state),
            isLoading: employerPageLoadingSelector(state),
        }),
        {
            submitCompanyInformation,
            changeCompanyInformation,
        }
    )(Company)
);
