import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {catchError, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {RootState} from '../reducers';
import {addAlert, AlertType, authTokenSelector, getErrorMessage, downloadOrganizationVerificationFileAPI} from 'jobhunter-common-web';
import {PayloadAction} from '@reduxjs/toolkit';
import {getSoughtPositionAPI} from '../../api/getSoughtPositionAPI';
import {
    changeIsSoughtPositionDetailsPageLoading,
    changeSoughtPositionDetailsPageError,
    downloadOrganizationVerificationFile,
    fetchSoughtPositionDetails,
    IFetchSoughtPosition,
    setSoughtPositionDetails,
} from '../reducers/soughtPositionDetailsPageSlice';
import {IRelocationFileAction} from '../reducers/relocationDetailsPageSlice';
import {saveAs} from 'file-saver';

const fetchSoughtPositionDetailsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchSoughtPositionDetails.type),
        switchMap((action: PayloadAction<IFetchSoughtPosition>): any => {
            const authToken = authTokenSelector(state$.value);
            return getSoughtPositionAPI(authToken, action.payload.soughtPositionId).pipe(
                switchMap((resp: any) => {
                    const actions = [setSoughtPositionDetails(resp), changeIsSoughtPositionDetailsPageLoading(false)];
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const downloadOrganizationVerificationFileEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(downloadOrganizationVerificationFile.type),
        switchMap((action: PayloadAction<IRelocationFileAction>): any => {
            const authToken = authTokenSelector(state$.value);
            return downloadOrganizationVerificationFileAPI(authToken, action.payload.fileId).pipe(
                switchMap((response: any) => {
                    let filename = null;
                    const disposition = response?.responseHeaders?.['content-disposition'];
                    if (disposition?.includes('attachment')) {
                        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        const matches = filenameRegex.exec(disposition);
                        if (matches?.[1]) {
                            filename = matches[1].replace(/['"]/g, '');
                            filename = decodeURIComponent(filename);
                            filename = filename.replace(/^UTF-8/i, '').trim();
                        }
                    }
                    saveAs(response.response, filename as any);

                    const message = 'relocationView.files.downloadFile.fileDownloaded',
                        actions = [addAlert({message: message})];
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const updateListErrorActions = (error: any): any[] => {
    const errorMessage =
        error.status && error.status === 403 ? 'employer.positions.employerDetails.errors.notAuthorized' : getErrorMessage(error);
    return [
        addAlert({message: errorMessage, type: AlertType.WARNING}),
        changeSoughtPositionDetailsPageError(getErrorMessage(error)),
        changeIsSoughtPositionDetailsPageLoading(false),
    ];
};

const soughtPositionDetailsEpic = combineEpics(fetchSoughtPositionDetailsEpic, downloadOrganizationVerificationFileEpic);

export default soughtPositionDetailsEpic;
