import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IApplicationOffer} from '../../model/offerDataModel';
import {IModelApiResponseViewObject} from '../../model/base';
import {IChangePagination, IPaginationParams, ISetMetadata} from './offersPageSlice';

export interface IFetchPreferredCandidatesList {
    offerId: string;
}

export interface ISetPreferredCandidatesList {
    preferredCandidatesList: IApplicationOffer[] | null;
}

export interface IPreferredCandidatesListLoading {
    isPreferredCandidatesListLoading: boolean;
}

export interface IPreferredCandidatesListInitialized {
    isPreferredCandidatesListInitialized: boolean;
}

export interface IPreferredCandidatesListError {
    preferredCandidatesPagePageError: string | null;
}

export interface IPreferredCandidatesState {
    preferredCandidatesList: IApplicationOffer[] | null;
    preferredCandidatesMetadata: IModelApiResponseViewObject | null;
    pagination: IPaginationParams | null;
    isPreferredCandidatesListLoading: boolean;
    isPreferredCandidatesListInitialized: boolean;
    preferredCandidatesPageError: string | null;
}
const initialState: IPreferredCandidatesState = {
    preferredCandidatesList: null,
    preferredCandidatesMetadata: null,
    pagination: {
        itemsPerPage: 25,
        page: 1,
    },
    isPreferredCandidatesListInitialized: false,
    isPreferredCandidatesListLoading: false,
    preferredCandidatesPageError: null,
};

const preferredCandidatesPageSlice = createSlice({
    name: 'preferredCandidatesPage',
    initialState: initialState,
    reducers: {
        fetchPreferredCandidatesList: {
            reducer: (state: IPreferredCandidatesState) => {
                return {
                    ...state,
                    isPreferredCandidatesListLoading: true,
                };
            },
            prepare: (offerId: string) => {
                return {
                    payload: {
                        offerId: offerId,
                    },
                };
            },
        },
        setPreferredCandidatesList: {
            reducer: (state: IPreferredCandidatesState, action: PayloadAction<ISetPreferredCandidatesList>) => {
                return {
                    ...state,
                    preferredCandidatesList: action.payload.preferredCandidatesList,
                };
            },
            prepare: (candidateList: IApplicationOffer[] | null) => {
                return {
                    payload: {
                        preferredCandidatesList: candidateList,
                    },
                };
            },
        },
        changeIsPreferredCandidatesListLoading: {
            reducer: (state: IPreferredCandidatesState, action: PayloadAction<IPreferredCandidatesListLoading>) => {
                return {
                    ...state,
                    isPreferredCandidatesListLoading: action.payload.isPreferredCandidatesListLoading,
                };
            },
            prepare: (isPreferredCandidatesListLoading: boolean) => {
                return {
                    payload: {
                        isPreferredCandidatesListLoading: isPreferredCandidatesListLoading,
                    },
                };
            },
        },
        changeIsPreferredCandidatesListInitialized: {
            reducer: (state: IPreferredCandidatesState, action: PayloadAction<IPreferredCandidatesListInitialized>) => {
                return {
                    ...state,
                    isPreferredCandidatesListInitialized: action.payload.isPreferredCandidatesListInitialized,
                };
            },
            prepare: (isPreferredCandidatesListInitialized: boolean) => {
                return {
                    payload: {
                        isPreferredCandidatesListInitialized: isPreferredCandidatesListInitialized,
                    },
                };
            },
        },
        changePreferredCandidatesListError: {
            reducer: (state: IPreferredCandidatesState, action: PayloadAction<IPreferredCandidatesListError>) => {
                return {
                    ...state,
                    preferredCandidatesPagePageError: action.payload.preferredCandidatesPagePageError,
                };
            },
            prepare: (preferredCandidatesPagePageError: string | null) => {
                return {
                    payload: {
                        preferredCandidatesPagePageError: preferredCandidatesPagePageError,
                    },
                };
            },
        },
        fetchRecruitersList: (state: IPreferredCandidatesState) => {
            return {
                ...state,
            };
        },
        setPreferredCandidatesMetadata: {
            reducer: (state: IPreferredCandidatesState, action: PayloadAction<ISetMetadata>) => {
                return {
                    ...state,
                    preferredCandidatesMetadata: action.payload.metadata,
                };
            },
            prepare(metadata: IModelApiResponseViewObject | null) {
                return {
                    payload: {
                        metadata: metadata,
                    },
                };
            },
        },
        changePreferredCandidatesPagination: {
            reducer: (state: IPreferredCandidatesState, action: PayloadAction<IChangePagination>) => {
                return {
                    ...state,
                    isPreferredCandidatesListLoading: true,
                    pagination: action.payload.pagination,
                };
            },
            prepare(pagination: IPaginationParams) {
                return {
                    payload: {pagination: pagination},
                };
            },
        },
        resetToInitialPreferredCandidatesState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setPreferredCandidatesList,
    changePreferredCandidatesListError,
    changeIsPreferredCandidatesListInitialized,
    changeIsPreferredCandidatesListLoading,
    fetchPreferredCandidatesList,
    resetToInitialPreferredCandidatesState,
    setPreferredCandidatesMetadata,
    changePreferredCandidatesPagination,
} = preferredCandidatesPageSlice.actions;

export default preferredCandidatesPageSlice.reducer;
