import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IOfferViewState} from '../reducers/offerViewPageSlice';

export const selectOfferViewPage = (state: RootState): IOfferViewState => {
    return state.offerViewPage;
};

export const offerDetailsSelector = createSelector([selectOfferViewPage], (state: IOfferViewState) => state.offerDetails);

export const isOfferViewLoadingSelector = createSelector([selectOfferViewPage], (state: IOfferViewState) => state.isOfferViewLoading);

export const isOfferViewInitializedSelector = createSelector(
    [selectOfferViewPage],
    (state: IOfferViewState) => state.isOfferViewInitialized
);

export const offerViewErrorSelector = createSelector([selectOfferViewPage], (state: IOfferViewState) => state.offerViewError);

export const offerRelatedPositionSelector = createSelector([selectOfferViewPage], (state: IOfferViewState) => state.offerRelatedPosition);
