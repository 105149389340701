import {DateComponent, IModelDictionaryDatum, IModelPayment, Price, Translation} from 'jobhunter-common-web';
import {Component} from 'react';
import {ChevronDown, ChevronUp} from 'react-feather';
import {Badge, Button, Modal, ModalBody, ModalHeader} from 'reactstrap';
import {IModelServiceListItem} from '../../../../model/servicesDataModel';
import styles from './styles.module.scss';

interface IServiceViewProps {
    toggleModal: (service: IModelServiceListItem | null) => void;
    service: IModelServiceListItem;
    payment?: typeof IModelPayment;
}

interface IServiceViewState {
    isDescriptionExpanded: boolean;
}

class ServiceView extends Component<IServiceViewProps, IServiceViewState> {
    constructor(props: IServiceViewProps) {
        super(props);

        this.state = {
            isDescriptionExpanded: false,
        };
    }

    render() {
        const service = this.props.service,
            payment = this.props.payment,
            serviceStatusTranslationKey = service.accepted
                ? 'marketplace.services.serviceStatus.accepted'
                : 'marketplace.services.serviceStatus.pending';

        return (
            <Modal isOpen={true} toggle={() => this.props.toggleModal(null)}>
                <ModalHeader toggle={() => this.props.toggleModal(null)}>
                    <Translation text="marketplace.serviceDetails.title" config={{title: service.title}} />
                </ModalHeader>
                <ModalBody className="service-payment-modal">
                    <div className={styles.serviceDetails}>
                        <dl className={styles.serviceDefinitionList}>
                            <div className={styles.serviceDetailRow}>
                                <dt className={styles.serviceDefinitionTerm}>
                                    <Translation text="marketplace.serviceDetails.serviceName" />
                                </dt>
                                <dd className={styles.serviceDefinitionDescription}>{service.title}</dd>
                            </div>
                            {/*Status is hidden in current version*/}
                            {/*<div className={styles.serviceDetailRow}>*/}
                            {/*<dt className={styles.serviceDefinitionTerm}>*/}
                            {/*<Translation text="marketplace.serviceDetails.serviceStatus" />*/}
                            {/*</dt>*/}
                            {/*<dd className={styles.serviceDefinitionDescription}>*/}
                            {/*<Badge color={`${service.accepted ? 'light-primary' : 'light-warning'}`} pill>*/}
                            {/*<span className="align-middle">*/}
                            {/*<Translation text={serviceStatusTranslationKey} />*/}
                            {/*</span>*/}
                            {/*</Badge>*/}
                            {/*</dd>*/}
                            {/*</div>*/}

                            <div className={styles.serviceDetailRow}>
                                <dt className={styles.serviceDefinitionTerm}>
                                    <Translation text="marketplace.serviceDetails.serviceTypes" />
                                </dt>
                                <dd className={styles.serviceDefinitionDescription}>{this.renderServiceTypes(service.types)}</dd>
                            </div>

                            <div className={styles.serviceDetailRow}>
                                <dt className={styles.serviceDefinitionTerm}>
                                    <Translation text="marketplace.serviceDetails.servicePrices.title" />
                                </dt>
                                <dd className={styles.serviceDefinitionDescription}>
                                    <p>
                                        <Translation text="marketplace.serviceDetails.servicePrices.grossPrice" />{' '}
                                        <span className={styles.priceValue}>
                                            <Price price={{amount: service.grossPrice}} />
                                        </span>
                                    </p>
                                    <p>
                                        <Translation text="marketplace.serviceDetails.servicePrices.netPrice" />{' '}
                                        <span className={styles.priceValue}>
                                            <Price price={{amount: service.netPrice}} />
                                        </span>
                                    </p>
                                    <p>
                                        <Translation text="marketplace.serviceDetails.servicePrices.taxRate" />
                                        <span className={styles.priceValue}>{service.taxRate}%</span>
                                    </p>
                                </dd>
                            </div>

                            <div className={styles.serviceDetailRow}>
                                <dt className={styles.serviceDefinitionTerm}>
                                    <Translation text="marketplace.serviceDetails.servicesBought" />
                                </dt>
                                <dd className={styles.serviceDefinitionDescription}>{service.purchasedCount}</dd>
                            </div>
                            <div className={`${styles.serviceDetailRow} ${styles.column}`}>
                                <dt className={styles.serviceDefinitionTerm}>
                                    <Translation text="marketplace.serviceDetails.serviceDescription" />
                                </dt>
                                {this.renderDescription(service?.description)}
                            </div>
                        </dl>
                    </div>
                    {this.renderPaymentDetails(payment)}
                    <div className={styles.actions}>
                        <Button color="primary" className="close-modal-button" onClick={() => this.props.toggleModal(null)}>
                            <Translation text="buttons.close" />
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private renderPaymentDetails(payment: typeof IModelPayment | undefined) {
        if (!payment) return null;
        return (
            <div className={styles.servicePaymentDetails}>
                <h3 className="text-center mb-2 h2">
                    <Translation text="marketplace.serviceDetails.paymentDetails.title" />
                </h3>
                <dl className={styles.serviceDefinitionList}>
                    <div className={styles.serviceDetailRow}>
                        <dt className={styles.serviceDefinitionTerm}>
                            <Translation text="marketplace.serviceDetails.paymentDetails.invoiceNumber" />
                        </dt>
                        <dd className={styles.serviceDefinitionDescription}>{payment.invoiceNumber}</dd>
                    </div>

                    <div className={styles.serviceDetailRow}>
                        <dt className={styles.serviceDefinitionTerm}>
                            <Translation text="marketplace.serviceDetails.paymentDetails.id" />
                        </dt>
                        <dd className={styles.serviceDefinitionDescription}>{payment.id}</dd>
                    </div>

                    <div className={styles.serviceDetailRow}>
                        <dt className={styles.serviceDefinitionTerm}>
                            <Translation text="marketplace.serviceDetails.paymentDetails.paidAt" />
                        </dt>
                        <dd className={styles.serviceDefinitionDescription}>
                            {payment.paidAt ? <DateComponent date={payment.paidAt} /> : '--'}
                        </dd>
                    </div>

                    <div className={styles.serviceDetailRow}>
                        <dt className={styles.serviceDefinitionTerm}>
                            <Translation text="marketplace.serviceDetails.paymentDetails.expiresAt" />
                        </dt>
                        <dd className={styles.serviceDefinitionDescription}>
                            <DateComponent date={payment.expiresAt} />
                        </dd>
                    </div>
                    <div className={styles.serviceDetailRow}>
                        <dt className={styles.serviceDefinitionTerm}>
                            <Translation text="marketplace.serviceDetails.paymentDetails.paymentPrices.title" />
                        </dt>
                        <dd className={styles.serviceDefinitionDescription}>
                            <p>
                                <Translation text="marketplace.serviceDetails.paymentDetails.paymentPrices.grossPrice" />{' '}
                                <span className={styles.priceValue}>
                                    <Price price={{amount: payment.grossValue}} />
                                </span>
                            </p>
                            <p>
                                <Translation text="marketplace.serviceDetails.paymentDetails.paymentPrices.netPrice" />{' '}
                                <span className={styles.priceValue}>
                                    <Price price={{amount: payment.netValue}} />
                                </span>
                            </p>
                            <p>
                                <Translation text="marketplace.serviceDetails.paymentDetails.paymentPrices.taxRate" />
                                <span className={styles.priceValue}>{payment.taxValue}%</span>
                            </p>
                        </dd>
                    </div>
                </dl>
            </div>
        );
    }

    private renderServiceTypes = (types: typeof IModelDictionaryDatum[]) => {
        return types.map((type: typeof IModelDictionaryDatum) => {
            return (
                <Badge color="light-primary" pill className="mb-025 mr-1" key={type.id}>
                    <span className="align-middle">
                        <Translation text={type.name} />
                    </span>
                </Badge>
            );
        });
    };

    private renderDescription = (description: string | undefined) => {
        if (!description) {
            return null;
        }
        if (description.length < 150) {
            return <dd className={styles.description}>{description}</dd>;
        }
        const shortenedDescription = description.substr(0, 150) + '...';
        return (
            <>
                <dd className={styles.description}>{this.state.isDescriptionExpanded ? description : shortenedDescription}</dd>

                <div className={styles.btnContainer}>
                    <Button color="flat-primary" onClick={() => this.setState({isDescriptionExpanded: !this.state.isDescriptionExpanded})}>
                        <span className="align-middle me-1">
                            {this.state.isDescriptionExpanded ? (
                                <Translation text="marketplace.serviceDetails.readLess" />
                            ) : (
                                <Translation text="marketplace.serviceDetails.readMore" />
                            )}
                        </span>
                        {this.state.isDescriptionExpanded ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
                    </Button>
                </div>
            </>
        );
    };
}

export default ServiceView;
