import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {Translation} from 'jobhunter-common-web';
import {connect} from 'react-redux';
import {rejectCandidate} from '../../../../store/reducers/candidatesPageSlice';
import {RootState} from '../../../../store/reducers';
import {isCandidateRejectedSelector} from '../../../../store/selectors/candidatesPageSelectors';
import {IApplicationOffer} from '../../../../model/offerDataModel';

interface IExternalRejectCandidateProps {
    isModalOpen: boolean;
    toggleModal: (candidate: IApplicationOffer | null) => void;
    candidate?: IApplicationOffer | null;
}
interface IConnectedRejectCandidateProps {
    readonly rejectCandidate: typeof rejectCandidate;
    readonly isCandidateRejected: boolean;
}
interface IRejectCandidateProps extends IConnectedRejectCandidateProps, IExternalRejectCandidateProps {}

interface IRejectCandidateState {
    isLoading: boolean;
}

class RejectCandidate extends Component<IRejectCandidateProps, IRejectCandidateState> {
    constructor(props: IRejectCandidateProps) {
        super(props);

        this.state = {
            isLoading: false,
        };
    }

    componentDidUpdate(prevProps: IRejectCandidateProps): void {
        if (this.props.isCandidateRejected !== prevProps.isCandidateRejected && this.props.isCandidateRejected) {
            this.props.toggleModal(null);
        }
    }

    render() {
        return (
            <Modal isOpen={this.props.isModalOpen} toggle={() => this.props.toggleModal(null)}>
                <ModalHeader toggle={() => this.props.toggleModal(null)}>
                    <Translation text="humanResources.dashboard.candidates.rejectCandidateModal.title" />
                </ModalHeader>
                <ModalBody>
                    <p className="modal-description">
                        <Translation text="humanResources.dashboard.candidates.rejectCandidateModal.description" />
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" className="close-modal-button" outline onClick={() => this.props.toggleModal(null)}>
                        <Translation text="buttons.cancel" />
                    </Button>

                    <Button color="primary" className="reject-candidate-button" onClick={() => this.rejectCandidate()}>
                        <Translation text="buttons.reject" />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    private rejectCandidate = () => {
        if (this.props.candidate === null || this.props.candidate === undefined || this.props.candidate?.applicationId === null) {
            return;
        }
        this.props.rejectCandidate(this.props.candidate?.applicationId);
    };
}

export default connect(
    (state: RootState) => ({
        isCandidateRejected: isCandidateRejectedSelector(state),
    }),
    {rejectCandidate}
)(RejectCandidate);
