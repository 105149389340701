import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {Translation} from 'jobhunter-common-web';

interface IRemoveCompanyModalProps {
    isModalOpen: boolean;
    toggleModal: (company: {[key: string]: any} | null) => void;
    company: {[key: string]: any} | null;
}

interface IRemoveCompanyModalState {}

class RemoveCompanyModal extends Component<IRemoveCompanyModalProps, IRemoveCompanyModalState> {
    render() {
        return (
            <Modal isOpen={this.props.isModalOpen} toggle={() => this.props.toggleModal(null)}>
                <ModalHeader toggle={() => this.props.toggleModal(null)}>
                    <Translation text="employer.cooperatingHr.deleteCompanyModal.title" />
                </ModalHeader>
                <ModalBody>
                    <p className="modal-description">
                        <Translation text="employer.cooperatingHr.deleteCompanyModal.description" />
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" className="close-modal-button" outline onClick={() => this.props.toggleModal(null)}>
                        <Translation text="buttons.cancel" />
                    </Button>
                    <Button color="primary" className="remove-hr-button" onClick={() => this.removeCompany()}>
                        <Translation text="buttons.remove" />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    private removeCompany = () => {
        console.log('remove company:', this.props.company);
    };
}

export default RemoveCompanyModal;
