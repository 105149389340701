import {Observable} from 'rxjs';
import {jobHunterAPI, RestQueryParams} from 'jobhunter-common-web';

export enum HRListType {
    ACCEPTED = 'accepted',
    PENDING = 'pending',
}

export function getHRListAPI(authToken: string | null, hRListType: HRListType): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return jobHunterAPI.get(`api/hr_invitations/list_${hRListType}`, new RestQueryParams(), headers);
}
