import {Container} from 'inversify';
import getDecorators, {fixInjectedProperties} from './decorators';
import {AlertManagerService, CalendarCreationService} from '../service';

const container = new Container();

container.bind<AlertManagerService>('AlertManagerService').to(AlertManagerService).inSingletonScope();
container.bind<CalendarCreationService>('CalendarCreationService').to(CalendarCreationService).inSingletonScope();

const {lazyInject} = getDecorators(container);

export {container, lazyInject, fixInjectedProperties};
