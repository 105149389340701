import React, {Component} from 'react';
import {Card, CardBody, CardTitle} from 'reactstrap';
import {Shield} from 'react-feather';
import {Form, FormControlChangeType, IFormConfig, Loader, LoaderType, Translation} from 'jobhunter-common-web';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {statusFormConfig} from './statusFormConfig';
import {changeIsRelocationStatusUpdated} from '../../../../store/reducers/relocationDetailsPageSlice';

interface IRelocationStatusProps {
    readonly relocationStatus: string | null;
    readonly error: string | null;
    readonly isRelocationStatusUpdated: boolean;
    readonly changeRelocationStatus: (status: string) => void;
    readonly changeIsRelocationStatusUpdated: typeof changeIsRelocationStatusUpdated;
}

interface IRelocationStatusState {
    value: any;
    formConfig: typeof IFormConfig | null;
    isLoading: boolean;
}

class RelocationStatus extends Component<IRelocationStatusProps, IRelocationStatusState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IRelocationStatusProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: null,
            isLoading: false,
        };
    }

    componentDidMount() {
        this.setFormConfig();
        this.getFormValueFromState();

        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    tap((data: any) => this.setState({value: data.value}))
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: Readonly<IRelocationStatusProps>) {
        if (
            (this.props.error !== prevProps.error && this.props.error) ||
            (this.props.isRelocationStatusUpdated !== prevProps.isRelocationStatusUpdated && this.props.isRelocationStatusUpdated)
        ) {
            this.setState({isLoading: false}, () => this.props.changeIsRelocationStatusUpdated(false));
        }

        if (this.props.relocationStatus !== prevProps.relocationStatus) {
            this.getFormValueFromState();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <Card className="relocation-status-card">
                <CardTitle>
                    <div className="card-header">
                        <div>
                            <Shield size={20} />
                            <Translation text="relocationView.status.title" />
                        </div>
                        <button className="btn btn-primary offers-button" onClick={() => this.changeRelocationStatus()}>
                            <Translation text="buttons.update" />
                        </button>
                    </div>
                </CardTitle>
                <CardBody>
                    {this.state.formConfig && (
                        <Form
                            config={this.state.formConfig}
                            onValueStateChange={this.onValueStateChange}
                            value={this.state.value}
                            controlName={'relocationStatusForm'}
                        />
                    )}
                </CardBody>
                <Loader type={LoaderType.Local} showLoader={this.state.isLoading} />
            </Card>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private setFormConfig = () => {
        this.setState({formConfig: statusFormConfig()});
    };

    private getFormValueFromState = () => {
        if (!this.props.relocationStatus) {
            return;
        }

        this.setState({value: {relocationStatus: this.props.relocationStatus}});
    };

    private changeRelocationStatus = () => {
        const status = this.state.value.relocationStatus;
        if (!status) {
            return;
        }

        this.setState({isLoading: true});
        this.props.changeRelocationStatus(status);
    };
}

export default RelocationStatus;
