import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {catchError, switchMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {RootState} from '../reducers';
import {
    addAlert,
    AlertType,
    authTokenSelector,
    flattenObj,
    getErrorMessage,
    getMetadataDetails,
    RestQueryParams,
} from 'jobhunter-common-web';
import {
    changeIsPreferredCandidatesListLoading,
    changePreferredCandidatesListError,
    changePreferredCandidatesPagination,
    fetchPreferredCandidatesList,
    IFetchPreferredCandidatesList,
    setPreferredCandidatesList,
    setPreferredCandidatesMetadata,
} from '../reducers/preferredCandidatesPageSlice';
import {preferredCandidatesPaginationSelector} from '../selectors/preferredCandidatedPageSelectors';
import {getPreferredCandidatesAPI} from '../../api/getPreferredCandidatesAPI';
import {PayloadAction} from '@reduxjs/toolkit';

const fetchPreferredCandidatesListEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getApplicationCandidates(action$, state$, fetchPreferredCandidatesList);
};

const changePreferredCandidatesPaginationEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getApplicationCandidates(action$, state$, changePreferredCandidatesPagination);
};

const getApplicationCandidates = (action$: Observable<any>, state$: StateObservable<RootState>, actionType: any) => {
    return action$.pipe(
        ofType(actionType.type),
        switchMap((action: PayloadAction<IFetchPreferredCandidatesList>): any => {
            const authToken = authTokenSelector(state$.value),
                offerId = action.payload.offerId,
                paginationParams = preferredCandidatesPaginationSelector(state$.value),
                flattenedParams = flattenObj(paginationParams),
                params = new RestQueryParams(flattenedParams);

            return getPreferredCandidatesAPI(authToken, offerId, params).pipe(
                switchMap((resp: any) => {
                    console.log('response:', resp);
                    const metadata = getMetadataDetails(resp['hydra:view']),
                        actions = successActions([
                            setPreferredCandidatesList(resp[`hydra:member`]),
                            setPreferredCandidatesMetadata(metadata),
                        ]);
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const successActions = (changeSliceList: any[]): any[] => {
    const actions = [changeIsPreferredCandidatesListLoading(false)];
    return [...changeSliceList, ...actions];
};

const updateListErrorActions = (error: any): any[] => {
    return [
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
        changePreferredCandidatesListError(getErrorMessage(error)),
    ];
};

const preferredCandidatesEpic = combineEpics(fetchPreferredCandidatesListEpic, changePreferredCandidatesPaginationEpic);

export default preferredCandidatesEpic;
