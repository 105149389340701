import {Observable} from 'rxjs';
import {jobHunterAPI, RestQueryParams} from 'jobhunter-common-web';

export interface IAddPositionPayload {
    name: string;
    description: string;
    minimumSalary: string;
    maximumSalary: string;
    seniorityId: string;
    cityId: string;
}

export function addPositionAPI(authToken: string | null, payload: IAddPositionPayload): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return jobHunterAPI.post(`api/positions`, payload, new RestQueryParams(), headers);
}
