import React from 'react';
import App from '../App';
import {Route} from 'react-router';
import PanelHost from '../components/PanelHost';
import {AuthPanel, NotFound, PrivateRoute, UserRole} from 'jobhunter-common-web';
import {loginFormConfig} from './authFormConfig/loginFormConfig';
import {registrationFormConfig} from './authFormConfig/registrationFormConfig';
import {newPasswordFormConfig} from './authFormConfig/newPasswordFormConfig';
import {resetPasswordFormConfig} from './authFormConfig/resetPasswordFormConfig';
import AcceptInvitation from '../components/Employer/CooperatingHumanResources/AcceptInvitation';

const routes = [
    <Route path="/" element={<App />} key="home" />,
    <Route
        path="/panel/*"
        key="panel"
        element={
            <PrivateRoute userRole={UserRole.ORGANIZATION}>
                <PanelHost />
            </PrivateRoute>
        }
    />,
    <Route
        path="/auth/*"
        element={
            <AuthPanel
                formConfig={{
                    login: loginFormConfig,
                    resetPassword: resetPasswordFormConfig,
                    registration: registrationFormConfig,
                    newPassword: newPasswordFormConfig,
                }}
                envData={process.env}
                userRole={UserRole.ORGANIZATION}
            />
        }
        key="auth"
    />,
    <Route path="/accept-invitation/:token" element={<AcceptInvitation />} key="accept_invitation" />,
    <Route path="*" key="not-found" element={<NotFound />} />,
];
export default routes;
