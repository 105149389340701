import {ICandidateFullInfo, IOrganizationFullInfo, UserRole} from 'jobhunter-common-web';
import {IModelApiObject} from './base';

export interface IModelExternalEmployee extends IModelApiObject {
    readonly id: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly email: string;
    readonly active: boolean;
    readonly type: typeof UserRole;
    readonly organizationEmployeeId: string | null;
}

export interface IModelOrganizationEmployee {
    readonly candidateFullInfo: typeof ICandidateFullInfo | null;
    readonly organizationFullInfo: typeof IOrganizationFullInfo | null;
}

export enum EmployeeType {
    EXTERNAL_EMPLOYEE = 'external_employee',
    ORGANIZATION_EMPLOYEE = 'organization_employee',
}
