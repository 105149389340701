import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelApiObject} from '../../model/base';
import {IModelCity, IModelDictionaryDatum} from '../../model/dictionaryDatum';

export interface IEmployerState {
    employerInformation: IEmployerInformation | null;
    companyInformation: ICompanyInformation | null;
    verificationFiles: IOrganizationVerificationDocuments | null;
    companyDetails: ICompanyDetails | null;
    isEmployerLoading: boolean;
    isEmployerInitialized: boolean;
    employerError: string | null;
}

export interface IEmployerInformation {
    firstName: string;
    lastName: string;
    phone: string;
    city: string;
    country: IModelDictionaryDatum;
    email: string;
    avatar: {
        contentUrl: string;
        fileUrls: any;
        id: string;
    };
    id: string;
    birthDate: string | null;
}

export interface ICompanyInformation {
    address: string | null;
    avatar: {
        contentUrl: string;
        fileUrls: any;
        id: string;
    };
    cityName: string | null;
    country: IModelDictionaryDatum;
    about: string | null;
    name: string;
    mobile: string | null;
    // vatNumber: string | null;
}

export interface IVerificationFile extends IModelApiObject {
    file: any;
    fileName: string;
    type: IModelDictionaryDatum;
}

export interface ICompanyDetails extends IModelApiObject {
    cities: IModelCity[];
    companyTypes: IModelDictionaryDatum[];
    industries: IModelDictionaryDatum[];
    organizationSize: IModelDictionaryDatum | null;
}

export interface IOrganizationVerificationDocuments extends IModelApiObject {
    files: IVerificationFile[];
}
export interface IEmployerData {
    employerInformation: IEmployerInformation | null;
    companyInformation: ICompanyInformation | null;
    verificationFiles: IOrganizationVerificationDocuments | null;
    companyDetails: ICompanyDetails | null;
}

export interface IEmployerInformation {
    firstName: string;
    lastName: string;
    phone: string;
    city: string;
    country: IModelDictionaryDatum;
    email: string;
    avatar: {
        contentUrl: string;
        fileUrls: any;
        id: string;
    };
    // id: string;
}

export interface ISetEmployerData {
    employerData: IEmployerData;
}
export interface IChangeEmployerInformation {
    employerInformation: IEmployerInformation | null;
}

export interface IChangeCompanyInformation {
    companyInformation: ICompanyInformation | null;
}

export interface IChangeVerificationFiles {
    verificationFiles: IOrganizationVerificationDocuments | null;
}

export interface IChangeCompanyDetails {
    companyDetails: ICompanyDetails | null;
}

export interface IChangeIsEmployerLoading {
    isEmployerLoading: boolean;
}

export interface IChangeIsEmployerInitialized {
    isEmployerInitialized: boolean;
}

export interface IChangeEmployerError {
    error: string | null;
}

const initialState: IEmployerState = {
    employerInformation: null,
    companyInformation: null,
    verificationFiles: null,
    companyDetails: null,
    isEmployerLoading: false,
    isEmployerInitialized: false,
    employerError: null,
};

const employerPageSlice = createSlice({
    name: 'employer',
    initialState: initialState,
    reducers: {
        setEmployerData: {
            reducer: (state: IEmployerState, action: PayloadAction<ISetEmployerData>) => {
                return {
                    ...state,
                    employerInformation: action.payload.employerData.employerInformation,
                    companyInformation: action.payload.employerData.companyInformation,
                    verificationFiles: action.payload.employerData.verificationFiles,
                    companyDetails: action.payload.employerData.companyDetails,
                };
            },
            prepare(employerData: IEmployerData) {
                return {
                    payload: {employerData: employerData},
                };
            },
        },
        changeEmployerInformation: {
            reducer: (state: IEmployerState, action: PayloadAction<IChangeEmployerInformation>) => {
                return {
                    ...state,
                    employerInformation: action.payload.employerInformation,
                };
            },
            prepare(employerInformation: IEmployerInformation) {
                return {
                    payload: {employerInformation: employerInformation},
                };
            },
        },
        changeCompanyInformation: {
            reducer: (state: IEmployerState, action: PayloadAction<IChangeCompanyInformation>) => {
                return {
                    ...state,
                    companyInformation: action.payload.companyInformation,
                };
            },
            prepare(companyInformation: ICompanyInformation | null) {
                return {
                    payload: {companyInformation: companyInformation},
                };
            },
        },
        changeVerificationFiles: {
            reducer: (state: IEmployerState, action: PayloadAction<IChangeVerificationFiles>) => {
                return {
                    ...state,
                    verificationFiles: action.payload.verificationFiles,
                };
            },
            prepare(verificationFiles: IOrganizationVerificationDocuments | null) {
                return {
                    payload: {verificationFiles: verificationFiles},
                };
            },
        },
        changeCompanyDetails: {
            reducer: (state: IEmployerState, action: PayloadAction<IChangeCompanyDetails>) => {
                return {
                    ...state,
                    companyDetails: action.payload.companyDetails,
                };
            },
            prepare(companyDetails: ICompanyDetails | null) {
                return {
                    payload: {companyDetails: companyDetails},
                };
            },
        },
        changeIsEmployerLoading: {
            reducer: (state: IEmployerState, action: PayloadAction<IChangeIsEmployerLoading>) => {
                return {
                    ...state,
                    isEmployerLoading: action.payload.isEmployerLoading,
                };
            },
            prepare(isEmployerLoading: boolean) {
                return {
                    payload: {isEmployerLoading: isEmployerLoading},
                };
            },
        },
        changeIsEmployerInitialized: {
            reducer: (state: IEmployerState, action: PayloadAction<IChangeIsEmployerInitialized>) => {
                return {
                    ...state,
                    isEmployerInitialized: action.payload.isEmployerInitialized,
                };
            },
            prepare(isEmployerInitialized: boolean) {
                return {
                    payload: {isEmployerInitialized: isEmployerInitialized},
                };
            },
        },
        changeEmployerError: {
            reducer: (state: IEmployerState, action: PayloadAction<IChangeEmployerError>) => {
                return {
                    ...state,
                    employerError: action.payload.error,
                };
            },
            prepare(error: any) {
                return {
                    payload: {error: error},
                };
            },
        },
        updateEmployerPage: {
            reducer: (state: IEmployerState) => {
                return {
                    ...state,
                    isEmployerLoading: true,
                };
            },
            prepare(payload: {[key: string]: any}) {
                return {
                    payload: payload,
                };
            },
        },
        submitPersonalInformation: {
            reducer: (state: IEmployerState) => {
                return {
                    ...state,
                    isEmployerLoading: true,
                };
            },
            prepare(payload: {[key: string]: any}) {
                return {
                    payload: payload,
                };
            },
        },
        submitCompanyInformation: {
            reducer: (state: IEmployerState) => {
                return {
                    ...state,
                    isEmployerLoading: true,
                };
            },
            prepare(payload: {[key: string]: any}) {
                return {
                    payload: payload,
                };
            },
        },
        submitCompanyDetails: {
            reducer: (state: IEmployerState) => {
                return {
                    ...state,
                    isEmployerLoading: true,
                };
            },
            prepare(payload: {[key: string]: any}) {
                return {
                    payload: payload,
                };
            },
        },
        submitVerificationFiles: {
            reducer: (state: IEmployerState) => {
                return {
                    ...state,
                    isEmployerLoading: true,
                };
            },
            prepare(payload: {[key: string]: any}) {
                return {
                    payload: payload,
                };
            },
        },
        fetchEmployerPageData: (state: IEmployerState) => {
            return {
                ...state,
                isEmployerLoading: true,
            };
        },
        resetToInitialEmployerPageState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setEmployerData,
    changeEmployerInformation,
    changeCompanyInformation,
    changeVerificationFiles,
    changeCompanyDetails,
    changeIsEmployerLoading,
    changeIsEmployerInitialized,
    changeEmployerError,
    updateEmployerPage,
    fetchEmployerPageData,
    resetToInitialEmployerPageState,
    submitPersonalInformation,
    submitCompanyInformation,
    submitCompanyDetails,
    submitVerificationFiles,
} = employerPageSlice.actions;

export default employerPageSlice.reducer;
