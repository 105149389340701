import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {catchError, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {addAlert, AlertType, authTokenSelector, getErrorMessage} from 'jobhunter-common-web';
import {
    fetchOfferDetails,
    IFetchOffer,
    setOfferDetails,
    changeIsOfferViewLoading,
    changeOfferViewError,
    addOffer,
    IAddOffer,
    IUpdateOffer,
    updateOffer,
    IFetchOfferRelatedPosition,
    fetchOfferRelatedPosition,
    setOfferRelatedPosition,
} from '../reducers/offerViewPageSlice';
import {RootState} from '../reducers';
import {PayloadAction} from '@reduxjs/toolkit';
import {getOfferDetailsAPI} from '../../api/getOfferDetails';
import {addOfferAPI} from '../../api/addOfferAPI';
import {push} from 'react-router-redux';
import {updateOfferAPI} from '../../api/updateOfferAPI';
import {getSoughtPositionAPI} from '../../api/getSoughtPositionAPI';

const fetchOfferDetailsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchOfferDetails.type),
        switchMap((action: PayloadAction<IFetchOffer>): any => {
            const authToken = authTokenSelector(state$.value);
            return getOfferDetailsAPI(authToken, action.payload.offerId).pipe(
                switchMap((resp: any) => {
                    const actions = [setOfferDetails(resp), changeIsOfferViewLoading(false)];
                    return of(...actions);
                }),
                catchError((error) => of(...offerDetailsErrorActions(error)))
            );
        }),
        catchError((error) => of(...offerDetailsErrorActions(error)))
    );
};

const addOfferEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(addOffer.type),
        switchMap((action: PayloadAction<IAddOffer>): any => {
            const authToken = authTokenSelector(state$.value);
            return addOfferAPI(authToken, action.payload.offerPayload).pipe(
                switchMap(() => {
                    const actions = [
                        changeIsOfferViewLoading(false),
                        addAlert({message: 'offers.editOffer.offerAdded'}),
                        push('/panel/human_resources/offers'),
                    ];
                    return of(...actions);
                }),
                catchError((error) => of(...offerDetailsErrorActions(error)))
            );
        }),
        catchError((error) => of(...offerDetailsErrorActions(error)))
    );
};

const updateOfferEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(updateOffer.type),
        switchMap((action: PayloadAction<IUpdateOffer>): any => {
            const authToken = authTokenSelector(state$.value);
            return updateOfferAPI(authToken, action.payload.offerPayload, action.payload.offerId).pipe(
                switchMap(() => {
                    const actions = [changeIsOfferViewLoading(false), addAlert({message: 'offers.editOffer.offerUpdated'})];
                    return of(...actions);
                }),
                catchError((error) => of(...offerDetailsErrorActions(error)))
            );
        }),
        catchError((error) => of(...offerDetailsErrorActions(error)))
    );
};

const fetchOfferRelatedPositionEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchOfferRelatedPosition.type),
        switchMap((action: PayloadAction<IFetchOfferRelatedPosition>): any => {
            const authToken = authTokenSelector(state$.value);
            return getSoughtPositionAPI(authToken, action.payload.soughtPositionId).pipe(
                switchMap((resp: any) => {
                    const actions = [setOfferRelatedPosition(resp)];
                    return of(...actions);
                }),
                catchError((error) => of(...offerDetailsErrorActions(error)))
            );
        }),
        catchError((error) => of(...offerDetailsErrorActions(error)))
    );
};

const offerDetailsErrorActions = (error: any): any[] => {
    return [
        changeIsOfferViewLoading(false),
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
        changeOfferViewError(getErrorMessage(error)),
    ];
};

const offerDetailsEpic = combineEpics(fetchOfferDetailsEpic, addOfferEpic, updateOfferEpic, fetchOfferRelatedPositionEpic);

export default offerDetailsEpic;
