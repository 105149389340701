import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {ICandidatesState} from '../reducers/candidatesPageSlice';

export const selectCandidatesPage = (state: RootState) => {
    return state.candidatesPage;
};

export const candidatesListSelector = createSelector([selectCandidatesPage], (state: ICandidatesState) => state.candidatesList);

export const candidatesMetadataSelector = createSelector([selectCandidatesPage], (state: ICandidatesState) => state.candidatesMetadata);

export const candidatesPaginationSelector = createSelector([selectCandidatesPage], (state: ICandidatesState) => state.pagination);

export const recruitersListSelector = createSelector([selectCandidatesPage], (state: ICandidatesState) => state.recruitersList);

export const isCandidatesListInitializedSelector = createSelector(
    [selectCandidatesPage],
    (state: ICandidatesState) => state.isCandidatesListInitialized
);

export const isCandidatesListLoadingSelector = createSelector(
    [selectCandidatesPage],
    (state: ICandidatesState) => state.isCandidatesListLoading
);

export const candidatesPageErrorSelector = createSelector([selectCandidatesPage], (state: ICandidatesState) => state.candidatesPageError);

export const candidatesFilterOptionsSelector = createSelector([selectCandidatesPage], (state: ICandidatesState) => state.filterOptions);

export const candidateFiltersSelector = createSelector([selectCandidatesPage], (state: ICandidatesState) => state.filters);

export const isCandidateRejectedSelector = createSelector([selectCandidatesPage], (state: ICandidatesState) => state.isCandidateRejected);

export const isCandidateHiredSelector = createSelector([selectCandidatesPage], (state: ICandidatesState) => state.isCandidateHired);

export const isApplicationMeetingScheduledSelector = createSelector(
    [selectCandidatesPage],
    (state: ICandidatesState) => state.isApplicationMeetingScheduled
);
