import {
    FormControlType,
    IFormConfig,
    InputType,
    ValidationRules,
    collectionInputDataMapper,
    collectionOutputDataMapper,
    MultiSelectType,
    IMultiselectOption,
    IModelSeniority,
    formatRangePickerLabel,
    FormButtonType,
} from 'jobhunter-common-web';

export const addPositionFormConfig: typeof IFormConfig = (
    city: typeof IMultiselectOption[] | [],
    seniorityLevels: {[key: number]: typeof IModelSeniority},
    value?: any
) => ({
    controlType: 'form',
    class: 'default-form',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'name',
            controlType: 'group',
            class: 'row',
            controls: {
                name: {
                    controlType: 'control',
                    defaultValue: value ? value.name : '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [],
                    placeholder: 'employer.positions.addPosition.form.labels.position',
                    isLabelHidden: false,
                    label: 'employer.positions.addPosition.form.labels.position',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-6 form-control',
                },
            },
        },
        {
            key: 'description',
            controlType: 'group',
            class: 'row',
            controls: {
                description: {
                    controlType: 'control',
                    defaultValue: value ? value.longDescription : '',
                    formControlType: FormControlType.TEXTAREA,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}, {name: ValidationRules.MAX_LENGTH, params: {length: 2000}}],
                    placeholder: 'employer.positions.addPosition.form.placeholders.description',
                    isLabelHidden: false,
                    rows: 5,
                    maxLength: 2000,
                    label: 'employer.positions.addPosition.form.labels.description',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12 form-control',
                },
            },
        },
        {
            key: 'salary',
            controlType: 'group',
            class: 'row',
            controls: {
                minimumSalary: {
                    controlType: 'control',
                    formControlType: FormControlType.QUANTITY_INPUT,
                    hostClass: 'col-xl-6 form-control',
                    label: 'employer.positions.addPosition.form.labels.minSalary',
                    minValue: 0,
                    stepValue: 100,
                    type: InputType.NUMBER,
                },
                maximumSalary: {
                    controlType: 'control',
                    formControlType: FormControlType.QUANTITY_INPUT,
                    hostClass: 'col-xl-6 form-control',
                    label: 'employer.positions.addPosition.form.labels.maxSalary',
                    minValue: 0,
                    stepValue: 100,
                    type: InputType.NUMBER,
                },
            },
        },
        {
            key: 'level',
            controlType: 'group',
            class: 'row',
            controls: {
                level: {
                    controlType: 'control',
                    defaultValue: value ? value.level : '',
                    formControlType: FormControlType.RANGE_PICKER,
                    validationRules: [],
                    rangeLabels: seniorityLevels,
                    label: 'Seniority Level',
                    labelFormat: (value: number) => formatRangePickerLabel(value, seniorityLevels),
                    maxRangeStep: Object.keys(seniorityLevels).length * 10,
                    isRangeLabelTranslated: true,
                },
            },
        },
        {
            key: 'address',
            controlType: 'group',
            class: 'row custom-row',
            controls: {
                city: {
                    controlType: 'control',
                    defaultValue: value ? value.city : '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    multiselectOptions: city ? city : [],
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: 'employer.positions.addPosition.form.labels.city',
                    isLabelHidden: false,
                    label: 'employer.positions.addPosition.form.placeholders.city',
                    hostClass: 'col-xl-6 form-control',
                    inputDataMapper: collectionInputDataMapper,
                    outputDataMapper: collectionOutputDataMapper,
                },
            },
        },
        {
            key: 'submit',
            controlType: 'group',
            class: 'row justify-content-end',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    inputStyles: 'btn-primary',
                    defaultContainerStyles: '',
                    hostClass: 'mb-0 mt-1 align-items-end w-auto',
                    containerStyles: '',
                    defaultValue: null,
                    isLabelHidden: true,
                    btnText: 'buttons.add',
                    buttonDisabled: (mappedOutputValue: any, isFormValid: boolean) => {
                        return !isFormValid;
                    },
                },
            },
        },
    ],
});
