import {
    IFormConfig,
    FormControlType,
    MultiSelectType,
    collectionInputDataMapper,
    collectionOutputDataMapper,
    IMultiselectOption,
} from 'jobhunter-common-web';

export const positionRelatedRecruitersFormConfig = (recruitersList?: typeof IMultiselectOption[] | []): typeof IFormConfig => ({
    controlType: 'group',
    class: 'default-form',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'candidates_filters',
            class: 'row',
            controls: {
                recruiter: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    multiselectOptions: recruitersList,
                    validationRules: [],
                    placeholder: 'employer.positions.positionDetails.offers.addHumanResources',
                    label: '',
                    isLabelHidden: true,
                    hostClass: 'multiselect-btn-wrapper',
                    inputDataMapper: collectionInputDataMapper,
                    outputDataMapper: collectionOutputDataMapper,
                },
            },
        },
    ],
});
