import {Observable} from 'rxjs';
import {jobHunterAPI, RestQueryParams} from 'jobhunter-common-web';
import {IAddEmployeePayload} from './addEmployeeAPI';

export function changeExternalEmployeeAPI(authToken: string | null, id: string, payload: IAddEmployeePayload): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return jobHunterAPI.put(`api/external_employees/${id}`, payload, new RestQueryParams(), headers);
}
