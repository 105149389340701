import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IMultiselectOption, isSameValue} from 'jobhunter-common-web';
import {IModelExternalEmployee} from '../../model/employeeDataModel';
import {IScheduleConsultationPayload} from '../../api/scheduleApplicationMeetingAPI';
import {IApplicationOffer} from '../../model/offerDataModel';
import {IModelApiResponseViewObject} from '../../model/base';
import {IChangePagination, IPaginationParams, ISetMetadata} from './offersPageSlice';

export interface ISetCandidatesList {
    candidatesList: IApplicationOffer[] | null;
}

export interface ISetRecruitersList {
    recruitersList: IModelExternalEmployee[] | null;
}

export interface ICandidatesListLoading {
    isCandidatesListLoading: boolean;
}

export interface ICandidatesListInitialized {
    isCandidatesListInitialized: boolean;
}

export interface ICandidatesListError {
    candidatesPagePageError: string | null;
}

export interface ICandidatesFilters {
    offer: null | {
        id: string[] | null;
        soughtPosition: {
            position: {
                name: string | null;
            };
        };
    };
    candidate: null | {
        account: {
            lastName: string | null;
        };
    };
}

export interface IChangeCandidateFilters {
    filters: ICandidatesFilters | null;
}

export interface ICandidatesFilterOptions {
    offer: typeof IMultiselectOption[] | [];
    position: typeof IMultiselectOption[] | [];
    candidate: typeof IMultiselectOption[] | [];
}
export interface ICandidateAction {
    applicationId: string;
}

export interface IChangeIsCandidateRejected {
    isCandidateRejected: boolean;
}

export interface IChangeIsApplicationMeetingScheduled {
    isApplicationMeetingScheduled: boolean;
}

export interface IChangeIsCandidateHired {
    isCandidateHired: boolean;
}

interface ISetCandidateFilterOptions {
    filterOptions: ICandidatesFilterOptions;
}

export interface IScheduleApplicationMeeting {
    applicationPayload: IScheduleConsultationPayload;
}

export interface ICandidatesState {
    candidatesList: IApplicationOffer[] | null;
    candidatesMetadata: IModelApiResponseViewObject | null;
    pagination: IPaginationParams | null;
    recruitersList: IModelExternalEmployee[] | null;
    filters: ICandidatesFilters | null;
    isCandidatesListLoading: boolean;
    isCandidatesListInitialized: boolean;
    candidatesPageError: string | null;
    filterOptions: ICandidatesFilterOptions | null;
    isCandidateRejected: boolean;
    isCandidateHired: boolean;
    isApplicationMeetingScheduled: boolean;
}

const initialState: ICandidatesState = {
    candidatesList: null,
    candidatesMetadata: null,
    pagination: {
        itemsPerPage: 25,
        page: 1,
    },
    recruitersList: null,
    isCandidatesListInitialized: false,
    isCandidatesListLoading: false,
    candidatesPageError: null,
    filters: null,
    filterOptions: null,
    isCandidateRejected: false,
    isCandidateHired: false,
    isApplicationMeetingScheduled: false,
};

const candidatesPageSlice = createSlice({
    name: 'candidatesPage',
    initialState: initialState,
    reducers: {
        hireCandidate: {
            reducer: (state: ICandidatesState, action: PayloadAction<ICandidateAction>) => {
                return {
                    ...state,
                    applicationId: action.payload.applicationId,
                };
            },
            prepare: (applicationId: string) => {
                return {
                    payload: {
                        applicationId: applicationId,
                    },
                };
            },
        },
        changeCandidateHired: {
            reducer: (state: ICandidatesState, action: PayloadAction<IChangeIsCandidateHired>) => {
                return {
                    ...state,
                    isCandidateHired: action.payload.isCandidateHired,
                };
            },
            prepare: (isCandidateHired: boolean) => {
                return {
                    payload: {
                        isCandidateHired: isCandidateHired,
                    },
                };
            },
        },
        acceptCandidate: {
            reducer: (state: ICandidatesState, action: PayloadAction<ICandidateAction>) => {
                return {
                    ...state,
                    applicationId: action.payload.applicationId,
                };
            },
            prepare: (applicationId: string) => {
                return {
                    payload: {
                        applicationId: applicationId,
                    },
                };
            },
        },
        changeCandidateRejected: {
            reducer: (state: ICandidatesState, action: PayloadAction<IChangeIsCandidateRejected>) => {
                return {
                    ...state,
                    isCandidateRejected: action.payload.isCandidateRejected,
                };
            },
            prepare: (isCandidateRejected: boolean) => {
                return {
                    payload: {
                        isCandidateRejected: isCandidateRejected,
                    },
                };
            },
        },
        rejectCandidate: {
            reducer: (state: ICandidatesState, action: PayloadAction<ICandidateAction>) => {
                return {
                    ...state,
                    applicationId: action.payload.applicationId,
                };
            },
            prepare: (applicationId: string) => {
                return {
                    payload: {
                        applicationId: applicationId,
                    },
                };
            },
        },
        applyCandidatesFilters: (state: ICandidatesState) => {
            return {
                ...state,
                isCandidatesListLoading: true,
            };
        },
        fetchCandidateFilterOptions: (state: ICandidatesState) => {
            return {
                ...state,
            };
        },
        changeCandidateFilters: {
            reducer: (state: ICandidatesState, action: PayloadAction<IChangeCandidateFilters>) => {
                if (isSameValue(action.payload.filters, state.filters)) {
                    return state;
                }
                return {
                    ...state,
                    filters: action.payload.filters,
                };
            },

            prepare: (filters: ICandidatesFilters) => {
                return {
                    payload: {
                        filters: filters,
                    },
                };
            },
        },
        setCandidateFilterOptions: {
            reducer: (state: ICandidatesState, action: PayloadAction<ISetCandidateFilterOptions>) => {
                if (isSameValue(action.payload.filterOptions, state.filters)) {
                    return state;
                }
                return {
                    ...state,
                    filterOptions: action.payload.filterOptions,
                };
            },
            prepare: (filters: ICandidatesFilterOptions) => {
                return {
                    payload: {
                        filterOptions: {
                            offer: filters.offer,
                            position: filters.position,
                            candidate: filters.candidate,
                        },
                    },
                };
            },
        },
        fetchCandidatesList: (state: ICandidatesState) => {
            return {
                ...state,
                isCandidatesListLoading: true,
            };
        },
        setCandidatesList: {
            reducer: (state: ICandidatesState, action: PayloadAction<ISetCandidatesList>) => {
                return {
                    ...state,
                    candidatesList: action.payload.candidatesList,
                };
            },
            prepare: (candidateList: IApplicationOffer[] | null) => {
                return {
                    payload: {
                        candidatesList: candidateList,
                    },
                };
            },
        },
        changeIsCandidatesListLoading: {
            reducer: (state: ICandidatesState, action: PayloadAction<ICandidatesListLoading>) => {
                return {
                    ...state,
                    isCandidatesListLoading: action.payload.isCandidatesListLoading,
                };
            },
            prepare: (isCandidatesListLoading: boolean) => {
                return {
                    payload: {
                        isCandidatesListLoading: isCandidatesListLoading,
                    },
                };
            },
        },
        changeIsCandidatesListInitialized: {
            reducer: (state: ICandidatesState, action: PayloadAction<ICandidatesListInitialized>) => {
                return {
                    ...state,
                    isCandidatesListInitialized: action.payload.isCandidatesListInitialized,
                };
            },
            prepare: (isCandidatesListInitialized: boolean) => {
                return {
                    payload: {
                        isCandidatesListInitialized: isCandidatesListInitialized,
                    },
                };
            },
        },
        changeCandidateListError: {
            reducer: (state: ICandidatesState, action: PayloadAction<ICandidatesListError>) => {
                return {
                    ...state,
                    candidatesPagePageError: action.payload.candidatesPagePageError,
                };
            },
            prepare: (candidatesPagePageError: string | null) => {
                return {
                    payload: {
                        candidatesPagePageError: candidatesPagePageError,
                    },
                };
            },
        },
        fetchRecruitersList: (state: ICandidatesState) => {
            return {
                ...state,
            };
        },
        setRecruitersList: {
            reducer: (state: ICandidatesState, action: PayloadAction<ISetRecruitersList>) => {
                return {
                    ...state,
                    recruitersList: action.payload.recruitersList,
                };
            },
            prepare: (recruitersList: IModelExternalEmployee[] | null) => {
                return {
                    payload: {
                        recruitersList: recruitersList,
                    },
                };
            },
        },
        scheduleApplicationMeeting: {
            reducer: (state: ICandidatesState) => {
                return {
                    ...state,
                };
            },
            prepare: (applicationPayload: IScheduleConsultationPayload) => {
                return {
                    payload: {
                        applicationPayload: applicationPayload,
                    },
                };
            },
        },
        changeIsApplicationScheduled: {
            reducer: (state: ICandidatesState, action: PayloadAction<IChangeIsApplicationMeetingScheduled>) => {
                return {
                    ...state,
                    isApplicationMeetingScheduled: action.payload.isApplicationMeetingScheduled,
                };
            },
            prepare: (isApplicationMeetingScheduled: boolean) => {
                return {
                    payload: {
                        isApplicationMeetingScheduled: isApplicationMeetingScheduled,
                    },
                };
            },
        },
        setCandidatesMetadata: {
            reducer: (state: ICandidatesState, action: PayloadAction<ISetMetadata>) => {
                return {
                    ...state,
                    candidatesMetadata: action.payload.metadata,
                };
            },
            prepare(metadata: IModelApiResponseViewObject | null) {
                return {
                    payload: {
                        metadata: metadata,
                    },
                };
            },
        },
        changeCandidatesPagination: {
            reducer: (state: ICandidatesState, action: PayloadAction<IChangePagination>) => {
                return {
                    ...state,
                    isCandidatesListLoading: true,
                    pagination: action.payload.pagination,
                };
            },
            prepare(pagination: IPaginationParams) {
                return {
                    payload: {pagination: pagination},
                };
            },
        },
        resetToInitialCandidatesState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setCandidatesList,
    changeCandidateListError,
    changeIsCandidatesListInitialized,
    changeIsCandidatesListLoading,
    fetchCandidatesList,
    resetToInitialCandidatesState,
    setCandidateFilterOptions,
    applyCandidatesFilters,
    fetchCandidateFilterOptions,
    rejectCandidate,
    changeCandidateFilters,
    changeCandidateRejected,
    hireCandidate,
    acceptCandidate,
    changeCandidateHired,
    fetchRecruitersList,
    setRecruitersList,
    changeIsApplicationScheduled,
    scheduleApplicationMeeting,
    setCandidatesMetadata,
    changeCandidatesPagination,
} = candidatesPageSlice.actions;

export default candidatesPageSlice.reducer;
