import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {Form, FormControlChangeType, IFormConfig, Loader, LoaderType, Translation} from 'jobhunter-common-web';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {sendInvitationFormConfig} from './sendInvitationFormConfig';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {IInvitationPayload} from '../../../../model/hrListDataModel';
import {hrPageErrorSelector, isHRAddedSelector} from '../../../../store/selectors/hrPageSelectors';
import {addHRInvitation, changeIsHRAdded} from '../../../../store/reducers/hrPageSlice';

interface IConnectedInvitationModalProps {
    readonly isHRAdded: boolean;
    readonly error: string | null;
    readonly changeIsHRAdded: typeof changeIsHRAdded;
    readonly addHRInvitation: typeof addHRInvitation;
}

interface IExternalInvitationModalProps {}

interface IInvitationModalProps extends IConnectedInvitationModalProps, IExternalInvitationModalProps {
    isModalOpen: boolean;
    toggleModal: () => void;
}

interface IInvitationModalState {
    value: any;
    formConfig: typeof IFormConfig | null;
    isLoading: boolean;
}

class InvitationModal extends Component<IInvitationModalProps, IInvitationModalState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IInvitationModalProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: sendInvitationFormConfig(),
            isLoading: false,
        };
    }

    componentDidMount(): void {
        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    tap((data: any) => this.setState({value: data.value}))
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: IInvitationModalProps): void {
        if (this.props.isHRAdded !== prevProps.isHRAdded && this.props.isHRAdded) {
            this.props.toggleModal();
        }

        if (this.props.error !== prevProps.error && this.props.error) {
            this.setState({isLoading: false});
        }
    }

    componentWillUnmount() {
        this.props.changeIsHRAdded(false);
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <Modal isOpen={this.props.isModalOpen} toggle={() => this.props.toggleModal()}>
                <ModalHeader toggle={() => this.props.toggleModal()}>
                    <Translation text="employer.cooperatingHr.invitationModal.title" />
                </ModalHeader>
                <ModalBody>
                    {this.state.formConfig && (
                        <Form
                            config={this.state.formConfig}
                            onValueStateChange={this.onValueStateChange}
                            value={this.state.value}
                            controlName={'inviteRecruiterForm'}
                        />
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" className="close-modal-button" outline onClick={() => this.props.toggleModal()}>
                        <Translation text="buttons.cancel" />
                    </Button>
                    <Button color="primary" className="send-hr-invitation-button" onClick={() => this.sendHrInvitation(this.state.value)}>
                        <Translation text="buttons.sendInvitation" />
                    </Button>
                </ModalFooter>

                <Loader type={LoaderType.Local} showLoader={this.state.isLoading} />
            </Modal>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private sendHrInvitation = (value: any) => {
        this.setState({isLoading: true});
        const payload: IInvitationPayload = {
            email: value.email,
            returnUrl: `${process.env.REACT_APP_ORGANIZATION_URL}/accept-invitation`,
        };
        this.props.addHRInvitation(payload);
    };
}

export default connect(
    (state: RootState) => ({
        isHRAdded: isHRAddedSelector(state),
        error: hrPageErrorSelector(state),
    }),
    {addHRInvitation, changeIsHRAdded}
)(InvitationModal);
