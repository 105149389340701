import {Observable} from 'rxjs';
import {jobHunterAPI, RestQueryParams} from 'jobhunter-common-web';

export interface IEmployerPageUpdatePayload {
    organization: {
        organization: {
            name: string | null;
            mobile: string | null;
            address: string | null;
            cityName: string | null;
            countryId: string | null;
            avatar: string | null;
            about: string | null;
            organizationVerificationFiles: string[];
        };
        organizationDetails: {
            cities: string[];
            companyTypes: string[];
            industries: string[];
            organizationSizeId: string;
        };
    };
    firstName: string | null;
    lastName: string | null;
    phone: string | null;
    birthDate: string | null;
    cityName: string | null;
    countryId: string | null;
    about: string | null;
    avatar: string | null;
}

export function updateCompanyAccountDataAPI(
    authToken: string | null,
    accountId: string,
    payload: IEmployerPageUpdatePayload
): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return jobHunterAPI.put(`api/accounts/${accountId}/change_organization_full_info`, payload, new RestQueryParams(), headers);
}
