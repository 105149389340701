import {Observable} from 'rxjs';
import {jobHunterAPI, RestQueryParams} from 'jobhunter-common-web';

export enum EmployeeType {
    EXTERNAL = 'external',
    ORGANIZATION = 'organization',
}

export function getEmployeesAPI(authToken: string | null, employeeType: EmployeeType, params?: typeof RestQueryParams): Observable<any> {
    let headers = undefined,
        queryParams = params ? params : new RestQueryParams();

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return jobHunterAPI.get(`api/${employeeType}_employees`, queryParams, headers);
}
