import React, {Component} from 'react';
import {authTokenSelector, getOnlineConsultationAPI, UserRole, VideoChat, withParams, WithParams} from 'jobhunter-common-web';
import {connect} from 'react-redux';
import {RootState} from '../../store/reducers';
import {Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import LayoutWrapper from '../LayoutWrapper';

declare type WithParamsProps = typeof WithParams;

interface IConnectedVideoChatHostProps {
    readonly authToken: string | null;
}

interface IExternalVideoChatHostProps extends WithParamsProps {}

interface IVideoChatHostProps extends IConnectedVideoChatHostProps, IExternalVideoChatHostProps {}

interface IVideoChatHostState {
    onlineConsultation: {[key: string]: any} | null;
    userSecret: string | null;
    userId: string | null;
    userToken: string | null;
}

class VideoChatHost extends Component<IVideoChatHostProps, IVideoChatHostState> {
    readonly subscriptions: Subscription[] = [];

    constructor(props: IVideoChatHostProps) {
        super(props);

        this.state = {
            onlineConsultation: null,
            userSecret: null,
            userId: null,
            userToken: null,
        };
    }

    componentDidMount(): void {
        const consultationId = this.props.params.id;
        if (consultationId) {
            this.getConsultationDetails(consultationId);
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <LayoutWrapper contentClass="video-host">
                <VideoChat
                    onlineConsultation={this.state.onlineConsultation}
                    userSecret={this.state.userSecret}
                    userRole={UserRole.HEADHUNTER}
                    userId={this.state.userId}
                    userToken={this.state.userToken}
                />
            </LayoutWrapper>
        );
    }

    private getConsultationDetails = (consultationId: string) => {
        if (null === this.props.authToken) {
            return;
        }
        return this.subscriptions.push(
            getOnlineConsultationAPI(consultationId, null, this.props.authToken)
                .pipe(
                    map((resp: any) => {
                        const applicantData = resp.participants.filter(
                                (participant: {[key: string]: any}) => participant.role === UserRole.HEADHUNTER
                            ),
                            userSecret = applicantData[0]?.accessSecret,
                            userId = applicantData[0]?.id,
                            userToken = applicantData[0]?.agoraToken;

                        this.setState({
                            onlineConsultation: resp,
                            userSecret: userSecret,
                            userId: userId,
                            userToken: userToken,
                        });
                    })
                )
                .subscribe()
        );
    };
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
    }),
    {}
)(withParams(VideoChatHost));
