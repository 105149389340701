import React, {Component} from 'react';
import LayoutWrapper from '../../LayoutWrapper';
import {Translation, Loader, LoaderType, Pagination} from 'jobhunter-common-web';
import {Card, CardBody, Table} from 'reactstrap';
import RecruitmentTableRow from './RecruitmentTableRow';
import {RootState} from '../../../store/reducers';
import {connect} from 'react-redux';
import {IModelRecruitment} from '../../../model/recruitmentDataModel';
import {
    isRecruitmentListLoadingSelector,
    recruitmentListSelector,
    recruitmentMetadataSelector,
} from '../../../store/selectors/recruitmentSelectors';
import {
    changeRecruitmentPagination,
    fetchRecruitmentList,
    resetToInitialRecruitmentState,
} from '../../../store/reducers/recruitmentPageSlice';
import {IModelApiResponseViewObject} from '../../../model/base';

interface IConnectedRecruitmentProps {
    readonly recruitmentList: IModelRecruitment[] | null;
    readonly recruitmentMetadata: IModelApiResponseViewObject | null;
    readonly resetToInitialRecruitmentState: typeof resetToInitialRecruitmentState;
    readonly fetchRecruitmentList: typeof fetchRecruitmentList;
    readonly isRecruitmentLoading: boolean;
    readonly changeRecruitmentPagination: typeof changeRecruitmentPagination;
}

interface IRecruitmentProps extends IConnectedRecruitmentProps {}

interface IRecruitmentState {}

class Recruitment extends Component<IRecruitmentProps, IRecruitmentState> {
    componentDidMount(): void {
        this.props.fetchRecruitmentList();
    }

    componentWillUnmount(): void {
        this.props.resetToInitialRecruitmentState();
    }

    render() {
        return (
            <LayoutWrapper>
                <div className="panel-section-header">
                    <Translation text="humanResources.recruitment.title" />
                </div>
                <Card className="recruitment-card">
                    <CardBody>
                        <Table responsive className="recruitment-table">
                            <thead>
                                <tr>
                                    <th>
                                        <Translation text="humanResources.recruitment.table.position" />
                                    </th>
                                    <th>
                                        <Translation text="humanResources.recruitment.table.candidates" />
                                    </th>
                                    <th>
                                        <Translation text="humanResources.recruitment.table.status" />
                                    </th>
                                    <th>
                                        <Translation text="humanResources.recruitment.table.offers" />
                                    </th>
                                    <th>
                                        <Translation text="humanResources.recruitment.table.actions" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>{this.renderRecruitmentTableRows()}</tbody>
                        </Table>
                    </CardBody>
                </Card>
                <Pagination listMetadata={this.props.recruitmentMetadata} changePage={this.props.changeRecruitmentPagination} />

                <Loader showLoader={this.props.isRecruitmentLoading} type={LoaderType.Local} />
            </LayoutWrapper>
        );
    }

    private renderRecruitmentTableRows() {
        if (!this.props.recruitmentList || !this.props.recruitmentList.length) {
            return (
                <tr key="no-recruitments">
                    <td colSpan={5}>
                        <Translation text="humanResources.recruitment.table.noData" />
                    </td>
                </tr>
            );
        }
        return this.props.recruitmentList?.map((item: IModelRecruitment, index: number) => {
            return <RecruitmentTableRow key={`${item.id}_${index}`} recruitmentItem={item} />;
        });
    }
}

export default connect(
    (state: RootState) => ({
        recruitmentList: recruitmentListSelector(state),
        recruitmentMetadata: recruitmentMetadataSelector(state),
        isRecruitmentLoading: isRecruitmentListLoadingSelector(state),
    }),
    {fetchRecruitmentList, resetToInitialRecruitmentState, changeRecruitmentPagination}
)(Recruitment);
