import React, {Component} from 'react';
import LayoutWrapper from '../../../LayoutWrapper';
import {Badge, Card, CardBody} from 'reactstrap';
import {
    ApexRadialbar,
    DateComponent,
    ICandidateCareer,
    ICandidateLanguage,
    ICandidateTechnology,
    IModelDictionaryDatum,
    Loader,
    LoaderType,
    Translation,
    withLocation,
    WithLocation,
} from 'jobhunter-common-web';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {fetchCandidateDetails} from '../../../../store/reducers/candidateViewPageSlice';
import {candidateDetailsSelector, isCandidateViewLoadingSelector} from '../../../../store/selectors/candidateViewPageSelectors';
import CandidatePersonalDetails from './CandidatePersonalDetails';
import {IModelCandidateDetails} from '../../../../model/candidateDataModel';

declare type WithLocationProps = typeof WithLocation;

interface IConnectedCandidateViewProps {
    readonly candidate: IModelCandidateDetails;
    readonly isLoading: boolean;
    readonly fetchCandidateDetails: typeof fetchCandidateDetails;
}

interface ICandidateViewProps extends IConnectedCandidateViewProps, WithLocationProps {}

interface ICandidateViewState {}

class CandidateView extends Component<ICandidateViewProps, ICandidateViewState> {
    componentDidMount(): void {
        if (this.props.location && this.props.location.pathname) {
            const applicationId = this.props.location.pathname.split('/').pop();
            this.props.fetchCandidateDetails(applicationId);
        }
    }

    render() {
        const candidate = this.props.candidate?.candidate;

        if (null === candidate) {
            return (
                <p>
                    <Translation text="humanResources.candidates.candidateView.noData" />
                </p>
            );
        }

        return (
            <LayoutWrapper>
                <Card className="cadidate-personal-details-card">
                    <CardBody>
                        <CandidatePersonalDetails candidate={candidate} />
                    </CardBody>
                </Card>

                <Card className="candidate-career-card">
                    <CardBody>
                        <div className="wizard-content">
                            <div className="career-section">
                                <p className="title">
                                    <Translation text="humanResources.candidates.candidateView.career" />
                                </p>
                                <p className="sub-title">
                                    <Translation text="humanResources.candidates.candidateView.careerTimeline" />
                                </p>
                                {!candidate?.careers || !candidate?.careers?.length ? (
                                    <p className="no-options">
                                        <Translation text="humanResources.candidates.candidateView.noCareer" />
                                    </p>
                                ) : (
                                    candidate?.careers.map((item: typeof ICandidateCareer) => this.renderCareerItem(item))
                                )}
                            </div>

                            <div className="career-section">
                                <p className="sub-title">
                                    <Translation text="humanResources.candidates.candidateView.technologySkills" />
                                </p>
                                {!candidate?.technologies || !candidate?.technologies?.length ? (
                                    <p className="no-options">
                                        <Translation text="humanResources.candidates.candidateView.noTechnologySkills" />
                                    </p>
                                ) : (
                                    candidate?.technologies.map((item: typeof ICandidateTechnology) => this.renderTechnologyItem(item))
                                )}
                            </div>

                            <div className="career-section">
                                <p className="sub-title">
                                    <Translation text="humanResources.candidates.candidateView.languageSkills" />
                                </p>
                                {!candidate?.languages || !candidate?.languages?.length ? (
                                    <p className="no-options">
                                        <Translation text="humanResources.candidates.candidateView.noLanguageSkills" />
                                    </p>
                                ) : (
                                    candidate?.languages.map((item: typeof ICandidateLanguage) => this.renderLanguageItem(item))
                                )}
                            </div>

                            <div className="personal-information-section">
                                <div className="header">
                                    <p className="title">
                                        <Translation text="humanResources.candidates.candidateView.skillsValidation" />
                                    </p>
                                </div>

                                <div className="skills-content">
                                    <div className="skills">
                                        <p>
                                            <Translation text="humanResources.candidates.candidateView.technologySkills" />
                                        </p>
                                        {!candidate?.technologyTestResults || !candidate?.technologyTestResults?.length ? (
                                            <p className="no-options">
                                                <Translation text="humanResources.candidates.candidateView.noTechnologyTests" />
                                            </p>
                                        ) : (
                                            candidate?.technologyTestResults.map((item: {[key: string]: any}) =>
                                                this.renderSkillResults(item, true)
                                            )
                                        )}
                                    </div>

                                    <div className="skills">
                                        <p>
                                            <Translation text="humanResources.candidates.candidateView.languageSkills" />
                                        </p>
                                        {!candidate?.languageTestResults || !candidate?.languageTestResults?.length ? (
                                            <p className="no-options">
                                                <Translation text="humanResources.candidates.candidateView.noLanguageTests" />
                                            </p>
                                        ) : (
                                            candidate?.languageTestResults.map((item: {[key: string]: any}) =>
                                                this.renderSkillResults(item)
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
                <Loader type={LoaderType.Local} showLoader={this.props.isLoading} />
            </LayoutWrapper>
        );
    }

    private renderCareerItem = (item: typeof ICandidateCareer) => {
        return (
            <div className="career-item" key={item.id}>
                <div className="duration">
                    <DateComponent date={item.from} />
                    {item.to ? (
                        <DateComponent date={item.to} />
                    ) : (
                        <Translation text="humanResources.candidates.candidateView.presentCareer" />
                    )}
                </div>
                <div className="career-details">
                    <div className="company-details">
                        <p className="company">{item.company}</p>
                        <p>{item.position}</p>
                    </div>
                    <div className="d-flex">
                        <div className="position me-5">
                            <Badge color="light-primary" pill>
                                <Translation text={item.seniority.name} />
                            </Badge>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    private renderTechnologyItem = (item: typeof ICandidateTechnology) => {
        const tools = item.technologyTool,
            seniority = item.seniority,
            technology = item.technology;

        return (
            <div className="career-item" key={item.id}>
                <div className="career-details">
                    <div className="technology">
                        {/*{item.logo ? <img className="rounded me-1" src={item.logo} height="30" alt={item.position} /> : null}*/}
                        <div className="technology-details">
                            <p>
                                <Translation text={technology.name} />
                            </p>
                            <div className="tools">
                                {tools
                                    ? tools.map((tool: typeof IModelDictionaryDatum) => {
                                          return (
                                              <p key={tool.id}>
                                                  <Translation text={tool.name} />
                                              </p>
                                          );
                                      })
                                    : null}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="position me-5">
                            <Badge color="light-primary">
                                <Translation text={seniority.name} />
                            </Badge>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    private renderLanguageItem = (item: typeof ICandidateLanguage) => {
        return (
            <div className="career-item" key={`${item.language.name}-${item.languageLevel.name}`}>
                <div className="career-details">
                    <div className="technology">
                        <p>
                            <Translation text={item.language.name} />
                        </p>
                    </div>
                    <div className="d-flex">
                        <div className="position me-5">
                            <Badge color="light-primary">
                                <Translation text={item.languageLevel.name} />
                            </Badge>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    private renderSkillResults = (item: {[key: string]: any}, isTechnology?: boolean) => {
        const resultDetails = isTechnology ? item.technology : item.language;
        return (
            <div className="skill-item col-xl-6" key={item.id}>
                <div className="d-flex align-items-center justify-content-center">
                    {item.avatar ? <img className="rounded me-1" src={item.avatar} height="30" alt={item.title} /> : null}
                    <p>
                        <Translation text={resultDetails.name} />
                    </p>
                </div>
                <div className="d-flex align-items-center">
                    {item.result ? (
                        <ApexRadialbar item={{value: item.result, series: [item.result]}} />
                    ) : (
                        <p className="no-options">
                            <Translation text="humanResources.candidates.candidateView.noTestResultsAvailable" />
                        </p>
                    )}
                </div>
            </div>
        );
    };
}

export default connect(
    (state: RootState) => ({candidate: candidateDetailsSelector(state), isLoading: isCandidateViewLoadingSelector(state)}),
    {
        fetchCandidateDetails,
    }
)(withLocation(CandidateView));
