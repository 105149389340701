import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {catchError, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {addAlert, AlertType, authTokenSelector, getErrorMessage} from 'jobhunter-common-web';
import {RootState} from '../reducers';
import {
    changeCandidateViewError,
    changeIsCandidateViewLoading,
    fetchCandidateDetails,
    IFetchCandidateDetails,
    setCandidateDetails,
} from '../reducers/candidateViewPageSlice';
import {getOfferApplicationAPI} from '../../api/getOfferApplicationAPI';
import {PayloadAction} from '@reduxjs/toolkit';

const fetchCalendarDetailsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchCandidateDetails.type),
        switchMap((action: PayloadAction<IFetchCandidateDetails>): any => {
            const authToken = authTokenSelector(state$.value),
                applicationId = action.payload.applicationId;
            return getOfferApplicationAPI(authToken, applicationId).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([setCandidateDetails(resp)]);
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const successActions = (changeSliceList?: any[]): any[] => {
    const actions = [changeIsCandidateViewLoading(false)];

    if (changeSliceList) {
        return actions.concat(changeSliceList);
    }
    return actions;
};

const updateListErrorActions = (error: any): any[] => {
    return [
        changeIsCandidateViewLoading(false),
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
        changeCandidateViewError(getErrorMessage(error)),
    ];
};

const candidateViewEpic = combineEpics(fetchCalendarDetailsEpic);

export default candidateViewEpic;
