import {
    collectionInputDataMapper,
    collectionOutputDataMapper,
    FormControlType,
    IFormConfig,
    MultiSelectType,
    ValidationRules,
    IMultiselectOption,
    FormButtonType,
} from 'jobhunter-common-web';

export const addRecruiterFormConfig: typeof IFormConfig = (
    availableDates: any[] = [],
    availableTimeSlots: any[] | null = [],
    recruiters: typeof IMultiselectOption[] | [],
    dismissModal: () => void
) => ({
    controlType: 'form',
    class: 'default-form',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'date',
            class: 'form-row',
            controls: {
                date: {
                    hostClass: 'col-xl-12 form-control month-calendar',
                    controlType: 'control',
                    defaultValue: null,
                    placeholder: '',
                    formControlType: FormControlType.CALENDAR,
                    isLabelHidden: true,
                    checkboxSlots: false,
                    isDateFiltered: false,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    availableDates: availableDates,
                    availableTimeSlots: availableTimeSlots,
                },
            },
        },
        {
            key: 'recruiter',
            controlType: 'group',
            class: 'row custom-row',
            controls: {
                recruiter: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    multiselectOptions: recruiters,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: 'humanResources.dashboard.candidates.addRecruiterModal.form.placeholders.recruiter',
                    label: 'humanResources.dashboard.candidates.addRecruiterModal.form.labels.recruiter',
                    hostClass: 'col-xl-12 form-control required',
                    inputDataMapper: collectionInputDataMapper,
                    outputDataMapper: collectionOutputDataMapper,
                },
            },
        },
        {
            controlType: 'group',
            key: 'schedule_meeting_buttons',
            class: 'row justify-content-end',
            controls: {
                closeModalButton: {
                    buttonName: 'previous',
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.BUTTON,
                    inputStyles: 'btn-prev btn-outline-primary d-flex align-items-center',
                    hostClass: 'mb-0 w-auto',
                    defaultContainerStyles: '',
                    containerStyles: '',
                    defaultValue: null,
                    btnText: 'buttons.cancel',
                    buttonDisabled: false,
                    customClickHandler: () => {
                        return dismissModal();
                    },
                },
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    inputStyles: 'btn-primary',
                    defaultContainerStyles: '',
                    hostClass: 'mb-0 align-items-end w-auto',
                    containerStyles: '',
                    defaultValue: null,
                    isLabelHidden: true,
                    btnText: 'buttons.add',
                    buttonDisabled: (mappedOutputValue: any, isFormValid: boolean) => {
                        const isDateSelected =
                            mappedOutputValue &&
                            mappedOutputValue.date &&
                            mappedOutputValue.date.name === 'selectedDate' &&
                            null !== mappedOutputValue.date.value;
                        return !isFormValid || !isDateSelected;
                    },
                },
            },
        },
    ],
});
