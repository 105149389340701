import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {catchError, map, switchMap} from 'rxjs/operators';
import {forkJoin, of} from 'rxjs';
import {RootState} from '../reducers';
import {
    changeBillingPageError,
    changeIsBillingLoading,
    changeIsPlansListLoading,
    fetchSubscriptionPlansList,
    fetchSubscriptions,
    setSubscriptionPlansList,
    setSubscriptions,
} from '../reducers/billingPageSlice';
import {addAlert, AlertType, authTokenSelector, getErrorMessage} from 'jobhunter-common-web';
import {getSubscriptionsListAPI} from '../../api/getSubscritionsListAPI';
import {getCurrentSubscriptionsAPI} from '../../api/getCurrentSubscriptionsAPI';
import {getPurchasedSubscriptionsAPI} from '../../api/getPurchasedSubscriptionsAPI';

const fetchSubscriptionPlansListEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchSubscriptionPlansList.type),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value);
            return getSubscriptionsListAPI(authToken).pipe(
                switchMap((resp: any) => {
                    const actions = successActions(setSubscriptionPlansList(resp['hydra:member']));
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const fetchSubscriptionsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchSubscriptions.type),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value),
                requestSubs = {
                    current: getCurrentSubscriptionsAPI(authToken).pipe(map((resp: any) => resp['hydra:member'])),
                    purchased: getPurchasedSubscriptionsAPI(authToken).pipe(map((resp: any) => resp['hydra:member'])),
                };
            return forkJoin(requestSubs).pipe(
                switchMap((resp: any) => {
                    const actions = successActions(setSubscriptions(resp));
                    return of(...actions);
                })
            );
        })
    );
};

const successActions = (changeSliceList: any): any[] => {
    return [changeSliceList, changeIsPlansListLoading(false), changeIsBillingLoading(false)];
};

const updateListErrorActions = (error: any): any[] => {
    return [
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
        [changeBillingPageError(getErrorMessage(error)), changeIsPlansListLoading(false), changeIsBillingLoading(false)],
    ];
};

const billingEpic = combineEpics(fetchSubscriptionPlansListEpic, fetchSubscriptionsEpic);

export default billingEpic;
