import {
    FormControlType,
    IFormConfig,
    InputType,
    IMultiselectOption,
    collectionOutputDataMapper,
    collectionInputDataMapper,
    MultiSelectType,
    IModelSeniority,
    formatRangePickerLabel,
} from 'jobhunter-common-web';

export const editPositionFormConfig: typeof IFormConfig = (
    cityList: typeof IMultiselectOption[] | [],
    seniorityLevels: {[key: number]: typeof IModelSeniority}
) => ({
    controlType: 'form',
    class: 'default-form',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'name',
            controlType: 'group',
            class: 'row',
            controls: {
                name: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [],
                    placeholder: 'employer.positions.addPosition.form.labels.position',
                    isLabelHidden: false,
                    label: 'employer.positions.addPosition.form.labels.position',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-6 form-control',
                },
            },
        },
        {
            controlType: 'group',
            key: 'country_seniority',
            class: 'row',
            controls: {
                city: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    multiselectOptions: cityList ? cityList : [],
                    validationRules: [],
                    placeholder: 'employer.positions.addPosition.form.placeholders.city',
                    label: 'employer.positions.addPosition.form.labels.city',
                    hostClass: 'col-xl-6 form-control',
                    inputDataMapper: collectionInputDataMapper,
                    outputDataMapper: collectionOutputDataMapper,
                },
                seniority: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.RANGE_PICKER,
                    validationRules: [],
                    rangeLabels: seniorityLevels,
                    label: 'employer.positions.addPosition.form.labels.seniority',
                    hostClass: 'col-xl-6 form-control',
                    labelFormat: (value: number) => formatRangePickerLabel(value, seniorityLevels),
                    maxRangeStep: Object.keys(seniorityLevels).length * 10,
                    isRangeLabelTranslated: true,
                },
            },
        },
        {
            key: 'salary',
            controlType: 'group',
            class: 'row',
            controls: {
                minSalary: {
                    controlType: 'control',
                    formControlType: FormControlType.QUANTITY_INPUT,
                    hostClass: 'col-xl-3 form-control',
                    label: 'employer.positions.addPosition.form.labels.minSalary',
                    minValue: 0,
                    stepValue: 100,
                    type: InputType.NUMBER,
                },
                maxSalary: {
                    controlType: 'control',
                    formControlType: FormControlType.QUANTITY_INPUT,
                    hostClass: 'col-xl-3 form-control',
                    label: 'employer.positions.addPosition.form.labels.maxSalary',
                    minValue: 0,
                    stepValue: 100,
                    type: InputType.NUMBER,
                },
            },
        },
        {
            key: 'description',
            controlType: 'group',
            class: 'row',
            controls: {
                description: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.RICHTEXT,
                    validationRules: [],
                    placeholder: 'employer.positions.addPosition.form.placeholders.description',
                    label: 'employer.positions.addPosition.form.labels.description',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12 form-control',
                },
            },
        },
    ],
});
