import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelApiResponseViewObject} from 'jobhunter-common-web';
import {IAddServicePayload, IModelServiceListItem} from '../../model/servicesDataModel';

export interface IFetchServiceList {
    params: {[key: string]: any} | null;
}

export interface IPaginationParams {
    page: number;
    itemsPerPage: number;
}

export interface ISetServiceList {
    servicesList: IModelServiceListItem[] | null;
}

export interface ISetServicesMetadata {
    servicesMetadata: typeof IModelApiResponseViewObject | null;
}

export interface IChangeServicesPagination {
    pagination: IPaginationParams;
}

export interface IServicesListLoading {
    isServicesListLoading: boolean;
}

export interface IServicesListInitialized {
    isServicesListInitialized: boolean;
}

export interface IServiceListError {
    servicesListError: string | null;
}

export interface IServiceUpdated {
    isServiceUpdated: boolean;
}

export interface IAddService {
    service: IAddServicePayload;
}

export interface IDeleteService {
    serviceId: string;
}

export interface IServicesListState {
    servicesList: IModelServiceListItem[] | null;
    servicesMetadata: typeof IModelApiResponseViewObject | null;
    pagination: IPaginationParams | null;
    isServicesListLoading: boolean;
    isServicesListInitialized: boolean;
    servicesListError: string | null;
    isServiceAdded: boolean;
    isServiceRemoved: boolean;
    isServiceStatusUpdated: boolean;
}

const initialState: IServicesListState = {
    servicesList: null,
    servicesMetadata: null,
    pagination: {
        itemsPerPage: 10,
        page: 1,
    },
    isServicesListLoading: false,
    isServicesListInitialized: false,
    servicesListError: null,
    isServiceAdded: false,
    isServiceRemoved: false,
    isServiceStatusUpdated: false,
};

const servicesPageSlice = createSlice({
    name: 'servicesList',
    initialState: initialState,
    reducers: {
        addService: {
            reducer: (state: IServicesListState) => {
                return {
                    ...state,
                };
            },
            prepare: (service: IAddServicePayload) => {
                return {
                    payload: {
                        service: service,
                    },
                };
            },
        },
        changeServiceAdded: {
            reducer: (state: IServicesListState, action: PayloadAction<IServiceUpdated>) => {
                return {
                    ...state,
                    isServiceAdded: action.payload.isServiceUpdated,
                };
            },
            prepare: (isServiceUpdated: boolean) => {
                return {
                    payload: {
                        isServiceUpdated: isServiceUpdated,
                    },
                };
            },
        },
        setServicesList: {
            reducer: (state: IServicesListState, action: PayloadAction<ISetServiceList>) => {
                return {
                    ...state,
                    servicesList: action.payload.servicesList,
                };
            },
            prepare: (servicesList: IModelServiceListItem[] | null) => {
                return {
                    payload: {
                        servicesList: servicesList,
                    },
                };
            },
        },
        setServicesMetadata: {
            reducer: (state: IServicesListState, action: PayloadAction<ISetServicesMetadata>) => {
                return {
                    ...state,
                    servicesMetadata: action.payload.servicesMetadata,
                };
            },
            prepare(servicesMetadata: typeof IModelApiResponseViewObject | null) {
                return {
                    payload: {
                        servicesMetadata: servicesMetadata,
                    },
                };
            },
        },
        changeServicesPagination: {
            reducer: (state: IServicesListState, action: PayloadAction<IChangeServicesPagination>) => {
                return {
                    ...state,
                    isServicesListLoading: true,
                    pagination: action.payload.pagination,
                };
            },
            prepare(pagination: IPaginationParams) {
                return {
                    payload: {pagination: pagination},
                };
            },
        },

        changeServicesPageLoading: {
            reducer: (state: IServicesListState, action: PayloadAction<IServicesListLoading>) => {
                return {
                    ...state,
                    isServicesListLoading: action.payload.isServicesListLoading,
                };
            },
            prepare: (isServicesListLoading: boolean) => {
                return {
                    payload: {
                        isServicesListLoading: isServicesListLoading,
                    },
                };
            },
        },
        changeServicesPageInitialized: {
            reducer: (state: IServicesListState, action: PayloadAction<IServicesListInitialized>) => {
                return {
                    ...state,
                    isServicesListInitialized: action.payload.isServicesListInitialized,
                };
            },
            prepare: (isServicesListInitialized: boolean) => {
                return {
                    payload: {
                        isServicesListInitialized: isServicesListInitialized,
                    },
                };
            },
        },
        changeServicesPageError: {
            reducer: (state: IServicesListState, action: PayloadAction<IServiceListError>) => {
                return {
                    ...state,
                    servicesListError: action.payload.servicesListError,
                };
            },
            prepare: (servicesListError: any) => {
                return {
                    payload: {
                        servicesListError: servicesListError,
                    },
                };
            },
        },
        fetchServicesList: {
            reducer: (state: IServicesListState) => {
                return {
                    ...state,
                    isServicesListLoading: true,
                };
            },
            prepare: (params: {[key: string]: any} | null) => {
                return {
                    payload: {
                        params: params,
                    },
                };
            },
        },
        deleteService: {
            reducer: (state: IServicesListState) => {
                return {
                    ...state,
                };
            },
            prepare: (serviceId: string) => {
                return {
                    payload: {
                        serviceId: serviceId,
                    },
                };
            },
        },
        changeServiceRemoved: {
            reducer: (state: IServicesListState, action: PayloadAction<IServiceUpdated>) => {
                return {
                    ...state,
                    isServiceRemoved: action.payload.isServiceUpdated,
                };
            },
            prepare: (isServiceUpdated: boolean) => {
                return {
                    payload: {
                        isServiceUpdated: isServiceUpdated,
                    },
                };
            },
        },
        changeIsServiceStatusUpdated: {
            reducer: (state: IServicesListState, action: PayloadAction<IServiceUpdated>) => {
                return {
                    ...state,
                    isServiceStatusUpdated: action.payload.isServiceUpdated,
                };
            },
            prepare: (isServiceUpdated: boolean) => {
                return {
                    payload: {
                        isServiceUpdated: isServiceUpdated,
                    },
                };
            },
        },
        resetToInitialServicePageState: () => {
            return {
                ...initialState,
            };
        },
    },
});
export const {
    addService,
    setServicesList,
    fetchServicesList,
    setServicesMetadata,
    changeServicesPagination,
    resetToInitialServicePageState,
    changeServicesPageError,
    changeServicesPageInitialized,
    changeServicesPageLoading,
    changeServiceAdded,
    deleteService,
    changeIsServiceStatusUpdated,
    changeServiceRemoved,
} = servicesPageSlice.actions;

export default servicesPageSlice.reducer;
