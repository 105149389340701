import {Observable} from 'rxjs';
import {jobHunterAPI, RestQueryParams} from 'jobhunter-common-web';

export function getPreferredCandidatesAPI(authToken: string | null, offerId: string, params?: typeof RestQueryParams): Observable<any> {
    let headers = undefined,
        queryParams = params ? params : new RestQueryParams();

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return jobHunterAPI.get(`api/offer_applications/preferred_candidates/${offerId}`, queryParams, headers);
}
