import React from 'react';
import {CheckSquare, File, FileText, Grid, Home, Inbox, MessageSquare, PenTool, Shuffle, User, Users} from 'react-feather';
import {ISideMenuGroup, ISideMenuItem, ISideMenuHeader} from 'jobhunter-common-web';

const menu = (
    isHeadhunterOnly: boolean,
    isStandardEmployee: boolean
): (typeof ISideMenuGroup | typeof ISideMenuItem | typeof ISideMenuHeader)[] => {
    const menuItems = [
        {
            id: 'dashboards',
            title: 'Dashboards',
            icon: <Home size={20} />,
            navLink: '/panel/dashboard',
            badge: 'primary',
            navLinkClassname: 'dashboard-link',
            // badgeText is hidden in current version
            // badgeText: '2',
        },
        {
            id: 'employer',
            title: 'Employer',
            icon: <User size={20} />,
            navLink: '/panel/employer',
            navLinkClassname: 'employer-link',
            children: [
                {
                    id: 'positions',
                    title: 'Positions',
                    icon: <Users size={20} />,
                    navLink: '/panel/employer/positions',
                    navLinkClassname: 'positions-link',
                },
                {
                    id: 'cooperating-hr',
                    title: 'Cooperating HR',
                    icon: <Inbox size={20} />,
                    navLink: '/panel/employer/cooperating_human_resources',
                    navLinkClassname: 'cooperating-hr-link',
                },
            ],
        },
        {
            id: 'hr',
            title: 'HR',
            icon: <CheckSquare size={20} />,
            navLink: '/panel/human_resources',
            navLinkClassname: 'human-resources-link',
            children: [
                {
                    id: 'employee',
                    title: 'Employee',
                    icon: <Users size={20} />,
                    navLink: '/panel/human_resources/employee',
                    navLinkClassname: 'employee-link',
                },
                {
                    id: 'recruitment',
                    title: 'Recruitment',
                    icon: <File size={20} />,
                    navLink: '/panel/human_resources/recruitment',
                    navLinkClassname: 'recruitment-link',
                },
                {
                    id: 'offers',
                    title: 'Offers',
                    icon: <PenTool size={20} />,
                    navLink: '/panel/human_resources/offers',
                    navLinkClassname: 'offers-link',
                },
                {
                    id: 'candidates',
                    title: 'Candidates',
                    icon: <Users size={20} />,
                    navLink: '/panel/human_resources/candidates',
                    navLinkClassname: 'candidates-link',
                },
            ],
        },
        {
            id: 'marketplace',
            title: 'Marketplace',
            icon: <Grid size={20} />,
            isLabel: true,
            children: [
                {
                    id: 'services',
                    title: 'My Services',
                    icon: <FileText size={20} />,
                    navLink: '/panel/marketplace/services',
                    navLinkClassname: 'services-link',
                },
                {
                    id: 'purchased_services',
                    title: 'Purchased services',
                    icon: <FileText size={20} />,
                    navLink: '/panel/marketplace/purchased_services',
                    navLinkClassname: 'purchased-services-link',
                },
                {
                    id: 'chat',
                    title: 'Chat',
                    icon: <MessageSquare size={20} />,
                    navLink: '/panel/marketplace/chat',
                    navLinkClassname: 'chat-link',
                },
            ],
        },
        {
            id: 'relocations',
            title: 'Relocations',
            icon: <Shuffle size={20} />,
            navLink: '/panel/relocations',
            navLinkClassname: 'relocations-link',
        },
    ];

    if (isHeadhunterOnly) {
        menuItems.splice(1, 1);
    }

    if (isStandardEmployee) {
        menuItems.splice(2, 1);
    }

    return menuItems;
};
export default menu;
