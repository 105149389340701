import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {catchError, switchMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {RootState} from '../reducers';
import {
    addAlert,
    AlertType,
    authTokenSelector,
    flattenObj,
    getErrorMessage,
    getMetadataDetails,
    RestQueryParams,
} from 'jobhunter-common-web';
import {
    addEmployee,
    changeEmployeesPageError,
    changeIsEmployeeAdded,
    changeIsEmployeeDeleted,
    changeIsExternalListLoading,
    deleteEmployee,
    fetchExternalEmployeesList,
    IAddEmployee,
    IEmployeeAction,
    setExternalEmployeesList,
    changeEmployeeRole,
    changeExternalEmployee,
    IChangeExternalEmployee,
    setEmployeesMetadata,
    changeEmployeesPagination,
} from '../reducers/employeesPageSlice';
import {getEmployeesAPI, EmployeeType} from '../../api/getEmployeesAPI';
import {addEmployeeAPI} from '../../api/addEmployeeAPI';
import {PayloadAction} from '@reduxjs/toolkit';
import {deleteEmployeeAPI} from '../../api/deleteEmployeeAPI';
import {changeEmployeeRoleAPI} from '../../api/changeEmployeeRoleAPI';
import {changeExternalEmployeeAPI} from '../../api/changeExternalEmployeeAPI';
import {employeesPaginationSelector} from '../selectors/employeesPageSelectors';

const fetchExternalEmployeesListEpic: Epic = (action$: Observable<any>, state$: StateObservable<RootState>) => {
    return getEmployees(action$, state$, fetchExternalEmployeesList);
};

const changeEmployeesPaginationEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getEmployees(action$, state$, changeEmployeesPagination);
};

const getEmployees = (action$: Observable<any>, state$: StateObservable<any>, actionType: any) => {
    return action$.pipe(
        ofType(actionType),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value),
                paginationParams = employeesPaginationSelector(state$.value),
                filterObj = {
                    ...paginationParams,
                },
                flattened = flattenObj(filterObj),
                queryParams = new RestQueryParams(flattened);

            return getEmployeesAPI(authToken, EmployeeType.EXTERNAL, queryParams).pipe(
                switchMap((resp: any) => {
                    const metadata = getMetadataDetails(resp['hydra:view']),
                        actions = successActions([setExternalEmployeesList(resp['hydra:member']), setEmployeesMetadata(metadata)]);
                    return of(...actions);
                }),
                catchError((error: any) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error: any) => of(...updateListErrorActions(error)))
    );
};

const deleteEmployeeEpic: Epic = (action$: Observable<any>, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(deleteEmployee.type),
        switchMap((action: PayloadAction<IEmployeeAction>): any => {
            const authToken = authTokenSelector(state$.value);
            return employeeAction(
                deleteEmployeeAPI(authToken, action.payload.employeeId),
                'humanResources.deleteEmployeeModal.employeeDeleted',
                updateListErrorActions,
                [changeIsEmployeeDeleted(true)]
            );
        }),
        catchError((error: any) => of(...updateListErrorActions(error)))
    );
};

const changeEmployeeRoleEpic: Epic = (action$: Observable<any>, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(changeEmployeeRole.type),
        switchMap((action: PayloadAction<IEmployeeAction>): any => {
            const authToken = authTokenSelector(state$.value);
            if (!action.payload.role) {
                return of();
            }
            return employeeAction(
                changeEmployeeRoleAPI(authToken, action.payload.employeeId, action.payload.role),
                'humanResources.addEmployeeModal.employeeUpdated',
                updateListErrorActions,
                [changeIsEmployeeAdded(true)]
            );
        }),
        catchError((error: any) => of(...updateListErrorActions(error)))
    );
};

const addNewEmployeeEpic: Epic = (action$: Observable<any>, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(addEmployee.type),
        switchMap((action: PayloadAction<IAddEmployee>): any => {
            const authToken = authTokenSelector(state$.value);
            return employeeAction(
                addEmployeeAPI(authToken, action.payload.employee),
                'humanResources.addEmployeeModal.employeeAdded',
                updateListErrorActions,
                [changeIsEmployeeAdded(true)]
            );
        }),
        catchError((error: any) => of(...updateListErrorActions(error)))
    );
};

const changeExternalEmployeeEpic: Epic = (action$: Observable<any>, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(changeExternalEmployee.type),
        switchMap((action: PayloadAction<IChangeExternalEmployee>) => {
            const authToken = authTokenSelector(state$.value);
            return employeeAction(
                changeExternalEmployeeAPI(authToken, action.payload.id, action.payload.info),
                'humanResources.addEmployeeModal.employeeUpdated',
                updateListErrorActions,
                [changeIsEmployeeAdded(true)]
            );
        }),
        catchError((error: any) => of(...updateListErrorActions(error)))
    );
};

export const employeeAction = (
    api: Observable<any>,
    successMessage: string,
    errorActions: (error: any) => any[],
    successActions: any[]
) => {
    return api.pipe(
        switchMap(() => {
            let actions = [changeIsExternalListLoading(false), fetchExternalEmployeesList(), addAlert({message: successMessage})];
            actions = actions.concat(successActions);
            return of(...actions);
        }),
        catchError((error: any) => of(...errorActions(error)))
    );
};

const successActions = (changeSliceList: any[]): any[] => {
    const actions = [changeIsExternalListLoading(false), changeEmployeesPageError(null)];
    return [...changeSliceList, ...actions];
};

const updateListErrorActions = (error: any): any[] => {
    return [
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
        changeEmployeesPageError(getErrorMessage(error)),
        changeIsExternalListLoading(false),
    ];
};

const employeesPageEpic = combineEpics(
    fetchExternalEmployeesListEpic,
    addNewEmployeeEpic,
    deleteEmployeeEpic,
    changeEmployeeRoleEpic,
    changeExternalEmployeeEpic,
    changeEmployeesPaginationEpic
);

export default employeesPageEpic;
