import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelPosition} from '../../model/positionsDataModel';

export interface ISoughtPositionDetailsError {
    soughtPositionDetailsError: string | null;
}

export interface ISoughtPositionDetailsIsLoading {
    isSoughtPositionDetailsPageLoading: boolean;
}

export interface ISoughtPositionDetailsIsInitialized {
    isSoughtPositionDetailsPageInitialized: boolean;
}

export interface ISetSoughtPositionDetails {
    soughtPositionDetails: IModelPosition[] | null;
}

export interface IFetchSoughtPosition {
    soughtPositionId: string;
}

export interface ISoughtPositionDetailsState {
    soughtPositionDetails: IModelPosition[] | null;
    isSoughtPositionDetailsPageLoading: boolean;
    isSoughtPositionDetailsPageInitialized: boolean;
    soughtPositionDetailsPageError: string | null;
}

const initialState: ISoughtPositionDetailsState = {
    soughtPositionDetails: null,
    isSoughtPositionDetailsPageLoading: false,
    isSoughtPositionDetailsPageInitialized: false,
    soughtPositionDetailsPageError: null,
};

const soughtPositionDetailsPageSlice = createSlice({
    name: 'soughtPositionDetailsPage',
    initialState: initialState,
    reducers: {
        setSoughtPositionDetails: {
            reducer: (state: ISoughtPositionDetailsState, action: PayloadAction<ISetSoughtPositionDetails>) => {
                return {
                    ...state,
                    soughtPositionDetails: action.payload.soughtPositionDetails,
                };
            },
            prepare: (soughtPositionDetails: IModelPosition[] | null) => {
                return {
                    payload: {
                        soughtPositionDetails: soughtPositionDetails,
                    },
                };
            },
        },
        changeIsSoughtPositionDetailsPageInitialized: {
            reducer: (state: ISoughtPositionDetailsState, action: PayloadAction<ISoughtPositionDetailsIsInitialized>) => {
                return {
                    ...state,
                    isSoughtPositionDetailsPageInitialized: action.payload.isSoughtPositionDetailsPageInitialized,
                };
            },
            prepare: (isSoughtPositionDetailsPageInitialized: boolean) => {
                return {
                    payload: {
                        isSoughtPositionDetailsPageInitialized: isSoughtPositionDetailsPageInitialized,
                    },
                };
            },
        },
        changeIsSoughtPositionDetailsPageLoading: {
            reducer: (state: ISoughtPositionDetailsState, action: PayloadAction<ISoughtPositionDetailsIsLoading>) => {
                return {
                    ...state,
                    isSoughtPositionDetailsPageLoading: action.payload.isSoughtPositionDetailsPageLoading,
                };
            },
            prepare: (isSoughtPositionDetailsPageLoading: boolean) => {
                return {
                    payload: {
                        isSoughtPositionDetailsPageLoading: isSoughtPositionDetailsPageLoading,
                    },
                };
            },
        },
        fetchSoughtPositionDetails: {
            reducer: (state: ISoughtPositionDetailsState) => {
                return {
                    ...state,
                    isSoughtPositionDetailsPageLoading: true,
                };
            },
            prepare: (soughtPositionId: string) => {
                return {
                    payload: {soughtPositionId: soughtPositionId},
                };
            },
        },
        changeSoughtPositionDetailsPageError: {
            reducer: (state: ISoughtPositionDetailsState, action: PayloadAction<ISoughtPositionDetailsError>) => {
                return {
                    ...state,
                    soughtPositionDetailsPageError: action.payload.soughtPositionDetailsError,
                };
            },
            prepare: (soughtPositionDetailsError: string | null) => {
                return {
                    payload: {
                        soughtPositionDetailsError: soughtPositionDetailsError,
                    },
                };
            },
        },
        downloadOrganizationVerificationFile: {
            reducer: (state: ISoughtPositionDetailsState) => {
                return {
                    ...state,
                };
            },
            prepare: (fileId: string) => {
                return {
                    payload: {
                        fileId: fileId,
                    },
                };
            },
        },
        resetToInitialSoughtPositionDetailsState: () => {
            return {
                ...initialState,
            };
        },
    },
});
export const {
    setSoughtPositionDetails,
    changeIsSoughtPositionDetailsPageInitialized,
    changeIsSoughtPositionDetailsPageLoading,
    changeSoughtPositionDetailsPageError,
    fetchSoughtPositionDetails,
    downloadOrganizationVerificationFile,
    resetToInitialSoughtPositionDetailsState,
} = soughtPositionDetailsPageSlice.actions;

export default soughtPositionDetailsPageSlice.reducer;
