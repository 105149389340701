import {FormButtonType, FormControlType, IFormConfig, InputType, ValidationRules} from 'jobhunter-common-web';

export const addEmployeeFormConfig: typeof IFormConfig = (dismissModal: () => void, value?: any) => ({
    controlType: 'form',
    class: 'default-form',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'name',
            controlType: 'group',
            class: 'row',
            controls: {
                firstName: {
                    controlType: 'control',
                    defaultValue: value ? value.firstName : '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: 'humanResources.addEmployeeModal.form.placeholders.firstName',
                    isLabelHidden: false,
                    label: 'humanResources.addEmployeeModal.form.labels.firstName',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-6 form-control',
                },
                lastName: {
                    controlType: 'control',
                    defaultValue: value ? value.lastName : '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: 'humanResources.addEmployeeModal.form.placeholders.lastName',
                    isLabelHidden: false,
                    label: 'humanResources.addEmployeeModal.form.labels.lastName',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-6 form-control',
                },
            },
        },
        {
            key: 'email',
            controlType: 'group',
            class: 'row custom-row',
            controls: {
                email: {
                    controlType: 'control',
                    defaultValue: value ? value.email : '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}, {name: ValidationRules.IS_EMAIL, params: {}}],
                    placeholder: 'humanResources.addEmployeeModal.form.placeholders.email',
                    isLabelHidden: false,
                    label: 'humanResources.addEmployeeModal.form.labels.email',
                    type: InputType.EMAIL,
                    hostClass: 'col-xl-12 form-control',
                },
            },
        },
        {
            controlType: 'group',
            key: 'schedule_meeting_buttons',
            class: 'row justify-content-end',
            controls: {
                closeModalButton: {
                    buttonName: 'previous',
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.BUTTON,
                    inputStyles: 'btn-prev btn-outline-primary d-flex align-items-center',
                    hostClass: 'mb-0 w-auto',
                    defaultContainerStyles: '',
                    containerStyles: '',
                    defaultValue: null,
                    btnText: 'buttons.cancel',
                    buttonDisabled: false,
                    customClickHandler: () => {
                        return dismissModal();
                    },
                },
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    inputStyles: 'btn-primary',
                    defaultContainerStyles: '',
                    hostClass: 'mb-0 align-items-end w-auto',
                    containerStyles: '',
                    defaultValue: null,
                    isLabelHidden: true,
                    btnText: value ? 'buttons.save' : 'buttons.add',
                    buttonDisabled: (mappedOutputValue: any, isFormValid: boolean) => {
                        return !isFormValid;
                    },
                },
            },
        },
    ],
});
