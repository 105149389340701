import {IModelApiObject} from './base';

export enum CalendarEventType {
    ONLINE_CONSULTATION = 'online_consultation',
    TASK = 'task',
    RELOCATION = 'relocation',
}

export enum CalendarEventMetaType {
    TITLE = 'title',
    DESCRIPTION = 'description',
    ICON_TYPE = 'iconType',
}

export enum CalendarIconType {
    INFO = 'info',
    WARNING = 'warning',
    ALERT = 'alert',
}

export interface ICalendarPrice {
    readonly amount: string;
    readonly currency: string;
}

export interface ICalendar extends IModelApiObject {
    readonly account: string;
    readonly availableFrom: string | null;
    readonly availableUntil: string | null;
    readonly calendarFreeTerms: any[];
    readonly calendarRules: any[];
    readonly id: string;
    readonly name: string;
    readonly price: ICalendarPrice;
    readonly public: boolean;
    readonly slotLength: number;
    readonly timezone: string;
    readonly validUntil: string;
}

export interface ICalendarEventAccount extends IModelApiObject {
    readonly calendarEvents: string[];
    readonly calendars: ICalendar[];
    readonly createdAt: string;
    readonly id: string;
}

export interface IEventMetadataItem extends IModelApiObject {
    readonly key: string;
    readonly value: string;
}

export interface IEventMetadata extends IModelApiObject {
    readonly eventMetadata: IEventMetadataItem[];
}

export interface ICalendarEventName extends IModelApiObject {
    readonly email: string;
    readonly name: string;
}

export interface ICalendarEventNames extends IModelApiObject {
    readonly eventNames: ICalendarEventName[];
}

export interface ICalendarEventRelation extends IModelApiObject {
    readonly relations: any[];
}

export interface ICalendarEventTag extends IModelApiObject {
    readonly tagName: string;
}

export interface ICalendarEventTags extends IModelApiObject {
    readonly tags: ICalendarEventTag[];
}

export interface ICalendarEvent {
    readonly accounts: ICalendarEventAccount[];
    readonly calendars: any[];
    readonly endsAt: string;
    readonly eventMeta: IEventMetadata;
    readonly id: string;
    readonly name: ICalendarEventNames;
    readonly relatesTo: ICalendarEventRelation;
    readonly startsAt: string;
    readonly tags: ICalendarEventTags;
    readonly type: CalendarEventType;
    readonly validFrom: string | null;
    readonly validUntil: string | null;
    readonly description?: string | null;
}
