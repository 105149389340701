import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelRecruitment} from '../../model/recruitmentDataModel';
import {ICalendarEvent} from '../../model/calendarEventModel';
import {IApplicationOffer} from '../../model/offerDataModel';

export interface ISetCandidatesList {
    candidatesList: IApplicationOffer[] | null;
}

export interface IChangeIsCandidatesListLoading {
    isCandidatesListLoading: boolean;
}

export interface IChangeIsCandidatesListInitialized {
    isCandidatesListInitialized: boolean;
}

export interface IChangeCandidatesListError {
    candidatesPagePageError: string | null;
}

export interface ICandidateAction {
    applicationId: string;
}

export interface IChangeIsCandidateRejected {
    isCandidateRejected: boolean;
}

export interface IChangeIsCandidateHired {
    isCandidateHired: boolean;
}

export interface IChangeIsRecruitmentLoading {
    isRecruitmentListLoading: boolean;
}

export interface IChangeIsRecruitmentInitialized {
    isRecruitmentListInitialized: boolean;
}

export interface IRecruitmentPageError {
    recruitmentListError: string | null;
}

export interface ISetRecruitmentList {
    recruitmentList: IModelRecruitment[] | null;
}

export interface IChangeIsCalendarLoading {
    isCalendarLoading: boolean;
}

export interface ISetCalendarDetails {
    calendarDetails: ICalendarEvent[] | null;
}

export interface IHumanResourcesPageState {
    recruitmentList: IModelRecruitment[] | null;
    isRecruitmentListLoading: boolean;
    isRecruitmentListInitialized: boolean;
    recruitmentListError: string | null;
    candidatesList: IApplicationOffer[] | null;
    isCandidatesListLoading: boolean;
    isCandidatesListInitialized: boolean;
    candidatesListError: string | null;
    isCandidateRejected: boolean;
    isCandidateHired: boolean;
    calendarDetails: ICalendarEvent[] | null;
    isCalendarLoading: boolean;
}

const initialState: IHumanResourcesPageState = {
    recruitmentList: null,
    isRecruitmentListInitialized: false,
    isRecruitmentListLoading: false,
    recruitmentListError: null,
    candidatesList: null,
    isCandidatesListInitialized: false,
    isCandidatesListLoading: false,
    candidatesListError: null,
    isCandidateRejected: false,
    isCandidateHired: false,
    calendarDetails: null,
    isCalendarLoading: false,
};

const humanResourcesPageSlice = createSlice({
    name: 'humanResourcesPage',
    initialState: initialState,
    reducers: {
        setCalendarDetails: {
            reducer: (state: IHumanResourcesPageState, action: PayloadAction<ISetCalendarDetails>) => {
                return {
                    ...state,
                    calendarDetails: action.payload.calendarDetails,
                };
            },
            prepare(calendarDetails: ICalendarEvent[]) {
                return {
                    payload: {
                        calendarDetails: calendarDetails,
                    },
                };
            },
        },
        changeCalendarLoading: {
            reducer: (state: IHumanResourcesPageState, action: PayloadAction<IChangeIsCalendarLoading>) => {
                return {
                    ...state,
                    isCalendarLoading: action.payload.isCalendarLoading,
                };
            },
            prepare: (isCalendarLoading: boolean) => {
                return {
                    payload: {
                        isCalendarLoading: isCalendarLoading,
                    },
                };
            },
        },
        fetchCalendarDetails: (state: IHumanResourcesPageState) => {
            return {
                ...state,
                isCalendarLoading: true,
            };
        },
        hireCandidate: {
            reducer: (state: IHumanResourcesPageState, action: PayloadAction<ICandidateAction>) => {
                return {
                    ...state,
                    applicationId: action.payload.applicationId,
                };
            },
            prepare: (applicationId: string) => {
                return {
                    payload: {
                        applicationId: applicationId,
                    },
                };
            },
        },
        changeCandidateHired: {
            reducer: (state: IHumanResourcesPageState, action: PayloadAction<IChangeIsCandidateHired>) => {
                return {
                    ...state,
                    isCandidateHired: action.payload.isCandidateHired,
                };
            },
            prepare: (isCandidateHired: boolean) => {
                return {
                    payload: {
                        isCandidateHired: isCandidateHired,
                    },
                };
            },
        },
        changeCandidateRejected: {
            reducer: (state: IHumanResourcesPageState, action: PayloadAction<IChangeIsCandidateRejected>) => {
                return {
                    ...state,
                    isCandidateRejected: action.payload.isCandidateRejected,
                };
            },
            prepare: (isCandidateRejected: boolean) => {
                return {
                    payload: {
                        isCandidateRejected: isCandidateRejected,
                    },
                };
            },
        },
        rejectCandidate: {
            reducer: (state: IHumanResourcesPageState, action: PayloadAction<ICandidateAction>) => {
                return {
                    ...state,
                    applicationId: action.payload.applicationId,
                };
            },
            prepare: (applicationId: string) => {
                return {
                    payload: {
                        applicationId: applicationId,
                    },
                };
            },
        },
        fetchCandidatesList: (state: IHumanResourcesPageState) => {
            return {
                ...state,
                isCandidatesListLoading: true,
            };
        },
        setCandidatesList: {
            reducer: (state: IHumanResourcesPageState, action: PayloadAction<ISetCandidatesList>) => {
                return {
                    ...state,
                    candidatesList: action.payload.candidatesList,
                };
            },
            prepare: (candidateList: IApplicationOffer[] | null) => {
                return {
                    payload: {
                        candidatesList: candidateList,
                    },
                };
            },
        },
        changeIsCandidatesListLoading: {
            reducer: (state: IHumanResourcesPageState, action: PayloadAction<IChangeIsCandidatesListLoading>) => {
                return {
                    ...state,
                    isCandidatesListLoading: action.payload.isCandidatesListLoading,
                };
            },
            prepare: (isCandidatesListLoading: boolean) => {
                return {
                    payload: {
                        isCandidatesListLoading: isCandidatesListLoading,
                    },
                };
            },
        },
        changeIsCandidatesListInitialized: {
            reducer: (state: IHumanResourcesPageState, action: PayloadAction<IChangeIsCandidatesListInitialized>) => {
                return {
                    ...state,
                    isCandidatesListInitialized: action.payload.isCandidatesListInitialized,
                };
            },
            prepare: (isCandidatesListInitialized: boolean) => {
                return {
                    payload: {
                        isCandidatesListInitialized: isCandidatesListInitialized,
                    },
                };
            },
        },
        changeCandidateListError: {
            reducer: (state: IHumanResourcesPageState, action: PayloadAction<IChangeCandidatesListError>) => {
                return {
                    ...state,
                    candidatesPagePageError: action.payload.candidatesPagePageError,
                };
            },
            prepare: (candidatesPagePageError: string | null) => {
                return {
                    payload: {
                        candidatesPagePageError: candidatesPagePageError,
                    },
                };
            },
        },
        setRecruitmentList: {
            reducer: (state: IHumanResourcesPageState, action: PayloadAction<ISetRecruitmentList>) => {
                return {
                    ...state,
                    recruitmentList: action.payload.recruitmentList,
                };
            },
            prepare: (recruitmentList: IModelRecruitment[] | null) => {
                return {
                    payload: {
                        recruitmentList: recruitmentList,
                    },
                };
            },
        },
        fetchRecruitmentList: (state: IHumanResourcesPageState) => {
            return {
                ...state,
                isRecruitmentListLoading: true,
            };
        },
        changeIsRecruitmentLoading: {
            reducer: (state: IHumanResourcesPageState, action: PayloadAction<IChangeIsRecruitmentLoading>) => {
                return {
                    ...state,
                    isRecruitmentListLoading: action.payload.isRecruitmentListLoading,
                };
            },
            prepare: (isRecruitmentListLoading: boolean) => {
                return {
                    payload: {
                        isRecruitmentListLoading: isRecruitmentListLoading,
                    },
                };
            },
        },
        changeIsRecruitmentInitialized: {
            reducer: (state: IHumanResourcesPageState, action: PayloadAction<IChangeIsRecruitmentInitialized>) => {
                return {
                    ...state,
                    isRecruitmentListInitialized: action.payload.isRecruitmentListInitialized,
                };
            },
            prepare: (isRecruitmentListInitialized: boolean) => {
                return {
                    payload: {
                        isRecruitmentListInitialized: isRecruitmentListInitialized,
                    },
                };
            },
        },
        changeRecruitmentPageError: {
            reducer: (state: IHumanResourcesPageState, action: PayloadAction<IRecruitmentPageError>) => {
                return {
                    ...state,
                    recruitmentListErrors: action.payload.recruitmentListError,
                };
            },
            prepare: (recruitmentListError: string | null) => {
                return {
                    payload: {
                        recruitmentListError: recruitmentListError,
                    },
                };
            },
        },
        resetToInitialHumanResourcesState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setCandidatesList,
    setRecruitmentList,
    changeCalendarLoading,
    fetchCalendarDetails,
    changeCandidateHired,
    changeCandidateListError,
    changeCandidateRejected,
    changeIsCandidatesListInitialized,
    changeIsCandidatesListLoading,
    changeIsRecruitmentInitialized,
    changeIsRecruitmentLoading,
    changeRecruitmentPageError,
    fetchCandidatesList,
    fetchRecruitmentList,
    setCalendarDetails,
    hireCandidate,
    resetToInitialHumanResourcesState,
    rejectCandidate,
} = humanResourcesPageSlice.actions;

export default humanResourcesPageSlice.reducer;
