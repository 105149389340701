import {Observable} from 'rxjs';
import {jobHunterAPI, RestQueryParams} from 'jobhunter-common-web';

export interface IScheduleConsultationPayload {
    meetingDate: string;
    endsAt: string;
    offerApplicationId: string;
    recruiter: {
        id: string;
        type: string;
    };
}

export function scheduleApplicationMeetingAPI(authToken: string | null, payload: IScheduleConsultationPayload): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return jobHunterAPI.post(`api/online_consultations/schedule_application_meeting`, payload, new RestQueryParams(), headers);
}
