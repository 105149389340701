import {
    deepCloneObject,
    isNullOrUndefined,
    Translation,
    Tooltip,
    isNotNullOrUndefined,
    WithNavigate,
    withNavigation,
} from 'jobhunter-common-web';
import React from 'react';
import OfferItemRow from './OfferItemRow';
import {Badge, Button} from 'reactstrap';
import {ChevronDown, ChevronUp, Eye} from 'react-feather';
import {fixInjectedProperties} from '../../../../ioc';
import {WithTranslation, withTranslation} from 'react-i18next';
import {IModelRecruitment} from '../../../../model/recruitmentDataModel';
import styles from './styles.module.scss';
import {IOffer, OfferStatus} from '../../../../model/offerDataModel';

declare type WithNavigationProps = typeof WithNavigate;

interface IRecruitmentTableRowExternalProps {
    readonly recruitmentItem: IModelRecruitment | null;
}

interface IRecruitmentTableRowProps extends IRecruitmentTableRowExternalProps, WithTranslation, WithNavigationProps {}

interface IRecruitmentTableRowState {
    shownRecruitmentOffersArray: string[];
}

class RecruitmentTableRow extends React.Component<IRecruitmentTableRowProps, IRecruitmentTableRowState> {
    constructor(props: IRecruitmentTableRowProps) {
        super(props);

        this.state = {
            shownRecruitmentOffersArray: [],
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        if (this.props.recruitmentItem && this.props.recruitmentItem.offers && this.props.recruitmentItem.offers.length === 1) {
            this.setState({shownRecruitmentOffersArray: [this.props.recruitmentItem.id]});
        }
    }

    render() {
        const item = this.props.recruitmentItem,
            {t} = this.props;

        if (item === null) {
            return (
                <>
                    <p>
                        <Translation text={'offers.offerView.noData'} />
                    </p>
                </>
            );
        }
        return (
            <React.Fragment key={item.id}>
                <tr key={`positions_${item.id}`}>
                    <td className={styles.itemName}>{item.name}</td>
                    <td>{item.applicationsCount}</td>
                    <td>
                        <Badge
                            color={
                                item.status === OfferStatus.ACTIVE
                                    ? 'light-success'
                                    : item.status === OfferStatus.PENDING
                                    ? 'light-warning'
                                    : 'light-secondary'
                            }
                            pill>
                            <span className="align-middle">{item.status}</span>
                        </Badge>
                    </td>
                    <td>{isNotNullOrUndefined(item.offers) ? item.offers.length : 0}</td>
                    <td>
                        <Button color="flat-primary" className="view-recruitment-button" onClick={() => this.viewItem(item)}>
                            <Eye id={`view_${item.id}`} size={20} />
                            <Tooltip target={`view_${item.id}`} tooltipText={t('humanResources.recruitment.tooltips.viewPosition')} />
                        </Button>

                        {this.renderExpandButton(item)}
                    </td>
                </tr>
                {this.renderServiceRows(item, item.offers, item.id)}
            </React.Fragment>
        );
    }

    private renderServiceRows(item: IModelRecruitment, offerList: IOffer[], itemId: string) {
        if (!offerList || offerList.length === 0 || !this.isOfferInformationExpanded(itemId)) {
            return null;
        }

        return offerList.map((offer: any) => <OfferItemRow key={offer.id} offer={offer} item={item} />);
    }

    private renderExpandButton(item: IModelRecruitment) {
        if (isNullOrUndefined(item.offers) || item.offers.length < 1) {
            return null;
        }
        const {t} = this.props,
            translationKey = this.isOfferInformationExpanded(item.id)
                ? 'humanResources.recruitment.tooltips.hideOffers'
                : 'humanResources.recruitment.tooltips.showOffers';
        return (
            <Button color="flat-primary" className="expand-button" onClick={() => this.toggleRecruitmentOffersShown(item.id)}>
                <span id={`expand_${item.id}`}>{this.isOfferInformationExpanded(item.id) ? <ChevronUp /> : <ChevronDown />}</span>
                <Tooltip target={`expand_${item.id}`} tooltipText={t(translationKey)} />
            </Button>
        );
    }

    private toggleRecruitmentOffersShown(id: string) {
        const serviceToToggleIndex = this.state.shownRecruitmentOffersArray.findIndex((openedItemId: string) => id === openedItemId),
            updatedShownServicesArray = deepCloneObject(this.state.shownRecruitmentOffersArray);
        serviceToToggleIndex === -1 ? updatedShownServicesArray.push(id) : updatedShownServicesArray.splice(serviceToToggleIndex, 1);

        this.setState({shownRecruitmentOffersArray: updatedShownServicesArray});
    }

    private isOfferInformationExpanded = (offerId: string) => {
        return this.state.shownRecruitmentOffersArray.some((id: string) => id === offerId);
    };

    private viewItem = (item: IModelRecruitment) => {
        this.props.navigate(`/panel/human_resources/recruitment/${item.id}`);
    };
}

export default withTranslation()(withNavigation(RecruitmentTableRow));
