import {combineEpics} from 'redux-observable';
import {
    accountEpic,
    changePasswordEpic,
    chatContactsEpic,
    dictionaryDataEpic,
    loginEpic,
    reauthenticateEpic,
    renewAuthTokenEpic,
    authEpic,
} from 'jobhunter-common-web';
import positionsEpic from './positionsPageEpic';
import employerPageEpic from './employerPageEpic';
import employeesPageEpic from './employeesPageEpic';
import dashboardEpic from './dashboardPageEpic';
import candidateViewEpic from './candidateViewPageEpic';
import offerDetailsEpic from './offerViewPageEpic';
import positionDetailsEpic from './positionDetailsPageEpic';
import billingInfoEpic from './settingsPageEpic';
import hrPageEpic from './hrPageEpic';
import candidatesEpic from './candidatesPageEpic';
import offersPageEpic from './offersPageEpic';
import myServicesEpic from './myServicesEpic';
import billingEpic from './billingPageEpic';
import recruitmentEpic from './recruitmentPageEpic';
import purchasedServicesEpic from './purchasedServicesEpic';
import humanResourcesPageEpic from './humanResourcesPageEpic';
import soughtPositionDetailsEpic from './soughtPositionDetailsPageEpic';
import relocationsPageEpic from './relocationsPageEpic';
import relocationDetailsPageEpic from './relocationDetailsPageEpic';
import preferredCandidatesEpic from './preferredCandidatesPageEpic';

export const rootEpic = combineEpics(
    loginEpic,
    renewAuthTokenEpic,
    reauthenticateEpic,
    changePasswordEpic,
    authEpic,
    accountEpic,
    chatContactsEpic,
    dictionaryDataEpic,
    employerPageEpic,
    billingInfoEpic,
    employeesPageEpic,
    positionDetailsEpic,
    dashboardEpic,
    candidateViewEpic,
    positionsEpic,
    offerDetailsEpic,
    hrPageEpic,
    candidatesEpic,
    offersPageEpic,
    recruitmentEpic,
    billingEpic,
    humanResourcesPageEpic,
    myServicesEpic,
    purchasedServicesEpic,
    soughtPositionDetailsEpic,
    relocationsPageEpic,
    relocationDetailsPageEpic,
    preferredCandidatesEpic
);
