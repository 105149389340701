import {FormButtonType, FormControlType, IFormConfig, InputType, ValidationRules} from 'jobhunter-common-web';

export const sendInvitationFormConfig: typeof IFormConfig = (dismissModal: () => void) => ({
    controlType: 'form',
    class: 'default-form',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'name',
            controlType: 'group',
            class: 'row custom-row',
            controls: {
                email: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.IS_EMAIL, params: {}},
                    ],
                    placeholder: 'humanResources.offers.recommendOffer.form.placeholders.email',
                    isLabelHidden: false,
                    label: 'humanResources.offers.recommendOffer.form.labels.email',
                    type: InputType.EMAIL,
                    hostClass: 'col-xl-12 form-control',
                },
            },
        },
        {
            controlType: 'group',
            key: 'recommend_offer_buttons',
            class: 'row justify-content-end',
            controls: {
                closeModalButton: {
                    buttonName: 'cancel',
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.BUTTON,
                    inputStyles: 'btn-prev btn-outline-primary d-flex align-items-center',
                    hostClass: 'mb-0 w-auto',
                    defaultContainerStyles: '',
                    containerStyles: '',
                    defaultValue: null,
                    btnText: 'buttons.cancel',
                    buttonDisabled: false,
                    customClickHandler: () => {
                        return dismissModal();
                    },
                },
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    inputStyles: 'btn-primary',
                    defaultContainerStyles: '',
                    hostClass: 'mb-0 align-items-end w-auto',
                    containerStyles: '',
                    defaultValue: null,
                    isLabelHidden: true,
                    btnText: 'buttons.sendInvitation',
                    buttonDisabled: (mappedOutputValue: any, isFormValid: boolean) => {
                        return !isFormValid;
                    },
                },
            },
        },
    ],
});
