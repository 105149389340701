import {
    citiesSelector,
    companyTypesSelector,
    convertToMultiselectLabels,
    Form,
    FormControlChangeType,
    IFormConfig,
    IModelCity,
    IModelDictionaryDatum,
    industriesSelector,
    Loader,
    LoaderType,
    organizationSizesSelector,
    sortMultiselectLabels,
    Translation,
} from 'jobhunter-common-web';
import {Component} from 'react';
import {ArrowLeft, Save} from 'react-feather';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {BehaviorSubject, Subscription} from 'rxjs';
import {debounceTime, filter, tap} from 'rxjs/operators';
import {RootState} from '../../../../store/reducers';
import {changeCompanyDetails, ICompanyDetails, submitCompanyDetails} from '../../../../store/reducers/employerPageSlice';
import {companyDetailsSelector, employerPageLoadingSelector} from '../../../../store/selectors/employerPageSelectors';
import {companyDetailsFormConfig} from './companyDetailsFormConfig';

interface IConnectedCompanyDetailsProps {
    readonly companyDetails: ICompanyDetails | null;
    readonly cities: typeof IModelCity[] | null;
    readonly companyTypes: typeof IModelDictionaryDatum[] | null;
    readonly industries: typeof IModelDictionaryDatum[] | null;
    readonly organizationSizes: typeof IModelDictionaryDatum[] | null;
    readonly isLoading: boolean;
    readonly changeCompanyDetails: typeof changeCompanyDetails;
    readonly submitCompanyDetails: typeof submitCompanyDetails;
}

interface IExternalCompanyDetailsProps {
    readonly stepper: any;
    readonly isAdminAccount: boolean;
}

interface ICompanyDetailsProps extends IConnectedCompanyDetailsProps, IExternalCompanyDetailsProps, WithTranslation {}

interface ICompanyDetailsState {
    value: any;
    formConfig: typeof IFormConfig | null;
}

class CompanyDetails extends Component<ICompanyDetailsProps, ICompanyDetailsState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: ICompanyDetailsProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: null,
        };
    }

    componentDidMount(): void {
        this.setFormValuesFromState();
        this.setFormConfig();
        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    debounceTime(500),
                    tap((data: any) => this.changeStateCompanyDetails(data.value))
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: Readonly<ICompanyDetailsProps>) {
        if (this.props.companyDetails && this.props.companyDetails !== prevProps.companyDetails) {
            this.setFormValuesFromState();
        }

        if (
            this.props.cities !== prevProps.cities ||
            this.props.companyTypes !== prevProps.companyTypes ||
            this.props.organizationSizes !== prevProps.organizationSizes ||
            this.props.industries !== prevProps.industries
        ) {
            this.setFormConfig();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <section className="wizard-content">
                <div className="personal-information-section">
                    <div className="header">
                        <div>
                            <h2 className="title">
                                <Translation text="employer.companyDetails.title" />
                            </h2>
                            <p className="sub-title">
                                <Translation
                                    text={`employer.companyDetails.${this.props.isAdminAccount ? 'description' : 'nonAdminDescription'}`}
                                />
                            </p>
                        </div>
                    </div>
                </div>

                <div>
                    {this.state.formConfig && (
                        <Form
                            config={this.state.formConfig}
                            onValueStateChange={this.onValueStateChange}
                            value={this.state.value}
                            controlName={'changeCompanyDetailsForm'}
                            onButtonClicked={this.goNext}
                        />
                    )}
                </div>
                <Loader type={LoaderType.Local} showLoader={this.props.isLoading} />
            </section>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private changeStateCompanyDetails = (data: ICompanyDetails) => {
        this.props.changeCompanyDetails(data);
        this.setState({value: data});
    };
    private goNext = (name: string) => {
        if (name === 'submit') {
            return this.submitCompanyDetails(this.state.value);
        }
        return this.props.stepper.previous();
    };
    private setFormConfig = () => {
        const {t} = this.props;
        const cities = this.props.cities ? sortMultiselectLabels(convertToMultiselectLabels(this.props.cities, t)) : [],
            companyTypes = this.props.companyTypes ? sortMultiselectLabels(convertToMultiselectLabels(this.props.companyTypes, t)) : [],
            industries = this.props.industries ? sortMultiselectLabels(convertToMultiselectLabels(this.props.industries, t)) : [],
            organizationSizes = this.props.organizationSizes
                ? sortMultiselectLabels(convertToMultiselectLabels(this.props.organizationSizes, t))
                : [],
            prevButtonIcon = <ArrowLeft size={14} className="align-middle me-sm-25 me-0" />,
            nextButtonIcon = <Save size={14} className="align-middle me-sm-25 me-0" />,
            isAdminAccount = this.props.isAdminAccount;

        this.setState({
            formConfig: companyDetailsFormConfig(
                cities,
                companyTypes,
                industries,
                organizationSizes,
                prevButtonIcon,
                nextButtonIcon,
                isAdminAccount
            ),
        });
    };

    private setFormValuesFromState = () => {
        const companyDetails = this.props.companyDetails,
            values = {
                cities: companyDetails?.cities ? companyDetails.cities.map((type) => type.id) : [],
                companyTypes: companyDetails?.companyTypes ? companyDetails.companyTypes.map((type) => type.id) : [],
                industries: companyDetails?.industries ? companyDetails.industries.map((industry) => industry.id) : [],
                organizationSize: companyDetails?.organizationSize ? companyDetails.organizationSize?.id : [],
            };

        this.setState({value: values});
    };

    private submitCompanyDetails = (data: any) => {
        this.props.submitCompanyDetails(data);
    };
}

export default connect(
    (state: RootState) => ({
        companyDetails: companyDetailsSelector(state),
        cities: citiesSelector(state),
        companyTypes: companyTypesSelector(state),
        industries: industriesSelector(state),
        organizationSizes: organizationSizesSelector(state),
        isLoading: employerPageLoadingSelector(state),
    }),
    {
        changeCompanyDetails,
        submitCompanyDetails,
    }
)(withTranslation()(CompanyDetails));
