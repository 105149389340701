import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {Loader, LoaderType, Translation} from 'jobhunter-common-web';
import {connect} from 'react-redux';
import {RootState} from '../../../../../store/reducers';
import {addHRToPosition, changeIsHRAdded} from '../../../../../store/reducers/positionDetailsPageSlice';
import {isHRAddedSelector, positionDetailsErrorSelector} from '../../../../../store/selectors/positionDetailsSelectors';

interface IConnectedConfirmInvitationProps {
    readonly addHRToPosition: typeof addHRToPosition;
    readonly isHRAdded: boolean;
    readonly error: string | null;
    readonly changeIsHRAdded: typeof changeIsHRAdded;
}

interface IConfirmInvitationProps extends IConnectedConfirmInvitationProps {
    isModalOpen: boolean;
    toggleModal: () => void;
    positionId: string | null | undefined;
    hrDepartmentId: string | null;
}

interface IConfirmInvitationState {
    isLoading: boolean;
}

class ConfirmInvitation extends Component<IConfirmInvitationProps, IConfirmInvitationState> {
    constructor(props: IConfirmInvitationProps) {
        super(props);

        this.state = {
            isLoading: false,
        };
    }

    componentDidUpdate(prevProps: Readonly<IConfirmInvitationProps>): void {
        if (this.props.isHRAdded !== prevProps.isHRAdded) {
            this.props.toggleModal();
        }

        if (this.props.error !== prevProps.error && this.props.error) {
            this.setState({isLoading: false});
        }
    }
    componentWillUnmount() {
        this.props.changeIsHRAdded(false);
    }

    render() {
        return (
            <Modal isOpen={this.props.isModalOpen} toggle={() => this.props.toggleModal()}>
                <ModalHeader toggle={() => this.props.toggleModal()}>
                    <Translation text="employer.positions.positionDetails.confirmInvitation.title" />
                </ModalHeader>
                <ModalBody>
                    <p className="modal-description fw-bold">
                        <Translation text="employer.positions.positionDetails.confirmInvitation.confirmInvitationPrompt" />
                    </p>

                    <p className="modal-description">
                        <Translation text="employer.positions.positionDetails.confirmInvitation.confirmation" />
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" className="close-modal-button" outline onClick={() => this.props.toggleModal()}>
                        <Translation text="buttons.cancel" />
                    </Button>

                    <Button color="primary" className="send-invitation-button" onClick={() => this.confirmInvitation()}>
                        <Translation text="buttons.confirm" />
                    </Button>
                </ModalFooter>
                <Loader type={LoaderType.Local} showLoader={this.state.isLoading} />
            </Modal>
        );
    }

    private confirmInvitation = () => {
        if (!this.props.positionId || !this.props.hrDepartmentId) {
            return;
        }

        this.setState({isLoading: true});
        this.props.addHRToPosition(this.props.positionId, this.props.hrDepartmentId);
    };
}

export default connect(
    (state: RootState) => ({
        isHRAdded: isHRAddedSelector(state),
        error: positionDetailsErrorSelector(state),
    }),
    {
        addHRToPosition,
        changeIsHRAdded,
    }
)(ConfirmInvitation);
