import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IInvitationPayload, IModelHumanResourceInvitation} from '../../model/hrListDataModel';

export interface ICooperatingListLoading {
    isCooperatingListLoading: boolean;
}

export interface IInvitedListLoading {
    isInvitedListLoading: boolean;
}

export interface ICooperatingListInitialized {
    isCooperatingListInitialized: boolean;
}

export interface IInvitedListInitialized {
    isInvitedListInitialized: boolean;
}

export interface ICooperatingListError {
    cooperatingHRError: string | null;
}

export interface IInvitedListError {
    invitedHRError: string | null;
}
export interface IAddInvitation {
    invitation: IInvitationPayload;
}
export interface ISetCooperatingList {
    cooperatingHRList: IModelHumanResourceInvitation[];
}

export interface IAcceptInvitation {
    token: string;
}

export interface IChangeAcceptPageLoading {
    isAcceptPageLoading: boolean;
}
export interface ISetInvitedList {
    invitedHRList: IModelHumanResourceInvitation[];
}
export interface IHRAdded {
    isHRAdded: boolean;
}
export interface IHRState {
    cooperatingHRList: IModelHumanResourceInvitation[] | null;
    invitedHRList: IModelHumanResourceInvitation[] | null;
    isCooperatingListLoading: boolean;
    isInvitedListLoading: boolean;
    isCooperatingListInitialized: boolean;
    isInvitedListInitialized: boolean;
    cooperatingHRError: string | null;
    invitedHRError: string | null;
    isHRAdded: boolean;
    isAcceptPageLoading: boolean;
}

const initialState: IHRState = {
    cooperatingHRList: null,
    invitedHRList: null,
    isCooperatingListLoading: false,
    isInvitedListLoading: false,
    isCooperatingListInitialized: false,
    isInvitedListInitialized: false,
    cooperatingHRError: null,
    invitedHRError: null,
    isHRAdded: false,
    isAcceptPageLoading: false,
};

const hrPageSlice = createSlice({
    name: 'hrPage',
    initialState: initialState,
    reducers: {
        acceptInvitation: {
            reducer: (state: IHRState) => {
                return {
                    ...state,
                    isAcceptPageLoading: true,
                };
            },
            prepare: (token: string) => {
                return {
                    payload: {
                        token: token,
                    },
                };
            },
        },
        addHRInvitation: {
            reducer: (state: IHRState) => {
                return {
                    ...state,
                };
            },
            prepare: (invitation: IInvitationPayload) => {
                return {
                    payload: {
                        invitation: invitation,
                    },
                };
            },
        },
        changeIsHRAdded: {
            reducer: (state: IHRState, action: PayloadAction<IHRAdded>) => {
                return {
                    ...state,
                    isHRAdded: action.payload.isHRAdded,
                };
            },
            prepare: (isHRAdded: boolean) => {
                return {
                    payload: {
                        isHRAdded: isHRAdded,
                    },
                };
            },
        },
        setCooperatingList: {
            reducer: (state: IHRState, action: PayloadAction<ISetCooperatingList>) => {
                return {
                    ...state,
                    cooperatingHRList: action.payload.cooperatingHRList,
                };
            },
            prepare: (cooperatingHRList: IModelHumanResourceInvitation[]) => {
                return {
                    payload: {
                        cooperatingHRList: cooperatingHRList,
                    },
                };
            },
        },
        setInvitedList: {
            reducer: (state: IHRState, action: PayloadAction<ISetInvitedList>) => {
                return {
                    ...state,
                    invitedHRList: action.payload.invitedHRList,
                };
            },
            prepare: (invitedHRList: IModelHumanResourceInvitation[]) => {
                return {
                    payload: {
                        invitedHRList: invitedHRList,
                    },
                };
            },
        },
        changeCooperatingListError: {
            reducer: (state: IHRState, action: PayloadAction<ICooperatingListError>) => {
                return {
                    ...state,
                    cooperatingHRError: action.payload.cooperatingHRError,
                };
            },
            prepare: (cooperatingHRError: string | null) => {
                return {
                    payload: {
                        cooperatingHRError: cooperatingHRError,
                    },
                };
            },
        },
        changeInvitedListError: {
            reducer: (state: IHRState, action: PayloadAction<IInvitedListError>) => {
                return {
                    ...state,
                    cooperatingHRError: action.payload.invitedHRError,
                };
            },
            prepare: (invitedHRError: string | null) => {
                return {
                    payload: {
                        invitedHRError: invitedHRError,
                    },
                };
            },
        },
        changeIsInvitedListInitialized: {
            reducer: (state: IHRState, action: PayloadAction<IInvitedListInitialized>) => {
                return {
                    ...state,
                    isInvitedListInitialized: action.payload.isInvitedListInitialized,
                };
            },
            prepare: (isInvitedListInitialized: boolean) => {
                return {
                    payload: {
                        isInvitedListInitialized: isInvitedListInitialized,
                    },
                };
            },
        },
        changeIsCooperatingListInitialized: {
            reducer: (state: IHRState, action: PayloadAction<ICooperatingListInitialized>) => {
                return {
                    ...state,
                    isCooperatingListInitialized: action.payload.isCooperatingListInitialized,
                };
            },
            prepare: (isCooperatingListInitialized: boolean) => {
                return {
                    payload: {
                        isCooperatingListInitialized: isCooperatingListInitialized,
                    },
                };
            },
        },
        fetchCooperatingHRList: (state: IHRState) => {
            return {
                ...state,
                isCooperatingListLoading: true,
            };
        },
        fetchInvitedHRList: (state: IHRState) => {
            return {
                ...state,
                isInvitedListLoading: true,
            };
        },
        changeIsAcceptPageLoading: {
            reducer: (state: IHRState, action: PayloadAction<IChangeAcceptPageLoading>) => {
                return {
                    ...state,
                    isAcceptPageLoading: action.payload.isAcceptPageLoading,
                };
            },
            prepare: (isAcceptPageLoading: boolean) => {
                return {
                    payload: {
                        isAcceptPageLoading: isAcceptPageLoading,
                    },
                };
            },
        },
        changeIsCooperatingListLoading: {
            reducer: (state: IHRState, action: PayloadAction<ICooperatingListLoading>) => {
                return {
                    ...state,
                    isCooperatingListLoading: action.payload.isCooperatingListLoading,
                };
            },
            prepare: (isCooperatingListLoading: boolean) => {
                return {
                    payload: {
                        isCooperatingListLoading: isCooperatingListLoading,
                    },
                };
            },
        },
        changeIsInvitedListLoading: {
            reducer: (state: IHRState, action: PayloadAction<IInvitedListLoading>) => {
                return {
                    ...state,
                    isInvitedListLoading: action.payload.isInvitedListLoading,
                };
            },
            prepare: (isInvitedListLoading: boolean) => {
                return {
                    payload: {
                        isInvitedListLoading: isInvitedListLoading,
                    },
                };
            },
        },
        resetToInitialHRPageState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    changeCooperatingListError,
    changeIsHRAdded,
    changeInvitedListError,
    changeIsCooperatingListInitialized,
    changeIsCooperatingListLoading,
    changeIsInvitedListInitialized,
    changeIsInvitedListLoading,
    fetchCooperatingHRList,
    fetchInvitedHRList,
    resetToInitialHRPageState,
    setInvitedList,
    setCooperatingList,
    addHRInvitation,
    changeIsAcceptPageLoading,
    acceptInvitation,
} = hrPageSlice.actions;

export default hrPageSlice.reducer;
