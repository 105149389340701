import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelApiResponseViewObject} from '../../model/base';
import {isSameValue} from 'jobhunter-common-web';
import {IOfferItem} from '../../model/offerDataModel';

export interface IOfferFilters {
    'exists[soughtPosition]': string;
}

export interface IPaginationParams {
    page: number;
    itemsPerPage: number;
}

export interface IOffersPageState {
    offers: IOfferItem[] | null;
    offersMetadata: IModelApiResponseViewObject | null;
    pagination: IPaginationParams | null;
    offersFilters: IOfferFilters | null;
    isOffersPageLoading: boolean;
    isOffersPageInitialized: boolean;
    isOfferActionProcessing: boolean;
    isOfferActionComplete: boolean;
    offersError: string | null;
}

export interface ISetOffers {
    offers: IOfferItem[] | null;
}

export interface ISetMetadata {
    metadata: IModelApiResponseViewObject | null;
}

export interface IChangePagination {
    pagination: IPaginationParams;
}

export interface IChangeIsOffersPageLoading {
    isOffersPageLoading: boolean;
}

export interface IChangeIsOffersPageInitialized {
    isOffersPageInitialized: boolean;
}

export interface IChangeOffersError {
    error: string | null;
}

export interface IOfferAction {
    offerId: string;
    actionPayload?: any;
}

export interface IChangeIsOfferActionProcessing {
    isOfferActionProcessing: boolean;
}

export interface IChangeIsOfferActionComplete {
    isOfferActionComplete: boolean;
}

export interface IChangeOffersFilters {
    filters: IOfferFilters;
}

const initialState: IOffersPageState = {
    offers: null,
    offersMetadata: null,
    pagination: {
        itemsPerPage: 25,
        page: 1,
    },
    offersFilters: null,
    isOffersPageLoading: false,
    isOffersPageInitialized: false,
    isOfferActionProcessing: false,
    isOfferActionComplete: false,
    offersError: null,
};

const offersPageSlice = createSlice({
    name: 'offersPage',
    initialState: initialState,
    reducers: {
        setOffers: {
            reducer: (state: IOffersPageState, action: PayloadAction<ISetOffers>) => {
                return {
                    ...state,
                    offers: action.payload.offers,
                };
            },
            prepare(offers: IOfferItem[] | null) {
                return {
                    payload: {
                        offers: offers,
                    },
                };
            },
        },
        setOffersMetadata: {
            reducer: (state: IOffersPageState, action: PayloadAction<ISetMetadata>) => {
                return {
                    ...state,
                    offersMetadata: action.payload.metadata,
                };
            },
            prepare(metadata: IModelApiResponseViewObject | null) {
                return {
                    payload: {
                        metadata: metadata,
                    },
                };
            },
        },
        changeOffersPagination: {
            reducer: (state: IOffersPageState, action: PayloadAction<IChangePagination>) => {
                return {
                    ...state,
                    isOffersPageLoading: true,
                    pagination: action.payload.pagination,
                };
            },
            prepare(pagination: IPaginationParams) {
                return {
                    payload: {pagination: pagination},
                };
            },
        },
        changeIsOffersPageLoading: {
            reducer: (state: IOffersPageState, action: PayloadAction<IChangeIsOffersPageLoading>) => {
                return {
                    ...state,
                    isOffersPageLoading: action.payload.isOffersPageLoading,
                };
            },
            prepare(isOffersPageLoading: boolean) {
                return {
                    payload: {isOffersPageLoading: isOffersPageLoading},
                };
            },
        },
        changeIsOffersPageInitialized: {
            reducer: (state: IOffersPageState, action: PayloadAction<IChangeIsOffersPageInitialized>) => {
                return {
                    ...state,
                    isOffersPageInitialized: action.payload.isOffersPageInitialized,
                };
            },
            prepare(isOffersPageInitialized: boolean) {
                return {
                    payload: {isOffersPageInitialized: isOffersPageInitialized},
                };
            },
        },
        changeOffersError: {
            reducer: (state: IOffersPageState, action: PayloadAction<IChangeOffersError>) => {
                return {
                    ...state,
                    offersError: action.payload.error,
                };
            },
            prepare(error: any) {
                return {
                    payload: {error: error},
                };
            },
        },
        closeOffer: {
            reducer: (state: IOffersPageState) => {
                return {
                    ...state,
                    isOfferActionProcessing: true,
                };
            },
            prepare(offerId: string) {
                return {
                    payload: {
                        offerId: offerId,
                    },
                };
            },
        },
        recommendOffer: {
            reducer: (state: IOffersPageState) => {
                return {
                    ...state,
                    isOfferActionProcessing: true,
                };
            },
            prepare(offerId: string, actionPayload?: any) {
                return {
                    payload: {
                        offerId: offerId,
                        actionPayload: actionPayload,
                    },
                };
            },
        },
        cancelOffer: {
            reducer: (state: IOffersPageState) => {
                return {
                    ...state,
                    isOfferActionProcessing: true,
                };
            },
            prepare(offerId: string) {
                return {
                    payload: {
                        offerId: offerId,
                    },
                };
            },
        },
        deleteOffer: {
            reducer: (state: IOffersPageState) => {
                return {
                    ...state,
                    isOfferActionProcessing: true,
                };
            },
            prepare(offerId: string) {
                return {
                    payload: {
                        offerId: offerId,
                    },
                };
            },
        },
        changeIsOfferActionProcessing: {
            reducer: (state: IOffersPageState, action: PayloadAction<IChangeIsOfferActionProcessing>) => {
                return {
                    ...state,
                    isOfferActionProcessing: action.payload.isOfferActionProcessing,
                };
            },
            prepare(isOfferActionProcessing: boolean) {
                return {
                    payload: {
                        isOfferActionProcessing: isOfferActionProcessing,
                    },
                };
            },
        },
        changeIsOfferActionComplete: {
            reducer: (state: IOffersPageState, action: PayloadAction<IChangeIsOfferActionComplete>) => {
                return {
                    ...state,
                    isOfferActionComplete: action.payload.isOfferActionComplete,
                };
            },
            prepare(isOfferActionComplete: boolean) {
                return {
                    payload: {
                        isOfferActionComplete: isOfferActionComplete,
                    },
                };
            },
        },
        fetchOffers: (state: IOffersPageState) => {
            return {
                ...state,
                isOffersPageLoading: true,
            };
        },
        applyOffersFilters: (state: IOffersPageState) => {
            return {
                ...state,
                isOffersPageLoading: true,
            };
        },
        changeOffersFilters: {
            reducer: (state: IOffersPageState, action: PayloadAction<IChangeOffersFilters>) => {
                if (isSameValue(action.payload.filters, state.offersFilters)) {
                    return {
                        ...state,
                    };
                }

                return {
                    ...state,
                    isOfferListLoading: true,
                    offersFilters: action.payload.filters,
                };
            },
            prepare(filters: IOfferFilters) {
                return {
                    payload: {
                        filters: {
                            'exists[soughtPosition]': filters['exists[soughtPosition]'],
                        },
                    },
                };
            },
        },
        resetToInitialOffersPageState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setOffers,
    setOffersMetadata,
    changeOffersPagination,
    changeIsOffersPageLoading,
    changeIsOffersPageInitialized,
    changeOffersError,
    cancelOffer,
    closeOffer,
    deleteOffer,
    recommendOffer,
    changeIsOfferActionProcessing,
    changeIsOfferActionComplete,
    fetchOffers,
    changeOffersFilters,
    applyOffersFilters,
    resetToInitialOffersPageState,
} = offersPageSlice.actions;

export default offersPageSlice.reducer;
