import {
    collectionInputDataMapper,
    collectionOutputDataMapper,
    FormControlType,
    IFormConfig,
    IMultiselectOption,
    InputType,
    MultiSelectType,
    ValidationRules,
    FormButtonType,
} from 'jobhunter-common-web';

export const personalInformationFormConfig: typeof IFormConfig = (
    countryList: typeof IMultiselectOption[] | [],
    nextButtonIcon: any,
    isAdminAccount: boolean,
    value?: any
) => ({
    controlType: 'form',
    class: 'default-form employer-page-form',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'name',
            controlType: 'group',
            class: 'row',
            controls: {
                firstName: {
                    controlType: 'control',
                    defaultValue: value ? value.firstName : '',
                    disabled: !isAdminAccount,
                    formControlType: FormControlType.INPUT,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: 'employer.personalInformation.form.placeholders.firstName',
                    isLabelHidden: false,
                    label: 'employer.personalInformation.form.labels.firstName',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-6 form-control required',
                },
                lastName: {
                    controlType: 'control',
                    defaultValue: value ? value.lastName : '',
                    disabled: !isAdminAccount,
                    formControlType: FormControlType.INPUT,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: 'employer.personalInformation.form.placeholders.lastName',
                    isLabelHidden: false,
                    label: 'employer.personalInformation.form.labels.lastName',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-6 form-control required',
                },
            },
        },
        {
            key: 'birthdate',
            controlType: 'group',
            class: 'row',
            controls: {
                birthDate: {
                    controlType: 'control',
                    defaultValue: '',
                    additionalStyles: '',
                    formControlType: FormControlType.DATE,
                    validationRules: [
                        {
                            name: ValidationRules.IS_DATE_LTE_THAN,
                            params: {
                                valueAccessor: (data: any) => data,
                                compareAccessor: () => new Date(),
                            },
                        },
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'employer.personalInformation.form.labels.birthdate',
                    isLabelHidden: false,
                    label: 'employer.personalInformation.form.labels.birthdate',
                    maxDate: new Date(),
                    type: InputType.DATE,
                    dateFormat: 'dd/MM/yyyy',
                    minDate: new Date(1920, 1, 1),
                    showYearDropdown: true,
                    showMonthDropdown: true,
                    openToDate: new Date(1990, 1, 1),
                    hostClass: 'col-xl-6 form-control required',
                },
            },
        },
        {
            key: 'basic_information',
            controlType: 'group',
            class: 'row',
            controls: {
                phone: {
                    controlType: 'control',
                    defaultValue: value ? value.phone : '',
                    formControlType: FormControlType.INPUT,
                    disabled: !isAdminAccount,
                    validationRules: [],
                    placeholder: 'employer.personalInformation.form.placeholders.phoneNumber',
                    isLabelHidden: false,
                    label: 'employer.personalInformation.form.labels.phoneNumber',
                    type: InputType.TEL,
                    hostClass: 'col-xl-6 form-control required',
                },
                email: {
                    controlType: 'control',
                    disabled: true,
                    defaultValue: value ? value.email : '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [],
                    placeholder: 'employer.personalInformation.form.placeholders.email',
                    isLabelHidden: false,
                    label: 'employer.personalInformation.form.labels.email',
                    type: InputType.EMAIL,
                    hostClass: 'col-xl-6 form-control',
                },
            },
        },
        {
            key: 'address',
            controlType: 'group',
            class: 'row',
            controls: {
                city: {
                    controlType: 'control',
                    defaultValue: value ? value.city : '',
                    disabled: !isAdminAccount,
                    formControlType: FormControlType.INPUT,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: 'employer.personalInformation.form.placeholders.city',
                    isLabelHidden: false,
                    label: 'employer.personalInformation.form.labels.city',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-6 form-control required',
                },
                country: {
                    controlType: 'control',
                    defaultValue: '',
                    disabled: !isAdminAccount,
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    multiselectOptions: countryList ? countryList : [],
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: 'employer.personalInformation.form.placeholders.country',
                    label: 'employer.personalInformation.form.labels.country',
                    hostClass: 'col-xl-6 form-control required',
                    inputDataMapper: collectionInputDataMapper,
                    outputDataMapper: collectionOutputDataMapper,
                },
            },
        },
        {
            controlType: 'group',
            key: 'personal_information_buttons',
            class: 'row justify-content-between',
            controls: {
                submitButton: {
                    buttonName: 'submit',
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    inputStyles: 'btn-submit btn-primary d-flex align-items-center',
                    hostClass: 'mb-0 align-items-end',
                    defaultContainerStyles: '',
                    containerStyles: '',
                    buttonPreIcon: isAdminAccount ? nextButtonIcon : null,
                    buttonPostIcon: isAdminAccount ? null : nextButtonIcon,
                    defaultValue: null,
                    btnText: `buttons.${isAdminAccount ? 'save' : 'next'}`,
                    buttonDisabled: (mappedOutputValue: any, isFormValid: boolean) => {
                        return !isFormValid;
                    },
                },
            },
        },
    ],
});
