import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IHRState} from '../reducers/hrPageSlice';

export const selectHRPage = (state: RootState) => {
    return state.hrPage;
};

export const cooperatingHRListSelector = createSelector([selectHRPage], (state: IHRState) => state.cooperatingHRList);

export const invitedHRListSelector = createSelector([selectHRPage], (state: IHRState) => state.invitedHRList);

export const isCooperatingListLoadingSelector = createSelector([selectHRPage], (state: IHRState) => state.isCooperatingListLoading);

export const isInvitedListLoadingSelector = createSelector([selectHRPage], (state: IHRState) => state.isInvitedListLoading);

export const isCooperatingListInitializedSelector = createSelector([selectHRPage], (state: IHRState) => state.isCooperatingListInitialized);

export const isInvitedListInitializedSelector = createSelector([selectHRPage], (state: IHRState) => state.isInvitedListInitialized);

export const hrPageErrorSelector = createSelector([selectHRPage], (state: IHRState) => state.cooperatingHRError);

export const invitedHRErrorPageSelector = createSelector([selectHRPage], (state: IHRState) => state.invitedHRError);

export const isHRAddedSelector = createSelector([selectHRPage], (state: IHRState) => state.isHRAdded);

export const isAcceptPageLoadingSelector = createSelector([selectHRPage], (state: IHRState) => state.isAcceptPageLoading);
