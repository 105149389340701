import {
    collectionInputDataMapper,
    collectionOutputDataMapper,
    formatRangePickerLabel,
    FormControlType,
    IFormConfig,
    IMultiselectOption,
    IModelDictionaryDatum,
    InputType,
    multiCollectionInputDataMapper,
    multiCollectionOutputDataMapper,
    MultiSelectType,
    ValidationRules,
    FormButtonType,
} from 'jobhunter-common-web';

export const editOfferFormConfig: typeof IFormConfig = (
    seniorityLevels: any[],
    countries: typeof IMultiselectOption[] | [],
    companyTypes: typeof IMultiselectOption[] | [],
    industries: typeof IMultiselectOption[] | [],
    contractTypes: typeof IMultiselectOption[] | [],
    employmentTypes: typeof IMultiselectOption[] | [],
    workTypes: typeof IMultiselectOption[] | [],
    organizationSize: typeof IMultiselectOption[] | [],
    technologyTools?: typeof IModelDictionaryDatum[] | null,
    technologies?: typeof IModelDictionaryDatum[] | null,
    preferenceTags?: typeof IMultiselectOption[] | [],
    saveOfferAsDraft?: () => void
) => ({
    controlType: 'form',
    class: 'default-form',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'offer-details',
            controlType: 'group',
            class: 'row custom-row',
            controls: {
                name: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: 'offers.editOffer.form.placeholders.offer',
                    isLabelHidden: false,
                    label: 'offers.editOffer.form.labels.offer',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-4 form-control',
                },
                seniority: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.RANGE_PICKER,
                    validationRules: [],
                    rangeLabels: seniorityLevels,
                    label: 'offers.editOffer.form.labels.seniority',
                    labelFormat: (value: number) => formatRangePickerLabel(value, seniorityLevels),
                    maxRangeStep: Object.keys(seniorityLevels).length * 10,
                    hostClass: 'col-xl-4 form-control',
                    isRangeLabelTranslated: true,
                },
                minimumSalary: {
                    controlType: 'control',
                    formControlType: FormControlType.QUANTITY_INPUT,
                    hostClass: 'col-xl-2 form-control',
                    label: 'offers.editOffer.form.labels.minSalary',
                    minValue: 0,
                    stepValue: 100,
                    type: InputType.NUMBER,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                },
                maximumSalary: {
                    controlType: 'control',
                    formControlType: FormControlType.QUANTITY_INPUT,
                    hostClass: 'col-xl-2 form-control',
                    label: 'offers.editOffer.form.labels.maxSalary',
                    minValue: 0,
                    stepValue: 100,
                    type: InputType.NUMBER,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                },
            },
        },
        {
            key: 'description',
            controlType: 'group',
            class: 'row custom-row',
            controls: {
                description: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.RICHTEXT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                        // {name: ValidationRules.MAX_LENGTH, params: {length: 2000}}
                    ],
                    placeholder: 'offers.editOffer.form.placeholders.description',
                    label: 'offers.editOffer.form.labels.description',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12 form-control',
                },
            },
        },
        {
            key: 'technology',
            controlType: 'group',
            class: 'row custom-row',
            controls: {
                technology: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.TECHNOLOGY_CONTROL,
                    validationRules: [],
                    placeholder: '',
                    label: 'Technology stack',
                    hostClass: 'col-xl-12 form-control',
                    technologyTools: technologyTools,
                    technologies: technologies,
                    seniorityLevels: seniorityLevels,
                },
            },
        },
        {
            key: 'location',
            controlType: 'group',
            class: 'row',
            controls: {
                location: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    multiselectOptions: countries,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: 'offers.editOffer.form.placeholders.location',
                    label: 'offers.editOffer.form.labels.location',
                    hostClass: 'col-xl-12 form-control',
                    inputDataMapper: collectionInputDataMapper,
                    outputDataMapper: collectionOutputDataMapper,
                },
            },
        },
        {
            key: 'companyType',
            controlType: 'group',
            class: 'row',
            controls: {
                companyType: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.DEFAULT,
                    multiselectOptions: companyTypes,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: 'offers.editOffer.form.placeholders.companyType',
                    label: 'offers.editOffer.form.labels.companyType',
                    hostClass: 'col-xl-12 form-control',
                    inputDataMapper: multiCollectionInputDataMapper,
                    outputDataMapper: multiCollectionOutputDataMapper,
                },
            },
        },
        {
            key: 'industry',
            controlType: 'group',
            class: 'row',
            controls: {
                industry: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.DEFAULT,
                    multiselectOptions: industries,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: 'offers.editOffer.form.placeholders.industry',
                    label: 'offers.editOffer.form.labels.industry',
                    hostClass: 'col-xl-12 form-control',
                    inputDataMapper: multiCollectionInputDataMapper,
                    outputDataMapper: multiCollectionOutputDataMapper,
                },
            },
        },
        {
            key: 'contractType',
            controlType: 'group',
            class: 'row custom-row',
            controls: {
                contractType: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.DEFAULT,
                    multiselectOptions: contractTypes,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: 'offers.editOffer.form.placeholders.contractType',
                    label: 'offers.editOffer.form.labels.contractType',
                    hostClass: 'col-xl-12 form-control',
                    inputDataMapper: multiCollectionInputDataMapper,
                    outputDataMapper: multiCollectionOutputDataMapper,
                },
            },
        },
        {
            key: 'location_options',
            controlType: 'group',
            class: 'row',
            controls: {
                employmentType: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.DEFAULT,
                    multiselectOptions: employmentTypes,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: 'offers.editOffer.form.placeholders.employmentType',
                    label: 'offers.editOffer.form.labels.employmentType',
                    hostClass: 'col-xl-12 form-control',
                    inputDataMapper: multiCollectionInputDataMapper,
                    outputDataMapper: multiCollectionOutputDataMapper,
                },
                workType: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.DEFAULT,
                    multiselectOptions: workTypes,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: 'offers.editOffer.form.placeholders.workType',
                    label: 'offers.editOffer.form.labels.workType',
                    hostClass: 'col-xl-12 form-control',
                    inputDataMapper: multiCollectionInputDataMapper,
                    outputDataMapper: multiCollectionOutputDataMapper,
                },
                organizationSize: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    multiselectOptions: organizationSize,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: 'offers.editOffer.form.placeholders.organizationSize',
                    label: 'offers.editOffer.form.labels.organizationSize',
                    hostClass: 'col-xl-12 form-control',
                    inputDataMapper: collectionInputDataMapper,
                    outputDataMapper: collectionOutputDataMapper,
                },
            },
        },
        {
            key: 'work_options',
            controlType: 'group',
            class: 'row custom-row',
            controls: {
                onlyRelocation: {
                    controlType: 'control',
                    defaultValue: false,
                    formControlType: FormControlType.SWITCH,
                    validationRules: [],
                    placeholder: '',
                    label: 'offers.editOffer.form.labels.relocation',
                    hostClass: 'col-xl-4 form-control switch-inline-container',
                },
            },
        },
        {
            key: 'tag_preferences',
            controlType: 'group',
            class: 'row',
            controls: {
                preferenceTags: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.DEFAULT,
                    multiselectOptions: preferenceTags,
                    validationRules: [],
                    placeholder: 'offers.editOffer.form.placeholders.tagPreferences',
                    label: 'offers.editOffer.form.labels.tagPreferences',
                    hostClass: 'col-xl-12 form-control',
                    inputDataMapper: multiCollectionInputDataMapper,
                    outputDataMapper: multiCollectionOutputDataMapper,
                },
            },
        },
        {
            key: 'job_board_options',
            controlType: 'group',
            class: 'row',
            controls: {
                postJobFree: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.CHECKBOX,
                    validationRules: [],
                    label: 'offers.editOffer.form.labels.jobBoardOption',
                    checkboxLabel: 'offers.editOffer.form.labels.postJobFree',
                    hostClass: 'col-xl-12 form-control mb-0',
                },
                workUa: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.CHECKBOX,
                    validationRules: [],
                    isLabelHidden: true,
                    checkboxLabel: 'offers.editOffer.form.labels.workUa',
                    hostClass: 'col-xl-12 form-control mb-0',
                },
                careerAge: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.CHECKBOX,
                    validationRules: [],
                    isLabelHidden: true,
                    checkboxLabel: 'offers.editOffer.form.labels.careerAge',
                    hostClass: 'col-xl-12 form-control mb-0',
                },
            },
        },
        {
            controlType: 'group',
            key: 'save-offer',
            class: 'row justify-content-end',
            controls: {
                saveDraft: {
                    buttonName: 'previous',
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.BUTTON,
                    inputStyles: 'btn-outline-primary d-flex align-items-center',
                    hostClass: 'mb-0 w-auto',
                    defaultContainerStyles: '',
                    containerStyles: '',
                    defaultValue: null,
                    btnText: 'buttons.saveDraft',
                    buttonDisabled: (mappedOutputValue: any, isFormValid: boolean) => {
                        return !isFormValid;
                    },
                    customClickHandler: () => {
                        if (saveOfferAsDraft) {
                            return saveOfferAsDraft();
                        }
                    },
                },
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    inputStyles: 'btn btn-primary',
                    defaultContainerStyles: '',
                    hostClass: 'mb-0 w-auto',
                    containerStyles: 'd-flex align-self-end',
                    defaultValue: null,
                    isLabelHidden: true,
                    btnText: 'buttons.publishOffer',
                    buttonDisabled: (mappedOutputValue: any, isFormValid: boolean) => {
                        return !isFormValid;
                    },
                },
            },
        },
    ],
});
