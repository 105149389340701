import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ICandidateFullInfo} from 'jobhunter-common-web';

export interface ICandidateViewState {
    candidateDetails: typeof ICandidateFullInfo | null;
    isCandidateViewLoading: boolean;
    isCandidateViewInitialized: boolean;
    candidateViewError: string | null;
}

export interface ISetCandidateDetails {
    candidate: typeof ICandidateFullInfo | null;
}

export interface IChangeIsCandidateViewLoading {
    isCandidateViewLoading: boolean;
}

export interface IChangeIsCandidateViewInitialized {
    isCandidateViewInitialized: boolean;
}

export interface IChangeCandidateViewError {
    error: string | null;
}

export interface IFetchCandidateDetails {
    applicationId: string;
}

const initialState: ICandidateViewState = {
    candidateDetails: null,
    isCandidateViewLoading: false,
    isCandidateViewInitialized: false,
    candidateViewError: null,
};

const candidateViewPageSlice = createSlice({
    name: 'candidateViewPage',
    initialState: initialState,
    reducers: {
        setCandidateDetails: {
            reducer: (state: ICandidateViewState, action: PayloadAction<ISetCandidateDetails>) => {
                return {
                    ...state,
                    candidateDetails: action.payload.candidate,
                };
            },
            prepare(candidate: typeof ICandidateFullInfo | null) {
                return {
                    payload: {
                        candidate: candidate,
                    },
                };
            },
        },
        changeIsCandidateViewLoading: {
            reducer: (state: ICandidateViewState, action: PayloadAction<IChangeIsCandidateViewLoading>) => {
                return {
                    ...state,
                    isCandidateViewLoading: action.payload.isCandidateViewLoading,
                };
            },
            prepare(isCandidateViewLoading: boolean) {
                return {
                    payload: {isCandidateViewLoading: isCandidateViewLoading},
                };
            },
        },
        changeIsCandidateViewInitialized: {
            reducer: (state: ICandidateViewState, action: PayloadAction<IChangeIsCandidateViewInitialized>) => {
                return {
                    ...state,
                    isCandidateViewInitialized: action.payload.isCandidateViewInitialized,
                };
            },
            prepare(isCandidateViewInitialized: boolean) {
                return {
                    payload: {isCandidateViewInitialized: isCandidateViewInitialized},
                };
            },
        },
        changeCandidateViewError: {
            reducer: (state: ICandidateViewState, action: PayloadAction<IChangeCandidateViewError>) => {
                return {
                    ...state,
                    offersError: action.payload.error,
                };
            },
            prepare(error: any) {
                return {
                    payload: {error: error},
                };
            },
        },
        fetchCandidateDetails: {
            reducer: (state: ICandidateViewState) => {
                return {
                    ...state,
                    isCandidateViewLoading: true,
                };
            },
            prepare(applicationId: string) {
                return {
                    payload: {applicationId: applicationId},
                };
            },
        },
        resetToInitialCandidateViewPageState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setCandidateDetails,
    changeIsCandidateViewLoading,
    changeIsCandidateViewInitialized,
    changeCandidateViewError,
    fetchCandidateDetails,
    resetToInitialCandidateViewPageState,
} = candidateViewPageSlice.actions;

export default candidateViewPageSlice.reducer;
