const en = {
    dashboard: {
        positions: {
            positionsWithOffersTitle: 'Last Positions with Offers',
            positionsWithoutOffersTitle: 'Last Positions without Offers',
            table: {
                headers: {
                    project: 'Project',
                    city: 'City',
                    salary: 'Salary',
                    publication: 'Publication',
                },
                noData: 'There are no positions added.',
            },
        },
        offers: {
            title: 'Offers',
            table: {
                headers: {
                    position: 'Position',
                    candidates: 'Candidates',
                    status: 'Status',
                    actions: 'Actions',
                },
                noData: 'There are no offers added.',
            },
            tooltips: {
                viewCandidates: 'Candidate details',
                viewOffer: 'Offer details',
                share: 'Share',
            },
            offerCopied: 'Link to offer has beed copied',
        },
        offerApplications: {
            title: 'Offer Applications',
            table: {
                headers: {
                    candidate: 'Candidate',
                    interview: 'Interview',
                    actions: 'Actions',
                },
                noData: 'There are no applications.',
            },
            tooltips: {
                video: 'Redirect to video',
                addRecruiter: 'Add recruiter',
                candidateDetails: 'Candidate details',
                offerDetails: 'Offer details',
                rejectCandidate: 'Reject candidate',
            },
            addRecruiterModal: {
                title: 'Add recruiter',
                candidate: 'Candidate: {{candidateName}}',
                description:
                    'Select a date and time for the recruitment interview. Candidate and recruiter will receive an email notification',
                meetingScheduled: 'Interview has been successfully scheduled',
            },
            rejectApplicantModal: {
                title: 'Reject Applicant',
                description: 'Are you sure you want to reject an applicant for selected application?',
                candidateRejected: 'Candidate was rejected',
            },
        },
        profile: {
            title: 'Profile',
            personalInformation: {
                title: 'Personal',
                description: 'Lorem impusm sit dolor',
            },
            companyInformation: {
                title: 'Company information',
                description: 'Complete your Company information',
            },
            verification: {
                title: 'Verification',
                description: 'Add verification documents',
            },
            companyDetails: {
                title: 'Company details',
                description: 'Complete your Company details',
            },
            contactInformation: {
                title: 'Contact information',
                description: 'Complete your Contact details',
            },
            settings: {
                title: 'Settings',
                description: 'Lorem ipsum sit dolor',
            },
        },
        calendar: {
            title: 'Calendar',
            eventCalendar: {
                title: 'Events',
                noEvents: 'There are no events',
            },
        },
    },
    employer: {
        acceptInvitation: 'To accept invitation click button below:',
        invitationAccepted: 'Invitation has been accepted',
        personalInformation: {
            title: 'Personal information',
            description: 'Enter your information',
            nonAdminDescription: 'View your information',
            form: {
                placeholders: {
                    firstName: 'First Name',
                    lastName: 'Last Name',
                    birthdate: 'Birth date',
                    email: 'Email',
                    phoneNumber: 'Mobile number',
                    city: 'City',
                    country: 'Country',
                },
                labels: {
                    firstName: 'First Name',
                    lastName: 'Last Name',
                    birthdate: 'Birth date',
                    email: 'Email',
                    phoneNumber: 'Mobile number',
                    city: 'City',
                    country: 'Country',
                },
            },
        },
        company: {
            title: 'Company Information',
            description: 'Enter your company information',
            form: {
                placeholders: {
                    companyName: 'Company Name',
                    phoneNumber: 'Mobile number',
                    address: 'Address',
                    vatId: 'VAT ID',
                    city: 'City',
                    country: 'Country',
                    description: 'Write something about your company',
                },
                labels: {
                    companyName: 'Company Name',
                    phoneNumber: 'Mobile number',
                    address: 'Address',
                    vatId: 'VAT ID',
                    city: 'City',
                    country: 'Country',
                    description: 'About Company',
                },
            },
        },
        verification: {
            title: 'Verification',
            description: 'Add company registration documents',
            nonAdminDescription: 'View your company registration documents',
            uploadInformation: 'You must upload your company documents for verification',
            documentsLeft: 'You can upload {{documentsLeft}} more documents',
            noDocumentsLeft: 'You have reached limit of 10 documents',
            fileUploadAllowedFiles: 'File must be of .pdf type',
            files: 'Verification file list:',
            removeDocument: {
                title: 'Remove document',
                description: 'Confirm that you want to remove document:',
            },
            addDocument: {
                title: 'Add document',
                description: 'Upload an pdf document to verify your company',

                form: {
                    labels: {
                        documentType: 'Document Type',
                        addDocument: 'Upload file',
                    },
                    placeholders: {
                        documentName: 'Document Name',
                    },
                },
            },
        },
        companyDetails: {
            title: 'About your company',
            description: 'Enter your information',
            nonAdminDescription: 'View your information',
            form: {
                labels: {
                    location: 'Location',
                    companyType: 'Company type',
                    serviceType: 'Service type',
                    industry: 'Industry',
                    employers: 'Employers',
                    organisationSize: 'Organisation size',
                },
                placeholders: {
                    select: 'Select',
                },
            },
        },
        positions: {
            title: 'Positions',
            add: 'Add new position',
            table: {
                position: 'Position',
                description: 'Description',
                created: 'Created Date',
                status: 'Status',
                actions: 'Actions',
                offers: 'Offers',
                noPositions: 'There are no positions added',
            },
            hasOffers: 'Positions with offers added',
            addOffer: 'Add offer',
            tooltips: {
                createOffer: 'Create offer',
                details: 'Details',
                editPosition: 'Edit Position',
                closePosition: 'Close Position',
                openPosition: 'Open Position',
                deletePosition: 'Delete position',
            },
            positionDetails: {
                title: 'Position',
                seniority: 'Seniority:',
                location: 'Location:',
                salary: 'Salary:',
                tooltips: {
                    edit: 'Edit',
                    delete: 'Delete',
                    save: 'Save',
                },
                offers: {
                    title: 'Offers',
                    addHumanResources: 'Add HR',
                    table: {
                        hr: 'HR company',
                        candidates: 'Candidates',
                        status: 'Status',
                        actions: 'Actions',
                        view: 'view',
                        noData: 'There are no offers added.',
                    },
                    tooltips: {
                        view: 'View',
                        delete: 'Delete',
                    },
                },
                deletePosition: {
                    title: 'Remove position',
                    description: 'Are you sure you want to remove selected position?',
                    deleteWarning: 'You cannot delete a position!',
                    deleteInfo: 'You must close the cooperation with the HR companies involved in recruiting',
                    positionRemoved: 'The position has been removed.',
                },
                confirmInvitation: {
                    title: 'Invite HR Company',
                    confirmInvitationPrompt: 'Are you sure you want to invite selected recruiter to work with you?',
                    confirmation:
                        'If you confirm, a recruiter will get an invitation to cooperate and will start looking for candidates for you',
                    invitationSent: 'An invitation has been sent.',
                },
                updatePosition: {
                    positionUpdated: 'Position details have been updated.',
                },
            },
            employerDetails: {
                title: 'Employer details',
                noFiles: 'There are no files provided by the organization',
                taxId: 'Tax ID:',
                noTaxId: 'There is no tax id provided.',
                errors: {
                    notAuthorized: "Please accept organization's invitation to cooperate in order to download files",
                },
            },
            addPosition: {
                title: 'Add position',
                form: {
                    labels: {
                        position: 'Position',
                        description: 'Description',
                        seniority: 'Seniority level',
                        minSalary: 'Salary min:',
                        maxSalary: 'Salary max:',
                        city: 'City',
                    },
                    placeholders: {
                        position: 'Position name',
                        description: 'Job description',
                        city: 'Select city',
                    },
                },
                positionAdded: 'Position has been added',
            },
            closePosition: {
                title: 'Close Position',
                description: 'Are you sure you want to close selected position?',
                positionClosed: 'Selected position has been closed',
            },
            openPosition: {
                title: 'Open Position',
                description: 'Are you sure you want to open selected position?',
                positionOpened: 'Selected position has been opened',
            },
            editPosition: {
                form: {
                    labels: {
                        location: 'Location',
                        companyType: 'Company type',
                        industry: 'Industry',
                        contractType: 'Contract type',
                        onlyRemote: 'Only remote',
                        partTimeRemote: 'Part time remote',
                        stationary: 'Only stationary',
                        fullTime: 'Full time',
                        partTime: 'Part time',
                        relocation: 'Only relocation',
                        employers: 'Employers',
                        seniority: 'Seniority',
                        workType: 'Work type',
                        employmentType: 'Employment type',
                    },
                    placeholders: {
                        select: 'Select',
                    },
                },
                saveDraft: 'Save draft',
                publishOffer: 'Publish offer',
            },
        },
        cooperatingHr: {
            title: 'Cooperating HR',
            addCompany: 'Invite new',
            invitationHr: 'Invitation HR',
            table: {
                company: 'HR company',
                actions: 'Actions',
                emptyAcceptedList: 'There are no cooperating HR added',
                emptyPendingList: 'There are no invitations sent',
                noCompanyName: 'Unknown Organization',
            },
            invitationModal: {
                title: 'Invite new HR Company',
                form: {
                    labels: {
                        companyEmail: 'Company email',
                    },
                    placeholders: {
                        companyEmail: 'Company email',
                    },
                },
                alerts: {
                    success: 'The invitation was successfully sent',
                },
            },
            deleteCompanyModal: {
                title: 'Remove HR Company',
                description: 'Are you sure you want to remove HR company?',
            },
        },
    },
    humanResources: {
        title: 'HR Dashboard',
        dashboard: {
            candidates: {
                title: 'Candidates',
                form: {
                    search: 'Search:',
                    labels: {
                        candidates: 'Candidates',
                        offer: 'Offer',
                        position: 'Position',
                    },
                    placeholders: {
                        candidates: 'First name and last name',
                        offer: 'Offer name',
                        position: 'Position name',
                    },
                },
                table: {
                    candidate: 'Candidate',
                    interview: 'Interview',
                    offer: 'Offer',
                    position: 'Position',
                    actions: 'Actions',
                    noCandidates: 'There are no candidates',
                    unknownCandidate: 'Unknown Candidate',
                },
                tooltips: {
                    consultation: 'Start consultation',
                    addRecruiter: 'Add recruiter',
                    candidate: 'Candidate details',
                    recruitment: 'Recruitment',
                    hireCandidate: 'Hire candidate. {{additionalInformation}}',
                    additionalInformation: 'Offer needs to be closed in order to hire a candidate',
                    reject: 'Reject',
                    offerDetails: 'Offer details',
                    acceptCandidate: 'Accept candidate for selected position.',
                },
                addRecruiterModal: {
                    title: 'Candidate: {{ candidate }}',
                    description:
                        'Select a date and time for the recruitment interview. Candidate and recruiter will receive an email notification',
                    form: {
                        labels: {
                            recruiter: 'Add recruiter',
                        },
                        placeholders: {
                            recruiter: 'Start typing name',
                        },
                    },
                },
                acceptCandidateModal: {
                    title: 'Accept candidate for a position',
                    description: 'Are you sure you want to accept the candidate for selected position?',
                    candidateAccepted: 'Candidate was accepted for a selected position',
                },
                hireCandidateModal: {
                    title: 'Hire candidate for a position',
                    description: 'Are you sure you want to hire the candidate for selected position?',
                    candidateHired: 'Candidate was hired for a selected position',
                },
                rejectCandidateModal: {
                    title: 'Reject candidate for a position',
                    description: 'Are you sure you want to reject the candidate?',
                    candidateRejected: 'Candidate was rejected for a selected position',
                },
            },
            recruitment: {
                title: 'Recruitment',
                table: {
                    position: 'Position',
                    candidates: 'Candidates',
                    status: 'Status',
                    actions: 'Actions',
                    noRecruitment: 'There are no active recruitment processes.',
                },
            },
        },
        employee: {
            title: 'HR employees',
            addNewEmployee: 'Add new',
            types: {
                external: 'External Employee',
                organization: 'Organization Employee',
            },
            table: {
                employees: 'Employees',
                emptyExternal: 'There are no organization employees added.',
                email: 'Email',
                active: 'Active',
                actions: 'Actions',
                type: 'Type',
            },
            tooltips: {
                view: 'View',
                edit: 'Edit',
                delete: 'Delete',
            },
        },
        addEmployeeModal: {
            title: 'Add new employee',
            employeeAdded: 'Employee was successfully added',
            employeeUpdated: 'Employee was successfully updated',
            form: {
                labels: {
                    firstName: 'First name',
                    lastName: 'Last name',
                    email: 'Email',
                },
                placeholders: {
                    firstName: 'First name',
                    lastName: 'Last name',
                    email: 'Email',
                },
            },
        },
        editEmployee: {
            title: 'Edit employee information',
            chooseRole: 'Choose employee role',
            role: 'Role',
            roleAdmin: 'Organization Admin',
            roleHeadhunter: 'Headhunter',
            roleEmployee: 'Standard Employee',
        },
        employeeView: {
            email: 'Email:',
            status: 'Status:',
        },
        deleteEmployeeModal: {
            title: 'Delete employee',
            description: 'Are you sure you want to delete the employee?',
            employeeDeleted: 'Employee was successfully deleted',
        },
        addRecruiterModal: {
            title: 'Candidate: {{ candidate }}',
            description: 'Select a date and time for the recruitment interview. Candidate and recruiter will receive an email notification',
            form: {
                labels: {
                    addRecruiter: 'Add recruiter',
                },
                placeholders: 'Start typing name or email',
            },
        },
        candidates: {
            title: 'Candidates',
            filterForm: {
                labels: {
                    technology: 'Technology',
                },
                placeholders: {
                    select: 'Select',
                },
            },
            table: {
                headers: {
                    candidate: 'Candidate',
                    interview: 'Interview',
                    action: 'Action',
                },
            },
            candidateView: {
                career: 'Career',
                careerTimeline: 'Career timeline',
                presentCareer: 'now',
                noCareer: 'Candidate does not have any experience added.',
                technologySkills: 'Technology Skills',
                noTechnologySkills: 'Candidate does not have any technology skills added',
                languageSkills: 'Language Skills',
                noLanguageSkills: 'Candidate does not have any languages added',
                skillsValidation: 'Skills validation',
                noTechnologyTests: 'Candidate has not complete any technology validation tests yet',
                noLanguageTests: 'Candidate has not complete any language validation tests yet',
                noData: 'There is no information available regarding the selected candidate.',
                noTestResultsAvailable: 'There are no test results available yet',
            },
        },
        recruitment: {
            title: 'Recruitment',
            table: {
                position: 'Position',
                candidates: 'Candidates',
                status: 'Status',
                offers: 'Offers',
                actions: 'Actions',
                noData: 'There are no recruitments.',
            },
            tooltips: {
                viewCandidate: 'View candidates',
                viewPosition: 'View position',
                viewOffer: 'View offer',
                shareOffer: 'Share offer',
                copy: 'Copy link',
                showOffers: 'Show offers',
                hideOffers: 'Hide offers',
            },
        },
        offers: {
            title: 'Offers',
            cardTitle: 'My offers',
            addOffer: 'Add new offer',
            viewRecommendedCandidates: 'View Recommended Candidates',
            filters: {
                hasPosition: 'Offers with position added:',
            },
            table: {
                offer: 'Offer',
                candidates: 'Candidates',
                status: 'Status',
                position: 'Position',
                actions: 'Actions',
                noData: 'There are no offers added.',
            },
            tooltips: {
                candidateDetails: 'Candidate details',
                viewDetails: 'View details',
                positionDetails: 'Position details',
                recommend: 'Send offer recommendation',
                edit: 'Edit offer',
                delete: 'Delete offer',
                view: 'View offer',
                closeOffer: 'Close offer',
            },
            closeOffer: {
                title: 'Close an offer',
                description: 'Are you sure you want to close an offer?',
                offerClosed: 'An offer has been closed',
            },
            closeOfferNonAvailable: {
                title: 'You cannot close an offer!',
                description: 'You cannot close an offer to which applicants have applied',
            },
            deleteOfferNonAvailable: {
                title: 'You cannot delete an offer!',
                description: 'You cannot delete an offer to which applicants have applied',
            },
            deleteOffer: {
                title: 'Delete an offer',
                description: 'Are you sure you want to delete an offer?',
                offerDeleted: 'An offer was deleted',
            },
            recommendOffer: {
                title: 'Recommend an Offer',
                recommendationSent: 'Offer recommendation was successfully sent',
                form: {
                    labels: {
                        email: 'Email',
                    },
                    placeholders: {
                        email: 'Type an email',
                    },
                },
            },
        },
    },
    relocations: {
        title: 'Relocations',
        table: {
            employee: 'Employee',
            employer: 'Employer',
            hireDate: 'Hire date',
            status: 'Status',
            actions: 'Actions',
            noData: 'There are no relocations available.',
        },
        tooltips: {
            view: 'View relocation',
        },
    },
    relocationView: {
        title: 'Relocation',
        chat: {
            title: 'Chat',
        },
        status: {
            title: 'Status',
            form: {
                labels: {
                    currentStatus: 'Current status',
                },
                placeholders: {
                    currentStatus: 'Enter current status',
                },
            },
            statusUpdated: 'Relocation status has been updated',
        },
        calendar: {
            title: 'Calendar',
            events: 'Events',
            tooltips: {
                delete: 'Delete an event',
                view: 'View an event',
            },
            addEvent: {
                title: 'Add event',
                form: {
                    labels: {
                        title: 'Title',
                        description: 'Description',
                        date: 'Date',
                        type: 'Type',
                    },
                    placeholders: {
                        title: 'Title',
                        description: 'Enter description',
                        date: 'Select date',
                        type: 'Select type',
                    },
                },
                eventAdded: 'Relocation event has been added',
            },
            removeEvent: {
                title: 'Are you sure you want to delete this event?',
                description: 'Click Delete to confirm',
                eventRemoved: 'Even has beed removed',
            },
            eventView: {
                noTitle: 'Unknown event',
                noDescription: 'Description of the event was not provided.',
            },
            eventTypes: {
                alert: 'Alert',
                info: 'Info',
                warning: 'Warning',
            },
        },
        files: {
            title: 'Files',
            table: {
                fileName: 'File name',
                actions: 'Actions',
                noData: 'There are no files added.',
            },
            tooltips: {
                download: 'Download file',
                delete: 'Delete file',
            },
            removeFile: {
                title: 'Are you sure you want to delete this file?',
                description: 'Click Delete to confirm',
                fileRemoved: 'Relocation file has been removed',
            },
            addFile: {
                title: 'Add file',
                form: {
                    labels: {
                        selectFile: 'Select relocation file',
                    },
                },
                fileAdded: 'Relocation file has been added.',
            },
            downloadFile: {
                fileDownloaded: 'Relocation file has been downloaded',
            },
        },
    },
    auth: {
        authFooter: {
            srTitle: 'Footer containing contact information and social links',
            addressColumn: {
                title: 'JobHunter Sp z o.o',
                address1: 'ul. Zygmunta Krasińskiego 2',
                address2: '20-709 Lublin',
                nip: 'NIP: 9662131616',
            },
            copyright: 'JobHunter {{date}} © Wszystkie prawa zastrzeżone.',
            socialIcons: {
                facebook: 'Link to Facebooku profile',
                instagram: 'Link to Instagram profile',
                linkedin: 'Link to Linkedin profile',
            },
        },
        formControls: {
            placeholders: {
                companyName: 'Company Name',
                email: 'Email',
                password: 'Password',
                confirmPassword: 'Confirm Password',
            },
            email: 'Email',
            password: 'Password',
            confirmPassword: 'Confirm Password',
            newPassword: 'Nowe hasło',
            forgotPassword: 'Forgot Password?',
            repeatPassword: 'Powtórz nowe hasło',
            registrationTermsAgreement: ``,
            phone: 'Telefon',
            firstName: 'Company name',
            lastName: 'Nazwisko',
            registrationTerms: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempr. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempr.`,
        },
        register: {
            title: 'Welcome to Job Hunter 👋🏻',
            haveAccount: 'Already have an account? ',
            logIn: 'Sign in instead',
            backToLogin: 'Back to login',
            registerWith: {
                title: 'lub zarejestruj się bezpośrednio za pomocą swojego konta na:',
                facebook: 'Zarejestruj się przez Facebook',
                google: 'Zarejestruj się przez Google',
            },
        },
        confirmRegistration: {
            title: 'Confirm registration',
            subtitle: 'In order to complete registration on the platform click button bellow',
        },
        login: {
            title: 'Welcome to Job Hunter 👋🏻',
            subtitle: '',
            haveAccount: 'New on our platform?',
            register: 'Create an account',
            resetPassword: 'Nie pamiętasz hasła?',
            loginWith: {
                title: 'lub zaloguj się bezpośrednio za pomocą swojego konta na:',
                facebook: 'Zaloguj się przez Facebook',
                google: 'Zaloguj się przez Google',
            },
        },
        resetPassword: {
            title: 'Reset Password 🔐',
            subtitle: 'Please provide an email used during registration process. Further instructions will be sent to your email',
        },
        newPassword: {
            title: 'Change Password 🔐',
            subtitle: 'Enter a new password',
        },
        alert: {
            loginSuccess: 'Login successful.',
            loginFailed: 'Incorrect login or password. Please try again.',
            logout: 'You have been logged out.',
            registrationSuccess: 'Registration data sent correctly, confirm registration by clicking on the link provided in the email.',
            confirmRegistrationSuccess: 'Your email has been confirmed. You can now login to the application.',
            sendResetPasswordMailSuccess: 'Please check your email for password reset link.',
            confirmResetPasswordSuccess: 'Your password has been changed. You can log in to the panel.',
            authenticationError: 'You must be logged in to access the data.',
        },
    },
    settings: {
        changePassword: {
            title: 'Change password',
            form: {
                labels: {
                    currentPassword: 'Current password',
                    newPassword: 'New password',
                    confirmPassword: 'Confirm new password',
                },
                placeholders: {
                    currentPassword: 'Enter current password',
                    newPassword: 'Enter new password',
                    confirmPassword: 'Confirm your new password',
                },
            },
            passwordRequirements: {
                title: 'Password requirements:',
                length: 'Minimum 8 characters long - the more, the better',
                uppercase: 'At least one lowercase character',
                characters: 'At least one number, symbol, or whitespace character',
            },
            alerts: {
                passwordChanged: 'Password successfully changed',
            },
        },
        billingInformation: {
            title: 'Billing address',
            form: {
                labels: {
                    companyName: 'Company Name',
                    billingEmail: 'Billing Email',
                    taxId: 'Tax ID',
                    mobile: 'Mobile',
                    country: 'Country',
                    billingAddress: 'Billing Address',
                    city: 'City',
                    state: 'State',
                    zipCode: 'Zip Code',
                },
                placeholders: {
                    companyName: 'Enter company name',
                    billingEmail: 'Enter billing email',
                    taxId: 'Enter tax ID',
                    mobile: 'Enter mobile number',
                    country: 'Select country',
                    billingAddress: 'Enter billing address',
                    city: 'Enter city',
                    state: 'Enter state',
                    zipCode: 'Enter zip code',
                },
            },
            alerts: {
                updated: 'Billing information was successfully updated',
            },
        },
    },
    paymentCard: {
        form: {
            labels: {
                cardholderName: 'Cardholder name',
                cardNumber: 'Card number',
            },
            placeholders: {
                cardholderName: 'Cardholder name',
            },
        },
    },
    offers: {
        title: 'Find your new job',
        description: 'Find job in Poland',
        tabs: {
            offers: 'Offers',
            myApplications: 'My applications',
            myRecruitment: 'My recruitment',
            favourite: 'Favourites',
        },
        form: {
            placeholders: {
                search: 'Search',
            },
        },
        filters: {
            moreFilters: 'More filters',
            lessFilters: 'Less filters',
            form: {
                placeholders: {
                    select: 'Select',
                },
                labels: {
                    technology: 'Technology',
                    seniority: 'Seniority',
                    minSalary: 'Min. salary EUR',
                    bestMatch: 'Best match',
                    contactType: 'Contract type',
                    industry: 'Industry',
                    companyType: 'Company type',
                    jobType: 'Job type',
                    employmentType: 'Employment type',
                    relocation: 'Relocation',
                },
            },
        },
        table: {
            project: 'Project',
            seniority: 'Seniority',
            salary: 'Salary',
            remote: 'Remote / Stationary',
            status: 'Status',
            actions: 'Actions',
            tooltips: {
                observe: 'Observe',
                apply: 'Apply',
                details: 'Details',
                share: 'Share',
            },
        },
        offerView: {
            noData: 'There is no data.',
            labels: {
                seniority: 'Seniority',
                employees: 'Employees',
                relocation: 'Relocation',
                salary: 'Salary',
                industry: 'Industry',
                companyType: 'Company type',
                contractType: 'Contract type',
                employmentType: 'Employment type',
                jobType: 'Job type',
            },
            description: {
                technology: 'Tech stack',
                jobDescription: 'Job description',
                responsibilities: 'Your responsibilities',
                benefits: 'What we offer',
                preferenceTags: 'Preference tags',
                jobBoards: 'Job Boards',
                postJobFreeLabel: 'PostJobFree (Global)',
                workUaLabel: 'Work.ua (Ukraine)',
                careerAgeLabel: 'CareerAge (India)',
                pending: 'Pending',
                noJobBoardLinks: "There are no Job Boards' data provided.",
            },
            applicationForm: {
                title: 'Apply',
                description: 'Lorem ipsum',
                labels: {
                    applicationAgreement: 'I confirm that I want to apply for this job.',
                    dataProcessingAgreement: 'I agree to the processing of my personal data for recruitment procedures',
                },
            },
        },
        editOffer: {
            techStack: 'Tech stack',
            offerUpdated: 'The offer has been successfully updated',
            offerAdded: 'Offer has been added',
            addSkills: 'Add skills',
            form: {
                labels: {
                    position: 'Position',
                    offer: 'Offer name',
                    seniority: 'Seniority level',
                    minSalary: 'Salary min.',
                    maxSalary: 'Salary max.',
                    description: 'Description',
                    location: 'Location',
                    companyType: 'Company type',
                    industry: 'Industry',
                    contractType: 'Contract type',
                    employmentType: 'Employment type',
                    workType: 'Work type',
                    organizationSize: 'Organization size',
                    relocation: 'Relocation',
                    technology: 'Technology',
                    technologyTool: 'Technology tool',
                    tagPreferences: 'Preference tags',
                    jobBoardOption: 'JobBoard option',
                    postJobFree: 'PostJobFree (Global)',
                    workUa: 'Work.ua (Ukraine)',
                    careerAge: 'CareerAge (India)',
                },
                placeholders: {
                    position: 'Type position name',
                    offer: 'Type offer name',
                    description: 'Offer description',
                    location: 'Select location',
                    companyType: 'Select company type',
                    industry: 'Select industry',
                    contractType: 'Select contract type',
                    employmentType: 'Select employment type',
                    organizationSize: 'Select organization size',
                    workType: 'Select work type',
                    technology: 'Select technology',
                    technologyTool: 'Select tool',
                    tagPreferences: 'Select preference tags',
                },
            },
        },
    },
    marketplace: {
        title: 'Find a service',
        description: 'find the service that fits your needs',
        form: {
            labels: {
                serviceType: 'Service type',
                salaryRange: 'Salary range',
            },
        },
        serviceDetails: {
            title: 'Service {{title}} details',
            readMore: 'Read more',
            readLess: 'Read less',
            serviceName: 'Service name',
            serviceStatus: 'Service status',
            serviceTypes: 'Service types',
            servicePrices: {
                title: 'Service prices',
                grossPrice: 'Gross price: ',
                netPrice: 'Net price: ',
                taxRate: 'Tax rate: ',
            },
            servicesBought: 'Services bought: ',
            serviceDescription: 'Service description',
            paymentDetails: {
                title: 'Payment details',
                invoiceNumber: 'Invoice number: ',
                id: 'ID: ',
                paidAt: 'Paid at: ',
                expiresAt: 'Expires at: ',
                paymentPrices: {
                    title: 'Payment prices',
                    grossPrice: 'Gross price: ',
                    netPrice: 'Net price: ',
                    taxRate: 'Tax rate: ',
                },
            },
        },
        buyService: {
            title: 'Payment',
            description: 'Add card for further billing',
            billingSummary: 'Billing summary',
            servicePurchased: 'The payment has been successful.',
        },
        purchaseComplete: {
            title: 'Thanks for purchase!',
            invoice: 'Invoice #',
            serviceName: 'Service name',
            subTotal: 'Subtotal',
            tax: 'Tax',
            total: 'Total',
            details: 'Your details',
            serviceTitle: 'Service title',
            planTitle: 'Plan title',
            companyName: 'Company name',
            noticeError: 'Notice something wrong?',
            report: 'let us know',
        },
        services: {
            title: 'My services',
            table: {
                headers: {
                    service: 'Service',
                    type: 'Type',
                    description: 'Description',
                    price: 'Price',
                    status: 'Status',
                    actions: 'Actions',
                    paymentStatus: 'Payment status',
                },
                tooltips: {
                    delete: 'Delete',
                    purchasedCannotDelete: 'You cannot delete purchased service',
                    details: 'Details',
                    view: 'View',
                },
                noData: 'There are no services added yet.',
            },
            modal: {
                removeService: {
                    title: 'Remove service',
                    description: 'Are you sure you want to remove {{title}} service?',
                },
            },
            addService: {
                title: 'Add Service',
                description: 'The services added by you will appear in the marketplace after being accepted by the system administrator',
                form: {
                    placeholders: {
                        location: 'Location',
                        type: 'Type',
                        title: 'Service title',
                        description: 'Service description',
                        taxRate: 'f.e. 23%',
                        netPrice: 'f.e. 100',
                    },
                    labels: {
                        location: 'Location',
                        type: 'Type',
                        title: 'Title',
                        description: 'Description',
                        price: 'Price',
                        taxRate: 'Tax Rate',
                        netPrice: 'Your netto',
                        grossPrice: 'Your brutto',
                        hiredEmployeeNeeded: 'Service only for hired employee',
                    },
                },
            },
            serviceStatus: {
                accepted: 'accepted',
                pending: 'pending',
                paid: 'paid',
                notPaid: 'not paid',
            },
        },
        purchasedServices: {
            title: 'Purchased Services',
            table: {
                headers: {
                    invoice: 'Invoice',
                    service: 'Service',
                    info: 'Info',
                    serviceDescription: 'Service description',
                    cost: 'Cost',
                    status: 'Status',
                    actions: 'Actions',
                },
                tooltips: {
                    buyAgain: 'Buy again',
                    details: 'Details',
                },
                noData: 'There are no purchased services',
            },
        },
    },
    billings: {
        title: 'Billings',
        table: {
            plan: 'Plan',
            accountingPeriod: 'Accounting period',
            issueDate: 'Issue date',
            paymentDate: 'Payment date',
            cost: 'Cost',
            status: 'Status',
            noPlans: 'There are no plans purchased',
            emptyPlansList: 'There are no subscription plans available.',
            currentPlan: 'Current plan',
            days: 'days',
            statusPaid: 'Paid',
            statusNotPaid: 'Not paid',
        },
    },
    plans: {
        title: 'Plans',
    },
    videoChat: {
        consultationStart: 'Meeting starts at ',
        consultationTimeZone: 'Time zone: Eastern European Time',
        startConsultation: 'Remember to click button "Start consultation" in order to join the consultation.',
        wailTillConsultationStarts: 'Wait for the HR specialist to let you in.',
        noAccess: 'You cannot join the consultation.',
        contactAdmin: 'Check URL or contact admin.',
        consultationEnded: 'Meeting is already over',
        rescheduleConsultation: 'In case you have missed the meeting, please contact the admin.',
        consultationTimeTooltip:
            'The timer will start count down 10 minutes before the end of the consultation. Once the time is up, there will be no possibility to return to the conversation.',
        settings: {
            title: 'Settings',
            select: 'Select',
            selectCamera: 'Choose a camera',
            selectSpeaker: 'Choose a speaker',
            selectMicrophone: 'Choose a microphone',
            testMicrophone: 'Say something to test a microphone',
            poor: 'poor',
            excellent: 'excellent',
            saveChanges: 'Save preferences',
        },
        chat: {
            errorMessage: 'Error. Can not send message. Try again later.',
            placeholder: 'Write a message',
            noMessages: 'You do not have any messages',
            status: {
                online: 'Online',
            },
        },
    },
    buttons: {
        add: 'Add',
        addNew: 'Add new',
        upload: 'Upload',
        more: 'More',
        login: 'Login',
        saveChanges: 'Save changes',
        discard: 'Discard',
        apply: 'Apply',
        buy: 'Buy',
        readLess: 'Read less',
        readMore: 'Read more',
        register: 'Sign Up',
        resetPassword: 'Set new password',
        close: 'Close',
        join: 'Join',
        showAll: 'Show all',
        next: 'Next',
        previous: 'Previous',
        submit: 'Submit',
        change: 'Change',
        remove: 'Remove',
        delete: 'Delete',
        cancel: 'Cancel',
        reject: 'Reject',
        confirm: 'Confirm',
        save: 'Save',
        ok: 'Ok',
        edit: 'Edit',
        sendInvitation: 'Send invitation',
        yes: 'yes',
        no: 'no',
        accept: 'Accept',
        acceptInvitation: 'Accept invitation',
        choosePhoto: 'Choose photo',
        reset: 'Reset',
        chooseFile: 'Choose file',
        sendEmail: 'Send email',
        publishOffer: 'Publish offer',
        saveDraft: 'Save draft',
        update: 'Update',
    },
    form: {
        fileUploadAllowedFiles: 'Allowed files: ',
    },
    formValidation: {
        errors: {
            minLength: 'The value is to short',
            isEmailValid: 'Email is not valid',
            isRequired: 'Field is required',
            isCheckedTrue: 'Musi być zaznaczone',
            isTrue: 'Musi być zaznaczone',
            isPasswordSame: 'Passwords cannot be different',
            isStartDateValid: 'Selected date should be greater than current date',
            isStartDateGreaterOrEvenValid: 'Please select future date',
            isEndDateValid: 'Birthdate should be greater than current date',
            isNumber: 'Wartość musi być liczbą',
            isValidUrl: 'Provided input is not a valid url.',
            isPositiveNumberOrZero: 'Value should be greater than 0',
        },
    },
    footer: {
        copyright: `JobHunter.io {{date}} © All rights reserved`,
    },
    maintenance: {
        pageNotFound: 'Error - page was not found',
    },
    chat: {
        noUserListProvided: 'There are no contacts added.',
        fileIsReadyToSent: 'Files are ready to be sent:',
        fileSize: 'File size:',
        noNodeConnection: 'Server connection failed. Chat is currently unavailable.',
        noConnectionReasons: 'Possible Reasons:',
        already_online: 'Someone else is using this account.',
        expired_token: 'Token has expired. You have to relog your account',
        not_authorized: 'You have no authorisation rights to use chat. Contact with admin',
        no_token: 'No token provided. Relog your account',
        'websocket error': 'Unexpected connection error. Try again later',
    },
    alerts: {
        noAccessError: 'You are not authorized to login to panel.',
        baseError: 'Something went wrong. Please try again later.',
        employer: {
            uploadSuccess: {
                employer_information: 'Personal information updated successfully',
                company_information: 'Company information updated successfully',
                verification_files: 'Verification files updated successfully',
                company_details: 'Company details updated successfully',
            },
        },
        services: {
            success: {
                create: 'Service created successfully',
                delete: 'Service deleted successfully',
            },
        },
        authError: 'The username or password you entered is incorrect. Please try again',
        loginError: 'An error occurred while logging in',
        tryAgain: 'Please try again',
        tokenRefreshed: 'Your session has been renewed',
        unknown: 'Unknown error occurred.',
    },
    db: {
        service_type: {
            buddy: 'Buddy',
            relocation: 'Relocation',
            housing: 'Housing',
            family: 'Family',
            language: 'Language',
        },
        seniority: {
            novice: 'Novice',
            trainee: 'Trainee',
            junior: 'Junior',
            middle: 'Mid',
            senior: 'Senior',
            architect: 'Architect',
        },
        technology: {
            javascript: 'JavaScript',
            php: 'PHP',
            java: 'Java',
            cpp: 'C++',
            csharp: 'C#',
            python: 'Python',
            html: 'HTML',
            html5: 'HTML5',
            ruby: 'Ruby',
            scala: 'Scala',
            golang: 'Go',
            sql: 'SQL',
            rust: 'Rust',
        },
        technology_tool: {
            laravel: 'Laravel',
            reactjs: 'Reactjs',
            yii: 'Yii',
            angularjs: 'Angular',
            angular: 'Angular',
            symfony: 'Symfony',
            doctrine: 'Doctrine',
            phalcon: 'Phalcon',
            spring: 'Spring',
            hibernate: 'Hibernate',
            javalin: 'Javalin',
            dotnet: '.NET',
            xamarin: 'Xamarin',
            infernet: 'Infer.NET',
            vuejs: 'Vue.js',
            gatsby: 'Gatsby',
            django: 'Django',
            flask: 'Flask',
            framework7: 'Framework7',
            kendoui: 'Kendo UI',
            rubyonrails: 'Ruby on Rails',
            sinatra: 'Sinatra',
            hanami: 'Hanami',
            grape: 'Grape',
            ramaze: 'Ramaze',
            slick: 'Slick',
            playframework: 'Play Framework',
            akka: 'Akka',
            gin: 'Gin',
            echo: 'Echo',
            kit: 'Kit',
            fasthttp: 'Fasthttp',
            aeron: 'Aeron',
            tensorflow: 'TensorFlow',
            devart: 'Devart',
            dbvisualizer: 'DbVisualizer',
            dbschema: 'DbSchema',
            manageengine: 'ManageEngine',
            tauri: 'Tauri',
            yew: 'Yew',
            rocket: 'Rocket',
            juniper: 'Juniper',
            dioxus: 'Dioxus',
        },
        city: {
            warsaw: 'Warsaw',
            poznan: 'Poznań',
            wroclaw: 'Wrocław',
            krakow: 'Kraków',
        },
        organization_verification_file_type: {
            krs: 'KRS',
            gus: 'GUS',
            ceidg: 'CEIDG',
            other: 'Other',
        },
        subscription: {
            aut: 'Aut',
            fugit: 'Fugit',
            qui: 'Qui',
            fugiat: 'Fugiat',
            earum: 'Earum',
            aliquam: 'Aliquam',
            accusamus: 'Accusamus',
            quia: 'Quia',
            libero: 'Libero',
            consequatur: 'Consequatur',
            in: 'In',
        },
        preference_tag: {
            clubs_high: 'Clubs high',
            nature_high: 'Nature high',
            education_medium: 'Education medium',
            'holidays_26-30': 'Holidays 26-30',
            climate_hot: 'Climate hot',
            pace_of_life_slow: 'Pace of life slow',
            climate_dry: 'Climate dry',
            climate_forest: 'Climate forest',
            climate_mountains: 'Climate mountains',
            nature_medium: 'Nature medium',
            'holidays_21-25': 'Holidays 21-25',
            climate_humid: 'Climate humid',
            holidays_11_15: 'Holidays 11-15',
            education_low: 'Education low',
            nature_low: 'Nature low',
            holidays_no_more_than_10: 'Holidays no more than 10',
            religion_buddhism: 'Religion buddhism',
            climate_snow: 'Climate snow',
            religion_judaism: 'Religion judaism',
            holidays_31_or_more: 'Holidays 31 or more',
            religion_muslim: 'Religion muslim',
            religion_hindu: 'Religion hindu',
            clubs_medium: 'Clubs medium',
            climate_cold: 'Climate cold',
            education_high: 'Education high',
            pace_of_life_medium: 'Pace of life medium',
            religion_taoist: 'Religion taoist',
            religion_protestant: 'Religion protestant',
            'holidays_16-20': 'Holidays 16-20',
            climate_temperate: 'Climate temperate',
            pace_of_life_fast: 'Pace of life fast',
            religion_catholic: 'Religion catholic',
            clubs_low: 'Clubs low',
        },
        country: {
            AF: 'Afghanistan',
            AL: 'Albania',
            DZ: 'Algeria',
            AS: 'American Samoa',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarctica',
            AG: 'Antigua and Barbuda',
            AR: 'Argentina',
            AM: 'Armenia',
            AW: 'Aruba',
            AU: 'Australia',
            AT: 'Austria',
            AZ: 'Azerbaijan',
            BS: 'Bahamas (the)',
            BH: 'Bahrain',
            BD: 'Bangladesh',
            BB: 'Barbados',
            BY: 'Belarus',
            BE: 'Belgium',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermuda',
            BT: 'Bhutan',
            BO: 'Bolivia (Plurinational State of)',
            BQ: 'Bonaire, Sint Eustatius and Saba',
            BA: 'Bosnia and Herzegovina',
            BW: 'Botswana',
            BV: 'Bouvet Island',
            BR: 'Brazil',
            IO: 'British Indian Ocean Territory (the)',
            BN: 'Brunei Darussalam',
            BG: 'Bulgaria',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            CV: 'Cabo Verde',
            KH: 'Cambodia',
            CM: 'Cameroon',
            CA: 'Canada',
            KY: 'Cayman Islands (the)',
            CF: 'Central African Republic (the)',
            TD: 'Chad',
            CL: 'Chile',
            CN: 'China',
            CX: 'Christmas Island',
            CC: 'Cocos (Keeling) Islands (the)',
            CO: 'Colombia',
            KM: 'Comoros (the)',
            CD: 'Congo (the Democratic Republic of the)',
            CG: 'Congo (the)',
            CK: 'Cook Islands (the)',
            CR: 'Costa Rica',
            HR: 'Croatia',
            CU: 'Cuba',
            CW: 'Curaçao',
            CY: 'Cyprus',
            CZ: 'Czechia',
            CI: "Côte d'Ivoire",
            DK: 'Denmark',
            DJ: 'Djibouti',
            DM: 'Dominica',
            DO: 'Dominican Republic (the)',
            EC: 'Ecuador',
            EG: 'Egypt',
            SV: 'El Salvador',
            GQ: 'Equatorial Guinea',
            ER: 'Eritrea',
            EE: 'Estonia',
            SZ: 'Eswatini',
            ET: 'Ethiopia',
            FK: 'Falkland Islands (the) [Malvinas]',
            FO: 'Faroe Islands (the)',
            FJ: 'Fiji',
            FI: 'Finland',
            FR: 'France',
            GF: 'French Guiana',
            PF: 'French Polynesia',
            TF: 'French Southern Territories (the)',
            GA: 'Gabon',
            GM: 'Gambia (the)',
            GE: 'Georgia',
            DE: 'Germany',
            GH: 'Ghana',
            GI: 'Gibraltar',
            GR: 'Greece',
            GL: 'Greenland',
            GD: 'Grenada',
            GP: 'Guadeloupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guinea',
            GW: 'Guinea-Bissau',
            GY: 'Guyana',
            HT: 'Haiti',
            HM: 'Heard Island and McDonald Islands',
            VA: 'Holy See (the)',
            HN: 'Honduras',
            HK: 'Hong Kong',
            HU: 'Hungary',
            IS: 'Iceland',
            IN: 'India',
            ID: 'Indonesia',
            IR: 'Iran (Islamic Republic of)',
            IQ: 'Iraq',
            IE: 'Ireland',
            IM: 'Isle of Man',
            IL: 'Israel',
            IT: 'Italy',
            JM: 'Jamaica',
            JP: 'Japan',
            JE: 'Jersey',
            JO: 'Jordan',
            KZ: 'Kazakhstan',
            KE: 'Kenya',
            KI: 'Kiribati',
            KP: "Korea (the Democratic People's Republic of)",
            KR: 'Korea (the Republic of)',
            KW: 'Kuwait',
            KG: 'Kyrgyzstan',
            LA: "Lao People's Democratic Republic (the)",
            LV: 'Latvia',
            LB: 'Lebanon',
            LS: 'Lesotho',
            LR: 'Liberia',
            LY: 'Libya',
            LI: 'Liechtenstein',
            LT: 'Lithuania',
            LU: 'Luxembourg',
            MO: 'Macao',
            MG: 'Madagascar',
            MW: 'Malawi',
            MY: 'Malaysia',
            MV: 'Maldives',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Marshall Islands (the)',
            MQ: 'Martinique',
            MR: 'Mauritania',
            MU: 'Mauritius',
            YT: 'Mayotte',
            MX: 'Mexico',
            FM: 'Micronesia (Federated States of)',
            MD: 'Moldova (the Republic of)',
            MC: 'Monaco',
            MN: 'Mongolia',
            ME: 'Montenegro',
            MS: 'Montserrat',
            MA: 'Morocco',
            MZ: 'Mozambique',
            MM: 'Myanmar',
            NA: 'Namibia',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Netherlands (the)',
            NC: 'New Caledonia',
            NZ: 'New Zealand',
            NI: 'Nicaragua',
            NE: 'Niger (the)',
            NG: 'Nigeria',
            NU: 'Niue',
            NF: 'Norfolk Island',
            MP: 'Northern Mariana Islands (the)',
            NO: 'Norway',
            OM: 'Oman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'Palestine, State of',
            PA: 'Panama',
            PG: 'Papua New Guinea',
            PY: 'Paraguay',
            PE: 'Peru',
            PH: 'Philippines (the)',
            PN: 'Pitcairn',
            PL: 'Poland',
            PT: 'Portugal',
            PR: 'Puerto Rico',
            QA: 'Qatar',
            MK: 'Republic of North Macedonia',
            RO: 'Romania',
            RU: 'Russian Federation (the)',
            RW: 'Rwanda',
            RE: 'Réunion',
            BL: 'Saint Barthélemy',
            SH: 'Saint Helena, Ascension and Tristan da Cunha',
            KN: 'Saint Kitts and Nevis',
            LC: 'Saint Lucia',
            MF: 'Saint Martin (French part)',
            PM: 'Saint Pierre and Miquelon',
            VC: 'Saint Vincent and the Grenadines',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Sao Tome and Principe',
            SA: 'Saudi Arabia',
            SN: 'Senegal',
            RS: 'Serbia',
            SC: 'Seychelles',
            SL: 'Sierra Leone',
            SG: 'Singapore',
            SX: 'Sint Maarten (Dutch part)',
            SK: 'Slovakia',
            SI: 'Slovenia',
            SB: 'Solomon Islands',
            SO: 'Somalia',
            ZA: 'South Africa',
            GS: 'South Georgia and the South Sandwich Islands',
            SS: 'South Sudan',
            ES: 'Spain',
            LK: 'Sri Lanka',
            SD: 'Sudan (the)',
            SR: 'Suriname',
            SJ: 'Svalbard and Jan Mayen',
            SE: 'Sweden',
            CH: 'Switzerland',
            SY: 'Syrian Arab Republic',
            TW: 'Taiwan (Province of China)',
            TJ: 'Tajikistan',
            TZ: 'Tanzania, United Republic of',
            TH: 'Thailand',
            TL: 'Timor-Leste',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad and Tobago',
            TN: 'Tunisia',
            TR: 'Turkey',
            TM: 'Turkmenistan',
            TC: 'Turks and Caicos Islands (the)',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ukraine',
            AE: 'United Arab Emirates (the)',
            GB: 'United Kingdom of Great Britain and Northern Ireland (the)',
            UM: 'United States Minor Outlying Islands (the)',
            US: 'United States of America (the)',
            UY: 'Uruguay',
            UZ: 'Uzbekistan',
            VU: 'Vanuatu',
            VE: 'Venezuela (Bolivarian Republic of)',
            VN: 'Viet Nam',
            VG: 'Virgin Islands (British)',
            VI: 'Virgin Islands (U.S.)',
            WF: 'Wallis and Futuna',
            EH: 'Western Sahara',
            YE: 'Yemen',
            ZM: 'Zambia',
            ZW: 'Zimbabwe',
            AX: 'Åland Islands',
        },
        organization_size: {
            tiny: 'Tiny',
            small: 'Small',
            medium: 'Medium',
            large: 'Large',
            huge: 'Huge',
        },
        company_type: {
            support: 'Support',
            hoster: 'Hoster',
            softwarehouse: 'Softwarehouse',
            agency: 'Agency',
            integrator: 'Integrator',
            startup: 'Startup',
            interactive_agency: 'Interactive Agency',
            team_leasing_company: 'Team Leasing Company',
            body_leasing_company: 'Body Leasing Company',
            own_it_department: 'Own IT department',
        },
        work_type: {
            partially_remote: 'Partially remote',
            stationary: 'Stationary',
            remote: 'Remote',
        },
        industry: {
            government: 'Government',
            finance: 'Finance',
            it: 'IT',
            education: 'Education',
            healthcare: 'Healthcare',
            hardware: 'Hardware',
            software: 'Software',
            saas: 'Software as a Service',
            platforms: 'Platforms',
            systems_integrator: 'Systems Integrator',
            business_process_outsourcing: 'Business Process Outsourcing',
            infrastructure: 'Infrastructure',
            data_centers: 'Data Centers',
            telecom: 'Telecom',
            it_consulting: 'IT Consulting',
            creative_services: 'Creative Services',
            information_security: 'Information Security',
            data_services: 'Data Services',
            ai: 'Artificial Intelligence',
            robotics: 'Robotics',
            gaming: 'Gaming',
            media: 'Media',
            simulation: 'Simulation',
            research_n_development: 'Research & Development',
            ecommerce: 'Ecommerce',
            energy: 'Energy',
            marketing: 'Marketing',
            market_research: 'Market Research',
            competitive_intelligence: 'Competitive Intelligence',
            public_services: 'Public Services',
            smart_cities: 'Smart Cities',
            building_n_home_automation: 'Building & Home Automation',
            immersive_environments: 'Immersive Environments',
            sharing_economy: 'Sharing Economy',
            consumer_products: 'Consumer Products',
            mass_customization: 'Mass Customization',
            consumer_services: 'Consumer Services',
            business_services: 'Business Services',
            reputation_systems: 'Reputation Systems',
            architecture: 'Architecture',
            agriculture: 'Agriculture',
            automotive: 'Automotive',
            others: 'Others',
        },
        employment_type: {
            part_time: 'Part time',
            full_time: 'Full time',
        },
        contract_type: {
            employment: 'Employment',
            freelance: 'Freelance',
            b2b: 'B2B',
        },
        language: {
            de: 'German',
            en: 'English',
            pl: 'Polish',
        },
        language_level: {
            a1: 'A1',
            a2: 'A2',
            b1: 'B1',
            b2: 'B2',
            c1: 'C1',
            c2: 'C2',
        },
    },
    accessibilityMenu: {
        title: 'Accessibility Menu',
        openAccessibilityMenu: 'Open accessibility menu',
        closeAccessibilityMenu: 'Close accessibility menu',
        contrastMode: {
            title: 'Contrast Mode {{mode}}',
            toggleButton: 'Toggle contrast mode',
            on: 'on',
            off: 'off',
        },
        fontSize: {
            title: 'Font Size',
            increase: 'Increase Font Size',
            decrease: 'Decrease Font Size',
        },
    },
};

export default en;
