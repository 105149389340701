import React, {Component} from 'react';
import {Button, Card, CardBody, CardTitle, Table} from 'reactstrap';
import {Clipboard, Search, UserPlus, Users, UserX, Video} from 'react-feather';
import {ListItemDetails, Tooltip, Translation, WithNavigate, withNavigation} from 'jobhunter-common-web';
import {rejectOfferApplicationCandidate} from '../../../store/reducers/dashboardPageSlice';
import styles from '../../Employer/Positions/styles.module.scss';
import {WithTranslation, withTranslation} from 'react-i18next';
import RejectApplicantModal from './RejectApplicantModal';
import {connect} from 'react-redux';
import AddRecruiter from '../../HumanResources/CandidatesCard/AddRecruiterModal';
import {IApplicationOffer} from '../../../model/offerDataModel';

declare type WithNavigationProps = typeof WithNavigate;

interface IConnectedOfferApplicationsProps {
    readonly rejectOfferApplicationCandidate: typeof rejectOfferApplicationCandidate;
}

interface IOfferApplicationsProps extends IConnectedOfferApplicationsProps, WithTranslation, WithNavigationProps {
    offerApplications: IApplicationOffer[] | null;
}

interface IOfferApplicationsState {
    isAddRecruiterModalVisible: boolean;
    isRejectApplicantModalVisible: boolean;
    selectedOfferApplication: IApplicationOffer | null;
}

class OfferApplications extends Component<IOfferApplicationsProps, IOfferApplicationsState> {
    constructor(props: IOfferApplicationsProps) {
        super(props);

        this.state = {
            isAddRecruiterModalVisible: false,
            isRejectApplicantModalVisible: false,
            selectedOfferApplication: null,
        };
    }

    render() {
        return (
            <>
                <Card className="offer-applications-card">
                    <CardBody>
                        <CardTitle>
                            <div className="card-header">
                                <div>
                                    <Users size={20} />
                                    <Translation text="dashboard.offerApplications.title" />
                                </div>
                                <button className="btn btn-primary applications-button" onClick={() => this.redirectToApplications()}>
                                    <Translation text="buttons.showAll" />
                                </button>
                            </div>
                        </CardTitle>
                        <Table responsive className="applications-table">
                            <thead>
                                <tr>
                                    <th>
                                        <Translation text="dashboard.offerApplications.table.headers.candidate" />
                                    </th>
                                    <th>
                                        <Translation text="dashboard.offerApplications.table.headers.interview" />
                                    </th>
                                    <th>
                                        <Translation text="dashboard.offerApplications.table.headers.actions" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>{this.renderApplicationsTableRows()}</tbody>
                        </Table>
                    </CardBody>
                </Card>

                {this.state.isRejectApplicantModalVisible ? (
                    <RejectApplicantModal
                        isModalOpen={this.state.isRejectApplicantModalVisible}
                        toggleModal={this.toggleRejectApplicationModal}
                        rejectCandidate={this.rejectCandidate}
                    />
                ) : null}

                {this.state.isAddRecruiterModalVisible ? (
                    <AddRecruiter
                        isModalOpen={this.state.isAddRecruiterModalVisible}
                        toggleModal={this.toggleAddRecruiterModal}
                        candidate={this.state.selectedOfferApplication}
                    />
                ) : null}
            </>
        );
    }

    private renderApplicationsTableRows() {
        const {t} = this.props;

        if (this.props.offerApplications === null || !this.props.offerApplications.length) {
            return (
                <tr key={'no-applications-data'}>
                    <td colSpan={4}>
                        <p>
                            <Translation text="dashboard.offerApplications.table.noData" />
                        </p>
                    </td>
                </tr>
            );
        }

        return this.props.offerApplications.map((item: IApplicationOffer) => {
            return (
                <tr key={`applications_${item.applicationId}`}>
                    <td>{this.renderDescription(item)}</td>
                    <td>
                        <div>
                            <Button
                                color="flat-primary"
                                className="consultation-button"
                                onClick={() => this.redirectToVideo(item)}
                                disabled={item.consultation === null}>
                                <Video id={`video_${item.offerId}`} size={20} />
                                <Tooltip target={`video_${item.offerId}`} tooltipText={t('dashboard.offerApplications.tooltips.video')} />
                            </Button>
                            <Button
                                color="flat-primary"
                                onClick={() => this.toggleAddRecruiterModal(item)}
                                className="add-recruiter-button"
                                disabled={item.consultation !== null}>
                                <UserPlus id={`add_recruiter_${item.offerId}`} size={20} color="#28C76F" />
                                <Tooltip
                                    target={`add_recruiter_${item.offerId}`}
                                    tooltipText={t('dashboard.offerApplications.tooltips.addRecruiter')}
                                />
                            </Button>
                        </div>
                    </td>
                    <td>
                        <div className={styles.actions}>
                            <Button
                                color="flat-primary"
                                className="candidate-details-button"
                                onClick={() => this.redirectToCandidateDetails(item)}>
                                <Search id={`candidate_${item.offerId}`} size={20} />
                                <Tooltip
                                    target={`candidate_${item.offerId}`}
                                    tooltipText={t('dashboard.offerApplications.tooltips.candidateDetails')}
                                />
                            </Button>
                            <Button
                                color="flat-primary"
                                className="offer-details-button"
                                onClick={() => this.redirectToApplicationDetails(item)}>
                                <Clipboard id={`details_${item.offerId}`} size={20} />
                                <Tooltip
                                    target={`details_${item.offerId}`}
                                    tooltipText={t('dashboard.offerApplications.tooltips.offerDetails')}
                                />
                            </Button>
                            <Button
                                color="flat-danger"
                                className="remove-application-button"
                                onClick={() => this.toggleRejectApplicationModal(item)}>
                                <UserX id={`delete_${item.offerId}`} size={20} />
                                <Tooltip
                                    target={`delete_${item.offerId}`}
                                    tooltipText={t('dashboard.offerApplications.tooltips.rejectCandidate')}
                                />
                            </Button>
                        </div>
                    </td>
                </tr>
            );
        });
    }

    private renderDescription = (item: IApplicationOffer) => {
        const candidateAccount = item.candidate.account,
            candidateName = `${candidateAccount.firstName} ${candidateAccount.lastName}`;

        return <ListItemDetails avatar={candidateAccount.avatar} name={candidateName} description={item.name} />;
    };

    private redirectToVideo = (item: IApplicationOffer) => {
        console.log('item:', item);
    };

    private toggleAddRecruiterModal = (item: IApplicationOffer | null) => {
        this.setState({
            isAddRecruiterModalVisible: !this.state.isAddRecruiterModalVisible,
            selectedOfferApplication: item ? item : null,
        });
    };

    private redirectToCandidateDetails = (item: IApplicationOffer) => {
        this.props.navigate(`/panel/human_resources/candidates/${item.applicationId}`);
    };

    private redirectToApplicationDetails = (item: IApplicationOffer) => {
        this.props.navigate(`/panel/human_resources/offers/${item.offerId}`);
    };

    private toggleRejectApplicationModal = (item?: IApplicationOffer) => {
        this.setState({
            isRejectApplicantModalVisible: !this.state.isRejectApplicantModalVisible,
            selectedOfferApplication: item ? item : null,
        });
    };

    private redirectToApplications = () => {
        this.props.navigate('/panel/human_resources/candidates');
    };

    private rejectCandidate = () => {
        const offerApplication = this.state.selectedOfferApplication;
        if (null === offerApplication) {
            return;
        }

        this.props.rejectOfferApplicationCandidate(offerApplication.applicationId);
    };
}

export default connect(() => ({}), {
    rejectOfferApplicationCandidate,
})(withTranslation()(withNavigation(OfferApplications)));
