import React, {Component} from 'react';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {
    Form,
    FormControlChangeType,
    IFormConfig,
    Translation,
    citiesSelector,
    IModelCity,
    IModelSeniority,
    senioritySelector,
    convertToMultiselectLabels,
    convertSeniorityToRangeValues,
    IRangePickerOption,
    convertPriceToPayloadValue,
    Loader,
    LoaderType,
    sortMultiselectLabels,
} from 'jobhunter-common-web';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {addPositionFormConfig} from './addPositionFormConfig';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {addPosition, changePositionAdded} from '../../../../store/reducers/positionsPageSlice';
import {WithTranslation, withTranslation} from 'react-i18next';
import {isPositionAddedSelector, positionsPageError} from '../../../../store/selectors/positionSelectors';
import {IAddPositionPayload} from '../../../../api/addPositionApi';

interface IConnectedAddPositionProps {
    readonly addPosition: typeof addPosition;
    readonly city: typeof IModelCity[];
    readonly seniorityLevel: typeof IModelSeniority[];
    readonly error: string | null;
    readonly isPositionAdded: boolean;
    readonly changePositionAdded: typeof changePositionAdded;
}

interface IAddPositionProps extends IConnectedAddPositionProps, WithTranslation {
    isModalOpen: boolean;
    toggleModal: () => void;
}

interface IAddPositionState {
    value: any;
    formConfig: typeof IFormConfig | null;
    isLoading: boolean;
}

class AddPosition extends Component<IAddPositionProps, IAddPositionState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IAddPositionProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: null,
            isLoading: false,
        };
    }

    componentDidMount(): void {
        this.setFormConfig();
        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    tap((data: any) => this.setState({value: data.value}))
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: IAddPositionProps): void {
        if (this.props.isPositionAdded !== prevProps.isPositionAdded && this.props.isPositionAdded) {
            this.props.toggleModal();
        }

        if (this.props.error !== prevProps.error && this.props.error) {
            this.setState({isLoading: false});
        }
    }

    componentWillUnmount() {
        this.props.changePositionAdded(false);
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <Modal isOpen={this.props.isModalOpen} toggle={() => this.props.toggleModal()}>
                <ModalHeader toggle={() => this.props.toggleModal()}>
                    <Translation text="employer.positions.addPosition.title" />
                </ModalHeader>
                <ModalBody>
                    {this.state.formConfig && (
                        <Form
                            config={this.state.formConfig}
                            onValueStateChange={this.onValueStateChange}
                            value={this.state.value}
                            submitForm={() => this.addNewPosition()}
                            controlName={'addPositionForm'}
                        />
                    )}
                </ModalBody>
                <Loader type={LoaderType.Local} showLoader={this.state.isLoading} />
            </Modal>
        );
    }

    private setFormConfig = () => {
        const {t} = this.props,
            city = sortMultiselectLabels(convertToMultiselectLabels(this.props.city, t)),
            seniorityLevel: {[key: number]: typeof IRangePickerOption} = convertSeniorityToRangeValues(this.props.seniorityLevel),
            formConfig = addPositionFormConfig(city, seniorityLevel);

        this.setState({formConfig});
    };
    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private addNewPosition = () => {
        this.setState({isLoading: true});

        const value = this.state.value,
            payload: IAddPositionPayload = {
                name: value.name,
                description: value.description,
                minimumSalary: convertPriceToPayloadValue(value.minimumSalary),
                maximumSalary: convertPriceToPayloadValue(value.maximumSalary),
                seniorityId: value.level,
                cityId: value.city,
            };
        this.props.addPosition(payload);
    };
}

export default connect(
    (state: RootState) => ({
        seniorityLevel: senioritySelector(state),
        city: citiesSelector(state),
        error: positionsPageError(state),
        isPositionAdded: isPositionAddedSelector(state),
    }),
    {addPosition, changePositionAdded}
)(withTranslation()(AddPosition));
