import React, {Component} from 'react';
import LayoutWrapper from '../../LayoutWrapper';
import {Pagination, Translation, WithLocation, withLocation} from 'jobhunter-common-web';
import CandidatesCard from '../CandidatesCard';
import CandidateFilters from './CandidateFilters';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {
    candidatesListSelector,
    candidatesMetadataSelector,
    isCandidatesListLoadingSelector,
} from '../../../store/selectors/candidatesPageSelectors';
import {
    applyCandidatesFilters,
    changeCandidateFilters,
    changeCandidatesPagination,
    fetchCandidatesList,
    ICandidatesFilters,
    resetToInitialCandidatesState,
} from '../../../store/reducers/candidatesPageSlice';
import {IApplicationOffer} from '../../../model/offerDataModel';
import {IModelApiResponseViewObject} from '../../../model/base';

declare type WithLocationProps = typeof WithLocation;

interface IConnectedCandidatesProps {
    readonly candidatesList: IApplicationOffer[] | null;
    readonly candidatesMetadata: IModelApiResponseViewObject | null;
    readonly isLoading: boolean;
    readonly fetchCandidatesList: typeof fetchCandidatesList;
    readonly resetToInitialCandidatesState: typeof resetToInitialCandidatesState;
    readonly changeCandidateFilters: typeof changeCandidateFilters;
    readonly applyCandidatesFilters: typeof applyCandidatesFilters;
    readonly changeCandidatesPagination: typeof changeCandidatesPagination;
}

interface ICandidatesProps extends IConnectedCandidatesProps, WithLocationProps {}

interface ICandidatesState {}

class Candidates extends Component<ICandidatesProps, ICandidatesState> {
    componentDidMount(): void {
        if (this.props.location && this.props.location.state) {
            const offerId = this.props.location.state.offerId;
            const positionId = this.props.location.state.positionId;
            this.changeCandidateFilters({offers: [offerId], positions: positionId});
        } else {
            this.props.fetchCandidatesList();
        }
    }

    componentWillUnmount(): void {
        this.props.resetToInitialCandidatesState();
    }

    render() {
        return (
            <LayoutWrapper>
                <div className="panel-section-header">
                    <Translation text="humanResources.candidates.title" />
                </div>
                <CandidateFilters candidates={this.props.candidatesList} changeCandidateFilters={this.changeCandidateFilters} />
                <CandidatesCard candidates={this.props.candidatesList} isExpandedList={true} isLoading={this.props.isLoading} />
                <Pagination listMetadata={this.props.candidatesMetadata} changePage={this.props.changeCandidatesPagination} />
            </LayoutWrapper>
        );
    }

    private convertToParams = (value: any) => {
        const params: ICandidatesFilters = {
            offer: {
                id: value.offers ? value.offers : null,
                soughtPosition: {
                    position: {
                        name: value.positions ? value.positions : null,
                    },
                },
            },
            candidate: {
                account: {
                    lastName: value.candidates ? value.candidates : null,
                },
            },
        };
        return params;
    };

    private changeCandidateFilters = (value: any) => {
        const params: ICandidatesFilters = this.convertToParams(value);
        this.props.changeCandidateFilters(params);
        this.props.applyCandidatesFilters();
    };
}

export default connect(
    (state: RootState) => ({
        candidatesList: candidatesListSelector(state),
        candidatesMetadata: candidatesMetadataSelector(state),
        isLoading: isCandidatesListLoadingSelector(state),
    }),
    {
        fetchCandidatesList,
        resetToInitialCandidatesState,
        changeCandidateFilters,
        applyCandidatesFilters,
        changeCandidatesPagination,
    }
)(withLocation(Candidates));
