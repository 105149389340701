import {FormControlType, IFormConfig, InputType} from 'jobhunter-common-web';

export const statusFormConfig: typeof IFormConfig = () => {
    return {
        controlType: 'form',
        class: 'default-form',
        dataAccessor: (data: any) => data,
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);
            return previousStateSnapshot;
        },
        controls: [
            {
                key: 'relocation_status',
                controlType: 'group',
                class: 'row',
                controls: {
                    relocationStatus: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.INPUT,
                        validationRules: [],
                        placeholder: 'relocationView.status.form.placeholders.currentStatus',
                        isLabelHidden: false,
                        label: 'relocationView.status.form.labels.currentStatus',
                        type: InputType.TEXT,
                        hostClass: 'col-xl-12 form-control',
                    },
                },
            },
        ],
    };
};
