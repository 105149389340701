import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelBillingInformation} from '../../model/billingDataModel';
import {IUpdateBillingInformationPayload} from '../../api/updateBillingInfoAPI';

export interface ISetBillingInformation {
    billingInformation: IModelBillingInformation | null;
}

export interface IUpdateBillingInformation {
    billingInformation: IUpdateBillingInformationPayload;
}

export interface IChangeBillingInformationLoading {
    isBillingInformationLoading: boolean;
}

export interface IChangeBillingInformationInitialized {
    isBillingInformationInitialized: boolean;
}

export interface IChangeBillingInformationError {
    billingInformationError: string | null;
}

export interface IBillingInformationState {
    billingInformation: IModelBillingInformation | null;
    isBillingInformationLoading: boolean;
    isBillingInformationInitialized: boolean;
    billingInformationError: string | null;
}

const initialState: IBillingInformationState = {
    billingInformation: null,
    isBillingInformationLoading: false,
    isBillingInformationInitialized: false,
    billingInformationError: null,
};
const settingsPageSlice = createSlice({
    name: 'settingsPage',
    initialState: initialState,
    reducers: {
        updateBillingInformation: {
            reducer: (state: IBillingInformationState) => {
                return {
                    ...state,
                    isBillingInformationLoading: true,
                };
            },
            prepare: (billingInformation: IUpdateBillingInformationPayload) => {
                return {
                    payload: {
                        billingInformation: billingInformation,
                    },
                };
            },
        },
        setBillingInformation: {
            reducer: (state: IBillingInformationState, action: PayloadAction<ISetBillingInformation>) => {
                return {
                    ...state,
                    billingInformation: action.payload.billingInformation,
                };
            },
            prepare: (billingInformation: IModelBillingInformation | null) => {
                return {
                    payload: {
                        billingInformation: billingInformation,
                    },
                };
            },
        },
        changeBillingInformationPageLoading: {
            reducer: (state: IBillingInformationState, action: PayloadAction<IChangeBillingInformationLoading>) => {
                return {
                    ...state,
                    isBillingInformationLoading: action.payload.isBillingInformationLoading,
                };
            },
            prepare: (isBillingInformationLoading: boolean) => {
                return {
                    payload: {
                        isBillingInformationLoading: isBillingInformationLoading,
                    },
                };
            },
        },
        changeBillingInformationPageInitialized: {
            reducer: (state: IBillingInformationState, action: PayloadAction<IChangeBillingInformationInitialized>) => {
                return {
                    ...state,
                    isBillingInformationInitialized: action.payload.isBillingInformationInitialized,
                };
            },
            prepare: (isBillingInformationInitialized: boolean) => {
                return {
                    payload: {
                        isBillingInformationInitialized: isBillingInformationInitialized,
                    },
                };
            },
        },
        changeBillingInformationPageError: {
            reducer: (state: IBillingInformationState, action: PayloadAction<IChangeBillingInformationError>) => {
                return {
                    ...state,
                    billingInformationError: action.payload.billingInformationError,
                };
            },
            prepare: (billingInformationError: string | null) => {
                return {
                    payload: {
                        billingInformationError: billingInformationError,
                    },
                };
            },
        },
        fetchBillingInformation: (state: IBillingInformationState) => {
            return {
                ...state,
                isBillingInformationLoading: true,
            };
        },
        resetToInitialBillingInformationState: () => {
            return {
                ...initialState,
            };
        },
    },
});
export const {
    updateBillingInformation,
    setBillingInformation,
    fetchBillingInformation,
    resetToInitialBillingInformationState,
    changeBillingInformationPageError,
    changeBillingInformationPageInitialized,
    changeBillingInformationPageLoading,
} = settingsPageSlice.actions;

export default settingsPageSlice.reducer;
