import React, {Component} from 'react';
import {Form, FormControlChangeType, IMultiselectOption, IFormConfig} from 'jobhunter-common-web';
import {BehaviorSubject, Subscription} from 'rxjs';
import {positionRelatedRecruitersFormConfig} from './positionRelatedRecruitersFormConfig';
import {connect} from 'react-redux';
import {positionRelatedHrListSelector} from '../../../../../store/selectors/positionDetailsSelectors';
import {RootState} from '../../../../../store/reducers';
import {filter, tap} from 'rxjs/operators';
import {IModelPositionRelatedHr} from '../../../../../model/positionsDataModel';
import {WithTranslation, withTranslation} from 'react-i18next';

interface IConnectedPositionRelatedRecruitersProps {
    readonly relatedRecruiters: IModelPositionRelatedHr[] | null;
}

interface IExternalPositionRelatedRecruitersProps {
    onRecruiterChange: (recruiterId: string) => void;
}

interface IPositionRelatedRecruitersProps
    extends IConnectedPositionRelatedRecruitersProps,
        IExternalPositionRelatedRecruitersProps,
        WithTranslation {}

interface IPositionRelatedRecruitersState {
    value: any;
    formConfig: typeof IFormConfig | null;
}

class PositionRelatedRecruiters extends Component<IPositionRelatedRecruitersProps, IPositionRelatedRecruitersState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];
    constructor(props: IPositionRelatedRecruitersProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: null,
        };
    }

    componentDidMount(): void {
        this.setFormConfig();
        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    tap((data: any) => this.onRecruiterChange(data.value))
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: Readonly<IPositionRelatedRecruitersProps>): void {
        if (this.props.relatedRecruiters !== prevProps.relatedRecruiters) {
            this.setFormConfig();
        }
    }

    componentWillUnmount(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <Form
                config={this.state.formConfig}
                value={this.state.value}
                controlName="selectRecruiterForm"
                onValueStateChange={this.onValueStateChange}
            />
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private setFormConfig = () => {
        const selectOptions: typeof IMultiselectOption[] = [];

        if (null !== this.props.relatedRecruiters) {
            this.props.relatedRecruiters.map((organization: IModelPositionRelatedHr) => {
                const {t} = this.props,
                    organizationName = organization.name ? organization.name : t('employer.cooperatingHr.table.noCompanyName');

                return selectOptions.push({
                    value: organization.hrDepartmentId,
                    label: organizationName,
                });
            });
        }

        const formConfig = positionRelatedRecruitersFormConfig(selectOptions);
        this.setState({formConfig});
    };

    private onRecruiterChange = (value: {[key: string]: any}) => {
        // if (value.recruiter && value.recruiter === 'add_new_recruiter') {
        //     console.log('Add new recruiter');
        // }

        this.setState({value});
        this.props.onRecruiterChange(value.recruiter);
    };
}

export default connect((state: RootState) => ({
    relatedRecruiters: positionRelatedHrListSelector(state),
}))(withTranslation()(PositionRelatedRecruiters));
