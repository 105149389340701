import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelApiObject} from '../../model/base';
import {IModelDictionaryDatum, IModelSeniority} from 'jobhunter-common-web';
import {IOfferItem} from '../../model/offerDataModel';
import {IAddOfferPayload} from '../../api/addOfferAPI';
import {IModelRecruitment} from '../../model/recruitmentDataModel';

export interface IOfferDescription extends IModelApiObject {
    description: string;
    id: string;
}

export interface IOfferTechnology extends IModelApiObject {
    id: string;
    seniority: typeof IModelSeniority;
    technology: typeof IModelDictionaryDatum;
}

export interface IOfferTechnologyTool extends IModelApiObject {
    id: string;
    seniority: typeof IModelSeniority;
    technologyTool: typeof IModelDictionaryDatum;
}

export interface IOfferDetails extends IModelApiObject {
    offer: IOfferItem;
    offerDescription: IOfferDescription;
    technologies: IOfferTechnology[];
    technologyTools: IOfferTechnologyTool[];
}

export interface IOfferViewState {
    offerDetails: IOfferDetails | null;
    offerRelatedPosition: IModelRecruitment | null;
    isOfferViewLoading: boolean;
    isOfferViewInitialized: boolean;
    offerViewError: string | null;
}

export interface ISetOfferDetails {
    offer: any | null;
}

export interface IChangeIsOfferViewLoading {
    isOfferViewLoading: boolean;
}

export interface IChangeIsOfferViewInitialized {
    isOfferViewInitialized: boolean;
}

export interface IChangeOfferViewError {
    error: string | null;
}

export interface IFetchOffer {
    offerId: string;
}

export interface IAddOffer {
    offerPayload: IAddOfferPayload;
}

export interface IUpdateOffer {
    offerId: string;
    offerPayload: IAddOfferPayload;
}

export interface IFetchOfferRelatedPosition {
    soughtPositionId: string;
}

export interface ISetOfferRelatedPosition {
    offerRelatedPosition: IModelRecruitment | null;
}

const initialState: IOfferViewState = {
    offerDetails: null,
    offerRelatedPosition: null,
    isOfferViewLoading: false,
    isOfferViewInitialized: false,
    offerViewError: null,
};

const offerViewPageSlice = createSlice({
    name: 'offerViewPage',
    initialState: initialState,
    reducers: {
        setOfferDetails: {
            reducer: (state: IOfferViewState, action: PayloadAction<ISetOfferDetails>) => {
                return {
                    ...state,
                    offerDetails: action.payload.offer,
                };
            },
            prepare(offer: any | null) {
                return {
                    payload: {
                        offer: offer,
                    },
                };
            },
        },
        changeIsOfferViewLoading: {
            reducer: (state: IOfferViewState, action: PayloadAction<IChangeIsOfferViewLoading>) => {
                return {
                    ...state,
                    isOfferViewLoading: action.payload.isOfferViewLoading,
                };
            },
            prepare(isOfferViewLoading: boolean) {
                return {
                    payload: {isOfferViewLoading: isOfferViewLoading},
                };
            },
        },
        changeIsOfferViewInitialized: {
            reducer: (state: IOfferViewState, action: PayloadAction<IChangeIsOfferViewInitialized>) => {
                return {
                    ...state,
                    isOfferViewInitialized: action.payload.isOfferViewInitialized,
                };
            },
            prepare(isOfferViewInitialized: boolean) {
                return {
                    payload: {isOfferViewInitialized: isOfferViewInitialized},
                };
            },
        },
        changeOfferViewError: {
            reducer: (state: IOfferViewState, action: PayloadAction<IChangeOfferViewError>) => {
                return {
                    ...state,
                    offersError: action.payload.error,
                };
            },
            prepare(error: any) {
                return {
                    payload: {error: error},
                };
            },
        },
        fetchOfferDetails: {
            reducer: (state: IOfferViewState) => {
                return {
                    ...state,
                    isOfferViewLoading: true,
                };
            },
            prepare(offerId: string) {
                return {
                    payload: {offerId: offerId},
                };
            },
        },
        addOffer: {
            reducer: (state: IOfferViewState) => {
                return {
                    ...state,
                    isOfferViewLoading: true,
                };
            },
            prepare(offerPayload: IAddOfferPayload) {
                return {
                    payload: {offerPayload: offerPayload},
                };
            },
        },
        updateOffer: {
            reducer: (state: IOfferViewState) => {
                return {
                    ...state,
                    isOfferViewLoading: true,
                };
            },
            prepare(offerId: string, offerPayload: IAddOfferPayload) {
                return {
                    payload: {offerId: offerId, offerPayload: offerPayload},
                };
            },
        },
        setOfferRelatedPosition: {
            reducer: (state: IOfferViewState, action: PayloadAction<ISetOfferRelatedPosition>) => {
                return {
                    ...state,
                    offerRelatedPosition: action.payload.offerRelatedPosition,
                };
            },
            prepare(offerRelatedPosition: IModelRecruitment | null) {
                return {
                    payload: {offerRelatedPosition: offerRelatedPosition},
                };
            },
        },
        fetchOfferRelatedPosition: {
            reducer: (state: IOfferViewState) => {
                return {
                    ...state,
                };
            },
            prepare(soughtPositionId: string) {
                return {
                    payload: {soughtPositionId: soughtPositionId},
                };
            },
        },
        resetToInitialOfferViewPageState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setOfferDetails,
    changeIsOfferViewLoading,
    changeIsOfferViewInitialized,
    changeOfferViewError,
    fetchOfferDetails,
    addOffer,
    updateOffer,
    setOfferRelatedPosition,
    fetchOfferRelatedPosition,
    resetToInitialOfferViewPageState,
} = offerViewPageSlice.actions;

export default offerViewPageSlice.reducer;
