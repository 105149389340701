import React, {Component} from 'react';
import {ListItemDetails, Tooltip, Translation, WithNavigate, withNavigation} from 'jobhunter-common-web';
import {Badge, Button, Table} from 'reactstrap';
import {Edit3, Eye, Trash2} from 'react-feather';
import styles from './styles.module.scss';
import {WithTranslation, withTranslation} from 'react-i18next';
import {ISoughtPosition} from '../../../../../model/positionsDataModel';
import CloseOfferModal, {CloseOfferModalType} from '../../../../HumanResources/Offers/CloseOfferModal';
import {IOfferItem, OfferStatus} from '../../../../../model/offerDataModel';

interface IExternalOffersListProps {
    readonly offersList: ISoughtPosition[] | null;
    readonly isOrganizationHeadhunter: boolean;
}

interface IConnectedOffersListProps {}

declare type WithNavigationProps = typeof WithNavigate;

interface IOffersListProps extends WithTranslation, WithNavigationProps, IExternalOffersListProps, IConnectedOffersListProps {}

interface IOffersListState {
    isDeleteModalShown: boolean;
    selectedOffer: IOfferItem | null;
}

class OffersList extends Component<IOffersListProps, IOffersListState> {
    constructor(props: IOffersListProps) {
        super(props);

        this.state = {
            isDeleteModalShown: false,
            selectedOffer: null,
        };
    }

    render() {
        return (
            <div>
                {this.renderOffersList()}
                {this.state.isDeleteModalShown ? (
                    <CloseOfferModal
                        isModalOpen={this.state.isDeleteModalShown}
                        toggleModal={this.toggleDeleteOfferModal}
                        type={CloseOfferModalType.REMOVE_OFFER}
                        offer={this.state.selectedOffer}
                    />
                ) : null}
            </div>
        );
    }

    private renderOffersList = () => {
        return (
            <div>
                <Table striped responsive className="position-offers-table">
                    <thead>
                        <tr>
                            <th>
                                <Translation text="employer.positions.positionDetails.offers.table.hr" />
                            </th>
                            <th>
                                <Translation text="employer.positions.positionDetails.offers.table.candidates" />
                            </th>
                            <th>
                                <Translation text="employer.positions.positionDetails.offers.table.status" />
                            </th>
                            <th>
                                <Translation text="employer.positions.positionDetails.offers.table.actions" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>{this.renderTableRows()}</tbody>
                </Table>
            </div>
        );
    };

    private renderTableRows() {
        const {t} = this.props;
        if (!this.props.offersList || !this.props.offersList.length || !this.props.offersList[0].offers.length) {
            return (
                <tr key={'no-data'}>
                    <td colSpan={4}>
                        <p>
                            <Translation text="employer.positions.positionDetails.offers.table.noData" />
                        </p>
                    </td>
                </tr>
            );
        }

        if (this.props.offersList) {
            return this.props.offersList.map((item: ISoughtPosition) => {
                const offerStatus = item.offers[0]?.offer.status,
                    status =
                        offerStatus === OfferStatus.COMPLETED
                            ? 'light-success'
                            : offerStatus === OfferStatus.DRAFT
                            ? 'light-primary'
                            : offerStatus === OfferStatus.ACTIVE
                            ? 'light-info'
                            : 'light-warning';

                return (
                    <tr key={item.id}>
                        <td>
                            <ListItemDetails
                                avatar={item.organization.organizationCompany.avatar}
                                name={item.organization.organizationCompany.name}
                            />
                        </td>
                        <td>
                            <div className={styles.candidates}>
                                {item.offers[0]?.offer.candidatesCount ? item.offers[0]?.offer.candidatesCount : '0'}

                                <Button
                                    disabled={
                                        item.offers[0]?.offer.candidatesCount === 0 ||
                                        offerStatus === OfferStatus.CANCELLED ||
                                        !item.offers[0]?.offer.candidatesCount
                                    }
                                    color="flat-primary"
                                    onClick={() => this.showOffers(item.offers[0]?.id)}
                                    className={`${styles.navLink} view-candidates-button`}>
                                    <p>
                                        <Translation text="employer.positions.positionDetails.offers.table.view" />
                                    </p>
                                </Button>
                            </div>
                        </td>
                        <td>
                            <Badge pill color={status}>
                                {item.offers[0]?.offer.status}
                            </Badge>
                        </td>
                        <td>
                            <div className={styles.actions}>
                                <Button
                                    color="flat-primary"
                                    className="view-offer-button"
                                    onClick={() => this.showDetails(item.offers[0]?.id)}
                                    disabled={!item.offers[0]?.id}>
                                    <Eye id={`view_${item.id}`} />
                                    <Tooltip
                                        target={`view_${item.id}`}
                                        tooltipText={t('employer.positions.positionDetails.offers.tooltips.view')}
                                    />
                                </Button>
                                {this.props.isOrganizationHeadhunter ? (
                                    <Button
                                        className={`${styles.button} edit-offer-button`}
                                        color="flat-primary"
                                        onClick={() => this.editOffer(item.offers[0]?.id)}
                                        disabled={!item.offers[0]?.offer}>
                                        <Edit3 id={`edit`} size={24} />
                                        <Tooltip target={`edit`} tooltipText={t('employer.positions.positionDetails.tooltips.edit')} />
                                    </Button>
                                ) : null}
                                <Button
                                    color="flat-primary"
                                    className="remove-offer-button"
                                    onClick={() => this.toggleDeleteOfferModal(item.offers[0]?.offer)}
                                    disabled={!item.offers[0]?.offer}>
                                    <Trash2 id={`delete_${item.id}`} />
                                    <Tooltip
                                        target={`delete_${item.id}`}
                                        tooltipText={t('employer.positions.positionDetails.offers.tooltips.delete')}
                                    />
                                </Button>
                            </div>
                        </td>
                    </tr>
                );
            });
        }
    }

    private toggleDeleteOfferModal = (item?: IOfferItem | null) => {
        const selectedOffer = undefined === item ? null : item;
        this.setState({isDeleteModalShown: !this.state.isDeleteModalShown, selectedOffer: selectedOffer});
    };

    private showDetails = (offerId: string) => {
        this.props.navigate(`/panel/human_resources/offers/${offerId}`);
    };

    private showOffers = (offerId: string) => {
        this.props.navigate(`/panel/human_resources/candidates`, {state: {offerId}});
    };

    private editOffer = (offerId: string) => {
        this.props.navigate(`/panel/human_resources/edit-offer/${offerId}`);
    };
}

export default withTranslation()(withNavigation(OffersList));
