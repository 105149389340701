import React, {Component} from 'react';
import {DateComponent, ListItemDetails, Loader, LoaderType, Tooltip, Translation, WithNavigate, withNavigation} from 'jobhunter-common-web';
import {Button, Card, CardBody, CardTitle, Table} from 'reactstrap';
import {Clipboard, MessageCircle, Search, UserCheck, UserPlus, Users, UserX, Video} from 'react-feather';
import {WithTranslation, withTranslation} from 'react-i18next';
import AddRecruiter from './AddRecruiterModal';
import AcceptCandidate, {CandidateModalType} from './AcceptCandidate';
import RejectCandidate from './RejectCandidate';
import {IApplicationOffer, IConsultation, OfferStatus} from '../../../model/offerDataModel';

declare type WithNavigationProps = typeof WithNavigate;

interface IConnectedCandidateCardProps {}

interface IExternalCandidateCardProps {
    isLoading: boolean;
    candidates: IApplicationOffer[] | null;
    isExpandedList: boolean;
}

interface ICandidatesCardProps extends WithNavigationProps, WithTranslation, IConnectedCandidateCardProps, IExternalCandidateCardProps {}

interface ICandidatesCardState {
    isAddRecruiterModalShown: boolean;
    isAcceptCandidateModalShown: boolean;
    isRejectCandidateModalShown: boolean;
    selectedCandidate: IApplicationOffer | null;
    selectedType: CandidateModalType;
}

class CandidatesCard extends Component<ICandidatesCardProps, ICandidatesCardState> {
    constructor(props: ICandidatesCardProps) {
        super(props);

        this.state = {
            isAddRecruiterModalShown: false,
            isAcceptCandidateModalShown: false,
            isRejectCandidateModalShown: false,
            selectedCandidate: null,
            selectedType: CandidateModalType.ACCEPT,
        };
    }

    render() {
        return (
            <>
                <Card className="candidate-card">
                    <CardTitle>
                        <div className="card-header">
                            <div>
                                <Users size={20} />
                                <Translation text="humanResources.dashboard.candidates.title" />
                            </div>
                            {!this.props.isExpandedList ? (
                                <button className="btn btn-primary" onClick={() => this.redirectToCandidates()}>
                                    <Translation text="buttons.showAll" />
                                </button>
                            ) : null}
                        </div>
                    </CardTitle>

                    <CardBody>
                        <Table responsive className="candidate-table">
                            <thead>
                                <tr>
                                    <th>
                                        <Translation text="humanResources.dashboard.candidates.table.candidate" />
                                    </th>
                                    <th>
                                        <Translation text="humanResources.dashboard.candidates.table.interview" />
                                    </th>
                                    {this.props.isExpandedList ? (
                                        <th>
                                            <Translation text="humanResources.dashboard.candidates.table.offer" />
                                        </th>
                                    ) : null}
                                    {this.props.isExpandedList ? (
                                        <th>
                                            <Translation text="humanResources.dashboard.candidates.table.position" />
                                        </th>
                                    ) : null}
                                    <th className="text-center">
                                        <Translation text="humanResources.dashboard.candidates.table.actions" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>{this.renderCandidatesTableRows()}</tbody>
                        </Table>
                    </CardBody>
                    <Loader type={LoaderType.Local} showLoader={this.props.isLoading} />
                </Card>

                {this.state.isAddRecruiterModalShown ? (
                    <AddRecruiter
                        isModalOpen={this.state.isAddRecruiterModalShown}
                        toggleModal={this.toggleAddRecruiterModal}
                        candidate={this.state.selectedCandidate}
                    />
                ) : null}

                {this.state.isAcceptCandidateModalShown ? (
                    <AcceptCandidate
                        isModalOpen={this.state.isAcceptCandidateModalShown}
                        toggleModal={this.toggleAcceptCandidateModal}
                        candidate={this.state.selectedCandidate}
                        type={this.state.selectedType}
                    />
                ) : null}

                {this.state.isRejectCandidateModalShown ? (
                    <RejectCandidate
                        isModalOpen={this.state.isRejectCandidateModalShown}
                        toggleModal={this.toggleRejectCandidateModal}
                        candidate={this.state.selectedCandidate}
                    />
                ) : null}
            </>
        );
    }

    private renderCandidatesTableRows() {
        const {t} = this.props;
        if (undefined === this.props.candidates || null === this.props.candidates || this.props.candidates.length === 0) {
            return (
                <tr key={`no-data`}>
                    <td colSpan={5}>
                        <p>
                            <Translation text="humanResources.dashboard.candidates.table.noCandidates" />
                        </p>
                    </td>
                </tr>
            );
        }

        return this.props.candidates.map((item: IApplicationOffer) => {
            const isOfferPosition = item.offerOwnerOrganizationId === null && item.positionOwnerOrganizationId !== null;
            return (
                <tr key={`matches_${item.applicationId}`}>
                    <td>{this.renderDescription(item)}</td>
                    <td>
                        {item.consultation !== null ? <DateComponent date={item.consultation?.startsAt} format="D/MM/Y, HH:mm" /> : null}

                        <Button
                            color="flat-primary"
                            onClick={() => this.startInterview(item?.consultation)}
                            className="join-consultation-button"
                            disabled={item.consultation === null}>
                            <Video id={`consultation_${item.applicationId}`} size={20} />
                            <Tooltip
                                target={`consultation_${item.applicationId}`}
                                tooltipText={t('humanResources.dashboard.candidates.tooltips.consultation')}
                            />
                        </Button>

                        <Button
                            color="flat-primary"
                            onClick={() => this.toggleAddRecruiterModal(item)}
                            className="add-recruiter-button"
                            disabled={item.consultation !== null || isOfferPosition}>
                            <UserPlus id={`add_recruiter_${item.applicationId}`} size={20} color="#28C76F" />
                            <Tooltip
                                target={`add_recruiter_${item.applicationId}`}
                                tooltipText={t('humanResources.dashboard.candidates.tooltips.addRecruiter')}
                            />
                        </Button>
                    </td>
                    {this.props.isExpandedList ? (
                        <td>
                            <p>{item.name}</p>
                        </td>
                    ) : null}
                    {this.props.isExpandedList ? (
                        <td>
                            <p>{item.positionName}</p>
                        </td>
                    ) : null}
                    <td className="d-flex flex-wrap justify-content-center">
                        <Button color="flat-primary" className="view-candidate-button" onClick={() => this.viewCandidate(item)}>
                            <Search id={`view_${item.applicationId}`} size={20} />
                            <Tooltip
                                target={`view_${item.applicationId}`}
                                tooltipText={t('humanResources.dashboard.candidates.tooltips.candidate')}
                            />
                        </Button>

                        <Button color="flat-primary" className="view-recruitment-button" onClick={() => this.viewRecruitment(item)}>
                            <Clipboard id={`view_offer_${item.applicationId}`} size={20} />
                            <Tooltip
                                target={`view_offer_${item.applicationId}`}
                                tooltipText={t('humanResources.dashboard.candidates.tooltips.offerDetails')}
                            />
                        </Button>

                        <div id={`accept_candidate_${item.applicationId}`}>
                            <Button
                                color="flat-primary"
                                disabled={isOfferPosition || item.isHired}
                                className="accept-candidate-button"
                                onClick={() => this.toggleAcceptCandidateModal(item, CandidateModalType.ACCEPT)}>
                                <MessageCircle size={20} color="#28C76F" />
                                <Tooltip
                                    target={`accept_candidate_${item.applicationId}`}
                                    tooltipText={t('humanResources.dashboard.candidates.tooltips.acceptCandidate')}
                                />
                            </Button>
                        </div>

                        <Button
                            color="flat-primary"
                            disabled={isOfferPosition || item.isHired}
                            className="reject-candidate-button"
                            onClick={() => this.toggleRejectCandidateModal(item)}>
                            <UserX id={`reject_${item.applicationId}`} size={20} color="#EA5455" />
                            <Tooltip
                                target={`reject_${item.applicationId}`}
                                tooltipText={t('humanResources.dashboard.candidates.tooltips.reject')}
                            />
                        </Button>

                        <div id={`set_hired_${item.applicationId}`}>
                            <Button
                                color="flat-primary"
                                className="hire-candidate-button"
                                onClick={() => this.toggleAcceptCandidateModal(item, CandidateModalType.HIRE)}
                                disabled={item.offerStatus !== OfferStatus.CLOSED || isOfferPosition || item.isHired}>
                                <UserCheck size={20} color="#28C76F" />
                                <Tooltip
                                    target={`set_hired_${item.applicationId}`}
                                    tooltipText={t('humanResources.dashboard.candidates.tooltips.hireCandidate', {
                                        additionalInformation: t(
                                            item.offerStatus !== OfferStatus.CLOSED
                                                ? 'humanResources.dashboard.candidates.tooltips.additionalInformation'
                                                : ''
                                        ),
                                    })}
                                />
                            </Button>
                        </div>
                    </td>
                </tr>
            );
        });
    }

    private renderDescription = (item: IApplicationOffer) => {
        const {t} = this.props,
            candidateAccount = item.candidate.account,
            candidateName =
                candidateAccount.firstName && candidateAccount.firstName
                    ? `${candidateAccount.firstName} ${candidateAccount.lastName}`
                    : t('humanResources.dashboard.candidates.table.unknownCandidate');

        return <ListItemDetails avatar={candidateAccount.avatar} name={candidateName} iconStyles={'candidate-icon'} />;
    };

    private redirectToCandidates = () => {
        this.props.navigate('/panel/human_resources/candidates');
    };

    private startInterview = (item: IConsultation | null) => {
        this.props.navigate(`/panel/consultations/${item?.id}`);
    };

    private viewCandidate = (item: IApplicationOffer) => {
        this.props.navigate(`/panel/human_resources/candidates/${item.applicationId}`);
    };

    private viewRecruitment = (item: IApplicationOffer) => {
        this.props.navigate(`/panel/human_resources/offers/${item.offerId}`);
    };

    private toggleAcceptCandidateModal = (item: IApplicationOffer | null, type?: CandidateModalType) => {
        this.setState({
            isAcceptCandidateModalShown: !this.state.isAcceptCandidateModalShown,
            selectedCandidate: item,
            selectedType: type ? type : CandidateModalType.ACCEPT,
        });
    };

    private toggleRejectCandidateModal = (item: IApplicationOffer | null) => {
        this.setState({
            isRejectCandidateModalShown: !this.state.isRejectCandidateModalShown,
            selectedCandidate: item,
        });
    };

    private toggleAddRecruiterModal = (item: IApplicationOffer | null) => {
        this.setState({
            isAddRecruiterModalShown: !this.state.isAddRecruiterModalShown,
            selectedCandidate: item,
        });
    };
}

export default withTranslation()(withNavigation(CandidatesCard));
