import React, {ChangeEvent, Component} from 'react';
import LayoutWrapper from '../../LayoutWrapper';
import {Badge, Button, Card, CardBody, Table} from 'reactstrap';
import {Search, Edit2, FilePlus, FileMinus} from 'react-feather';
import {
    DateComponent,
    Tooltip,
    Translation,
    WithNavigate,
    withNavigation,
    LoaderType,
    Loader,
    WithLocation,
    withLocation,
    Pagination,
} from 'jobhunter-common-web';
import styles from './styles.module.scss';
import {WithTranslation, withTranslation} from 'react-i18next';
import AddPosition from './AddPosition';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {IModelPositionsInformation, PositionStatus} from '../../../model/positionsDataModel';
import {isPositionsPageLoading, positionsListSelector, positionsMetadataSelector} from '../../../store/selectors/positionSelectors';
import {
    applyPositionFilters,
    changePositionFilters,
    changePositionsPagination,
    fetchPositionsList,
    IPositionFilters,
    resetToInitialPositionPageState,
} from '../../../store/reducers/positionsPageSlice';
import ChangePositionStatus from './PositionDetails/ChangePositionStatus';
import SwitchFilter from './SwitchFilter';
import {IModelApiResponseViewObject} from '../../../model/base';

declare type WithNavigationProps = typeof WithNavigate;
declare type WithLocationProps = typeof WithLocation;

interface IConnectedPositionsProps {
    readonly positionsList: IModelPositionsInformation[];
    readonly positionsMetadata: IModelApiResponseViewObject | null;
    readonly isPositionsLoading: boolean;
    readonly fetchPositionsList: typeof fetchPositionsList;
    readonly resetToInitialPositionPageState: typeof resetToInitialPositionPageState;
    readonly changePositionFilters: typeof changePositionFilters;
    readonly applyPositionFilters: typeof applyPositionFilters;
    readonly changePositionsPagination: typeof changePositionsPagination;
}

interface IPositionsProps extends WithTranslation, WithNavigationProps, IConnectedPositionsProps, WithLocationProps {}

interface IPositionsState {
    selectedPosition: IModelPositionsInformation | null;
    isChangePositionStatusModalShown: boolean;
    isAddPositionModalVisible: boolean;
    isChecked: boolean;
}

class Positions extends Component<IPositionsProps, IPositionsState> {
    constructor(props: IPositionsProps) {
        super(props);

        this.state = {
            selectedPosition: null,
            isChangePositionStatusModalShown: false,
            isAddPositionModalVisible: false,
            isChecked: false,
        };
    }

    componentDidMount(): void {
        if (this.props.location && this.props.location.state) {
            this.setState({isChecked: this.props.location.state.hasOffers});
        }
        const params = this.props.location.state ? this.props.location.state : null;
        this.props.fetchPositionsList(params);
    }

    componentWillUnmount() {
        this.props.resetToInitialPositionPageState();
    }

    render() {
        return (
            <>
                <LayoutWrapper>
                    <div className={styles.header}>
                        <div className="panel-section-header">
                            <Translation text="employer.positions.title" />
                        </div>

                        <Button color="primary" className="add-position-button" onClick={() => this.toggleAddPosition()}>
                            <Translation text="employer.positions.add" />
                        </Button>
                    </div>
                    <Card className="positions-card">
                        <CardBody>
                            <div className="card-header justify-content-end">
                                <SwitchFilter
                                    name={'hasOffers'}
                                    isChecked={this.state.isChecked}
                                    changeFilters={this.changeFilter}
                                    label={'employer.positions.hasOffers'}
                                />
                            </div>
                            {this.renderPositions()}
                        </CardBody>
                    </Card>
                    <Pagination listMetadata={this.props.positionsMetadata} changePage={this.props.changePositionsPagination} />

                    <Loader type={LoaderType.Local} showLoader={this.props.isPositionsLoading} />
                </LayoutWrapper>

                {this.state.isAddPositionModalVisible ? (
                    <AddPosition isModalOpen={this.state.isAddPositionModalVisible} toggleModal={this.toggleAddPosition} />
                ) : null}

                {this.state.isChangePositionStatusModalShown ? (
                    <ChangePositionStatus
                        isModalOpen={this.state.isChangePositionStatusModalShown}
                        toggleModal={this.toggleStatusModal}
                        position={this.state.selectedPosition}
                    />
                ) : null}
            </>
        );
    }

    private renderPositions = () => {
        return (
            <Table responsive striped={true} className="positions-table">
                <thead>
                    <tr>
                        <th>
                            <Translation text="employer.positions.table.position" />
                        </th>
                        <th>
                            <Translation text="employer.positions.table.description" />
                        </th>
                        <th>
                            <Translation text="employer.positions.table.created" />
                        </th>
                        <th>
                            <Translation text="employer.positions.table.offers" />
                        </th>
                        <th>
                            <Translation text="employer.positions.table.status" />
                        </th>
                        <th>
                            <Translation text="employer.positions.table.actions" />
                        </th>
                    </tr>
                </thead>
                <tbody>{this.renderTableRows()}</tbody>
            </Table>
        );
    };

    private renderTableRows() {
        const {t} = this.props,
            positions: IModelPositionsInformation[] = this.props.positionsList;
        if (null === positions || positions.length <= 0) {
            return (
                <tr key={'no-data'}>
                    <td colSpan={6}>
                        <p>
                            <Translation text="employer.positions.table.noPositions" />
                        </p>
                    </td>
                </tr>
            );
        }

        return this.props.positionsList.map((item: any) => {
            const tooltipText =
                item.status === PositionStatus.ACTIVE
                    ? 'employer.positions.tooltips.closePosition'
                    : 'employer.positions.tooltips.openPosition';

            return (
                <tr key={item.id}>
                    <td>
                        <p className="fw-bold">{item.name}</p>
                    </td>
                    <td>{item.description}</td>
                    <td>
                        <DateComponent date={item.createdAt} />
                    </td>
                    <td>
                        <p className="">{item.offersCount}</p>
                    </td>
                    <td>
                        <Badge color={item.status === PositionStatus.ACTIVE ? 'light-success' : 'light-danger'} pill>
                            <span className="align-middle">{item.status}</span>
                        </Badge>
                    </td>
                    <td>
                        <div className={styles.actions}>
                            <Button color="flat-primary" className="edit-position-button" onClick={() => this.redirectToEdit(item)}>
                                <Edit2 id={`edit_${item.id}`} size={20} />
                                <Tooltip target={`edit_${item.id}`} tooltipText={t('employer.positions.tooltips.editPosition')} />
                            </Button>
                            <Button color="flat-primary" className="view-position-button" onClick={() => this.redirectToDetails(item)}>
                                <Search id={`details_${item.id}`} size={20} />
                                <Tooltip target={`details_${item.id}`} tooltipText={t('employer.positions.tooltips.details')} />
                            </Button>
                            <Button
                                color="flat-primary"
                                className="change-position-status-button"
                                onClick={() => this.toggleStatusModal(item)}>
                                <span id={`change_status_${item.id}`}>
                                    {item.status === PositionStatus.ACTIVE ? <FileMinus size={20} /> : <FilePlus size={20} />}
                                </span>
                                <Tooltip target={`change_status_${item.id}`} tooltipText={t(tooltipText)} />
                            </Button>
                        </div>
                    </td>
                </tr>
            );
        });
    }

    private changeFilter = (e: ChangeEvent<HTMLInputElement>) => {
        const hasOffers = e.target.checked,
            params: IPositionFilters = {hasOffers: hasOffers.toString()};
        this.props.changePositionFilters(params);
        this.props.applyPositionFilters();
    };

    private redirectToDetails = (item: {[key: string]: any}) => {
        this.props.navigate(`/panel/employer/positions/${item.id}`);
    };

    private redirectToEdit = (item: {[key: string]: any}) => {
        this.props.navigate(`/panel/employer/positions/edit/${item.id}`);
    };

    private toggleAddPosition = () => {
        this.setState({isAddPositionModalVisible: !this.state.isAddPositionModalVisible});
    };

    private toggleStatusModal = (item?: IModelPositionsInformation | null) => {
        const position = item ? item : null;
        this.setState({
            isChangePositionStatusModalShown: !this.state.isChangePositionStatusModalShown,
            selectedPosition: position,
        });
    };
}

export default connect(
    (state: RootState) => ({
        positionsList: positionsListSelector(state),
        isPositionsLoading: isPositionsPageLoading(state),
        positionsMetadata: positionsMetadataSelector(state),
    }),
    {
        fetchPositionsList,
        resetToInitialPositionPageState,
        changePositionFilters,
        applyPositionFilters,
        changePositionsPagination,
    }
)(withTranslation()(withLocation(withNavigation(Positions))));
