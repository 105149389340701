import {Translation} from 'jobhunter-common-web';
import {Component} from 'react';
import {Button, Modal, ModalBody, ModalHeader} from 'reactstrap';
import {IVerificationFile} from '../../../../../store/reducers/employerPageSlice';

interface IRemoveVerificationDocumentProps {
    isModalOpen: boolean;
    selectedVerificationDocument: IVerificationFile | null;
    removeVerificationDocument: (verificationDocumentId: string) => void;
    toggleRemoveModal: () => void;
}

class RemoveVerificationDocument extends Component<IRemoveVerificationDocumentProps> {
    render() {
        const fileName = this.props.selectedVerificationDocument?.file?.originalFileName
            ? this.props.selectedVerificationDocument.file.originalFileName
            : this.props.selectedVerificationDocument?.fileName;

        return (
            <Modal
                contentClassName="add-verification-file-modal"
                isOpen={this.props.isModalOpen}
                toggle={() => this.props.toggleRemoveModal()}>
                <ModalHeader toggle={() => this.props.toggleRemoveModal()}>
                    <Translation text="employer.verification.removeDocument.title" />
                </ModalHeader>
                <ModalBody>
                    <div className="modal-description remove-verification">
                        <p className="mb-2">
                            <Translation text="employer.verification.removeDocument.description" />
                        </p>
                        <span className="modal-description-document-name">{fileName}</span>
                    </div>
                    <div className="wizard-actions">
                        <Button color="primary" className="btn-prev close-modal-button" onClick={() => this.props.toggleRemoveModal()}>
                            <span className="align-middle d-sm-inline-block d-none">
                                <Translation text="buttons.cancel" />
                            </span>
                        </Button>
                        <Button
                            color="danger"
                            className="btn-next remove-verification-file-button"
                            onClick={() => this.removeVerificationDocument(this.props.selectedVerificationDocument?.file?.id)}>
                            <span className="align-middle d-sm-inline-block d-none">
                                <Translation text="buttons.remove" />
                            </span>
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private removeVerificationDocument = (verificationDocumentId: string | undefined) => {
        if (!verificationDocumentId) return;
        this.props.removeVerificationDocument(verificationDocumentId);
    };
}

export default RemoveVerificationDocument;
