import React, {Component} from 'react';
import {
    authUserRolesSelector,
    ITimelineItem,
    Timeline,
    Translation,
    UserRole,
    WithNavigate,
    withNavigation,
    usernameSelector,
} from 'jobhunter-common-web';
import {Card, CardBody, CardTitle} from 'reactstrap';
import {Calendar, User} from 'react-feather';
import EventCalendar from './EventCalendar';
import LayoutWrapper from '../LayoutWrapper';
import {connect} from 'react-redux';
import {
    fetchCalendarDetails,
    fetchOfferApplications,
    fetchOffers,
    fetchPositionsWithOffers,
    fetchPositionsWithoutOffers,
    fetchProfileDetails,
    IPosition,
    IProfileCompletion,
    resetToInitialDashboardPageState,
} from '../../store/reducers/dashboardPageSlice';
import {RootState} from '../../store/reducers';
import {
    isDashboardLoadingSelector,
    offerApplicationsSelector,
    offersSelector,
    positionsWithOffersSelector,
    positionsWithoutOffersSelector,
    calendarDetailsSelector,
    profileDetailsSelector,
} from '../../store/selectors/dashboardSelectors';
import PositionsList, {PositionOfferType} from './PositionsList';
import OfferApplications from './OfferApplications';
import DashboardOffers from './DashboardOffers';
import {ICalendarEvent} from '../../model/calendarEventModel';
import {IApplicationOffer, IOfferItem} from '../../model/offerDataModel';

declare type WithNavigationProps = typeof WithNavigate;

interface IConnectedDashboardProps {
    readonly userRoles: typeof UserRole | null;
    readonly positionsWithOffers: IPosition[] | null;
    readonly positionsWithoutOffers: IPosition[] | null;
    readonly offerApplications: IApplicationOffer[] | null;
    readonly offers: IOfferItem[] | null;
    readonly profileDetails: IProfileCompletion | null;
    readonly isDashboardLoading: boolean;
    readonly fetchPositionsWithOffers: typeof fetchPositionsWithOffers;
    readonly fetchPositionsWithoutOffers: typeof fetchPositionsWithoutOffers;
    readonly fetchOfferApplications: typeof fetchOfferApplications;
    readonly fetchOffers: typeof fetchOffers;
    readonly fetchProfileDetails: typeof fetchProfileDetails;
    readonly resetToInitialDashboardPageState: typeof resetToInitialDashboardPageState;
    readonly username: string;
    readonly calendarEvents: ICalendarEvent[] | null;
    readonly fetchCalendarDetails: typeof fetchCalendarDetails;
}

interface IDashboardProps extends IConnectedDashboardProps, WithNavigationProps {}

class Dashboard extends Component<IDashboardProps, any> {
    componentDidMount() {
        if (this.props.userRoles) {
            if (!this.isOrganizationHeadHunter()) {
                this.props.fetchPositionsWithOffers();
                this.props.fetchPositionsWithoutOffers();
            }

            if (this.isOrganizationHeadHunter()) {
                this.props.fetchOfferApplications();
                this.props.fetchOffers();
            }
        }
        this.props.fetchCalendarDetails();
        this.props.fetchProfileDetails();
    }

    componentWillUnmount() {
        this.props.resetToInitialDashboardPageState();
    }

    render() {
        return (
            <LayoutWrapper>
                <div className="row">
                    <div className="col-lg-8 col-12">
                        {!this.isOrganizationHeadHunter() ? (
                            <PositionsList
                                positions={this.props.positionsWithoutOffers}
                                positionOfferType={PositionOfferType.POSITION_WITHOUT_OFFER}
                                isLoading={this.props.isDashboardLoading}
                            />
                        ) : (
                            <OfferApplications offerApplications={this.props.offerApplications} />
                        )}

                        {!this.isOrganizationHeadHunter() ? (
                            <PositionsList
                                positions={this.props.positionsWithOffers}
                                positionOfferType={PositionOfferType.POSITION_WITH_OFFER}
                                isLoading={this.props.isDashboardLoading}
                            />
                        ) : (
                            <DashboardOffers offers={this.props.offers} />
                        )}
                    </div>
                    <div className="col-lg-4 col-12">
                        {this.renderProfile()}
                        {this.renderCalendar()}
                    </div>
                </div>
            </LayoutWrapper>
        );
    }

    private renderProfile = () => {
        return (
            <Card className="profile-card">
                <CardBody>
                    <CardTitle>
                        <div className="card-header">
                            <div>
                                <User size={20} />
                                <Translation text="dashboard.profile.title" />
                            </div>
                        </div>
                    </CardTitle>

                    <Timeline data={this.renderTimelineData()} />
                </CardBody>
            </Card>
        );
    };

    private renderCalendar = () => {
        return (
            <Card className="events-card">
                <CardBody>
                    <CardTitle>
                        <div className="card-header">
                            <div>
                                <Calendar size={20} />
                                <Translation text="dashboard.calendar.title" />
                            </div>
                        </div>
                    </CardTitle>
                    <EventCalendar username={this.props.username} calendarEvents={this.props.calendarEvents} />
                </CardBody>
            </Card>
        );
    };

    private isOrganizationHeadHunter = (): boolean => {
        return this.props.userRoles.includes(UserRole.HEADHUNTER);
    };

    private renderTimelineData = (): typeof ITimelineItem[] => {
        const profileDetails = this.props.profileDetails,
            timelineData: typeof ITimelineItem[] = [
                {
                    title: 'dashboard.profile.companyInformation.title',
                    content: profileDetails?.isCompanyDataComplete ? '' : 'dashboard.profile.companyInformation.description',
                    metaIcon: true,
                    isTimelineComplete: profileDetails?.isCompanyDataComplete,
                    timelineLink: '/panel/employer',
                    activeTab: 0,
                    color: profileDetails?.isCompanyDataComplete ? 'primary' : 'secondary',
                    linkClassName: 'company-information-link',
                },
                {
                    title: 'dashboard.profile.verification.title',
                    content: profileDetails?.isVerificationDataComplete ? '' : 'dashboard.profile.verification.description',
                    metaIcon: true,
                    isTimelineComplete: profileDetails?.isVerificationDataComplete,
                    timelineLink: '/panel/employer',
                    activeTab: 2,
                    color: profileDetails?.isVerificationDataComplete ? 'primary' : 'secondary',
                    linkClassName: 'verification-link',
                },
                {
                    title: 'dashboard.profile.companyDetails.title',
                    content: profileDetails?.isCompanyDetailsDataComplete ? '' : 'dashboard.profile.companyDetails.description',
                    metaIcon: true,
                    isTimelineComplete: profileDetails?.isCompanyDetailsDataComplete,
                    timelineLink: '/panel/employer',
                    activeTab: 3,
                    color: profileDetails?.isCompanyDetailsDataComplete ? 'primary' : 'secondary',
                    linkClassName: 'company-details-link',
                },
            ];

        if (this.isOrganizationHeadHunter()) {
            timelineData.splice(1, 0, {
                title: 'dashboard.profile.contactInformation.title',
                content: profileDetails?.isContactInformationComplete ? '' : 'dashboard.profile.contactInformation.description',
                metaIcon: true,
                isTimelineComplete: profileDetails?.isContactInformationComplete,
                timelineLink: '/panel/employer',
                activeTab: 1,
                color: profileDetails?.isContactInformationComplete ? 'primary' : 'secondary',
                linkClassName: 'contact-information-link',
            });
        }
        return timelineData;
    };
}

export default connect(
    (state: RootState) => ({
        userRoles: authUserRolesSelector(state),
        username: usernameSelector(state),
        calendarEvents: calendarDetailsSelector(state),
        positionsWithOffers: positionsWithOffersSelector(state),
        positionsWithoutOffers: positionsWithoutOffersSelector(state),
        offerApplications: offerApplicationsSelector(state),
        offers: offersSelector(state),
        profileDetails: profileDetailsSelector(state),
        isDashboardLoading: isDashboardLoadingSelector(state),
    }),
    {
        fetchPositionsWithOffers,
        fetchPositionsWithoutOffers,
        fetchOfferApplications,
        fetchOffers,
        fetchProfileDetails,
        resetToInitialDashboardPageState,
        fetchCalendarDetails,
    }
)(withNavigation(Dashboard));
