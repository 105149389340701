import React, {Component} from 'react';
import {Card, CardBody, CardTitle, Table} from 'reactstrap';
import {Target} from 'react-feather';
import {DateComponent, ListItemDetails, Loader, LoaderType, Price, Translation, WithNavigate, withNavigation} from 'jobhunter-common-web';
import {IPosition} from '../../../store/reducers/dashboardPageSlice';

export enum PositionOfferType {
    POSITION_WITH_OFFER = 'position_with_offer',
    POSITION_WITHOUT_OFFER = 'position_without_offer',
}

declare type WithNavigationProps = typeof WithNavigate;

interface IExternalPositionsListProps {
    positions: IPosition[] | null;
    isLoading: boolean;
    positionOfferType: PositionOfferType;
}

interface IPositionsListProps extends IExternalPositionsListProps, WithNavigationProps {}

class PositionsList extends Component<IPositionsListProps> {
    render() {
        const titleKey =
                this.props.positionOfferType === PositionOfferType.POSITION_WITH_OFFER
                    ? 'positionsWithOffersTitle'
                    : 'positionsWithoutOffersTitle',
            hasOffer = this.props.positionOfferType === PositionOfferType.POSITION_WITH_OFFER,
            cardClass =
                this.props.positionOfferType === PositionOfferType.POSITION_WITH_OFFER
                    ? 'positions-with-offers-card'
                    : 'positions-without-offers-card',
            tableClass =
                this.props.positionOfferType === PositionOfferType.POSITION_WITH_OFFER
                    ? 'positions-with-offers-table'
                    : 'positions-without-offers-table';
        return (
            <Card className={cardClass}>
                <CardBody>
                    <CardTitle>
                        <div className="card-header">
                            <div>
                                <Target size={20} />
                                <Translation text={`dashboard.positions.${titleKey}`} />
                            </div>
                            <button
                                className={`btn btn-primary ${
                                    this.props.positionOfferType === PositionOfferType.POSITION_WITH_OFFER
                                        ? 'positions-with-offers-button'
                                        : 'positions-without-offers-button'
                                }`}
                                onClick={() => this.redirectToOffers(hasOffer)}>
                                <Translation text="buttons.showAll" />
                            </button>
                        </div>
                    </CardTitle>
                    <Table responsive className={tableClass}>
                        <thead>
                            <tr>
                                <th>
                                    <Translation text="dashboard.positions.table.headers.project" />
                                </th>
                                <th>
                                    <Translation text="dashboard.positions.table.headers.city" />
                                </th>
                                <th>
                                    <Translation text="dashboard.positions.table.headers.salary" />
                                </th>
                                <th>
                                    <Translation text="dashboard.positions.table.headers.publication" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>{this.renderMatchesTableRows()}</tbody>
                    </Table>
                </CardBody>

                <Loader type={LoaderType.Local} showLoader={this.props.isLoading} />
            </Card>
        );
    }

    private renderMatchesTableRows() {
        if (this.props.positions === null || !this.props.positions.length) {
            return (
                <tr key={'no-data'}>
                    <td colSpan={4}>
                        <p>
                            <Translation text="dashboard.positions.table.noData" />
                        </p>
                    </td>
                </tr>
            );
        }

        return this.props.positions.map((item: IPosition) => {
            return (
                <tr key={`matches_${item.id}`}>
                    <td>
                        <ListItemDetails avatar={null} name={item.name} description={item.seniority.name} />
                    </td>
                    <td>
                        <Translation text={item.city.name} />
                    </td>
                    <td>
                        <Price
                            price={{
                                amount: item.minimumSalary,
                            }}
                        />
                        -
                        <Price
                            price={{
                                amount: item.maximumSalary,
                            }}
                        />
                    </td>
                    <td>
                        <DateComponent date={item.createdAt} />
                    </td>
                </tr>
            );
        });
    }

    private redirectToOffers = (hasOffers: boolean) => {
        this.props.navigate('/panel/employer/positions', {state: {hasOffers: hasOffers}});
    };
}

export default withNavigation(PositionsList);
