import React from 'react';
import {isNullOrUndefined, Tooltip, WithNavigate, withNavigation} from 'jobhunter-common-web';
import {IModelRecruitment} from '../../../../../model/recruitmentDataModel';
import {Button} from 'reactstrap';
import {Eye, Search, Send} from 'react-feather';
import {withTranslation} from 'react-i18next';
import {IOffer, OfferStatus} from '../../../../../model/offerDataModel';
import RecommendOfferModal from '../../../Offers/RecommendOfferModal';

declare type WithNavigationProps = typeof WithNavigate;

interface IExternalOfferItemRowProps {
    readonly offer: IOffer;
    readonly item: IModelRecruitment;
}

interface IOfferItemRowProps extends IExternalOfferItemRowProps, WithNavigationProps {}

interface IOfferItemRowState {
    isRecommendationModalVisible: boolean;
    selectedOffer: IOffer | null;
}

class OfferItemRow extends React.Component<IOfferItemRowProps, IOfferItemRowState> {
    constructor(props: IOfferItemRowProps) {
        super(props);

        this.state = {
            isRecommendationModalVisible: false,
            selectedOffer: null,
        };
    }

    render() {
        const offer = this.props.offer,
            item = this.props.item,
            {t} = this.props;

        return (
            <>
                <tr className="sought-position-offer">
                    <td>{this.renderDescription(offer, item)}</td>
                    <td>
                        <Button
                            color="flat-primary"
                            className="view-candidate-button"
                            onClick={() => this.viewCandidateDetails(item)}
                            disabled={item.applicationsCount === 0}>
                            <Search id={`details_${offer.id}`} size={20} />
                            <Tooltip target={`details_${offer.id}`} tooltipText={t('humanResources.recruitment.tooltips.viewCandidate')} />
                        </Button>
                    </td>
                    <td>---</td>
                    <td>---</td>
                    <td>
                        <Button color="flat-primary" className="view-offer-button" onClick={() => this.viewItem(item)}>
                            <Eye id={`offer_view_${offer.id}`} size={20} />
                            <Tooltip target={`offer_view_${offer.id}`} tooltipText={t('humanResources.recruitment.tooltips.viewOffer')} />
                        </Button>

                        <Button
                            disabled={item.status !== OfferStatus.ACTIVE}
                            className="recommend-offer-button"
                            color="flat-primary"
                            onClick={() => this.toggleRecommendationModal(offer)}>
                            <Send id={`recommend_${offer.id}`} size={20} />
                            <Tooltip target={`recommend_${offer.id}`} tooltipText={t('humanResources.recruitment.tooltips.shareOffer')} />
                        </Button>
                    </td>
                </tr>

                {this.state.isRecommendationModalVisible ? (
                    <RecommendOfferModal
                        isModalOpen={this.state.isRecommendationModalVisible}
                        toggleModal={this.toggleRecommendationModal}
                        offer={this.state.selectedOffer}
                    />
                ) : null}
            </>
        );
    }

    renderDescription = (offer: IOffer, item: IModelRecruitment) => {
        if (isNullOrUndefined(offer?.name)) {
            return <p>'--'</p>;
        }

        const {t} = this.props,
            organizationName = item.organizationName ? item.organizationName : t('employer.cooperatingHr.table.noCompanyName');

        return (
            <div className="custom-description">
                <div className="details">
                    <p className="title">{offer.name}</p>
                    <p className="description">{organizationName}</p>
                </div>
            </div>
        );
    };

    private viewItem = (item: IModelRecruitment) => {
        item.offers.map((offer: IOffer) => {
            this.props.navigate(`/panel/human_resources/offers/${offer.id}`);
        });
    };

    private viewCandidateDetails = (item: IModelRecruitment) => {
        this.props.navigate(`/panel/human_resources/candidates`, {state: {positionId: item.name}});
    };

    private toggleRecommendationModal = (item?: IOffer) => {
        this.setState({
            isRecommendationModalVisible: !this.state.isRecommendationModalVisible,
            selectedOffer: item ? item : null,
        });
    };
}

export default withTranslation()(withNavigation(OfferItemRow));
