import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IServicesListState} from '../reducers/myServicesSlice';

export const selectServicesPage = (state: RootState): IServicesListState => {
    return state.myServices;
};

export const servicesListSelector = createSelector([selectServicesPage], (state: IServicesListState) => state.servicesList);

export const servicesMetadataSelector = createSelector([selectServicesPage], (state: IServicesListState) => state.servicesMetadata);

export const servicesPaginationSelector = createSelector([selectServicesPage], (state: IServicesListState) => state.pagination);

export const isServicesPageLoading = createSelector([selectServicesPage], (state: IServicesListState) => state.isServicesListLoading);

export const isServicesPageInitialized = createSelector(
    [selectServicesPage],
    (state: IServicesListState) => state.isServicesListInitialized
);

export const isServiceAddedSelector = createSelector([selectServicesPage], (state: IServicesListState) => state.isServiceAdded);

export const servicesPageError = createSelector([selectServicesPage], (state: IServicesListState) => state.servicesListError);

export const isServiceStatusUpdatedSelector = createSelector(
    [selectServicesPage],
    (state: IServicesListState) => state.isServiceStatusUpdated
);
