import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {ISoughtPositionDetailsState} from '../reducers/soughtPositionDetailsPageSlice';

export const selectSoughtPositionDetailsPage = (state: RootState) => {
    return state.soughtPositionDetailsPage;
};

export const soughtPositionDetailsSelector = createSelector(
    [selectSoughtPositionDetailsPage],
    (state: ISoughtPositionDetailsState) => state.soughtPositionDetails
);

export const isSoughtPositionDetailsInitializedSelector = createSelector(
    [selectSoughtPositionDetailsPage],
    (state: ISoughtPositionDetailsState) => state.isSoughtPositionDetailsPageInitialized
);

export const isSoughtPositionDetailsLoadingSelector = createSelector(
    [selectSoughtPositionDetailsPage],
    (state: ISoughtPositionDetailsState) => state.isSoughtPositionDetailsPageLoading
);

export const soughtPositionDetailsErrorSelector = createSelector(
    [selectSoughtPositionDetailsPage],
    (state: ISoughtPositionDetailsState) => state.soughtPositionDetailsPageError
);
