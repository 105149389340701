import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {catchError, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {RootState} from '../reducers';
import {
    fetchPositionDetails,
    IFetchPosition,
    setPositionDetails,
    changeIsPositionDetailsLoading,
    changePositionDetailsPageError,
    fetchPositionRelatedHr,
    setPositionRelatedHr,
    removePosition,
    updatePosition,
    IUpdatePosition,
    addHRToPosition,
    IAddHRToPosition,
    changeIsHRAdded,
} from '../reducers/positionDetailsPageSlice';
import {getPositionAPI} from '../../api/getPositionAPI';
import {addAlert, AlertType, authTokenSelector, getErrorMessage} from 'jobhunter-common-web';
import {PayloadAction} from '@reduxjs/toolkit';
import {getPositionsHrListAPI} from '../../api/getPositionHrListAPI';
import {deletePositionAPI} from '../../api/deletePositionAPI';
import {push} from 'react-router-redux';
import {updatePositionAPI} from '../../api/updatePositionAPI';
import {invitePositionRelatedHrAPI} from '../../api/invitePositionRelatedHrAPI';

const fetchPositionDetailsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchPositionDetails.type),
        switchMap((action: PayloadAction<IFetchPosition>): any => {
            const authToken = authTokenSelector(state$.value);
            return getPositionAPI(authToken, action.payload.positionId).pipe(
                switchMap((resp: any) => {
                    const actions = successActions(setPositionDetails(resp));
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const fetchPositionRelatedHrListEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchPositionRelatedHr.type),
        switchMap((action: PayloadAction<IFetchPosition>): any => {
            const authToken = authTokenSelector(state$.value);
            return getPositionsHrListAPI(authToken, action.payload.positionId).pipe(
                switchMap((resp: any) => {
                    const actions = [setPositionRelatedHr(resp['organizations'])];
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const removePositionEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(removePosition.type),
        switchMap((action: PayloadAction<IFetchPosition>): any => {
            const authToken = authTokenSelector(state$.value);
            return deletePositionAPI(authToken, action.payload.positionId).pipe(
                switchMap(() => {
                    const actions = [
                        addAlert({message: 'employer.positions.positionDetails.deletePosition.positionRemoved'}),
                        push('/panel/employer/positions'),
                    ];
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const updatePositionEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(updatePosition.type),
        switchMap((action: PayloadAction<IUpdatePosition>): any => {
            const authToken = authTokenSelector(state$.value);
            return updatePositionAPI(authToken, action.payload.updatePayload, action.payload.positionId).pipe(
                switchMap(() => {
                    const actions = [
                        addAlert({message: 'employer.positions.positionDetails.updatePosition.positionUpdated'}),
                        changeIsPositionDetailsLoading(false),
                    ];
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const addHRToPositionEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(addHRToPosition.type),
        switchMap((action: PayloadAction<IAddHRToPosition>): any => {
            const authToken = authTokenSelector(state$.value);
            return invitePositionRelatedHrAPI(authToken, action.payload.positionId, action.payload.hrDepartmentId).pipe(
                switchMap(() => {
                    const message = 'employer.positions.positionDetails.confirmInvitation.invitationSent',
                        actions = [changeIsHRAdded(true), fetchPositionDetails(action.payload.positionId), addAlert({message: message})];
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const successActions = (changeSliceList: any): any[] => {
    return [changeSliceList, changeIsPositionDetailsLoading(false)];
};

const updateListErrorActions = (error: any): any[] => {
    return [
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
        changePositionDetailsPageError(getErrorMessage(error)),
        changeIsPositionDetailsLoading(false),
    ];
};

const positionDetailsEpic = combineEpics(
    fetchPositionDetailsEpic,
    fetchPositionRelatedHrListEpic,
    removePositionEpic,
    updatePositionEpic,
    addHRToPositionEpic
);

export default positionDetailsEpic;
