import React, {Component} from 'react';
import {Pagination, withLocation, WithLocation} from 'jobhunter-common-web';
import {WithTranslation, withTranslation} from 'react-i18next';
import {IApplicationOffer} from '../../../../model/offerDataModel';
import LayoutWrapper from '../../../LayoutWrapper';
import CandidatesCard from '../../CandidatesCard';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {
    isPreferredCandidatesListLoadingSelector,
    preferredCandidatesListSelector,
    preferredCandidatesMetadataSelector,
} from '../../../../store/selectors/preferredCandidatedPageSelectors';
import {
    changePreferredCandidatesPagination,
    fetchPreferredCandidatesList,
    resetToInitialPreferredCandidatesState,
} from '../../../../store/reducers/preferredCandidatesPageSlice';
import {IModelApiResponseViewObject} from '../../../../model/base';

declare type WithLocationProps = typeof WithLocation;

interface IConnectedPreferredCandidateCardProps {
    readonly candidatesList: IApplicationOffer[] | null;
    readonly candidatesMetadata: IModelApiResponseViewObject | null;
    readonly isLoading: boolean;
    readonly fetchPreferredCandidatesList: typeof fetchPreferredCandidatesList;
    readonly resetToInitialPreferredCandidatesState: typeof resetToInitialPreferredCandidatesState;
    readonly changePreferredCandidatesPagination: typeof changePreferredCandidatesPagination;
}

interface IExternalPreferredCandidateCardProps {}

interface IPreferredCandidatesProps
    extends WithLocationProps,
        WithTranslation,
        IConnectedPreferredCandidateCardProps,
        IExternalPreferredCandidateCardProps {}

interface IPreferredCandidatesState {}

class PreferredCandidates extends Component<IPreferredCandidatesProps, IPreferredCandidatesState> {
    componentDidMount(): void {
        if (this.props.location && this.props.location.pathname) {
            const offerId = this.props.location.pathname.split('/').pop();
            this.props.fetchPreferredCandidatesList(offerId);
        }
    }

    componentWillUnmount(): void {
        this.props.resetToInitialPreferredCandidatesState();
    }

    render() {
        return (
            <LayoutWrapper>
                <CandidatesCard candidates={this.props.candidatesList} isExpandedList={true} isLoading={this.props.isLoading} />
                <Pagination listMetadata={this.props.candidatesMetadata} changePage={this.props.changeCandidatesPagination} />
            </LayoutWrapper>
        );
    }
}

export default connect(
    (state: RootState) => ({
        candidatesList: preferredCandidatesListSelector(state),
        candidatesMetadata: preferredCandidatesMetadataSelector(state),
        isLoading: isPreferredCandidatesListLoadingSelector(state),
    }),
    {
        fetchPreferredCandidatesList,
        resetToInitialPreferredCandidatesState,
        changePreferredCandidatesPagination,
    }
)(withTranslation()(withLocation(PreferredCandidates)));
