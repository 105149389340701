import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IDashboardState} from '../reducers/dashboardPageSlice';

export const selectDashboardPage = (state: RootState): IDashboardState => {
    return state.dashboardPage;
};

export const positionsWithOffersSelector = createSelector([selectDashboardPage], (state: IDashboardState) => state.positionsWithOffers);

export const positionsWithoutOffersSelector = createSelector(
    [selectDashboardPage],
    (state: IDashboardState) => state.positionsWithoutOffers
);

export const offerApplicationsSelector = createSelector([selectDashboardPage], (state: IDashboardState) => state.offerApplications);

export const isOfferApplicationCandidateRejectedSelector = createSelector(
    [selectDashboardPage],
    (state: IDashboardState) => state.isOfferApplicationCandidateRejected
);

export const offersSelector = createSelector([selectDashboardPage], (state: IDashboardState) => state.offers);

export const profileDetailsSelector = createSelector([selectDashboardPage], (state: IDashboardState) => state.profileDetails);

export const calendarDetailsSelector = createSelector([selectDashboardPage], (state: IDashboardState) => state.calendarDetails);

export const isDashboardLoadingSelector = createSelector([selectDashboardPage], (state: IDashboardState) => state.isDashboardLoading);

export const isDashboardInitializedSelector = createSelector(
    [selectDashboardPage],
    (state: IDashboardState) => state.isDashboardInitialized
);

export const dashboardErrorSelector = createSelector([selectDashboardPage], (state: IDashboardState) => state.dashboardError);
