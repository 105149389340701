import {combineReducers} from 'redux';
import {createBrowserHistory} from 'history';
import {
    accountSlice,
    alertSlice,
    changePasswordSlice,
    chatContactsSlice,
    citySlice,
    companyTypeSlice,
    consultationDataSlice,
    contractTypeSlice,
    countrySlice,
    employmentTypeSlice,
    industrySlice,
    initLogout,
    languageLevelSlice,
    languageSlice,
    loginSlice,
    onlineConsultationSlice,
    organizationSizeSlice,
    organizationVerificationFileTypeSlice,
    sagaSlice,
    senioritySlice,
    serviceTypeSlice,
    technologySlice,
    technologyToolSlice,
    videoCallDetailsSlice,
    workTypeSlice,
    authSlice,
    preferenceTagsSlice,
    relocationsPageSlice,
} from 'jobhunter-common-web';
import {createReduxHistoryContext} from 'redux-first-history';
import employerPageSlice from './employerPageSlice';
import positionsPageSlice from './positionsPageSlice';
import dashboardPageSlice from './dashboardPageSlice';
import calendarSlice from './calendarSlice';
import candidateViewPageSlice from './candidateViewPageSlice';
import offerViewPageSlice from './offerViewPageSlice';
import positionDetailsPageSlice from './positionDetailsPageSlice';
import settingsPageSlice from './settingsPageSlice';
import hrPageSlice from './hrPageSlice';
import employeesPageSlice from './employeesPageSlice';
import candidatesPageSlice from './candidatesPageSlice';
import offersPageSlice from './offersPageSlice';
import servicesPageSlice from './myServicesSlice';
import purchasedServicesPageSlice from './purchasedServicesSlice';
import recruitmentPageSlice from './recruitmentPageSlice';
import billingPageSlice from './billingPageSlice';
import humanResourcesPageSlice from './humanResourcesPageSlice';
import soughtPositionDetailsPageSlice from './soughtPositionDetailsPageSlice';
import relocationDetailsPageSlice from './relocationDetailsPageSlice';
import preferredCandidatesPageSlice from './preferredCandidatesPageSlice';

const {routerReducer} = createReduxHistoryContext({history: createBrowserHistory()});

const appReducer = combineReducers({
    auth: authSlice,
    login: loginSlice,
    account: accountSlice,
    changePassword: changePasswordSlice,
    chatContacts: chatContactsSlice,
    alert: alertSlice,
    saga: sagaSlice,
    videoCallDetails: videoCallDetailsSlice,
    onlineConsultation: onlineConsultationSlice,
    city: citySlice,
    companyType: companyTypeSlice,
    contractType: contractTypeSlice,
    country: countrySlice,
    employmentType: employmentTypeSlice,
    industry: industrySlice,
    languageLevel: languageLevelSlice,
    language: languageSlice,
    organizationSize: organizationSizeSlice,
    organizationVerificationFileType: organizationVerificationFileTypeSlice,
    seniority: senioritySlice,
    serviceType: serviceTypeSlice,
    technology: technologySlice,
    technologyTool: technologyToolSlice,
    workType: workTypeSlice,
    preferenceTags: preferenceTagsSlice,
    calendar: calendarSlice,
    consultationData: consultationDataSlice,
    dashboardPage: dashboardPageSlice,
    positionsPage: positionsPageSlice,
    humanResourcesPage: humanResourcesPageSlice,
    candidateViewPage: candidateViewPageSlice,
    offerViewPage: offerViewPageSlice,
    positionDetailsPageSlice: positionDetailsPageSlice,
    billingInformationPage: settingsPageSlice,
    hrPage: hrPageSlice,
    employerPage: employerPageSlice,
    employeesPage: employeesPageSlice,
    candidatesPage: candidatesPageSlice,
    offersPage: offersPageSlice,
    recruitmentPage: recruitmentPageSlice,
    billingPage: billingPageSlice,
    relocationsPage: relocationsPageSlice,
    relocationDetailsPage: relocationDetailsPageSlice,
    router: routerReducer,
    myServices: servicesPageSlice,
    soughtPositionDetailsPage: soughtPositionDetailsPageSlice,
    preferredCandidatesPage: preferredCandidatesPageSlice,
    purchasedServices: purchasedServicesPageSlice,
});

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: any) => {
    if (action.type === initLogout.type) {
        return appReducer(undefined, {type: undefined});
    }

    return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
