import {Observable} from 'rxjs';
import {jobHunterAPI, RestQueryParams} from 'jobhunter-common-web';

export function getPurchasedServicesAPI(authToken: string | null, params?: typeof RestQueryParams): Observable<any> {
    let queryParams = params ? params : new RestQueryParams(),
        headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return jobHunterAPI.get(`api/purchased_services/list_mine`, queryParams, headers);
}
