import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {catchError, switchMap} from 'rxjs/operators';
import {of, Observable} from 'rxjs';
import {RootState} from '../reducers';
import {addAlert, AlertType, authTokenSelector, getErrorMessage, RestQueryParams} from 'jobhunter-common-web';
import {
    fetchCandidatesList,
    setCandidatesList,
    hireCandidate,
    changeCandidateHired,
    rejectCandidate,
    changeCandidateRejected,
    ICandidateAction,
    changeIsCandidatesListLoading,
    changeCandidateListError,
    fetchRecruitmentList,
    setRecruitmentList,
    changeRecruitmentPageError,
    changeIsRecruitmentLoading,
    fetchCalendarDetails,
    setCalendarDetails,
    changeCalendarLoading,
} from '../reducers/humanResourcesPageSlice';
import {getOfferApplicationsAPI} from '../../api/getOfferApplicationsAPI';
import {hireCandidateAPI} from '../../api/hireCandidateAPI';
import {rejectCandidateAPI} from '../../api/rejectCandidateAPI';
import {PayloadAction} from '@reduxjs/toolkit';
import {getSoughtPositionsAPI} from '../../api/getSoughtPositionsAPI';
import {getCalendarEventsAPI} from '../../api/getCalendarEventsAPI';

const fetchCandidatesListEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getApplicationCandidates(action$, state$, fetchCandidatesList, getOfferApplicationsAPI, setCandidatesList);
};
const fetchRecruitmentListEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getApplicationCandidates(action$, state$, fetchRecruitmentList, getSoughtPositionsAPI, setRecruitmentList);
};

const fetchCalendarDetailsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getApplicationCandidates(action$, state$, fetchCalendarDetails, getCalendarEventsAPI, setCalendarDetails);
};

const getApplicationCandidates = (
    action$: Observable<any>,
    state$: StateObservable<RootState>,
    actionType: any,
    api: any,
    setList: any
) => {
    return action$.pipe(
        ofType(actionType.type),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value),
                queryParams = new RestQueryParams().add('itemsPerPage', '5');
            return api(authToken, queryParams).pipe(
                switchMap((resp: any) => {
                    const actions = successActions(setList(resp[`hydra:member`]));
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const hireCandidateEpic: Epic = (action$: Observable<any>, state$: StateObservable<RootState>) => {
    return interactWithCandidate(action$, state$, hireCandidate, hireCandidateAPI, changeCandidateHired(true));
};
const rejectCandidateEpic: Epic = (action$: Observable<any>, state$: StateObservable<RootState>) => {
    return interactWithCandidate(action$, state$, rejectCandidate, rejectCandidateAPI, changeCandidateRejected(true));
};

const interactWithCandidate = (
    action$: Observable<any>,
    state$: StateObservable<RootState>,
    actionType: any,
    api: any,
    changeAction: any
) => {
    return action$.pipe(
        ofType(actionType.type),
        switchMap((action: PayloadAction<ICandidateAction>) => {
            const authToken = authTokenSelector(state$.value);
            return api(authToken, action.payload.applicationId).pipe(
                switchMap(() => {
                    const message =
                            actionType.type === 'candidatesPage/rejectCandidate'
                                ? 'humanResources.dashboard.candidates.rejectCandidateModal.candidateRejected'
                                : 'humanResources.dashboard.candidates.acceptCandidateModal.candidateAccepted',
                        actions = changeCandidateStatusSuccessActions(changeAction, message);

                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const successActions = (changeSliceList: any): any[] => {
    return [changeSliceList, changeIsCandidatesListLoading(false), changeIsRecruitmentLoading(false), changeCalendarLoading(false)];
};

const updateListErrorActions = (error: any): any[] => {
    return [
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
        changeCandidateListError(getErrorMessage(error)),
        changeRecruitmentPageError(getErrorMessage(error)),
        changeIsRecruitmentLoading(false),
        changeCalendarLoading(false),
    ];
};

const changeCandidateStatusSuccessActions = (changeAction: any, successMessage: string): any[] => {
    return [
        fetchCandidatesList(),
        fetchRecruitmentList(),
        fetchCalendarDetails(),
        changeAction,
        addAlert({
            message: successMessage,
            type: AlertType.SUCCESS,
        }),
    ];
};

const humanResourcesPageEpic = combineEpics(
    fetchCandidatesListEpic,
    fetchRecruitmentListEpic,
    hireCandidateEpic,
    rejectCandidateEpic,
    fetchCalendarDetailsEpic
);

export default humanResourcesPageEpic;
