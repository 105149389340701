import {Observable} from 'rxjs';
import {jobHunterAPI, RestQueryParams} from 'jobhunter-common-web';
import {IInvitationPayload} from '../model/hrListDataModel';

export function sendInvitationAPI(authToken: string | null, payload: IInvitationPayload): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }
    return jobHunterAPI.post('api/hr_invitations', payload, new RestQueryParams(), headers);
}
