import React, {Component} from 'react';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {Form, FormControlChangeType, IFormConfig, isSameCollection, Loader, LoaderType, Translation} from 'jobhunter-common-web';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {addRecruiterFormConfig} from './addRecruiterFormConfig';
import moment from 'moment';
import {isSafari} from 'react-device-detect';
import {connect} from 'react-redux';
import {
    changeIsApplicationScheduled,
    fetchRecruitersList,
    scheduleApplicationMeeting,
} from '../../../../store/reducers/candidatesPageSlice';
import {RootState} from '../../../../store/reducers';
import {EmployeeType, IModelExternalEmployee} from '../../../../model/employeeDataModel';
import {
    candidatesPageErrorSelector,
    isApplicationMeetingScheduledSelector,
    recruitersListSelector,
} from '../../../../store/selectors/candidatesPageSelectors';
import {IScheduleConsultationPayload} from '../../../../api/scheduleApplicationMeetingAPI';
import {IApplicationOffer} from '../../../../model/offerDataModel';

export interface ICalendarTimeSlot {
    displayValue: string;
    endsAt: string;
    isFree: boolean;
    value: string;
}

interface IConnectedAddRecruiterProps {
    readonly recruitersList: IModelExternalEmployee[] | null;
    readonly error: string | null;
    readonly isApplicationMeetingScheduled: boolean;
    readonly fetchRecruitersList: typeof fetchRecruitersList;
    readonly scheduleApplicationMeeting: typeof scheduleApplicationMeeting;
    readonly changeIsApplicationScheduled: typeof changeIsApplicationScheduled;
}

interface IExternalAddRecruiterProps {
    isModalOpen: boolean;
    toggleModal: (candidate: IApplicationOffer | null) => void;
    candidate?: IApplicationOffer | null;
    applicationId?: string | null;
}

interface IAddRecruiterProps extends IConnectedAddRecruiterProps, IExternalAddRecruiterProps {}

interface IAddRecruiterState {
    value: any;
    formConfig: typeof IFormConfig | null;
    isLoading: boolean;
    availableDates: Date[] | null;
    timeSlots: ICalendarTimeSlot[] | null;
    currentMonth: number;
}

class AddRecruiter extends Component<IAddRecruiterProps, IAddRecruiterState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IAddRecruiterProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: null,
            isLoading: false,
            availableDates: null,
            timeSlots: null,
            currentMonth: new Date().getMonth() + 1,
        };
    }

    componentDidMount(): void {
        this.props.fetchRecruitersList();

        this.getAvailableDates(new Date().getFullYear(), this.state.currentMonth);

        this.setFormConfig();

        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    tap((data: any) => this.onValueUpdated(data.value))
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: Readonly<IAddRecruiterProps>, prevState: Readonly<IAddRecruiterState>): void {
        if (
            this.props.recruitersList !== prevProps.recruitersList ||
            !isSameCollection(this.state.availableDates, prevState.availableDates) ||
            !isSameCollection(this.state.timeSlots, prevState.timeSlots)
        ) {
            this.setFormConfig();
        }

        if (
            this.props.isApplicationMeetingScheduled !== prevProps.isApplicationMeetingScheduled &&
            this.props.isApplicationMeetingScheduled
        ) {
            this.props.toggleModal(null);
        }

        if (this.props.error !== prevProps.error && this.props.error) {
            this.setState({isLoading: false});
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.props.changeIsApplicationScheduled(false);
    }

    render() {
        const selectedCandidate = this.props.candidate?.candidate;

        return (
            <Modal isOpen={this.props.isModalOpen} toggle={() => this.props.toggleModal(null)}>
                <ModalHeader toggle={() => this.props.toggleModal(null)}>
                    <Translation
                        text="humanResources.dashboard.candidates.addRecruiterModal.title"
                        config={{candidate: `${selectedCandidate?.account?.firstName} ${selectedCandidate?.account?.lastName}`}}
                    />
                </ModalHeader>
                <ModalBody>
                    <p className="modal-description">
                        <Translation text="humanResources.dashboard.candidates.addRecruiterModal.description" />
                    </p>

                    {this.state.formConfig && (
                        <Form
                            config={this.state.formConfig}
                            onValueStateChange={this.onValueStateChange}
                            value={this.state.value}
                            submitForm={() => this.addRecruiter()}
                            controlName={'addRecruiterForm'}
                        />
                    )}
                </ModalBody>

                <Loader type={LoaderType.Local} showLoader={this.state.isLoading} />
            </Modal>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onValueUpdated = (value: any) => {
        Object.keys(value).forEach((key) => {
            if (value[key] && value[key].hasOwnProperty('name') && value[key].name === 'month') {
                this.onMonthChange(value[key].value);
            }

            if (value[key] && value[key].hasOwnProperty('name') && value[key].name === 'date') {
                this.onDayChange(value[key].value);
            }
            if (value[key] && value[key].hasOwnProperty('name') && value[key].name === 'checkboxSelectedDate' && this.state.timeSlots) {
                this.onTimeSlotChange(value[key]);
            }

            // if (value[key] && value[key].hasOwnProperty('name') && value[key].name === 'selectedDate') {
            //     this.setState({selectedDate: value.date.value});
            // }

            this.setState({value: value});
        });
    };

    private onMonthChange = (monthValue: any) => {
        const currentMonth = monthValue.getMonth() + 1;

        this.setState({
            currentMonth: currentMonth,
            timeSlots: null,
        });

        this.getAvailableDates(new Date().getFullYear(), currentMonth);
    };

    private onDayChange = (dayValue: Date) => {
        const formatString = isSafari ? 'MM/DD/YYYY' : 'YYYY-MM-DD',
            formattedDate = moment(dayValue).format(formatString),
            startHour = 8,
            endHour = 20,
            availableTimeSlots = [];

        for (let i = startHour; i < endHour + 1; i++) {
            const hasTimeslotPassed = new Date(`${formattedDate} ${i}:00`) < new Date();
            availableTimeSlots.push({
                displayValue: `${i}:00`,
                endsAt: `${formattedDate} ${i + 1}:00`,
                isFree: !hasTimeslotPassed,
                value: `${formattedDate} ${i}:00`,
            });
        }

        return this.setState({
            timeSlots: availableTimeSlots,
            isLoading: false,
        });
    };

    private onTimeSlotChange = (timeSlotValue: any) => {
        if (this.state.timeSlots === null) {
            return;
        }

        const timeSlotsUpdated = [...this.state.timeSlots];
        timeSlotsUpdated.forEach((timeSlot) => {
            if (new Date(timeSlot.value).getTime() !== timeSlotValue.value.getTime()) {
                return;
            }
            timeSlot.isFree = !timeSlot.isFree;
        });
        this.setState({timeSlots: timeSlotsUpdated});
    };

    private addRecruiter = () => {
        if (!this.props.candidate) {
            return;
        }

        this.setState({isLoading: true});
        const selectedRecruiter = this.props.recruitersList?.find(
            (recruiter: IModelExternalEmployee) => recruiter.id === this.state.value.recruiter
        );

        if (!selectedRecruiter) {
            return;
        }

        const selectedDate = this.state.value.date ? new Date(this.state.value.date.value).toISOString() : new Date().toISOString(),
            endsAt = this.state.value.date ? moment(this.state.value.date.endsAt).format('YYYY-MM-DDTHH:mm:ss.sssZ') : '',
            payload: IScheduleConsultationPayload = {
                meetingDate: selectedDate,
                endsAt: endsAt,
                offerApplicationId: this.props.candidate?.applicationId,
                recruiter: {
                    id:
                        selectedRecruiter.type === EmployeeType.EXTERNAL_EMPLOYEE
                            ? selectedRecruiter.id
                            : selectedRecruiter.organizationEmployeeId
                            ? selectedRecruiter.organizationEmployeeId
                            : '',
                    type: selectedRecruiter.type === EmployeeType.EXTERNAL_EMPLOYEE ? 'external' : 'internal',
                },
            };

        this.props.scheduleApplicationMeeting(payload);
    };

    private setFormConfig = () => {
        const recruiters = this.props.recruitersList?.map((recruiter: IModelExternalEmployee) => {
                return {
                    value: recruiter.id,
                    label: `${recruiter.firstName} ${recruiter.lastName}`,
                };
            }),
            formConfig = addRecruiterFormConfig(this.state.availableDates, this.state.timeSlots, recruiters, this.props.toggleModal);

        this.setState({formConfig});
    };

    private getAvailableDates = (year: number, month: number) => {
        const availableDates = [],
            lastDayOfMonth = new Date(year, month, 0).getDate();

        for (let i = 1; i < lastDayOfMonth + 1; i++) {
            availableDates.push(new Date(year, month - 1, i));
        }

        return this.setState({availableDates: availableDates});
    };
}

export default connect(
    (state: RootState) => ({
        recruitersList: recruitersListSelector(state),
        error: candidatesPageErrorSelector(state),
        isApplicationMeetingScheduled: isApplicationMeetingScheduledSelector(state),
    }),
    {
        fetchRecruitersList,
        scheduleApplicationMeeting,
        changeIsApplicationScheduled,
    }
)(AddRecruiter);
