import {
    Form,
    FormControlChangeType,
    IFormConfig,
    Translation,
    changePasswordStart,
    Loader,
    LoaderType,
    passwordLoadingSelector,
    authUserRolesSelector,
    UserRole,
} from 'jobhunter-common-web';
import React, {Component} from 'react';
import {Card, CardBody, CardHeader} from 'reactstrap';
// import styles from './styles.module.scss';
import LayoutWrapper from '../LayoutWrapper';
import {filter, tap} from 'rxjs/operators';
import {changePasswordFormConfig} from './changePasswordFormConfig';
import {BehaviorSubject, Subscription} from 'rxjs';
import BillingInformation from './BillingInformation';
import {connect} from 'react-redux';
import {RootState} from '../../store/reducers';
// import PaymentFormCard from "./PaymentFormCard";

interface ISettingsConnectedProps {
    readonly userRoles: typeof UserRole | null;
    readonly changePasswordStart: typeof changePasswordStart;
    readonly passwordLoading: boolean;
}
interface ISettingsProps extends ISettingsConnectedProps {}

interface ISettingsState {
    value: any;
    formConfig: typeof IFormConfig | null;
    isLoading: boolean;
}

class Settings extends Component<ISettingsProps, ISettingsState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: ISettingsProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: changePasswordFormConfig(this.discardPasswordValue),
            isLoading: false,
        };
    }

    componentDidMount(): void {
        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    tap((data: any) => this.setState({value: data.value}))
                )
                .subscribe()
        );
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        const isOrganizationAdmin = this.isOrganizationAdmin();
        return (
            <LayoutWrapper>
                <Card className="change-password-card">
                    <CardHeader>
                        <Translation text="settings.changePassword.title" />
                    </CardHeader>
                    <CardBody>
                        {this.state.formConfig && (
                            <Form
                                config={this.state.formConfig}
                                onValueStateChange={this.onValueStateChange}
                                value={this.state.value}
                                submitForm={() => this.changePassword()}
                                controlName={'changePasswordForm'}
                            />
                        )}

                        {/*<div className={styles.passwordRequirements}>*/}
                        {/*<p>*/}
                        {/*<Translation text="settings.changePassword.passwordRequirements.title" />*/}
                        {/*</p>*/}
                        {/*<ul>*/}
                        {/*<li>*/}
                        {/*<Translation text="settings.changePassword.passwordRequirements.length" />*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*<Translation text="settings.changePassword.passwordRequirements.uppercase" />*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*<Translation text="settings.changePassword.passwordRequirements.characters" />*/}
                        {/*</li>*/}
                        {/*</ul>*/}
                        {/*</div>*/}
                    </CardBody>
                    <Loader type={LoaderType.Local} showLoader={this.props.passwordLoading} />
                </Card>

                {/*<PaymentFormCard />*/}
                {isOrganizationAdmin ? <BillingInformation /> : null}
            </LayoutWrapper>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private changePassword = () => {
        this.props.changePasswordStart(this.state.value.currentPassword, this.state.value.newPassword);
    };

    private discardPasswordValue = () => {
        this.setState({value: null});
    };

    private isOrganizationAdmin = (): boolean => {
        return this.props.userRoles.includes(UserRole.ORGANIZATION_ADMIN);
    };
}

export default connect(
    (state: RootState) => ({
        passwordLoading: passwordLoadingSelector(state),
        userRoles: authUserRolesSelector(state),
    }),
    {changePasswordStart}
)(Settings);
