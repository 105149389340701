import {Observable} from 'rxjs';
import {jobHunterAPI, RestQueryParams} from 'jobhunter-common-web';
import {IAddOfferPayload} from './addOfferAPI';

export function updateOfferAPI(authToken: string | null, payload: IAddOfferPayload, offerId: string): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return jobHunterAPI.put(`api/offers/${offerId}/update`, payload, new RestQueryParams(), headers);
}
