import React, {Component} from 'react';
import {Badge, Button, Table} from 'reactstrap';
import {ListItemDetails, Tooltip, Translation, WithRouterWrapperProps, withRouterWrapper} from 'jobhunter-common-web';
import {IOffer, OfferStatus} from '../../../../../model/offerDataModel';
import styles from '../../../../Employer/Positions/PositionDetails/OffersList/styles.module.scss';
import {Edit3, Eye} from 'react-feather';
import {withTranslation} from 'react-i18next';
import {IModelRecruitment} from '../../../../../model/recruitmentDataModel';

declare type WithRouterProps = typeof WithRouterWrapperProps;

interface IExternalSoughtPositionOffersListProps {
    readonly recruitment: IModelRecruitment | null;
    readonly isOrganizationHeadhunter: boolean;
}

interface ISoughtPositionOffersListProps extends IExternalSoughtPositionOffersListProps, WithRouterProps {}

interface ISoughtPositionOffersListState {
    isDeleteModalShown: boolean;
    selectedOffer: IOffer | null;
}

class SoughtPositionOffersList extends Component<ISoughtPositionOffersListProps, ISoughtPositionOffersListState> {
    constructor(props: ISoughtPositionOffersListProps) {
        super(props);

        this.state = {
            isDeleteModalShown: false,
            selectedOffer: null,
        };
    }

    render() {
        return (
            <Table striped responsive className="sought-position-offers-table">
                <thead>
                    <tr>
                        <th>
                            <Translation text="employer.positions.positionDetails.offers.table.hr" />
                        </th>
                        <th>
                            <Translation text="employer.positions.positionDetails.offers.table.candidates" />
                        </th>
                        <th>
                            <Translation text="employer.positions.positionDetails.offers.table.status" />
                        </th>
                        <th>
                            <Translation text="employer.positions.positionDetails.offers.table.actions" />
                        </th>
                    </tr>
                </thead>
                <tbody>{this.renderTableRows()}</tbody>
            </Table>
        );
    }

    private renderTableRows() {
        const {t} = this.props,
            recruitment = this.props.recruitment;

        if (!recruitment || !recruitment.offers || !recruitment.offers.length) {
            return (
                <tr key={'no-data'}>
                    <td colSpan={4}>
                        <p>
                            <Translation text="employer.positions.positionDetails.offers.table.noData" />
                        </p>
                    </td>
                </tr>
            );
        }

        const organizationName = recruitment.organizationName
            ? recruitment.organizationName
            : t('employer.cooperatingHr.table.noCompanyName');

        return recruitment.offers.map((item: IOffer) => {
            const offerStatus = recruitment?.status,
                status =
                    offerStatus === OfferStatus.COMPLETED
                        ? 'light-success'
                        : offerStatus === OfferStatus.DRAFT
                        ? 'light-primary'
                        : offerStatus === OfferStatus.ACTIVE
                        ? 'light-info'
                        : 'light-warning';

            return (
                <tr key={item.id}>
                    <td>
                        <ListItemDetails avatar={null} name={organizationName} />
                    </td>
                    <td>
                        <div className={styles.candidates}>
                            {recruitment.applicationsCount ? recruitment.applicationsCount : '0'}

                            <Button
                                disabled={
                                    recruitment.applicationsCount === 0 ||
                                    offerStatus === OfferStatus.CANCELLED ||
                                    !recruitment.applicationsCount
                                }
                                color="flat-primary"
                                onClick={() => this.showOffers(item.id)}
                                className={`${styles.navLink} view-offer-button`}>
                                <p>
                                    <Translation text="employer.positions.positionDetails.offers.table.view" />
                                </p>
                            </Button>
                        </div>
                    </td>
                    <td>
                        <Badge pill color={status}>
                            {recruitment?.status}
                        </Badge>
                    </td>
                    <td>
                        <div className={styles.actions}>
                            <Button
                                color="flat-primary"
                                className="view-recruitment-button"
                                onClick={() => this.showDetails(item.id)}
                                disabled={!item.id}>
                                <Eye id={`view_${item.id}`} />
                                <Tooltip
                                    target={`view_${item.id}`}
                                    tooltipText={t('employer.positions.positionDetails.offers.tooltips.view')}
                                />
                            </Button>
                            {this.props.isOrganizationHeadhunter ? (
                                <Button
                                    className={`${styles.button} edit-offer-button`}
                                    color="flat-primary"
                                    onClick={() => this.editOffer(item.id)}
                                    disabled={!item}>
                                    <Edit3 id={`edit`} size={24} />
                                    <Tooltip target={`edit`} tooltipText={t('employer.positions.positionDetails.tooltips.edit')} />
                                </Button>
                            ) : null}
                        </div>
                    </td>
                </tr>
            );
        });
    }

    private showDetails = (offerId: string) => {
        this.props.router.navigate(`/panel/human_resources/offers/${offerId}`);
    };

    private showOffers = (offerId: string) => {
        this.props.router.navigate(`/panel/human_resources/candidates`, {state: {offerId}});
    };

    private editOffer = (offerId: string) => {
        this.props.router.navigate(`/panel/human_resources/edit-offer/${offerId}`);
    };
}

export default withTranslation()(withRouterWrapper(SoughtPositionOffersList));
