import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelApiObject} from '../../model/base';
import {IModelCity, IModelSeniority, IFileOutput, IModelDictionaryDatum} from 'jobhunter-common-web';
import {ICalendarEvent} from '../../model/calendarEventModel';
import {IApplicationOffer, IOfferItem} from '../../model/offerDataModel';

export enum IPositionStatus {
    ACTIVE = 'active',
}

export interface IPosition extends IModelApiObject {
    city: typeof IModelCity;
    createdAt: string;
    description: string;
    id: string;
    maximumSalary: string;
    minimumSalary: string;
    name: string;
    offersCount: number;
    seniority: typeof IModelSeniority;
    status: IPositionStatus;
}

export interface ICandidateAccount extends IModelApiObject {
    about: string;
    avatar: typeof IFileOutput;
    birthDate: string;
    cityName: string;
    country: typeof IModelDictionaryDatum;
    firstName: string;
    id: string;
    lastName: string;
    locale: string;
    phone: string;
}

export interface IProfileCompletion {
    isCompanyDataComplete: boolean;
    isContactInformationComplete: boolean;
    isVerificationDataComplete: boolean;
    isCompanyDetailsDataComplete: boolean;
}

export interface IDashboardState {
    positionsWithOffers: IPosition[] | null;
    positionsWithoutOffers: IPosition[] | null;
    offerApplications: IApplicationOffer[] | null;
    offers: IOfferItem[] | null;
    profileDetails: IProfileCompletion | null;
    calendarDetails: ICalendarEvent[] | null;
    isOfferApplicationCandidateRejected: boolean;
    isDashboardLoading: boolean;
    isDashboardInitialized: boolean;
    dashboardError: string | null;
}

export interface ISetPositionsWithOffers {
    positionsWithOffers: IPosition[] | null;
}

export interface ISetPositionsWithoutOffers {
    positionsWithoutOffers: IPosition[] | null;
}

export interface ISetOfferApplications {
    offerApplications: IApplicationOffer[] | null;
}

export interface ISetOffers {
    offers: IOfferItem[] | null;
}

export interface ISetProfileDetails {
    profileDetails: IProfileCompletion | null;
}

export interface ISetCalendarDetails {
    calendarDetails: ICalendarEvent[] | null;
}

export interface IChangeIsDashboardLoading {
    isDashboardLoading: boolean;
}

export interface IChangeIsDashboardInitialized {
    isDashboardInitialized: boolean;
}

export interface IChangeDashboardError {
    error: string | null;
}

export interface IRejectOfferApplicationCandidate {
    candidateId: string;
}

export interface IChangeIsOfferApplicationCandidateRejected {
    isCandidateRejected: boolean;
}

const initialState: IDashboardState = {
    positionsWithOffers: null,
    positionsWithoutOffers: null,
    offerApplications: null,
    offers: null,
    profileDetails: null,
    calendarDetails: null,
    isOfferApplicationCandidateRejected: false,
    isDashboardLoading: false,
    isDashboardInitialized: false,
    dashboardError: null,
};

const dashboardPageSlice = createSlice({
    name: 'dashboardPage',
    initialState: initialState,
    reducers: {
        setPositionsWithOffers: {
            reducer: (state: IDashboardState, action: PayloadAction<ISetPositionsWithOffers>) => {
                return {
                    ...state,
                    positionsWithOffers: action.payload.positionsWithOffers,
                };
            },
            prepare(positionsWithOffers: IPosition[] | null) {
                return {
                    payload: {
                        positionsWithOffers: positionsWithOffers,
                    },
                };
            },
        },
        setPositionsWithoutOffers: {
            reducer: (state: IDashboardState, action: PayloadAction<ISetPositionsWithoutOffers>) => {
                return {
                    ...state,
                    positionsWithoutOffers: action.payload.positionsWithoutOffers,
                };
            },
            prepare(positionsWithoutOffers: IPosition[] | null) {
                return {
                    payload: {
                        positionsWithoutOffers: positionsWithoutOffers,
                    },
                };
            },
        },
        setOfferApplications: {
            reducer: (state: IDashboardState, action: PayloadAction<ISetOfferApplications>) => {
                return {
                    ...state,
                    offerApplications: action.payload.offerApplications,
                };
            },
            prepare(offerApplications: IApplicationOffer[] | null) {
                return {
                    payload: {
                        offerApplications: offerApplications,
                    },
                };
            },
        },
        setOffers: {
            reducer: (state: IDashboardState, action: PayloadAction<ISetOffers>) => {
                return {
                    ...state,
                    offers: action.payload.offers,
                };
            },
            prepare(offers: IOfferItem[] | null) {
                return {
                    payload: {
                        offers: offers,
                    },
                };
            },
        },
        setProfileDetails: {
            reducer: (state: IDashboardState, action: PayloadAction<ISetProfileDetails>) => {
                return {
                    ...state,
                    profileDetails: action.payload.profileDetails,
                };
            },
            prepare(profileDetails: IProfileCompletion | null) {
                return {
                    payload: {
                        profileDetails: profileDetails,
                    },
                };
            },
        },
        setCalendarDetails: {
            reducer: (state: IDashboardState, action: PayloadAction<ISetCalendarDetails>) => {
                return {
                    ...state,
                    calendarDetails: action.payload.calendarDetails,
                };
            },
            prepare(calendarDetails: ICalendarEvent[]) {
                return {
                    payload: {
                        calendarDetails: calendarDetails,
                    },
                };
            },
        },
        changeIsDashboardLoading: {
            reducer: (state: IDashboardState, action: PayloadAction<IChangeIsDashboardLoading>) => {
                return {
                    ...state,
                    isDashboardLoading: action.payload.isDashboardLoading,
                };
            },
            prepare(isDashboardLoading: boolean) {
                return {
                    payload: {isDashboardLoading: isDashboardLoading},
                };
            },
        },
        changeIsDashboardInitialized: {
            reducer: (state: IDashboardState, action: PayloadAction<IChangeIsDashboardInitialized>) => {
                return {
                    ...state,
                    isDashboardInitialized: action.payload.isDashboardInitialized,
                };
            },
            prepare(isDashboardInitialized: boolean) {
                return {
                    payload: {isDashboardInitialized: isDashboardInitialized},
                };
            },
        },
        changeDashboardError: {
            reducer: (state: IDashboardState, action: PayloadAction<IChangeDashboardError>) => {
                return {
                    ...state,
                    offersError: action.payload.error,
                };
            },
            prepare(error: any) {
                return {
                    payload: {error: error},
                };
            },
        },
        fetchPositionsWithOffers: (state: IDashboardState) => {
            return {
                ...state,
                isDashboardLoading: true,
            };
        },
        fetchPositionsWithoutOffers: (state: IDashboardState) => {
            return {
                ...state,
                isDashboardLoading: true,
            };
        },
        fetchOfferApplications: (state: IDashboardState) => {
            return {
                ...state,
                isDashboardLoading: true,
            };
        },
        fetchOffers: (state: IDashboardState) => {
            return {
                ...state,
                isDashboardLoading: true,
            };
        },
        fetchProfileDetails: (state: IDashboardState) => {
            return {
                ...state,
                isDashboardLoading: true,
            };
        },
        fetchCalendarDetails: (state: IDashboardState) => {
            return {
                ...state,
                isDashboardLoading: true,
            };
        },
        rejectOfferApplicationCandidate: {
            reducer: (state: IDashboardState) => {
                return {
                    ...state,
                };
            },
            prepare(candidateId: string) {
                return {
                    payload: {candidateId: candidateId},
                };
            },
        },
        changeIsOfferApplicationCandidateRejected: {
            reducer: (state: IDashboardState, action: PayloadAction<IChangeIsOfferApplicationCandidateRejected>) => {
                return {
                    ...state,
                    isOfferApplicationCandidateRejected: action.payload.isCandidateRejected,
                };
            },
            prepare(isCandidateRejected: boolean) {
                return {
                    payload: {isCandidateRejected: isCandidateRejected},
                };
            },
        },
        resetToInitialDashboardPageState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setPositionsWithOffers,
    setPositionsWithoutOffers,
    setOfferApplications,
    setOffers,
    setProfileDetails,
    setCalendarDetails,
    changeIsDashboardLoading,
    changeIsDashboardInitialized,
    changeDashboardError,
    fetchPositionsWithOffers,
    fetchPositionsWithoutOffers,
    fetchOfferApplications,
    fetchOffers,
    fetchProfileDetails,
    fetchCalendarDetails,
    rejectOfferApplicationCandidate,
    changeIsOfferApplicationCandidateRejected,
    resetToInitialDashboardPageState,
} = dashboardPageSlice.actions;

export default dashboardPageSlice.reducer;
