import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {catchError, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {RootState} from '../reducers';
import {authAccountIdSelector, addAlert, AlertType, authTokenSelector, getErrorMessage} from 'jobhunter-common-web';
import {
    changeBillingInformationPageError,
    changeBillingInformationPageLoading,
    fetchBillingInformation,
    IUpdateBillingInformation,
    setBillingInformation,
    updateBillingInformation,
} from '../reducers/settingsPageSlice';
import {getBillingInfoAPI} from '../../api/getBillingInfoAPI';
import {PayloadAction} from '@reduxjs/toolkit';
import {updateBillingInfoAPI} from '../../api/updateBillingInfoAPI';
import {IModelBillingInformation} from '../../model/billingDataModel';

const fetchBillingInformationEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchBillingInformation.type),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value),
                accountId = authAccountIdSelector(state$.value);
            return getBillingInfoAPI(authToken, accountId).pipe(
                switchMap((resp: any) => {
                    const billingInformation: IModelBillingInformation = resp,
                        actions = successActions(setBillingInformation(billingInformation));
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const updateBillingInformationEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(updateBillingInformation.type),
        switchMap((action: PayloadAction<IUpdateBillingInformation>): any => {
            const authToken = authTokenSelector(state$.value),
                accountId = authAccountIdSelector(state$.value);
            return updateBillingInfoAPI(authToken, action.payload.billingInformation, accountId).pipe(
                switchMap(() => {
                    const successMessage = 'settings.billingInformation.alerts.updated',
                        actions = successActions(addAlert({message: successMessage}));
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const successActions = (changeSliceList: any): any[] => {
    return [changeSliceList, changeBillingInformationPageLoading(false)];
};

const updateListErrorActions = (error: any): any[] => {
    return [
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
        changeBillingInformationPageLoading(false),
        changeBillingInformationPageError(getErrorMessage(error)),
    ];
};

const billingInfoEpic = combineEpics(fetchBillingInformationEpic, updateBillingInformationEpic);

export default billingInfoEpic;
