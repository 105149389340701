import {Observable} from 'rxjs';
import {jobHunterAPI, RestQueryParams} from 'jobhunter-common-web';
import {IAddServicePayload} from '../model/servicesDataModel';

export function addServiceAPI(authToken: string | null, payload: IAddServicePayload): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return jobHunterAPI.post(`api/services`, payload, new RestQueryParams(), headers);
}
