import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelRecruitment} from '../../model/recruitmentDataModel';
import {IModelApiResponseViewObject} from '../../model/base';
import {IChangePagination, IPaginationParams, ISetMetadata} from './offersPageSlice';

export interface IChangeRecruitmentLoading {
    isRecruitmentListLoading: boolean;
}

export interface IChangeRecruitmentInitialized {
    isRecruitmentListInitialized: boolean;
}

export interface IRecruitmentPageError {
    recruitmentListError: string | null;
}

export interface ISetRecruitmentList {
    recruitmentList: IModelRecruitment[] | null;
}

export interface IRecruitmentPageState {
    recruitmentList: IModelRecruitment[] | null;
    recruitmentMetadata: IModelApiResponseViewObject | null;
    pagination: IPaginationParams | null;
    isRecruitmentListLoading: boolean;
    isRecruitmentListInitialized: boolean;
    recruitmentListError: string | null;
}

const initialState: IRecruitmentPageState = {
    recruitmentList: null,
    recruitmentMetadata: null,
    pagination: {
        itemsPerPage: 25,
        page: 1,
    },
    isRecruitmentListInitialized: false,
    isRecruitmentListLoading: false,
    recruitmentListError: null,
};

const recruitmentPageSlice = createSlice({
    name: 'recruitmentPage',
    initialState: initialState,
    reducers: {
        setRecruitmentList: {
            reducer: (state: IRecruitmentPageState, action: PayloadAction<ISetRecruitmentList>) => {
                return {
                    ...state,
                    recruitmentList: action.payload.recruitmentList,
                };
            },
            prepare: (recruitmentList: IModelRecruitment[] | null) => {
                return {
                    payload: {
                        recruitmentList: recruitmentList,
                    },
                };
            },
        },
        fetchRecruitmentList: (state: IRecruitmentPageState) => {
            return {
                ...state,
                isRecruitmentListLoading: true,
            };
        },
        changeIsRecruitmentLoading: {
            reducer: (state: IRecruitmentPageState, action: PayloadAction<IChangeRecruitmentLoading>) => {
                return {
                    ...state,
                    isRecruitmentListLoading: action.payload.isRecruitmentListLoading,
                };
            },
            prepare: (isRecruitmentListLoading: boolean) => {
                return {
                    payload: {
                        isRecruitmentListLoading: isRecruitmentListLoading,
                    },
                };
            },
        },
        changeIsRecruitmentInitialized: {
            reducer: (state: IRecruitmentPageState, action: PayloadAction<IChangeRecruitmentInitialized>) => {
                return {
                    ...state,
                    isRecruitmentListInitialized: action.payload.isRecruitmentListInitialized,
                };
            },
            prepare: (isRecruitmentListInitialized: boolean) => {
                return {
                    payload: {
                        isRecruitmentListInitialized: isRecruitmentListInitialized,
                    },
                };
            },
        },
        changeRecruitmentPageError: {
            reducer: (state: IRecruitmentPageState, action: PayloadAction<IRecruitmentPageError>) => {
                return {
                    ...state,
                    recruitmentListError: action.payload.recruitmentListError,
                };
            },
            prepare: (recruitmentListError: string | null) => {
                return {
                    payload: {
                        recruitmentListError: recruitmentListError,
                    },
                };
            },
        },
        setRecruitmentMetadata: {
            reducer: (state: IRecruitmentPageState, action: PayloadAction<ISetMetadata>) => {
                return {
                    ...state,
                    recruitmentMetadata: action.payload.metadata,
                };
            },
            prepare(metadata: IModelApiResponseViewObject | null) {
                return {
                    payload: {
                        metadata: metadata,
                    },
                };
            },
        },
        changeRecruitmentPagination: {
            reducer: (state: IRecruitmentPageState, action: PayloadAction<IChangePagination>) => {
                return {
                    ...state,
                    isRecruitmentListLoading: true,
                    pagination: action.payload.pagination,
                };
            },
            prepare(pagination: IPaginationParams) {
                return {
                    payload: {pagination: pagination},
                };
            },
        },
        resetToInitialRecruitmentState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setRecruitmentList,
    fetchRecruitmentList,
    resetToInitialRecruitmentState,
    changeIsRecruitmentInitialized,
    changeIsRecruitmentLoading,
    changeRecruitmentPageError,
    setRecruitmentMetadata,
    changeRecruitmentPagination,
} = recruitmentPageSlice.actions;

export default recruitmentPageSlice.reducer;
