import {
    FormControlType,
    IFormConfig,
    multiCollectionInputDataMapper,
    multiCollectionOutputDataMapper,
    MultiSelectType,
    IMultiselectOption,
    FormButtonType,
} from 'jobhunter-common-web';

export const companyDetailsFormConfig: typeof IFormConfig = (
    cities: typeof IMultiselectOption[] | [],
    companyTypes: typeof IMultiselectOption[] | [],
    industries: typeof IMultiselectOption[] | [],
    organizationSizes: typeof IMultiselectOption[] | [],
    prevButtonIcon: any,
    nextButtonIcon: any,
    isAdminAccount: boolean
) => ({
    controlType: 'form',
    class: 'default-form employer-page-form',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'location',
            controlType: 'group',
            class: 'row',
            controls: {
                cities: {
                    controlType: 'control',
                    defaultValue: '',
                    disabled: !isAdminAccount,
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.DEFAULT,
                    multiselectOptions: cities,
                    validationRules: [],
                    placeholder: 'employer.companyDetails.form.placeholders.select',
                    label: 'employer.companyDetails.form.labels.location',
                    hostClass: 'col-xl-12 form-control',
                    inputDataMapper: multiCollectionInputDataMapper,
                    outputDataMapper: multiCollectionOutputDataMapper,
                },
            },
        },
        {
            key: 'company_type',
            controlType: 'group',
            class: 'row',
            controls: {
                companyTypes: {
                    controlType: 'control',
                    defaultValue: '',
                    disabled: !isAdminAccount,
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.DEFAULT,
                    multiselectOptions: companyTypes,
                    validationRules: [],
                    placeholder: 'employer.companyDetails.form.placeholders.select',
                    label: 'employer.companyDetails.form.labels.companyType',
                    hostClass: 'col-xl-12 form-control',
                    inputDataMapper: multiCollectionInputDataMapper,
                    outputDataMapper: multiCollectionOutputDataMapper,
                },
            },
        },
        {
            key: 'industry',
            controlType: 'group',
            class: 'row',
            controls: {
                industries: {
                    controlType: 'control',
                    defaultValue: '',
                    disabled: !isAdminAccount,
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.DEFAULT,
                    multiselectOptions: industries,
                    validationRules: [],
                    placeholder: 'employer.companyDetails.form.placeholders.select',
                    label: 'employer.companyDetails.form.labels.industry',
                    hostClass: 'col-xl-12 form-control',
                    inputDataMapper: multiCollectionInputDataMapper,
                    outputDataMapper: multiCollectionOutputDataMapper,
                },
            },
        },
        {
            key: 'organization_size',
            controlType: 'group',
            class: 'row',
            controls: {
                organizationSize: {
                    controlType: 'control',
                    defaultValue: '',
                    disabled: !isAdminAccount,
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    multiselectOptions: organizationSizes ? organizationSizes : [],
                    validationRules: [],
                    placeholder: 'employer.companyDetails.form.placeholders.select',
                    label: 'employer.companyDetails.form.labels.organisationSize',
                    hostClass: 'col-xl-12 form-control big-single-select',
                    inputDataMapper: multiCollectionInputDataMapper,
                    outputDataMapper: multiCollectionOutputDataMapper,
                },
            },
        },
        {
            controlType: 'group',
            key: 'company_details_buttons',
            class: 'row justify-content-between',
            controls: {
                previousButton: {
                    buttonName: 'previous',
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.BUTTON,
                    inputStyles: 'btn-prev btn-primary d-flex align-items-center company-details-prev-button',
                    hostClass: 'mb-0 w-auto',
                    disabled: false,
                    defaultContainerStyles: '',
                    containerStyles: '',
                    defaultValue: null,
                    buttonPreIcon: prevButtonIcon,
                    btnText: 'buttons.previous',
                    buttonDisabled: () => {
                        return false;
                    },
                },
                submitButton: {
                    buttonName: 'submit',
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    inputStyles: 'btn-submit btn-primary d-flex align-items-center',
                    hostClass: 'mb-0 align-items-end w-auto',
                    defaultContainerStyles: '',
                    containerStyles: '',
                    buttonPreIcon: nextButtonIcon,
                    defaultValue: null,
                    btnText: 'buttons.save',
                    buttonDisabled: (isFormValid: boolean) => {
                        return !isFormValid || !isAdminAccount;
                    },
                },
            },
        },
    ],
});
