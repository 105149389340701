import {Observable} from 'rxjs';
import {jobHunterAPI, RestQueryParams} from 'jobhunter-common-web';

export function acceptInvitationAPI(authToken: string | null): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            'X-Authentication-Token': authToken,
        };
    }
    return jobHunterAPI.post(`api/hr_invitations/accept`, {}, new RestQueryParams(), headers);
}
