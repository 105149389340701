import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {catchError, map, switchMap} from 'rxjs/operators';
import {forkJoin, Observable, of} from 'rxjs';
import {RootState} from '../reducers';
import {
    addAlert,
    AlertType,
    authTokenSelector,
    flattenObj,
    getErrorMessage,
    getMetadataDetails,
    RestQueryParams,
} from 'jobhunter-common-web';
import {
    acceptCandidate,
    applyCandidatesFilters,
    changeCandidateHired,
    changeCandidateListError,
    changeCandidateRejected,
    changeCandidatesPagination,
    changeIsApplicationScheduled,
    changeIsCandidatesListLoading,
    fetchCandidateFilterOptions,
    fetchCandidatesList,
    fetchRecruitersList,
    hireCandidate,
    ICandidateAction,
    IScheduleApplicationMeeting,
    rejectCandidate,
    scheduleApplicationMeeting,
    setCandidateFilterOptions,
    setCandidatesList,
    setCandidatesMetadata,
    setRecruitersList,
} from '../reducers/candidatesPageSlice';
import {getOfferApplicationsAPI} from '../../api/getOfferApplicationsAPI';
import {getOffersAPI} from '../../api/getOffersAPI';
import {getSoughtPositionsAPI} from '../../api/getSoughtPositionsAPI';
import {candidateFiltersSelector, candidatesPaginationSelector} from '../selectors/candidatesPageSelectors';
import {PayloadAction} from '@reduxjs/toolkit';
import {rejectCandidateAPI} from '../../api/rejectCandidateAPI';
import {hireCandidateAPI} from '../../api/hireCandidateAPI';
import {EmployeeType, getEmployeesAPI} from '../../api/getEmployeesAPI';
import {scheduleApplicationMeetingAPI} from '../../api/scheduleApplicationMeetingAPI';
import {acceptCandidateAPI} from '../../api/acceptCandidateAPI';

const applyCandidatesFiltersEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getApplicationCandidates(action$, state$, applyCandidatesFilters);
};

const fetchCandidatesListEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getApplicationCandidates(action$, state$, fetchCandidatesList);
};

const changeCandidatesPaginationEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getApplicationCandidates(action$, state$, changeCandidatesPagination);
};

const fetchFilterOptionsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchCandidateFilterOptions.type),
        switchMap(() => {
            const authToken = authTokenSelector(state$.value),
                offerParams = new RestQueryParams().add('itemsPerPage', '100');
            const requests = {
                candidate: getOfferApplicationsAPI(authToken).pipe(
                    map((resp: any) => {
                        const list = resp['hydra:member'].map((item: {[key: string]: any}) => {
                            return {
                                value: `${item.candidate.account.lastName}`,
                                label: `${item.candidate.account.firstName}  ${item.candidate.account.lastName}`,
                            };
                        });
                        return list.reduce((acc: {[key: string]: any}, current: {[key: string]: any}) => {
                            const x = acc.find((item: any) => item.value === current.value);
                            return !x ? acc.concat([current]) : acc;
                        }, []);
                    })
                ),
                offer: getOffersAPI(authToken, offerParams).pipe(
                    map((resp: any) => {
                        return resp[`hydra:member`].map((item: {[key: string]: any}) => {
                            return {label: item.offer.name, value: item.offer.id};
                        });
                    })
                ),
                position: getSoughtPositionsAPI(authToken).pipe(
                    map((resp: any) => {
                        return resp[`hydra:member`].map((item: {[key: string]: any}) => {
                            return {label: item.name, value: item.name};
                        });
                    })
                ),
            };
            return forkJoin(requests).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([setCandidateFilterOptions(resp)]);
                    return of(...actions);
                })
            );
        })
    );
};

const hireCandidateEpic: Epic = (action$: Observable<any>, state$: StateObservable<RootState>) => {
    return interactWithCandidate(
        action$,
        state$,
        hireCandidate,
        hireCandidateAPI,
        changeCandidateHired(true),
        'humanResources.dashboard.candidates.hireCandidateModal.candidateHired'
    );
};

const acceptCandidateEpic: Epic = (action$: Observable<any>, state$: StateObservable<RootState>) => {
    return interactWithCandidate(
        action$,
        state$,
        acceptCandidate,
        acceptCandidateAPI,
        changeCandidateHired(true),
        'humanResources.dashboard.candidates.acceptCandidateModal.candidateAccepted'
    );
};

const rejectCandidateEpic: Epic = (action$: Observable<any>, state$: StateObservable<RootState>) => {
    return interactWithCandidate(
        action$,
        state$,
        rejectCandidate,
        rejectCandidateAPI,
        changeCandidateRejected(true),
        'humanResources.dashboard.candidates.rejectCandidateModal.candidateRejected'
    );
};

const scheduleApplicationMeetingEpic: Epic = (action$: Observable<any>, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(scheduleApplicationMeeting.type),
        switchMap((action: PayloadAction<IScheduleApplicationMeeting>) => {
            const authToken = authTokenSelector(state$.value),
                payload = action.payload.applicationPayload;
            return scheduleApplicationMeetingAPI(authToken, payload).pipe(
                switchMap(() => {
                    const message = 'dashboard.offerApplications.addRecruiterModal.meetingScheduled',
                        actions = changeCandidateStatusSuccessActions(changeIsApplicationScheduled(true), message);

                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const interactWithCandidate = (
    action$: Observable<any>,
    state$: StateObservable<RootState>,
    actionType: any,
    api: any,
    changeAction: any,
    successMessage: string
) => {
    return action$.pipe(
        ofType(actionType.type),
        switchMap((action: PayloadAction<ICandidateAction>) => {
            const authToken = authTokenSelector(state$.value);
            return api(authToken, action.payload.applicationId).pipe(
                switchMap(() => {
                    const actions = changeCandidateStatusSuccessActions(changeAction, successMessage);

                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const getApplicationCandidates = (action$: Observable<any>, state$: StateObservable<RootState>, actionType: any) => {
    return action$.pipe(
        ofType(actionType.type),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value),
                candidateFilters = candidateFiltersSelector(state$.value),
                paginationParams = candidatesPaginationSelector(state$.value),
                filterObj = {
                    ...candidateFilters,
                    ...paginationParams,
                },
                flattenedParams = flattenObj(filterObj),
                params = new RestQueryParams(flattenedParams);

            return getOfferApplicationsAPI(authToken, params).pipe(
                switchMap((resp: any) => {
                    const metadata = getMetadataDetails(resp['hydra:view']),
                        actions = successActions([setCandidatesList(resp[`hydra:member`]), setCandidatesMetadata(metadata)]);
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const fetchRecruitersListEpic: Epic = (action$: Observable<any>, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchRecruitersList.type),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value);
            return getEmployeesAPI(authToken, EmployeeType.EXTERNAL).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([setRecruitersList(resp[`hydra:member`])]);
                    return of(...actions);
                }),
                catchError((error: any) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error: any) => of(...updateListErrorActions(error)))
    );
};

const changeCandidateStatusSuccessActions = (changeAction: any, successMessage: string): any[] => {
    return [
        fetchCandidatesList(),
        changeAction,
        addAlert({
            message: successMessage,
            type: AlertType.SUCCESS,
        }),
    ];
};

const successActions = (changeSliceList: any[]): any[] => {
    const actions = [changeIsCandidatesListLoading(false)];
    return [...changeSliceList, ...actions];
};

const updateListErrorActions = (error: any): any[] => {
    return [addAlert({message: getErrorMessage(error), type: AlertType.WARNING}), changeCandidateListError(getErrorMessage(error))];
};

const candidatesEpic = combineEpics(
    fetchCandidatesListEpic,
    fetchFilterOptionsEpic,
    applyCandidatesFiltersEpic,
    rejectCandidateEpic,
    hireCandidateEpic,
    fetchRecruitersListEpic,
    scheduleApplicationMeetingEpic,
    changeCandidatesPaginationEpic,
    acceptCandidateEpic
);

export default candidatesEpic;
