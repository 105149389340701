import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IBillingInformationState} from '../reducers/settingsPageSlice';

export const selectBillingInfoPage = (state: RootState) => {
    return state.billingInformationPage;
};

export const billingInformationSelector = createSelector(
    [selectBillingInfoPage],
    (state: IBillingInformationState) => state.billingInformation
);
export const isBillingInformationLoadingSelector = createSelector(
    [selectBillingInfoPage],
    (state: IBillingInformationState) => state.isBillingInformationLoading
);
export const isBillingInformationInitializedSelector = createSelector(
    [selectBillingInfoPage],
    (state: IBillingInformationState) => state.isBillingInformationInitialized
);
export const billingInformationErrorSelector = createSelector(
    [selectBillingInfoPage],
    (state: IBillingInformationState) => state.billingInformationError
);
