import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {Loader, LoaderType, Translation} from 'jobhunter-common-web';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {candidatesPageErrorSelector, isCandidateHiredSelector} from '../../../../store/selectors/candidatesPageSelectors';
import {acceptCandidate, changeCandidateHired, hireCandidate} from '../../../../store/reducers/candidatesPageSlice';
import {IApplicationOffer} from '../../../../model/offerDataModel';

export enum CandidateModalType {
    HIRE = 'hire',
    ACCEPT = 'accept',
}

interface IExternalAcceptCandidateProps {
    isModalOpen: boolean;
    toggleModal: (candidate: IApplicationOffer | null, type?: CandidateModalType) => void;
    type: CandidateModalType;
    candidate?: IApplicationOffer | null;
}
interface IConnectedAcceptCandidateProps {
    readonly isCandidateHired: boolean;
    readonly error: string | null;
    readonly hireCandidate: typeof hireCandidate;
    readonly acceptCandidate: typeof acceptCandidate;
    readonly changeCandidateHired: typeof changeCandidateHired;
}
interface IAcceptCandidateProps extends IConnectedAcceptCandidateProps, IExternalAcceptCandidateProps {}

interface IAcceptCandidateState {
    isLoading: boolean;
}

class AcceptCandidate extends Component<IAcceptCandidateProps, IAcceptCandidateState> {
    constructor(props: IAcceptCandidateProps) {
        super(props);

        this.state = {
            isLoading: false,
        };
    }

    componentDidUpdate(prevProps: IAcceptCandidateProps): void {
        if (this.props.isCandidateHired !== prevProps.isCandidateHired && this.props.isCandidateHired) {
            this.props.toggleModal(null);
        }

        if (this.props.error !== prevProps.error && this.props.error) {
            this.setState({isLoading: false});
        }
    }

    componentWillUnmount() {
        this.props.changeCandidateHired(false);
    }

    render() {
        const translationKey = this.props.type === CandidateModalType.ACCEPT ? 'acceptCandidateModal' : 'hireCandidateModal';
        return (
            <Modal isOpen={this.props.isModalOpen} toggle={() => this.props.toggleModal(null)}>
                <ModalHeader toggle={() => this.props.toggleModal(null)}>
                    <Translation text={`humanResources.dashboard.candidates.${translationKey}.title`} />
                </ModalHeader>
                <ModalBody>
                    <p className="modal-description">
                        <Translation text={`humanResources.dashboard.candidates.${translationKey}.description`} />
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" className="close-modal-button" outline onClick={() => this.props.toggleModal(null)}>
                        <Translation text="buttons.cancel" />
                    </Button>

                    <Button color="primary" className="accept-candidate-button" onClick={() => this.hireCandidate()}>
                        <Translation text="buttons.accept" />
                    </Button>
                </ModalFooter>
                <Loader type={LoaderType.Local} showLoader={this.state.isLoading} />
            </Modal>
        );
    }

    private hireCandidate = () => {
        if (this.props.candidate === null || this.props.candidate === undefined || this.props.candidate?.applicationId === null) {
            return;
        }

        this.setState({isLoading: true});

        this.props.type === CandidateModalType.ACCEPT
            ? this.props.acceptCandidate(this.props.candidate?.applicationId)
            : this.props.hireCandidate(this.props.candidate?.applicationId);
    };
}

export default connect(
    (state: RootState) => ({
        isCandidateHired: isCandidateHiredSelector(state),
        error: candidatesPageErrorSelector(state),
    }),
    {hireCandidate, acceptCandidate, changeCandidateHired}
)(AcceptCandidate);
