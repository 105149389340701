import React, {Component} from 'react';
import {Button, Card, CardBody, CardTitle, Table} from 'reactstrap';
import LayoutWrapper from '../../LayoutWrapper';
import InvitationModal from './InvitationModal';
import {Translation, LoaderType, Loader, ListItemDetails} from 'jobhunter-common-web';
// import {Trash2} from 'react-feather';
import {WithTranslation, withTranslation} from 'react-i18next';
import RemoveCompanyModal from './RemoveCompany';
import {HRListType} from '../../../api/getHRListAPI';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {IModelHumanResourceInvitation} from '../../../model/hrListDataModel';
import {
    cooperatingHRListSelector,
    invitedHRListSelector,
    isCooperatingListLoadingSelector,
    isInvitedListLoadingSelector,
} from '../../../store/selectors/hrPageSelectors';
import {fetchCooperatingHRList, fetchInvitedHRList, resetToInitialHRPageState} from '../../../store/reducers/hrPageSlice';

interface IConnectedCooperatingHumanResourcesProps {
    readonly pendingList: IModelHumanResourceInvitation[] | null;
    readonly acceptedList: IModelHumanResourceInvitation[] | null;
    readonly fetchCooperatingHRList: typeof fetchCooperatingHRList;
    readonly fetchInvitedHRList: typeof fetchInvitedHRList;
    readonly isAcceptedLoading: boolean;
    readonly isPendingLoading: boolean;
    readonly resetToInitialHRPageState: typeof resetToInitialHRPageState;
}

interface IExternalCooperatingHumanResourcesProps {}

interface ICooperatingHumanResourcesProps
    extends WithTranslation,
        IConnectedCooperatingHumanResourcesProps,
        IExternalCooperatingHumanResourcesProps {}

interface ICooperatingHumanResourcesState {
    isModalVisible: boolean;
    isDeleteModalVisible: boolean;
    selectedCompany: {[key: string]: any} | null;
}

class CooperatingHumanResources extends Component<ICooperatingHumanResourcesProps, ICooperatingHumanResourcesState> {
    constructor(props: ICooperatingHumanResourcesProps) {
        super(props);

        this.state = {
            isModalVisible: false,
            isDeleteModalVisible: false,
            selectedCompany: null,
        };
    }

    componentDidMount(): void {
        this.props.fetchCooperatingHRList();
        this.props.fetchInvitedHRList();
    }

    componentWillUnmount() {
        this.props.resetToInitialHRPageState();
    }

    render() {
        return (
            <>
                <LayoutWrapper>
                    <div className="panel-section-header">
                        <Translation text="employer.cooperatingHr.title" />
                    </div>

                    <div className="row">
                        <div className="col-lg-6 col-12">{this.renderCooperatingHumanResources()}</div>
                        <div className="col-lg-6 col-12">{this.renderInvitations()}</div>
                    </div>
                </LayoutWrapper>

                {this.state.isModalVisible ? (
                    <InvitationModal isModalOpen={this.state.isModalVisible} toggleModal={this.toggleModal} />
                ) : null}

                {this.state.isDeleteModalVisible ? (
                    <RemoveCompanyModal
                        isModalOpen={this.state.isDeleteModalVisible}
                        toggleModal={this.toggleDeleteModal}
                        company={this.state.selectedCompany}
                    />
                ) : null}
            </>
        );
    }

    private renderCooperatingHumanResources = () => {
        return (
            <Card className="cooperating-human-resources-card">
                <CardBody>
                    <CardTitle>
                        <div className="card-header">
                            <div>
                                <Translation text="employer.cooperatingHr.title" />
                            </div>
                        </div>
                    </CardTitle>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>
                                    <Translation text="employer.cooperatingHr.table.company" />
                                </th>
                                {/*<th>*/}
                                {/*    <Translation text="employer.cooperatingHr.table.actions" />*/}
                                {/*</th>*/}
                            </tr>
                        </thead>
                        <tbody>{this.renderTableRows(this.props.acceptedList, HRListType.ACCEPTED)}</tbody>
                    </Table>
                </CardBody>
                <Loader type={LoaderType.Local} showLoader={this.props.isAcceptedLoading} />
            </Card>
        );
    };

    private renderInvitations = () => {
        return (
            <Card className="invitations-card">
                <CardBody>
                    <CardTitle>
                        <div className="card-header">
                            <div>
                                <Translation text="employer.cooperatingHr.invitationHr" />
                            </div>
                            <Button color="primary" className="add-hr-company" onClick={() => this.toggleModal()}>
                                <Translation text="employer.cooperatingHr.addCompany" />
                            </Button>
                        </div>
                    </CardTitle>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>
                                    <Translation text="employer.cooperatingHr.table.company" />
                                </th>
                                {/*<th>*/}
                                {/*    <Translation text="employer.cooperatingHr.table.actions" />*/}
                                {/*</th>*/}
                            </tr>
                        </thead>
                        <tbody>{this.renderTableRows(this.props.pendingList, HRListType.PENDING)}</tbody>
                    </Table>
                </CardBody>
                <Loader type={LoaderType.Local} showLoader={this.props.isPendingLoading} />
            </Card>
        );
    };

    private renderTableRows(list: IModelHumanResourceInvitation[] | null, type: HRListType) {
        const {t} = this.props;
        if (list?.length === 0 || !list) {
            const translationKey =
                type === HRListType.ACCEPTED
                    ? 'employer.cooperatingHr.table.emptyAcceptedList'
                    : 'employer.cooperatingHr.table.emptyPendingList';
            return (
                <tr key={`${type}_empty_list`}>
                    <td>
                        <Translation text={translationKey} />
                    </td>
                </tr>
            );
        }

        return list.map((item: IModelHumanResourceInvitation) => {
            const organizationName = item?.organization?.name ? item?.organization?.name : t('employer.cooperatingHr.table.noCompanyName');
            return (
                <tr key={item.id}>
                    <td>
                        <ListItemDetails avatar={item?.organization?.avatar} name={organizationName} />
                    </td>
                    {/*There is no delete option in current version*/}
                    {/*<td>*/}
                    {/*    <Button color="flat-primary" onClick={() => this.toggleDeleteModal(item)}>*/}
                    {/*        <Trash2 id={`delete_${item.id}`} size={20} />*/}
                    {/*        <Tooltip target={`delete_${item.id}`} tooltipText={t('humanResources.employee.tooltips.delete')} />*/}
                    {/*    </Button>*/}
                    {/*</td>*/}
                </tr>
            );
        });
    }

    private toggleModal = () => {
        this.setState({isModalVisible: !this.state.isModalVisible});
    };

    private toggleDeleteModal = (company: {[key: string]: any} | null) => {
        this.setState({
            isDeleteModalVisible: !this.state.isDeleteModalVisible,
            selectedCompany: company,
        });
    };
}

export default connect(
    (state: RootState) => ({
        acceptedList: cooperatingHRListSelector(state),
        pendingList: invitedHRListSelector(state),
        isAcceptedLoading: isCooperatingListLoadingSelector(state),
        isPendingLoading: isInvitedListLoadingSelector(state),
    }),
    {fetchCooperatingHRList, fetchInvitedHRList, resetToInitialHRPageState}
)(withTranslation()(CooperatingHumanResources));
