import {Translation} from 'jobhunter-common-web';
import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {IModelServiceListItem} from '../../../../model/servicesDataModel';

interface IRemoveServiceProps {
    service: IModelServiceListItem;
    removeService: (serviceId: string) => void;
    toggleModal: (service: IModelServiceListItem | null) => void;
}

class RemoveService extends Component<IRemoveServiceProps> {
    render() {
        return (
            <Modal contentClassName="add-verification-file-modal" isOpen={true} toggle={() => this.props.toggleModal(null)}>
                <ModalHeader toggle={() => this.props.toggleModal(null)}>
                    <Translation text="marketplace.services.modal.removeService.title" />
                </ModalHeader>
                <ModalBody>
                    <div className="modal-description remove-verification">
                        <p className="mb-2">
                            <Translation
                                text="marketplace.services.modal.removeService.description"
                                config={{title: this.props.service.title}}
                            />
                        </p>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" outline className="btn-prev close-modal-button" onClick={() => this.props.toggleModal(null)}>
                        <span className="align-middle d-sm-inline-block d-none">
                            <Translation text="buttons.cancel" />
                        </span>
                    </Button>
                    <Button
                        color="primary"
                        className="btn-next remove-service-button"
                        onClick={() => this.removeService(this.props.service.id)}>
                        <span className="align-middle d-sm-inline-block d-none">
                            <Translation text="buttons.remove" />
                        </span>
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    private removeService = (serviceId: string | undefined) => {
        if (!serviceId) return;
        this.props.removeService(serviceId);
        this.props.toggleModal(null);
    };
}

export default RemoveService;
