import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IPreferredCandidatesState} from '../reducers/preferredCandidatesPageSlice';

export const selectPreferredCandidatesPage = (state: RootState) => {
    return state.preferredCandidatesPage;
};

export const preferredCandidatesListSelector = createSelector(
    [selectPreferredCandidatesPage],
    (state: IPreferredCandidatesState) => state.preferredCandidatesList
);

export const preferredCandidatesMetadataSelector = createSelector(
    [selectPreferredCandidatesPage],
    (state: IPreferredCandidatesState) => state.preferredCandidatesMetadata
);

export const preferredCandidatesPaginationSelector = createSelector(
    [selectPreferredCandidatesPage],
    (state: IPreferredCandidatesState) => state.pagination
);

export const isPreferredCandidatesListInitializedSelector = createSelector(
    [selectPreferredCandidatesPage],
    (state: IPreferredCandidatesState) => state.isPreferredCandidatesListInitialized
);

export const isPreferredCandidatesListLoadingSelector = createSelector(
    [selectPreferredCandidatesPage],
    (state: IPreferredCandidatesState) => state.isPreferredCandidatesListLoading
);

export const preferredCandidatesPageErrorSelector = createSelector(
    [selectPreferredCandidatesPage],
    (state: IPreferredCandidatesState) => state.preferredCandidatesPageError
);
