import React, {Component} from 'react';
import LayoutWrapper from '../../LayoutWrapper';
import {Badge, Button, Card, CardBody, Table} from 'reactstrap';
import {Tooltip, Translation, Switch, Loader, LoaderType, Pagination} from 'jobhunter-common-web';
import styles from './styles.module.scss';
import {Edit2, Eye, Trash2} from 'react-feather';
import {withTranslation, WithTranslation} from 'react-i18next';
import AddEmployee from './AddEmployeeModal';
import EmployeeView from './EmployeeView';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {
    changeEmployeesPagination,
    fetchExternalEmployeesList,
    resetToInitialEmployeesPageState,
} from '../../../store/reducers/employeesPageSlice';
import {
    employeesMetadataSelector,
    externalEmployeesListSelector,
    isExternalEmployeesListLoadingSelector,
} from '../../../store/selectors/employeesPageSelectors';
import {EmployeeType, IModelExternalEmployee} from '../../../model/employeeDataModel';
import DeleteEmployeeModal from './DeleteEmployeeModal';
import {IModelApiResponseViewObject} from '../../../model/base';

interface IConnectedEmployeeProps {
    readonly externalEmployeeList: IModelExternalEmployee[] | null;
    readonly employeesMetadata: IModelApiResponseViewObject | null;
    readonly isExternalListLoading: boolean;
    readonly fetchExternalEmployeesList: typeof fetchExternalEmployeesList;
    readonly changeEmployeesPagination: typeof changeEmployeesPagination;
    readonly resetToInitialEmployeesPageState: typeof resetToInitialEmployeesPageState;
}

interface IEmployeeProps extends IConnectedEmployeeProps, WithTranslation {}

interface IEmployeeState {
    isAddEmployeeModalOpen: boolean;
    isEmployeeViewModalOpen: boolean;
    isDeleteModalOpen: boolean;
    selectedEmployee: null | {[key: string]: any};
}

class Employee extends Component<IEmployeeProps, IEmployeeState> {
    constructor(props: IEmployeeProps) {
        super(props);

        this.state = {
            isAddEmployeeModalOpen: false,
            isEmployeeViewModalOpen: false,
            isDeleteModalOpen: false,
            selectedEmployee: null,
        };
    }

    componentDidMount(): void {
        this.props.fetchExternalEmployeesList();
    }

    componentWillUnmount() {
        this.props.resetToInitialEmployeesPageState();
    }

    render() {
        return (
            <>
                <LayoutWrapper>
                    <div className={styles.header}>
                        <div className="panel-section-header">
                            <Translation text="humanResources.employee.title" />
                        </div>
                        <Button color="primary" className="add-employee-button" onClick={() => this.toggleAddEmployeeModal()}>
                            <Translation text="humanResources.employee.addNewEmployee" />
                        </Button>
                    </div>

                    <Card className="employees-card">
                        <CardBody>{this.renderEmployees()}</CardBody>
                    </Card>

                    <Pagination listMetadata={this.props.employeesMetadata} changePage={this.props.changeEmployeesPagination} />
                </LayoutWrapper>

                {this.state.isAddEmployeeModalOpen ? (
                    <AddEmployee
                        isModalOpen={this.state.isAddEmployeeModalOpen}
                        toggleModal={this.toggleAddEmployeeModal}
                        employee={this.state.selectedEmployee}
                    />
                ) : null}

                {this.state.isEmployeeViewModalOpen ? (
                    <EmployeeView
                        isModalOpen={this.state.isEmployeeViewModalOpen}
                        toggleModal={this.toggleEmployeeViewModal}
                        employee={this.state.selectedEmployee}
                    />
                ) : null}

                {this.state.isDeleteModalOpen ? (
                    <DeleteEmployeeModal
                        isModalOpen={this.state.isDeleteModalOpen}
                        toggleModal={this.toggleDeleteModal}
                        employee={this.state.selectedEmployee}
                    />
                ) : null}
            </>
        );
    }

    private renderEmployees = () => {
        return (
            <>
                <Table responsive striped={true} className="employees-table">
                    <thead>
                        <tr>
                            <th>
                                <Translation text="humanResources.employee.table.employees" />
                            </th>
                            <th>
                                <Translation text="humanResources.employee.table.type" />
                            </th>
                            <th>
                                <Translation text="humanResources.employee.table.email" />
                            </th>
                            <th>
                                <Translation text="humanResources.employee.table.active" />
                            </th>
                            <th>
                                <Translation text="humanResources.employee.table.actions" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>{this.renderTableRows()}</tbody>
                </Table>
                <Loader type={LoaderType.Local} showLoader={this.props.isExternalListLoading} />
            </>
        );
    };

    private renderTableRows() {
        const {t} = this.props,
            list: IModelExternalEmployee[] | null = this.props.externalEmployeeList;

        if (list?.length === 0 || !list) {
            return (
                <tr key={`empty_list`}>
                    <td colSpan={5}>
                        <Translation text={'humanResources.employee.table.emptyExternal'} />
                    </td>
                </tr>
            );
        }

        return list.map((item: IModelExternalEmployee) => {
            const isExternalEmployee = item.type === EmployeeType.EXTERNAL_EMPLOYEE;
            return (
                <tr key={item.id}>
                    <td>
                        <p className="fw-bold">
                            {item.firstName} {item.lastName}
                        </p>
                    </td>
                    <td>
                        <Badge color={isExternalEmployee ? 'light-info' : 'light-primary'} pill>
                            <Translation
                                text={
                                    isExternalEmployee
                                        ? 'humanResources.employee.types.external'
                                        : 'humanResources.employee.types.organization'
                                }
                            />
                        </Badge>
                    </td>
                    <td>{item.email}</td>
                    <td>
                        {item.type === EmployeeType.ORGANIZATION_EMPLOYEE ? (
                            <Switch name={item.id} checked={item.active} disabled={item.type === EmployeeType.ORGANIZATION_EMPLOYEE} />
                        ) : (
                            <p>---</p>
                        )}
                    </td>
                    <td>
                        <div>
                            <Button
                                color="flat-primary"
                                className="view-employee-button"
                                onClick={() => this.toggleEmployeeViewModal(item)}>
                                <Eye id={`view_${item.id}`} size={20} />
                                <Tooltip target={`view_${item.id}`} tooltipText={t('humanResources.employee.tooltips.view')} />
                            </Button>

                            <Button color="flat-primary" className="edit-employee-button" onClick={() => this.toggleAddEmployeeModal(item)}>
                                <Edit2 id={`edit_${item.id}`} size={20} />
                                <Tooltip target={`edit_${item.id}`} tooltipText={t('humanResources.employee.tooltips.edit')} />
                            </Button>

                            <Button
                                color="flat-primary"
                                className="remove-employee-button"
                                onClick={() => this.toggleDeleteModal(item)}
                                disabled={item.type === EmployeeType.ORGANIZATION_EMPLOYEE}>
                                <Trash2 id={`delete_${item.id}`} size={20} />
                                <Tooltip target={`delete_${item.id}`} tooltipText={t('humanResources.employee.tooltips.delete')} />
                            </Button>
                        </div>
                    </td>
                </tr>
            );
        });
    }

    private toggleDeleteModal = (item?: {[key: string]: any} | null) => {
        this.setState({
            isDeleteModalOpen: !this.state.isDeleteModalOpen,
            selectedEmployee: item ? item : null,
        });
    };

    private toggleAddEmployeeModal = (item?: {[key: string]: any} | null) => {
        this.setState({
            isAddEmployeeModalOpen: !this.state.isAddEmployeeModalOpen,
            selectedEmployee: item ? item : null,
        });
    };

    private toggleEmployeeViewModal = (item: {[key: string]: any} | null) => {
        this.setState({
            selectedEmployee: item,
            isEmployeeViewModalOpen: !this.state.isEmployeeViewModalOpen,
        });
    };
}

export default connect(
    (state: RootState) => ({
        externalEmployeeList: externalEmployeesListSelector(state),
        employeesMetadata: employeesMetadataSelector(state),
        isExternalListLoading: isExternalEmployeesListLoadingSelector(state),
    }),
    {
        fetchExternalEmployeesList,
        changeEmployeesPagination,
        resetToInitialEmployeesPageState,
    }
)(withTranslation()(Employee));
