import {
    AccountImageHost,
    accountSelector,
    authTokenSelector,
    countriesSelector,
    deepCloneObject,
    Form,
    FormControlChangeType,
    IFileOutput,
    IFormConfig,
    IModelDictionaryDatum,
    convertToMultiselectLabels,
    isSameValue,
    Loader,
    LoaderType,
    Translation,
    sortMultiselectLabels,
} from 'jobhunter-common-web';
import {Component} from 'react';
import {ArrowRight, Save} from 'react-feather';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {BehaviorSubject, Subscription} from 'rxjs';
import {debounceTime, filter, tap} from 'rxjs/operators';
import {RootState} from '../../../../store/reducers';
import {changeEmployerInformation, IEmployerInformation, submitPersonalInformation} from '../../../../store/reducers/employerPageSlice';
import {employerInformationSelector, employerPageLoadingSelector} from '../../../../store/selectors/employerPageSelectors';
import {personalInformationFormConfig} from './personalInformationFormConfig';

interface IConnectedPersonalInformationProps {
    readonly countries: typeof IModelDictionaryDatum[] | null;
    readonly authToken: string | null;
    readonly account: any;
    readonly employerInformation: IEmployerInformation | null;
    readonly isLoading: boolean;
    readonly changeEmployerInformation: typeof changeEmployerInformation;
    readonly submitPersonalInformation: typeof submitPersonalInformation;
}

interface IExternalPersonalInformationProps {
    readonly stepper: any;
    readonly isAdminAccount: boolean;
}

interface IPersonalInformationProps extends IConnectedPersonalInformationProps, IExternalPersonalInformationProps, WithTranslation {}

interface IPersonalInformationState {
    value: any;
    formConfig: typeof IFormConfig | null;
}

class PersonalInformation extends Component<IPersonalInformationProps, IPersonalInformationState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IPersonalInformationProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: null,
        };
    }

    componentDidMount(): void {
        this.setFormValuesFromState();
        this.setFormConfig();

        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    debounceTime(500),
                    tap((data: any) => this.changeStatePersonalInformation(data.value))
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: Readonly<IPersonalInformationProps>): void {
        if (this.props.employerInformation && this.props.employerInformation !== prevProps.employerInformation) {
            this.setFormValuesFromState();
        }

        if (
            !isSameValue(this.props.countries, prevProps.countries) ||
            (!isSameValue(this.props.employerInformation, prevProps.employerInformation) && this.props.employerInformation)
        ) {
            this.setFormConfig();
        }
    }
    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        const personalInformationImage = this.props.employerInformation?.avatar ? this.props.employerInformation.avatar : null;
        return (
            <section className="wizard-content">
                <div className="personal-information-section">
                    <div className="header">
                        <div>
                            <h2 className="title">
                                <Translation text="employer.personalInformation.title" />
                            </h2>
                            <p className="sub-title">
                                <Translation
                                    text={`employer.personalInformation.${
                                        this.props.isAdminAccount ? 'description' : 'nonAdminDescription'
                                    }`}
                                />
                            </p>
                        </div>
                    </div>

                    <div className={`image-upload-host ${!this.props.isAdminAccount ? 'd-none' : ''}`}>
                        <AccountImageHost
                            externalImage={personalInformationImage}
                            imageUploadAction={(file: typeof IFileOutput) => this.submitPersonalInformation({avatar: file})}
                        />
                    </div>
                </div>

                <div>
                    {this.state.formConfig && (
                        <Form
                            config={this.state.formConfig}
                            onValueStateChange={this.onValueStateChange}
                            value={this.state.value}
                            controlName={'changePersonalDetailsForm'}
                            onButtonClicked={this.goNext}
                        />
                    )}
                </div>
                <Loader type={LoaderType.Local} showLoader={this.props.isLoading} />
            </section>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private goNext = (name: string) => {
        if (name === 'submit') {
            if (this.props.isAdminAccount) this.submitPersonalInformation(this.state.value);
            return this.props.stepper.next();
        }
        return this.props.stepper.previous();
    };

    private submitPersonalInformation = (data: any) => {
        const payloadData = deepCloneObject(data);
        if (data.avatar) {
            payloadData.avatar = data.avatar.id;
        }
        this.props.submitPersonalInformation(payloadData);
    };

    private changeStatePersonalInformation = (data: IEmployerInformation) => {
        const updateStatePayload: IEmployerInformation = this.mapFormDataToState(deepCloneObject(data));
        this.props.changeEmployerInformation(updateStatePayload);
        this.setState({value: updateStatePayload});
    };

    private mapFormDataToState(data: any): IEmployerInformation {
        const currentStateData = this.props.employerInformation,
            updateStatePayload: IEmployerInformation = {
                firstName: data.firstName ? data.firstName : currentStateData?.firstName,
                lastName: data.lastName ? data.lastName : currentStateData?.lastName,
                phone: data.phone ? data.phone : currentStateData?.phone,
                city: data.city ? data.city : currentStateData?.city,
                country: data.country ? data.country : currentStateData?.country,
                email: data.email ? data.email : currentStateData?.email,
                avatar: data.avatar ? data.avatar : currentStateData?.avatar,
                id: data.id ? data.id : currentStateData?.id,
                birthDate: data.birthDate ? data.birthDate : currentStateData?.birthDate,
            };
        return updateStatePayload;
    }

    private setFormConfig = () => {
        const {t} = this.props;
        const countries = this.props.countries ? sortMultiselectLabels(convertToMultiselectLabels(this.props.countries, t)) : [],
            nextButtonIcon = this.props.isAdminAccount ? (
                <Save size={14} className="align-middle me-sm-25 me-0" />
            ) : (
                <ArrowRight size={14} className="align-middle me-sm-25 me-0" />
            ),
            isAdminAccount = this.props.isAdminAccount;

        this.setState({
            formConfig: personalInformationFormConfig(countries, nextButtonIcon, isAdminAccount),
        });
    };

    private setFormValuesFromState = () => {
        const employerInformation = this.props.employerInformation,
            values = {
                firstName: employerInformation?.firstName,
                lastName: employerInformation?.lastName,
                phone: employerInformation?.phone,
                city: employerInformation?.city,
                email: employerInformation?.email,
                country:
                    employerInformation && employerInformation.country
                        ? employerInformation.country.id
                            ? employerInformation.country.id
                            : employerInformation.country
                        : null,
                birthDate: employerInformation?.birthDate,
            };

        this.setState({value: values});
    };
}

export default withTranslation()(
    connect(
        (state: RootState) => ({
            authToken: authTokenSelector(state),
            account: accountSelector(state),
            employerInformation: employerInformationSelector(state),
            countries: countriesSelector(state),
            isLoading: employerPageLoadingSelector(state),
        }),
        {
            changeEmployerInformation,
            submitPersonalInformation,
        }
    )(PersonalInformation)
);
