import {Observable} from 'rxjs';
import {jobHunterAPI, RestQueryParams} from 'jobhunter-common-web';

export function getOfferApplicationsAPI(authToken: string | null, params?: typeof RestQueryParams): Observable<any> {
    let headers = undefined,
        queryParams = params ? params : new RestQueryParams();

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return jobHunterAPI.get(`api/offer_applications/hr_list_mine`, queryParams, headers);
}
