import {Observable} from 'rxjs';
import {jobHunterAPI, RestQueryParams} from 'jobhunter-common-web';

export function changeOfferApplicationRelocationStatusAPI(
    authToken: string | null,
    applicationId: string,
    relocationStatus: string
): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    const payload = {
        relocationStatus: relocationStatus,
    };

    return jobHunterAPI.put(`api/offer_applications/${applicationId}/change_relocation_status`, payload, new RestQueryParams(), headers);
}
