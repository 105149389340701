import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {catchError, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {accountSelector, addAlert, AlertType, authTokenSelector, IUser, getErrorMessage, RestQueryParams} from 'jobhunter-common-web';
import {getOffersAPI} from '../../api/getOffersAPI';
import {RootState} from '../reducers';
import {
    changeIsDashboardLoading,
    fetchPositionsWithOffers,
    fetchPositionsWithoutOffers,
    fetchOfferApplications,
    fetchOffers,
    fetchCalendarDetails,
    fetchProfileDetails,
    IProfileCompletion,
    setPositionsWithOffers,
    setPositionsWithoutOffers,
    setOfferApplications,
    setOffers,
    setCalendarDetails,
    setProfileDetails,
    changeDashboardError,
    rejectOfferApplicationCandidate,
    IRejectOfferApplicationCandidate,
    changeIsOfferApplicationCandidateRejected,
} from '../reducers/dashboardPageSlice';
import {getCalendarEventsAPI} from '../../api/getCalendarEventsAPI';
import {getPositionsAPI} from '../../api/getPositionsAPI';
import {getOfferApplicationsAPI} from '../../api/getOfferApplicationsAPI';
import {PayloadAction} from '@reduxjs/toolkit';
import {rejectCandidateAPI} from '../../api/rejectCandidateAPI';

const fetchPositionsWithOffersEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchPositionsWithOffers.type),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value),
                params = new RestQueryParams().add('hasOffers', true).add('order[createdAt]', 'desc').add('itemsPerPage', 5);
            return getPositionsAPI(authToken, params).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([setPositionsWithOffers(resp['hydra:member'])]);
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const fetchPositionsWithoutOffersEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchPositionsWithoutOffers.type),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value),
                params = new RestQueryParams().add('hasOffers', 'false').add('order[createdAt]', 'desc').add('itemsPerPage', 5);
            return getPositionsAPI(authToken, params).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([setPositionsWithoutOffers(resp['hydra:member'])]);
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const fetchOfferApplicationsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchOfferApplications.type),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value),
                params = new RestQueryParams().add('order[createdAt]', 'desc').add('itemsPerPage', 5);
            return getOfferApplicationsAPI(authToken, params).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([setOfferApplications(resp['hydra:member'])]);
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const fetchOffersEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchOffers.type),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value),
                params = new RestQueryParams().add('order[createdAt]', 'desc').add('itemsPerPage', 5);
            return getOffersAPI(authToken, params).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([setOffers(resp['hydra:member'])]);
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const rejectOfferApplicationCandidateEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(rejectOfferApplicationCandidate.type),
        switchMap((action: PayloadAction<IRejectOfferApplicationCandidate>): any => {
            const authToken = authTokenSelector(state$.value),
                candidateId = action.payload.candidateId;

            return rejectCandidateAPI(authToken, candidateId).pipe(
                switchMap(() => {
                    const actions = successActions([
                        changeIsOfferApplicationCandidateRejected(true),
                        addAlert({message: 'dashboard.offerApplications.rejectApplicantModal.candidateRejected'}),
                        fetchOfferApplications(),
                    ]);
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const fetchProfileDetailsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchProfileDetails.type),
        switchMap((): any => {
            const accountData = accountSelector(state$.value),
                organizationAccount = accountData.organizationFullInfo,
                accountCompletion = accountData.organizationFullInfo.user.filled,
                isContactInformationComplete = isContactInformationSet(organizationAccount.user),
                profileDetails: IProfileCompletion = {
                    isCompanyDataComplete: accountCompletion.personal,
                    isContactInformationComplete: isContactInformationComplete,
                    isVerificationDataComplete: accountCompletion.verification,
                    isCompanyDetailsDataComplete: accountCompletion.about_company,
                },
                actions = successActions([setProfileDetails(profileDetails)]);

            return of(...actions);
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const fetchCalendarDetailsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchCalendarDetails.type),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value);
            return getCalendarEventsAPI(authToken).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([setCalendarDetails(resp['hydra:member'])]);
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const isContactInformationSet = (user: typeof IUser | null): boolean => {
    return (
        user !== null &&
        user.avatar !== null &&
        user.firstName !== null &&
        user.lastName !== null &&
        user.phone !== null &&
        user.cityName !== null &&
        user.country !== null
    );
};

const successActions = (changeSliceList?: any[]): any[] => {
    const actions = [changeIsDashboardLoading(false)];

    if (changeSliceList) {
        return actions.concat(changeSliceList);
    }
    return actions;
};

const updateListErrorActions = (error: any): any[] => {
    return [
        changeIsDashboardLoading(false),
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
        changeDashboardError(getErrorMessage(error)),
    ];
};

const dashboardEpic = combineEpics(
    fetchPositionsWithOffersEpic,
    fetchPositionsWithoutOffersEpic,
    fetchOfferApplicationsEpic,
    fetchOffersEpic,
    fetchProfileDetailsEpic,
    fetchCalendarDetailsEpic,
    rejectOfferApplicationCandidateEpic
);

export default dashboardEpic;
