import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {isSameValue} from 'jobhunter-common-web';
import {IAddPositionPayload} from '../../api/addPositionApi';
import {IModelPositionsInformation, PositionStatus} from '../../model/positionsDataModel';
import {IModelApiResponseViewObject} from '../../model/base';
import {IChangePagination, IPaginationParams, ISetMetadata} from './offersPageSlice';

export interface IFetchPositionList {
    params: {[key: string]: any} | null;
}

export interface ISetPositionList {
    positionsList: IModelPositionsInformation[] | null;
}

export interface IPositionsListLoading {
    isPositionsListLoading: boolean;
}

export interface IPositionsListInitialized {
    isPositionsListInitialized: boolean;
}

export interface IPositionListError {
    positionsListError: string | null;
}

export interface IPositionUpdated {
    isPositionUpdated: boolean;
}

export interface IChangePositionStatus {
    positionId: string;
    positionStatus: PositionStatus;
}

export interface IAddPosition {
    position: IAddPositionPayload;
}

export interface IDeletePosition {
    positionId: string;
}

export interface IPositionFilters {
    hasOffers: string;
}

export interface IChangePositionFilters {
    filters: IPositionFilters;
}

export interface IPositionsListState {
    positionsList: IModelPositionsInformation[] | null;
    positionsMetadata: IModelApiResponseViewObject | null;
    pagination: IPaginationParams | null;
    isPositionsListLoading: boolean;
    isPositionsListInitialized: boolean;
    positionsListError: string | null;
    isPositionAdded: boolean;
    isPositionRemoved: boolean;
    isPositionStatusUpdated: boolean;
    positionFilters: IPositionFilters | null;
}

const initialState: IPositionsListState = {
    positionsList: null,
    positionsMetadata: null,
    pagination: {
        itemsPerPage: 25,
        page: 1,
    },
    isPositionsListLoading: false,
    isPositionsListInitialized: false,
    positionsListError: null,
    isPositionAdded: false,
    isPositionRemoved: false,
    isPositionStatusUpdated: false,
    positionFilters: null,
};

const positionsPageSlice = createSlice({
    name: 'positionsList',
    initialState: initialState,
    reducers: {
        applyPositionFilters: (state: IPositionsListState) => {
            return {
                ...state,
                isPositionsListLoading: true,
            };
        },
        changePositionFilters: {
            reducer: (state: IPositionsListState, action: PayloadAction<IChangePositionFilters>) => {
                if (isSameValue(action.payload.filters, state.positionFilters)) {
                    return {
                        ...state,
                    };
                }

                return {
                    ...state,
                    isPositionsListLoading: true,
                    positionFilters: action.payload.filters,
                };
            },
            prepare: (filters: IPositionFilters) => {
                return {
                    payload: {
                        filters: {
                            hasOffers: filters['hasOffers'],
                        },
                    },
                };
            },
        },
        addPosition: {
            reducer: (state: IPositionsListState) => {
                return {
                    ...state,
                };
            },
            prepare: (position: IAddPositionPayload) => {
                return {
                    payload: {
                        position: position,
                    },
                };
            },
        },
        changePositionAdded: {
            reducer: (state: IPositionsListState, action: PayloadAction<IPositionUpdated>) => {
                return {
                    ...state,
                    isPositionAdded: action.payload.isPositionUpdated,
                };
            },
            prepare: (isPositionUpdated: boolean) => {
                return {
                    payload: {
                        isPositionUpdated: isPositionUpdated,
                    },
                };
            },
        },
        setPositionsList: {
            reducer: (state: IPositionsListState, action: PayloadAction<ISetPositionList>) => {
                return {
                    ...state,
                    positionsList: action.payload.positionsList,
                };
            },
            prepare: (positionsList: IModelPositionsInformation[] | null) => {
                return {
                    payload: {
                        positionsList: positionsList,
                    },
                };
            },
        },
        changePositionsPageLoading: {
            reducer: (state: IPositionsListState, action: PayloadAction<IPositionsListLoading>) => {
                return {
                    ...state,
                    isPositionsListLoading: action.payload.isPositionsListLoading,
                };
            },
            prepare: (isPositionsListLoading: boolean) => {
                return {
                    payload: {
                        isPositionsListLoading: isPositionsListLoading,
                    },
                };
            },
        },
        changePositionsPageInitialized: {
            reducer: (state: IPositionsListState, action: PayloadAction<IPositionsListInitialized>) => {
                return {
                    ...state,
                    isPositionsListInitialized: action.payload.isPositionsListInitialized,
                };
            },
            prepare: (isPositionsListInitialized: boolean) => {
                return {
                    payload: {
                        isPositionsListInitialized: isPositionsListInitialized,
                    },
                };
            },
        },
        changePositionsPageError: {
            reducer: (state: IPositionsListState, action: PayloadAction<IPositionListError>) => {
                return {
                    ...state,
                    positionsListError: action.payload.positionsListError,
                };
            },
            prepare: (positionsListError: any) => {
                return {
                    payload: {
                        positionsListError: positionsListError,
                    },
                };
            },
        },
        fetchPositionsList: {
            reducer: (state: IPositionsListState) => {
                return {
                    ...state,
                    isPositionsListLoading: true,
                };
            },
            prepare: (params: {[key: string]: any} | null) => {
                return {
                    payload: {
                        params: params,
                    },
                };
            },
        },
        deletePosition: {
            reducer: (state: IPositionsListState) => {
                return {
                    ...state,
                };
            },
            prepare: (positionId: string) => {
                return {
                    payload: {
                        positionId: positionId,
                    },
                };
            },
        },
        changePositionRemoved: {
            reducer: (state: IPositionsListState, action: PayloadAction<IPositionUpdated>) => {
                return {
                    ...state,
                    isPositionRemoved: action.payload.isPositionUpdated,
                };
            },
            prepare: (isPositionUpdated: boolean) => {
                return {
                    payload: {
                        isPositionUpdated: isPositionUpdated,
                    },
                };
            },
        },
        changePositionStatus: {
            reducer: (state: IPositionsListState) => {
                return {
                    ...state,
                };
            },
            prepare: (positionId: string, positionStatus: PositionStatus) => {
                return {
                    payload: {
                        positionId: positionId,
                        positionStatus: positionStatus,
                    },
                };
            },
        },
        changeIsPositionStatusUpdated: {
            reducer: (state: IPositionsListState, action: PayloadAction<IPositionUpdated>) => {
                return {
                    ...state,
                    isPositionStatusUpdated: action.payload.isPositionUpdated,
                };
            },
            prepare: (isPositionUpdated: boolean) => {
                return {
                    payload: {
                        isPositionUpdated: isPositionUpdated,
                    },
                };
            },
        },
        setPositionsMetadata: {
            reducer: (state: IPositionsListState, action: PayloadAction<ISetMetadata>) => {
                return {
                    ...state,
                    positionsMetadata: action.payload.metadata,
                };
            },
            prepare(metadata: IModelApiResponseViewObject | null) {
                return {
                    payload: {
                        metadata: metadata,
                    },
                };
            },
        },
        changePositionsPagination: {
            reducer: (state: IPositionsListState, action: PayloadAction<IChangePagination>) => {
                return {
                    ...state,
                    isPositionsListLoading: true,
                    pagination: action.payload.pagination,
                };
            },
            prepare(pagination: IPaginationParams) {
                return {
                    payload: {pagination: pagination},
                };
            },
        },
        resetToInitialPositionPageState: () => {
            return {
                ...initialState,
            };
        },
    },
});
export const {
    addPosition,
    setPositionsList,
    setPositionsMetadata,
    changePositionsPagination,
    fetchPositionsList,
    resetToInitialPositionPageState,
    changePositionsPageError,
    changePositionsPageInitialized,
    changePositionsPageLoading,
    changePositionAdded,
    deletePosition,
    changePositionStatus,
    changeIsPositionStatusUpdated,
    changePositionFilters,
    applyPositionFilters,
    changePositionRemoved,
} = positionsPageSlice.actions;

export default positionsPageSlice.reducer;
