import {
    convertToMultiselectLabels,
    convertPriceToPayloadValue,
    countriesSelector,
    deepCloneObject,
    Form,
    FormControlChangeType,
    IFormConfig,
    IModelDictionaryDatum,
    isSameValue,
    serviceTypesSelector,
    Translation,
    sortMultiselectLabels,
} from 'jobhunter-common-web';
import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {IAddServicePayload} from '../../../../model/servicesDataModel';
import {RootState} from '../../../../store/reducers';
import {addService} from '../../../../store/reducers/myServicesSlice';
import {addServiceFormConfig} from './addServiceFormConfig';

interface IConnectedPersonalInformationProps {
    readonly countries: typeof IModelDictionaryDatum[] | null;
    readonly serviceTypes: typeof IModelDictionaryDatum[] | null;
    readonly addService: typeof addService;
}

interface IExternalAddServiceProps {
    readonly isModalOpen: boolean;
    readonly toggleModal: () => void;
}

interface IAddServiceProps extends IConnectedPersonalInformationProps, IExternalAddServiceProps, WithTranslation {}

interface IAddServiceState {
    value: any;
    formConfig: typeof IFormConfig | null;
    isLoading: boolean;
}

class AddService extends Component<IAddServiceProps, IAddServiceState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IAddServiceProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: null,
            isLoading: false,
        };
    }

    componentDidMount(): void {
        this.setFormConfig();

        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    tap((data: any) => {
                        const updatedData = deepCloneObject(data);
                        const taxRate = Number(data.value.taxRate),
                            netPrice = Number(data.value.netPrice) * 100;
                        if (taxRate && netPrice) {
                            const taxPrice = netPrice * (taxRate / 100),
                                grossPrice = netPrice + taxPrice;
                            updatedData.value.grossPrice = (grossPrice / 100).toFixed(0);
                        }

                        if (taxRate === 0 && netPrice === 0) {
                            updatedData.value.grossPrice = 0;
                        }
                        this.setState({value: updatedData.value});
                    })
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: Readonly<IAddServiceProps>): void {
        if (
            !isSameValue(this.props.countries, prevProps.countries) ||
            (!isSameValue(this.props.serviceTypes, prevProps.serviceTypes) && this.props.serviceTypes)
        ) {
            this.setFormConfig();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <Modal modalClassName="add-service-modal" isOpen={this.props.isModalOpen} toggle={() => this.props.toggleModal()}>
                <ModalHeader toggle={() => this.props.toggleModal()}>
                    <Translation text="marketplace.services.addService.title" />
                </ModalHeader>
                <ModalBody>
                    <p className="modal-description">
                        <Translation text="marketplace.services.addService.description" />
                    </p>

                    <Form
                        config={this.state.formConfig}
                        onValueStateChange={this.onValueStateChange}
                        value={this.state.value}
                        controlName={'addServiceForm'}
                        onButtonClicked={this.buttonAction}
                    />
                </ModalBody>
            </Modal>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private setFormConfig = () => {
        const {t} = this.props,
            countries = sortMultiselectLabels(convertToMultiselectLabels(this.props.countries, t)),
            serviceTypes = sortMultiselectLabels(convertToMultiselectLabels(this.props.serviceTypes, t)),
            formConfig = addServiceFormConfig(countries, serviceTypes);

        this.setState({formConfig});
    };

    private buttonAction = (name: string) => {
        if (name === 'submit') {
            const netPricePayload = convertPriceToPayloadValue(this.state.value.netPrice),
                grossPricePayload = convertPriceToPayloadValue(this.state.value.grossPrice);
            let addServicePayload: IAddServicePayload = {
                title: this.state.value.title,
                description: this.state.value.description,
                netPrice: netPricePayload,
                grossPrice: grossPricePayload,
                taxRate: this.state.value.taxRate.toString(),
                serviceTypes: this.state.value.type ? this.state.value.type : [],
            };
            if (this.state.value.onlyForHiredUsers) {
                addServicePayload = {
                    ...addServicePayload,
                    onlyForHiredUsers: this.state.value.onlyForHiredUsers,
                };
            }
            this.props.addService(addServicePayload);
            return this.props.toggleModal();
        }
        return this.props.toggleModal();
    };
}

export default withTranslation()(
    connect(
        (state: RootState) => ({
            serviceTypes: serviceTypesSelector(state),
            countries: countriesSelector(state),
        }),
        {
            addService,
        }
    )(AddService)
);
