import React, {Component} from 'react';
import {IFormConfig, FormControlChangeType, Form, Translation} from 'jobhunter-common-web';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {candidateFiltersFormConfig} from './candidateFiltersFormConfig';
import styles from './styles.module.scss';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {candidateFiltersSelector, candidatesFilterOptionsSelector} from '../../../../store/selectors/candidatesPageSelectors';
import {fetchCandidateFilterOptions, ICandidatesFilterOptions, ICandidatesFilters} from '../../../../store/reducers/candidatesPageSlice';
import {IApplicationOffer} from '../../../../model/offerDataModel';

interface IExternalCandidateProps {
    candidates: IApplicationOffer[] | null;
    changeCandidateFilters: (value: {[key: string]: any}) => void;
}

interface IConnectedCandidateProps {
    readonly filterOptions: ICandidatesFilterOptions | null;
    readonly selectedFilters: ICandidatesFilters | null;
    readonly fetchCandidateFilterOptions: typeof fetchCandidateFilterOptions;
}

export interface ICandidateFiltersProps extends IConnectedCandidateProps, IExternalCandidateProps {}

export interface ICandidateFiltersState {
    value: any;
    formConfig: typeof IFormConfig | null;
}

class CandidateFilters extends Component<ICandidateFiltersProps, ICandidateFiltersState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];
    constructor(props: ICandidateFiltersProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: null,
        };
    }

    componentDidMount(): void {
        this.props.fetchCandidateFilterOptions();
        this.setDefaultFormValues();
        this.setFormConfig();
        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    tap((data): any => {
                        this.setState({value: data.value});
                        this.props.changeCandidateFilters(data.value);
                    })
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: Readonly<ICandidateFiltersProps>, prevState: Readonly<ICandidateFiltersState>): void {
        if (this.props.selectedFilters !== prevProps.selectedFilters) {
            this.setDefaultFormValues();
        }

        if (this.props.filterOptions !== prevProps.filterOptions) {
            this.setFormConfig();
        }
    }

    componentWillUnmount(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <div className={styles.candidateFilters}>
                <p className={styles.title}>
                    <Translation text="humanResources.dashboard.candidates.form.search" />
                </p>
                {this.state.formConfig && (
                    <Form
                        config={this.state.formConfig}
                        value={this.state.value}
                        controlName="candidateFiltersForm"
                        onValueStateChange={this.onValueStateChange}
                    />
                )}
            </div>
        );
    }

    private setFormConfig = () => {
        const formConfig = candidateFiltersFormConfig(
            this.props.filterOptions?.candidate,
            this.props.filterOptions?.offer,
            this.props.filterOptions?.position
        );
        this.setState({formConfig});
    };

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private setDefaultFormValues = () => {
        const value = {
            offers: this.props.selectedFilters?.offer?.id,
            candidates: this.props.selectedFilters?.candidate?.account?.lastName,
            positions: this.props.selectedFilters?.offer?.soughtPosition?.position?.name,
        };

        this.setState({value});
    };
}

export default connect(
    (state: RootState) => ({
        filterOptions: candidatesFilterOptionsSelector(state),
        selectedFilters: candidateFiltersSelector(state),
    }),
    {fetchCandidateFilterOptions}
)(CandidateFilters);
