import React, {Component} from 'react';
import LayoutWrapper from '../LayoutWrapper';
import {
    ListItemDetails,
    Translation,
    WithNavigate,
    withNavigation,
    LoaderType,
    Loader,
    usernameSelector,
    Tooltip,
} from 'jobhunter-common-web';
import {Badge, Button, Card, CardBody, CardTitle, Table} from 'reactstrap';
import {Calendar, Eye, File, Search, Send} from 'react-feather';
import EventCalendar from '../Dashboard/EventCalendar';
import {WithTranslation, withTranslation} from 'react-i18next';
import CandidatesCard from './CandidatesCard';
import {connect} from 'react-redux';
import {RootState} from '../../store/reducers';
import {
    candidateListPageSelector,
    isCandidatesListPageLoadingSelector,
    isRecruitmentListPageLoadingSelector,
    recruitmentListPageSelector,
    isCalendarLoadingSelector,
    calendarDetailsPageSelector,
} from '../../store/selectors/humanResourcesPageSelectors';
import {IModelRecruitment} from '../../model/recruitmentDataModel';
import {
    fetchCalendarDetails,
    fetchCandidatesList,
    fetchRecruitmentList,
    resetToInitialHumanResourcesState,
} from '../../store/reducers/humanResourcesPageSlice';
import {IApplicationOffer, IOffer, OfferStatus} from '../../model/offerDataModel';
import {ICalendarEvent} from '../../model/calendarEventModel';
import RecommendOfferModal from './Offers/RecommendOfferModal';

declare type WithNavigationProps = typeof WithNavigate;

interface IConnectedHumanResourcesProps {
    readonly candidatesList: IApplicationOffer[] | null;
    readonly fetchCandidatesList: typeof fetchCandidatesList;
    readonly isCandidatesLoading: boolean;
    readonly resetToInitialHumanResourcesState: typeof resetToInitialHumanResourcesState;
    readonly recruitmentList: IModelRecruitment[] | null;
    readonly fetchRecruitmentList: typeof fetchRecruitmentList;
    readonly isRecruitmentLoading: boolean;
    readonly isCalendarLoading: boolean;
    readonly username: string;
    readonly calendarEvents: ICalendarEvent[] | null;
    readonly fetchCalendarDetails: typeof fetchCalendarDetails;
}

interface IHumanResourcesProps extends WithNavigationProps, WithTranslation, IConnectedHumanResourcesProps {}

interface IHumanResourcesState {
    isRecommendationModalVisible: boolean;
    selectedOffer: IOffer | null;
}

class HumanResources extends Component<IHumanResourcesProps, IHumanResourcesState> {
    constructor(props: IHumanResourcesProps) {
        super(props);

        this.state = {
            isRecommendationModalVisible: false,
            selectedOffer: null,
        };
    }
    componentDidMount(): void {
        this.props.fetchRecruitmentList();
        this.props.fetchCandidatesList();
        this.props.fetchCalendarDetails();
    }

    componentWillUnmount(): void {
        this.props.resetToInitialHumanResourcesState();
    }
    render() {
        return (
            <LayoutWrapper>
                <div className="panel-section-header">
                    <Translation text="humanResources.title" />
                </div>

                <div className="row">
                    <div className="col-lg-8 col-12">
                        <CandidatesCard
                            candidates={this.props.candidatesList}
                            isExpandedList={false}
                            isLoading={this.props.isCandidatesLoading}
                        />
                        {this.renderRecruitment()}
                    </div>
                    <div className="col-lg-4 col-12">{this.renderCalendar()}</div>
                </div>
            </LayoutWrapper>
        );
    }

    private renderRecruitment = () => {
        return (
            <>
                <Card className="recruitment-card">
                    <CardBody>
                        <CardTitle>
                            <div className="card-header">
                                <div>
                                    <File size={20} />
                                    <Translation text="humanResources.dashboard.recruitment.title" />
                                </div>
                                <button className="btn btn-primary view-recruitments-button" onClick={() => this.redirectToRecruitment()}>
                                    <Translation text="buttons.showAll" />
                                </button>
                            </div>
                        </CardTitle>
                        <Table responsive className="hr-recruitment-table">
                            <thead>
                                <tr>
                                    <th>
                                        <Translation text="humanResources.dashboard.recruitment.table.position" />
                                    </th>
                                    <th>
                                        <Translation text="humanResources.dashboard.recruitment.table.candidates" />
                                    </th>
                                    <th>
                                        <Translation text="humanResources.dashboard.recruitment.table.status" />
                                    </th>
                                    <th>
                                        <Translation text="humanResources.dashboard.recruitment.table.actions" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>{this.renderRecruitmentTableRows()}</tbody>
                        </Table>
                        <Loader type={LoaderType.Local} showLoader={this.props.isRecruitmentLoading} />
                    </CardBody>
                </Card>

                {this.state.isRecommendationModalVisible ? (
                    <RecommendOfferModal
                        isModalOpen={this.state.isRecommendationModalVisible}
                        toggleModal={this.toggleRecommendationModal}
                        offer={this.state.selectedOffer ? this.state.selectedOffer : null}
                    />
                ) : null}
            </>
        );
    };

    private renderRecruitmentTableRows() {
        const {t} = this.props;
        if (null === this.props.recruitmentList || this.props.recruitmentList.length <= 0) {
            return (
                <tr key={'no-data'}>
                    <td colSpan={4}>
                        <p>
                            <Translation text="humanResources.dashboard.recruitment.table.noRecruitment" />
                        </p>
                    </td>
                </tr>
            );
        }

        return this.props.recruitmentList?.map((item: any) => {
            return (
                <tr key={`applications_${item.id}`}>
                    <td>{this.renderDescription(item)}</td>
                    <td>
                        {item.applicationsCount}
                        <Button
                            color="flat-primary"
                            onClick={() => this.viewCandidateDetails(item)}
                            disabled={item.applicationsCount === 0}>
                            <Search id={`details_${item.id}`} size={20} />
                            <Tooltip target={`details_${item.id}`} tooltipText={t('humanResources.recruitment.tooltips.viewCandidate')} />
                        </Button>
                    </td>
                    <td>
                        <Badge
                            color={
                                item.status === OfferStatus.ACTIVE
                                    ? 'light-success'
                                    : item.status === OfferStatus.PENDING
                                    ? 'light-warning'
                                    : 'light-secondary'
                            }
                            pill>
                            <span className="align-middle">{item.status}</span>
                        </Badge>
                    </td>
                    <td>
                        <Button color="flat-primary" onClick={() => this.viewItem(item)}>
                            <Eye id={`view_${item.id}`} size={20} />
                            <Tooltip target={`view_${item.id}`} tooltipText={t('humanResources.recruitment.tooltips.viewPosition')} />
                        </Button>

                        <Button color="flat-primary" onClick={() => this.toggleRecommendationModal(item)}>
                            <Send id={`copy_${item.id}`} size={20} />
                            <Tooltip target={`copy_${item.id}`} tooltipText={t('humanResources.offers.tooltips.recommend')} />
                        </Button>
                    </td>
                </tr>
            );
        });
    }

    private renderCalendar = () => {
        return (
            <Card className="events-card">
                <CardBody>
                    <CardTitle>
                        <div className="card-header">
                            <div>
                                <Calendar size={20} />
                                <Translation text="dashboard.calendar.title" />
                            </div>
                        </div>
                    </CardTitle>
                    <EventCalendar username={this.props.username} calendarEvents={this.props.calendarEvents} />
                    <Loader type={LoaderType.Local} showLoader={this.props.isCalendarLoading} />
                </CardBody>
            </Card>
        );
    };

    private viewItem = (item: IModelRecruitment) => {
        const offer = item.offers[0];
        this.props.navigate(`/panel/human_resources/offers/${offer.id}`);
    };

    private renderDescription = (item: {[key: string]: any}) => {
        const name = `${item?.name}`;
        return <ListItemDetails avatar={item?.offers[0]?.avatar} name={name} description={item.organizationName} />;
    };

    private redirectToRecruitment = () => {
        this.props.navigate('/panel/human_resources/recruitment');
    };

    private viewCandidateDetails = (item: IModelRecruitment) => {
        this.props.navigate(`/panel/human_resources/candidates`, {state: {positionId: item.name}});
    };

    private toggleRecommendationModal = (item?: IOffer) => {
        this.setState({
            isRecommendationModalVisible: !this.state.isRecommendationModalVisible,
            selectedOffer: item ? item : null,
        });
    };
}

export default connect(
    (state: RootState) => ({
        username: usernameSelector(state),
        calendarEvents: calendarDetailsPageSelector(state),
        candidatesList: candidateListPageSelector(state),
        isCandidatesLoading: isCandidatesListPageLoadingSelector(state),
        recruitmentList: recruitmentListPageSelector(state),
        isRecruitmentLoading: isRecruitmentListPageLoadingSelector(state),
        isCalendarLoading: isCalendarLoadingSelector(state),
    }),
    {fetchCandidatesList, resetToInitialHumanResourcesState, fetchRecruitmentList, fetchCalendarDetails}
)(withTranslation()(withNavigation(HumanResources)));
