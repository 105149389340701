import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IHumanResourcesPageState} from '../reducers/humanResourcesPageSlice';

export const selectHumanResourcesPage = (state: RootState) => {
    return state.humanResourcesPage;
};

export const recruitmentListPageSelector = createSelector(
    [selectHumanResourcesPage],
    (state: IHumanResourcesPageState) => state.recruitmentList
);
export const isRecruitmentListPageInitializedSelector = createSelector(
    [selectHumanResourcesPage],
    (state: IHumanResourcesPageState) => state.isRecruitmentListInitialized
);
export const isRecruitmentListPageLoadingSelector = createSelector(
    [selectHumanResourcesPage],
    (state: IHumanResourcesPageState) => state.isRecruitmentListLoading
);
export const recruitmentListPageErrorsSelector = createSelector(
    [selectHumanResourcesPage],
    (state: IHumanResourcesPageState) => state.recruitmentListError
);
export const candidateListPageSelector = createSelector(
    [selectHumanResourcesPage],
    (state: IHumanResourcesPageState) => state.candidatesList
);
export const candidatesListPageErrorSelector = createSelector(
    [selectHumanResourcesPage],
    (state: IHumanResourcesPageState) => state.candidatesListError
);
export const isCandidatesListPageInitializedSelector = createSelector(
    [selectHumanResourcesPage],
    (state: IHumanResourcesPageState) => state.isCandidatesListInitialized
);
export const isCandidatesListPageLoadingSelector = createSelector(
    [selectHumanResourcesPage],
    (state: IHumanResourcesPageState) => state.isCandidatesListLoading
);
export const isCandidateHiredPageSelector = createSelector(
    [selectHumanResourcesPage],
    (state: IHumanResourcesPageState) => state.isCandidateHired
);
export const isCandidateRejectedPageSelector = createSelector(
    [selectHumanResourcesPage],
    (state: IHumanResourcesPageState) => state.isCandidateRejected
);
export const calendarDetailsPageSelector = createSelector(
    [selectHumanResourcesPage],
    (state: IHumanResourcesPageState) => state.calendarDetails
);
export const isCalendarLoadingSelector = createSelector(
    [selectHumanResourcesPage],
    (state: IHumanResourcesPageState) => state.isCalendarLoading
);
