import React, {ChangeEvent, Component} from 'react';
import styles from './styles.module.scss';
import {Switch, Translation} from 'jobhunter-common-web';

interface IConnectedSwitchFilterProps {}

interface IExternalSwitchFilterProps {
    name: string;
    isChecked: boolean;
    changeFilters: (e: ChangeEvent<HTMLInputElement>) => void;
    label: string;
}

interface ISwitchFilterProps extends IConnectedSwitchFilterProps, IExternalSwitchFilterProps {}

class SwitchFilter extends Component<ISwitchFilterProps> {
    render() {
        return (
            <div className={styles.switchContainer}>
                <label htmlFor={this.props.name} className={styles.label}>
                    <Translation text={this.props.label} />
                </label>
                <Switch name={this.props.name} checked={this.props.isChecked} handleChange={(e: any) => this.props.changeFilters(e)} />
            </div>
        );
    }
}

export default SwitchFilter;
