import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {catchError, switchMap} from 'rxjs/operators';
import {of, Observable} from 'rxjs';
import {RootState} from '../reducers';
import {addAlert, AlertType, authTokenSelector, getErrorMessage} from 'jobhunter-common-web';
import {
    changeIsCooperatingListLoading,
    changeIsInvitedListLoading,
    fetchCooperatingHRList,
    setCooperatingList,
    fetchInvitedHRList,
    setInvitedList,
    changeInvitedListError,
    changeCooperatingListError,
    IAddInvitation,
    addHRInvitation,
    changeIsHRAdded,
    acceptInvitation,
    IAcceptInvitation,
    changeIsAcceptPageLoading,
} from '../reducers/hrPageSlice';
import {getHRListAPI, HRListType} from '../../api/getHRListAPI';
import {PayloadAction} from '@reduxjs/toolkit';
import {sendInvitationAPI} from '../../api/sendInvitationAPI';
import {acceptInvitationAPI} from '../../api/acceptInvitationAPI';
import {push} from 'react-router-redux';

const fetchCooperatingHRListEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getList(
        action$,
        state$,
        fetchCooperatingHRList,
        getHRListAPI,
        HRListType.ACCEPTED,
        (list: any) => successActions([setCooperatingList(list), changeIsCooperatingListLoading(false), changeCooperatingListError(null)]),
        (error) =>
            updateListErrorActions(error, [changeIsCooperatingListLoading(false), changeCooperatingListError(getErrorMessage(error))])
    );
};

const fetchInvitedHRListEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getList(
        action$,
        state$,
        fetchInvitedHRList,
        getHRListAPI,
        HRListType.PENDING,
        (list: any) => successActions([setInvitedList(list), changeIsInvitedListLoading(false), changeInvitedListError(null)]),
        (error) => updateListErrorActions(error, [changeIsInvitedListLoading(false), changeInvitedListError(getErrorMessage(error))])
    );
};

const addHRInvitationEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(addHRInvitation.type),
        switchMap((action: PayloadAction<IAddInvitation>): any => {
            const authToken = authTokenSelector(state$.value);
            return sendInvitationAPI(authToken, action.payload.invitation).pipe(
                switchMap(() => {
                    const message = 'employer.cooperatingHr.invitationModal.alerts.success',
                        actions = successActions([addAlert({message: message}), changeIsHRAdded(true), fetchInvitedHRList()]);
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const acceptInvitationEpic: Epic = (action$) => {
    return action$.pipe(
        ofType(acceptInvitation.type),
        switchMap((action: PayloadAction<IAcceptInvitation>): any => {
            return acceptInvitationAPI(action.payload.token).pipe(
                switchMap(() => {
                    const message = 'employer.invitationAccepted',
                        actions = successActions([
                            changeIsAcceptPageLoading(false),
                            addAlert({message: message, type: AlertType.SUCCESS}),
                            push('/auth/login'),
                        ]);
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error, [changeIsAcceptPageLoading(false)])))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error, [changeIsAcceptPageLoading(false)])))
    );
};

const getList = (
    action$: Observable<any>,
    state$: StateObservable<RootState>,
    actionType: any,
    api: (authToken: string | null, listType: HRListType) => Observable<any>,
    apiListType: HRListType,
    onSuccess: (list?: any[]) => Observable<any>[],
    onError: (error: any) => Observable<any>[]
) => {
    return action$.pipe(
        ofType(actionType.type),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value);
            return api(authToken, apiListType).pipe(
                switchMap((resp): any => of(...onSuccess(resp['hydra:member']))),
                catchError((error) => of(...onError(error)))
            );
        }),
        catchError((error) => of(...onError(error)))
    );
};

const successActions = (changeSliceList: any): any[] => {
    const actions: any[] = [];
    if (changeSliceList) {
        return actions.concat(changeSliceList);
    }
    return actions;
};

const updateListErrorActions = (error: any, errorActions?: any[]): any[] => {
    let actions = [addAlert({message: getErrorMessage(error), type: AlertType.WARNING}), changeInvitedListError(getErrorMessage(error))];

    if (errorActions) {
        actions = actions.concat(errorActions);
    }
    return actions;
};

const hrPageEpic = combineEpics(fetchCooperatingHRListEpic, fetchInvitedHRListEpic, addHRInvitationEpic, acceptInvitationEpic);

export default hrPageEpic;
