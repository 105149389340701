import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {catchError, switchMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {RootState} from '../reducers';
import {
    addAlert,
    AlertType,
    authTokenSelector,
    flattenObj,
    getErrorMessage,
    getMetadataDetails,
    RestQueryParams,
} from 'jobhunter-common-web';
import {
    changeIsRecruitmentLoading,
    changeRecruitmentPageError,
    changeRecruitmentPagination,
    fetchRecruitmentList,
    setRecruitmentList,
    setRecruitmentMetadata,
} from '../reducers/recruitmentPageSlice';
import {getSoughtPositionsAPI} from '../../api/getSoughtPositionsAPI';
import {recruitmentPaginationSelector} from '../selectors/recruitmentSelectors';

const fetchRecruitmentListEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getRecruitmentList(action$, state$, fetchRecruitmentList);
};

const changeRecruitmentPaginationEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getRecruitmentList(action$, state$, changeRecruitmentPagination);
};

const getRecruitmentList = (action$: Observable<any>, state$: StateObservable<any>, actionType: any) => {
    return action$.pipe(
        ofType(actionType),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value),
                paginationParams = recruitmentPaginationSelector(state$.value),
                filterObj = {
                    ...paginationParams,
                },
                flattened = flattenObj(filterObj),
                queryParams = new RestQueryParams(flattened);

            return getSoughtPositionsAPI(authToken, queryParams).pipe(
                switchMap((resp: any) => {
                    const metadata = getMetadataDetails(resp['hydra:view']),
                        actions = successActions([setRecruitmentList(resp['hydra:member']), setRecruitmentMetadata(metadata)]);
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const successActions = (changeSliceList: any[]): any[] => {
    const actions = [changeIsRecruitmentLoading(false)];
    return [...changeSliceList, ...actions];
};

const updateListErrorActions = (error: any): any[] => {
    return [
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
        changeRecruitmentPageError(getErrorMessage(error)),
        changeIsRecruitmentLoading(false),
    ];
};

const recruitmentEpic = combineEpics(fetchRecruitmentListEpic, changeRecruitmentPaginationEpic);
export default recruitmentEpic;
