import {Observable} from 'rxjs';
import {jobHunterAPI, RestQueryParams} from 'jobhunter-common-web';

export function hireCandidateAPI(authToken: string | null, applicationId: string): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return jobHunterAPI.put(`api/offer_applications/${applicationId}/set_hired`, {}, new RestQueryParams(), headers);
}
