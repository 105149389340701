import React, {Component} from 'react';
import LayoutWrapper from '../../LayoutWrapper';
import RelocationStatus from './RelocationStatus';
import {usernameSelector, withRouterWrapper, WithRouterWrapperProps, serverChatContactIntoChatContactMapper} from 'jobhunter-common-web';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {ICalendarEvent} from '../../../model/calendarEventModel';
import {
    addRelocationEvent,
    changeIsRelocationEventAdded,
    changeIsRelocationFileRemoved,
    changeIsRelocationStatusUpdated,
    changeRelocationStatus,
    downloadRelocationFile,
    fetchCalendarDetails,
    fetchRelocationDetails,
    fetchRelocationFiles,
    IRelocationFile,
    removeRelocationFile,
    resetToInitialRelocationDetailsPageState,
} from '../../../store/reducers/relocationDetailsPageSlice';
import RelocationChat from './RelocationChat';
import RelocationFiles from './RelocationFiles';
import RelocationCalendar from './RelocationCalendar';
import {
    calendarDetailsSelector,
    isRelocationEventAddedSelector,
    isRelocationFileRemovedSelector,
    isRelocationFilesListLoadingSelector,
    isRelocationStatusUpdatedSelector,
    relocationDetailsErrorSelector,
    relocationDetailsSelector,
    relocationFilesSelector,
} from '../../../store/selectors/relocationDetailsPageSelectors';
import {ChatContact, ChatProps} from '@solvee/reactjs-text-chat';
import {IApplicationOffer} from '../../../model/offerDataModel';

declare type WithRouterProps = typeof WithRouterWrapperProps;

interface IConnectedRelocationViewProps {
    readonly username: string;
    readonly error: string | null;
    readonly calendarEvents: ICalendarEvent[] | null;
    readonly isRelocationStatusUpdated: boolean;
    readonly fetchCalendarDetails: typeof fetchCalendarDetails;
    readonly changeRelocationStatus: typeof changeRelocationStatus;
    readonly changeIsRelocationStatusUpdated: typeof changeIsRelocationStatusUpdated;
    readonly relocationDetails: IApplicationOffer | null;
    readonly fetchRelocationDetails: typeof fetchRelocationDetails;
    readonly relocationFiles: IRelocationFile[] | null;
    readonly isRelocationFileRemoved: boolean;
    readonly isRelocationEventAdded: boolean;
    readonly isRelocationFilesListLoading: boolean;
    readonly fetchRelocationFiles: typeof fetchRelocationFiles;
    readonly removeRelocationFile: typeof removeRelocationFile;
    readonly changeIsRelocationFileRemoved: typeof changeIsRelocationFileRemoved;
    readonly downloadRelocationFile: typeof downloadRelocationFile;
    readonly addRelocationEvent: typeof addRelocationEvent;
    readonly changeIsRelocationEventAdded: typeof changeIsRelocationEventAdded;
    readonly resetToInitialRelocationDetailsPageState: typeof resetToInitialRelocationDetailsPageState;
}

interface IRelocationViewProps extends IConnectedRelocationViewProps, WithRouterProps, ChatProps {}

interface IRelocationViewState {
    candidateId: string | null;
    applicationId: string | null;
    relocationStatus: string | null;
}

class RelocationView extends Component<IRelocationViewProps, IRelocationViewState> {
    constructor(props: IRelocationViewProps) {
        super(props);

        this.state = {
            candidateId: null,
            applicationId: null,
            relocationStatus: null,
        };
    }

    componentDidMount() {
        this.props.fetchCalendarDetails();
        this.props.fetchRelocationFiles();

        if (this.props.router && this.props.router.location && this.props.router.location.state) {
            this.setState({
                candidateId: this.props.router.location.state.candidateId,
                relocationStatus: this.props.router.location.state.relocationStatus,
            });
        }

        if (this.props.router && this.props.router.params) {
            const applicationId = this.props.router.params.id;
            this.setState({applicationId: applicationId});
            this.props.fetchRelocationDetails(applicationId);
        }
    }

    componentDidUpdate(prevProps: IRelocationViewProps) {
        if (
            this.props.chatContactsList &&
            (this.props.relocationDetails !== prevProps.relocationDetails ||
                prevProps.chatContactsList?.length !== this.props.chatContactsList?.length)
        ) {
            const contact = this.props.chatContactsList
                    ? this.props.chatContactsList.find((contact) => contact.accountId === this.state.candidateId)
                    : null,
                relocation = this.props.relocationDetails;
            if (!contact && relocation) {
                if (relocation && this.props.joinChannels) {
                    const newContact: ChatContact[] = serverChatContactIntoChatContactMapper(
                        [{contact: relocation.chatContact, unread: 0}],
                        this.props.accountId
                    );

                    const channels = this.props.chatContactsList ? [...this.props.chatContactsList, ...newContact] : newContact;
                    this.props.joinChannels(channels);
                }
            }
            if (contact) {
                this.props.setSelectedChatRoomId(contact.roomId);
            }
        }
    }

    componentWillUnmount() {
        this.props.setSelectedChatRoomId('');
        this.props.resetToInitialRelocationDetailsPageState();
    }

    render() {
        return (
            <LayoutWrapper>
                <div className="row">
                    <div className="col-lg-8 col-12">
                        <RelocationChat {...this.props} />
                        <RelocationFiles
                            files={this.props.relocationFiles}
                            removeRelocationFile={this.removeRelocationFile}
                            error={this.props.error}
                            isLoading={this.props.isRelocationFilesListLoading}
                            isRelocationFileRemoved={this.props.isRelocationFileRemoved}
                            changeIsRelocationFileRemoved={this.props.changeIsRelocationFileRemoved}
                            downloadRelocationFile={this.props.downloadRelocationFile}
                            applicationId={this.state.applicationId}
                        />
                    </div>
                    <div className="col-lg-4 col-12">
                        <RelocationStatus
                            relocationStatus={this.state.relocationStatus}
                            changeRelocationStatus={this.changeRelocationStatus}
                            isRelocationStatusUpdated={this.props.isRelocationStatusUpdated}
                            error={this.props.error}
                            changeIsRelocationStatusUpdated={this.props.changeIsRelocationStatusUpdated}
                        />
                        <RelocationCalendar
                            username={this.props.username}
                            applicationId={this.state.applicationId}
                            addRelocationEvent={this.props.addRelocationEvent}
                            calendarEvents={this.props.calendarEvents}
                            isRelocationEventAdded={this.props.isRelocationEventAdded}
                            error={this.props.error}
                            changeIsRelocationEventAdded={this.props.changeIsRelocationEventAdded}
                        />
                    </div>
                </div>
            </LayoutWrapper>
        );
    }

    private changeRelocationStatus = (status: string) => {
        const applicationId = this.state.applicationId;
        if (!applicationId) {
            return;
        }

        this.props.changeRelocationStatus(applicationId, status);
    };

    private removeRelocationFile = (fileId: string) => {
        this.props.removeRelocationFile(fileId);
    };
}

export default connect(
    (state: RootState) => ({
        username: usernameSelector(state),
        error: relocationDetailsErrorSelector(state),
        calendarEvents: calendarDetailsSelector(state),
        isRelocationStatusUpdated: isRelocationStatusUpdatedSelector(state),
        relocationDetails: relocationDetailsSelector(state),
        relocationFiles: relocationFilesSelector(state),
        isRelocationFileRemoved: isRelocationFileRemovedSelector(state),
        isRelocationFilesListLoading: isRelocationFilesListLoadingSelector(state),
        isRelocationEventAdded: isRelocationEventAddedSelector(state),
    }),
    {
        fetchRelocationDetails,
        fetchCalendarDetails,
        fetchRelocationFiles,
        changeRelocationStatus,
        changeIsRelocationStatusUpdated,
        removeRelocationFile,
        changeIsRelocationFileRemoved,
        downloadRelocationFile,
        addRelocationEvent,
        changeIsRelocationEventAdded,
        resetToInitialRelocationDetailsPageState,
    }
)(withRouterWrapper(RelocationView));
