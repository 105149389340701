import React, {Component} from 'react';
import {Form, FormControlChangeType, IFormConfig, Loader, LoaderType, Translation} from 'jobhunter-common-web';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {filter, tap} from 'rxjs/operators';
import {BehaviorSubject, Subscription} from 'rxjs';
import {sendInvitationFormConfig} from './sendInvitationFormConfig';
import {connect} from 'react-redux';
import {changeIsOfferActionComplete, recommendOffer} from '../../../../store/reducers/offersPageSlice';
import {IOfferRecommendationPayload} from '../../../../api/sendOfferRecommendationAPI';
import {isOfferActionCompleteSelector, isOfferActionProcessingSelector} from '../../../../store/selectors/offersPageSelectors';
import {RootState} from '../../../../store/reducers';
import {IOffer} from '../../../../model/offerDataModel';

interface IConnectedRecommendationOfferModalProps {
    readonly isLoading: boolean;
    readonly isComplete: boolean;
    readonly recommendOffer: typeof recommendOffer;
    readonly changeIsOfferActionComplete: typeof changeIsOfferActionComplete;
}

interface IExternalRecommendOfferModalProps {
    isModalOpen: boolean;
    toggleModal: () => void;
    offer: IOffer | null;
}

interface IRecommendOfferModalProps extends IConnectedRecommendationOfferModalProps, IExternalRecommendOfferModalProps {}
interface IRecommendOfferModalState {
    value: any;
    formConfig: typeof IFormConfig | null;
}

class RecommendOfferModal extends Component<IRecommendOfferModalProps, IRecommendOfferModalState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IRecommendOfferModalProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: sendInvitationFormConfig(this.props.toggleModal),
        };
    }

    componentDidMount(): void {
        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    tap((data: any) => this.setState({value: data.value}))
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: Readonly<IRecommendOfferModalProps>) {
        if (this.props.isComplete !== prevProps.isComplete && this.props.isComplete) {
            this.props.toggleModal();
        }
    }

    componentWillUnmount() {
        this.props.changeIsOfferActionComplete(false);
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <Modal isOpen={this.props.isModalOpen} toggle={() => this.props.toggleModal()}>
                <ModalHeader toggle={() => this.props.toggleModal()}>
                    <Translation text="humanResources.offers.recommendOffer.title" />
                </ModalHeader>
                <ModalBody>
                    {this.state.formConfig && (
                        <Form
                            config={this.state.formConfig}
                            onValueStateChange={this.onValueStateChange}
                            value={this.state.value}
                            submitForm={this.sendOfferRecommendation}
                            controlName={'recommendOfferForm'}
                        />
                    )}
                </ModalBody>
                <Loader type={LoaderType.Local} showLoader={this.props.isLoading} />
            </Modal>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private sendOfferRecommendation = () => {
        if (null === this.props.offer) {
            return;
        }

        const payload: IOfferRecommendationPayload = {
            email: this.state.value.email,
        };

        this.props.recommendOffer(this.props.offer.id, payload);
    };
}

export default connect(
    (state: RootState) => ({
        isLoading: isOfferActionProcessingSelector(state),
        isComplete: isOfferActionCompleteSelector(state),
    }),
    {
        recommendOffer,
        changeIsOfferActionComplete,
    }
)(RecommendOfferModal);
