import {
    IFormConfig,
    FormControlType,
    MultiSelectType,
    collectionInputDataMapper,
    collectionOutputDataMapper,
    IMultiselectOption,
    FormButtonType,
} from 'jobhunter-common-web';

export const editEmployeeFormConfig = (dismissModal: () => void, roleList?: typeof IMultiselectOption[] | []): typeof IFormConfig => ({
    controlType: 'group',
    class: 'default-form',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'roles',
            class: 'row',
            controls: {
                role: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    multiselectOptions: roleList,
                    validationRules: [],
                    placeholder: 'humanResources.editEmployee.chooseRole',
                    label: 'humanResources.editEmployee.role',
                    hostClass: 'col-xl-6 form-control',
                    inputDataMapper: collectionInputDataMapper,
                    outputDataMapper: collectionOutputDataMapper,
                },
            },
        },
        {
            controlType: 'group',
            key: 'schedule_meeting_buttons',
            class: 'row justify-content-end',
            controls: {
                closeModalButton: {
                    buttonName: 'previous',
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.BUTTON,
                    inputStyles: 'btn-prev btn-outline-primary d-flex align-items-center',
                    hostClass: 'mb-0 w-auto',
                    defaultContainerStyles: '',
                    containerStyles: '',
                    defaultValue: null,
                    btnText: 'buttons.cancel',
                    buttonDisabled: false,
                    customClickHandler: () => {
                        return dismissModal();
                    },
                },
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    inputStyles: 'btn-primary',
                    defaultContainerStyles: '',
                    hostClass: 'mb-0 align-items-end w-auto',
                    containerStyles: '',
                    defaultValue: null,
                    isLabelHidden: true,
                    btnText: 'buttons.save',
                    buttonDisabled: (mappedOutputValue: any, isFormValid: boolean) => {
                        return !isFormValid;
                    },
                },
            },
        },
    ],
});
