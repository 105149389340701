import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IPurchasedServicesListState} from '../reducers/purchasedServicesSlice';

export const selectPurchasedServicesPage = (state: RootState): IPurchasedServicesListState => {
    return state.purchasedServices;
};

export const purchasedServicesListSelector = createSelector(
    [selectPurchasedServicesPage],
    (state: IPurchasedServicesListState) => state.purchasedServicesList
);

export const purchasedServicesMetadataSelector = createSelector(
    [selectPurchasedServicesPage],
    (state: IPurchasedServicesListState) => state.purchasedServicesMetadata
);

export const purchasedServicesPaginationSelector = createSelector(
    [selectPurchasedServicesPage],
    (state: IPurchasedServicesListState) => state.pagination
);

export const isPurchasedServicesPageLoading = createSelector(
    [selectPurchasedServicesPage],
    (state: IPurchasedServicesListState) => state.isPurchasedServicesListLoading
);

export const isPurchasedServicesPageInitialized = createSelector(
    [selectPurchasedServicesPage],
    (state: IPurchasedServicesListState) => state.isPurchasedServicesListInitialized
);

export const servicesPageError = createSelector(
    [selectPurchasedServicesPage],
    (state: IPurchasedServicesListState) => state.purchasedServicesListError
);
