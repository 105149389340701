import React, {Component} from 'react';
import {ListItemDetails, Tooltip, Translation, WithNavigate, withNavigation} from 'jobhunter-common-web';
import {Badge, Button, Card, CardBody, CardTitle, Table} from 'reactstrap';
import {Eye, File, Search, Send} from 'react-feather';
import styles from '../../Employer/Positions/styles.module.scss';
import {WithTranslation, withTranslation} from 'react-i18next';
import {IOffer, IOfferItem, OfferStatus} from '../../../model/offerDataModel';
import RecommendOfferModal from '../../HumanResources/Offers/RecommendOfferModal';
import {convertBadgeColor} from '../../HumanResources/Offers';

declare type WithNavigationProps = typeof WithNavigate;

interface IDashboardOffersProps extends WithNavigationProps, WithTranslation {
    offers: IOfferItem[] | null;
}

interface IDashboardOffersState {
    isRecommendationModalVisible: boolean;
    selectedOffer: IOfferItem | null;
}

class DashboardOffers extends Component<IDashboardOffersProps, IDashboardOffersState> {
    constructor(props: IDashboardOffersProps) {
        super(props);

        this.state = {
            isRecommendationModalVisible: false,
            selectedOffer: null,
        };
    }

    render() {
        return (
            <>
                <Card className="offers-card">
                    <CardBody>
                        <CardTitle>
                            <div className="card-header">
                                <div>
                                    <File size={20} />
                                    <Translation text="dashboard.offers.title" />
                                </div>
                                <button className="btn btn-primary offers-button" onClick={() => this.redirectToOffers()}>
                                    <Translation text="buttons.showAll" />
                                </button>
                            </div>
                        </CardTitle>
                        <Table responsive className="offer-table">
                            <thead>
                                <tr>
                                    <th>
                                        <Translation text="dashboard.offers.table.headers.position" />
                                    </th>
                                    <th>
                                        <Translation text="dashboard.offers.table.headers.candidates" />
                                    </th>
                                    <th>
                                        <Translation text="dashboard.offers.table.headers.status" />
                                    </th>
                                    <th>
                                        <Translation text="dashboard.offers.table.headers.actions" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>{this.renderOffersTableRows()}</tbody>
                        </Table>
                    </CardBody>
                </Card>

                {this.state.isRecommendationModalVisible ? (
                    <RecommendOfferModal
                        isModalOpen={this.state.isRecommendationModalVisible}
                        toggleModal={this.toggleRecommendationModal}
                        offer={this.state.selectedOffer ? this.state.selectedOffer.offer : null}
                    />
                ) : null}
            </>
        );
    }

    private renderOffersTableRows() {
        const {t} = this.props;

        if (this.props.offers === null || !this.props.offers.length) {
            return (
                <tr key={'no-data'}>
                    <td colSpan={4}>
                        <p>
                            <Translation text="dashboard.offers.table.noData" />
                        </p>
                    </td>
                </tr>
            );
        }

        return this.props.offers.map((item: IOfferItem) => {
            const badgeColor = convertBadgeColor(item.status, 'light');
            return (
                <tr key={`offers_${item.offer.id}`}>
                    <td>
                        <ListItemDetails avatar={item.offer.avatar} name={item.offer.name} />
                    </td>
                    <td>
                        <div>
                            {item.candidatesCount}
                            <Button color="flat-primary" onClick={() => this.redirectToCandidateDetails(item.offer)}>
                                <Search id={`candidate_${item.offer.id}`} size={20} />
                                <Tooltip
                                    target={`candidate_${item.offer.id}`}
                                    tooltipText={t('dashboard.offers.tooltips.viewCandidates')}
                                />
                            </Button>
                        </div>
                    </td>
                    <td>
                        <Badge color={badgeColor} pill>
                            {item.status}
                        </Badge>
                    </td>
                    <td>
                        <div className={styles.actions}>
                            <Button color="flat-primary" onClick={() => this.redirectToOfferDetails(item.offer.id)}>
                                <Eye id={`details_${item.offer.id}`} size={20} />
                                <Tooltip target={`details_${item.offer.id}`} tooltipText={t('dashboard.offers.tooltips.viewOffer')} />
                            </Button>

                            <Button
                                disabled={item.status !== OfferStatus.ACTIVE}
                                color="flat-primary"
                                onClick={() => this.toggleRecommendationModal(item)}>
                                <Send id={`recommend_${item.offer.id}`} size={20} />
                                <Tooltip
                                    target={`recommend_${item.offer.id}`}
                                    tooltipText={t('humanResources.offers.tooltips.recommend')}
                                />
                            </Button>
                        </div>
                    </td>
                </tr>
            );
        });
    }

    private redirectToCandidateDetails = (item: IOffer) => {
        this.props.navigate(`/panel/human_resources/candidates`, {state: {offerId: item.id}});
    };

    private redirectToOfferDetails = (offerId: string) => {
        this.props.navigate(`/panel/human_resources/offers/${offerId}`);
    };

    private redirectToOffers = () => {
        this.props.navigate('/panel/human_resources/offers');
    };

    private toggleRecommendationModal = (item?: IOfferItem) => {
        this.setState({
            isRecommendationModalVisible: !this.state.isRecommendationModalVisible,
            selectedOffer: item ? item : null,
        });
    };
}

export default withTranslation()(withNavigation(DashboardOffers));
