import {Observable} from 'rxjs';
import {jobHunterAPI, RestQueryParams} from 'jobhunter-common-web';

export interface ITechnologyPayload {
    id: string;
    technologyId: string;
    seniorityId: string;
}

export interface ITechnologyToolPayload {
    id: string;
    technologyToolId: string;
    seniorityId: string;
}

export interface IAddOfferPayload {
    soughtPositionId: string | null;
    name: string;
    description: string;
    seniorityId: string;
    minimumSalary: string;
    maximumSalary: string;
    technologies: ITechnologyPayload[];
    technologyTools: ITechnologyToolPayload[];
    cityId: string;
    companyTypes: string[];
    industries: string[];
    contractTypes: string[];
    workTypes: string[];
    employmentTypes: string[];
    relocation: string;
    organizationSizeId: string;
    publishAt: string | null;
    preferenceTags: string[];
    requestedJobBoards: string[];
}

export function addOfferAPI(authToken: string | null, payload: IAddOfferPayload): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return jobHunterAPI.post(`api/offers`, payload, new RestQueryParams(), headers);
}
