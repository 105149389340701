import {
    collectionInputDataMapper,
    collectionOutputDataMapper,
    FormControlType,
    IFormConfig,
    InputType,
    MultiSelectType,
    IMultiselectOption,
    ValidationRules,
    FormButtonType,
} from 'jobhunter-common-web';

export const companyInformationFormConfig: typeof IFormConfig = (
    countryList: typeof IMultiselectOption[] | [],
    prevButtonIcon: any,
    nextButtonIcon: any,
    value?: any
) => ({
    controlType: 'form',
    class: 'default-form',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'name',
            controlType: 'group',
            class: 'row',
            controls: {
                name: {
                    controlType: 'control',
                    defaultValue: value ? value.name : '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: 'employer.company.form.placeholders.companyName',
                    isLabelHidden: false,
                    label: 'employer.company.form.labels.companyName',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-6 form-control required',
                },
                mobile: {
                    controlType: 'control',
                    defaultValue: value ? value.mobile : '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [],
                    placeholder: 'employer.personalInformation.form.placeholders.phoneNumber',
                    isLabelHidden: false,
                    label: 'employer.personalInformation.form.labels.phoneNumber',
                    type: InputType.TEL,
                    hostClass: 'col-xl-6 form-control required',
                },
            },
        },
        {
            key: 'basic_information',
            controlType: 'group',
            class: 'row',
            controls: {
                address: {
                    controlType: 'control',
                    defaultValue: value ? value.address : '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [],
                    placeholder: 'employer.company.form.placeholders.address',
                    isLabelHidden: false,
                    label: 'employer.company.form.labels.address',
                    type: InputType.TEL,
                    hostClass: 'col-xl-6 form-control required',
                },
            },
        },
        {
            key: 'address',
            controlType: 'group',
            class: 'row',
            controls: {
                cityName: {
                    controlType: 'control',
                    defaultValue: value ? value.cityName : '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: 'employer.personalInformation.form.placeholders.city',
                    isLabelHidden: false,
                    label: 'employer.personalInformation.form.labels.city',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-6 form-control required',
                },
                country: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    multiselectOptions: countryList ? countryList : [],
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: 'employer.personalInformation.form.placeholders.country',
                    label: 'employer.personalInformation.form.labels.country',
                    hostClass: 'col-xl-6 form-control required',
                    inputDataMapper: collectionInputDataMapper,
                    outputDataMapper: collectionOutputDataMapper,
                },
            },
        },
        {
            key: 'about',
            controlType: 'group',
            class: 'row',
            controls: {
                about: {
                    controlType: 'control',
                    defaultValue: value ? value.about : '',
                    formControlType: FormControlType.TEXTAREA,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}, {name: ValidationRules.MAX_LENGTH, params: {length: 2000}}],
                    placeholder: 'employer.company.form.placeholders.description',
                    isLabelHidden: false,
                    rows: 5,
                    maxLength: 2000,
                    label: 'employer.company.form.labels.description',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12 form-control required',
                },
            },
        },
        {
            controlType: 'group',
            key: 'company_information_buttons',
            class: 'row justify-content-between',
            controls: {
                previousButton: {
                    buttonName: 'previous',
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.BUTTON,
                    inputStyles: 'btn-prev btn-primary d-flex align-items-center company-info-prev-button',
                    hostClass: 'mb-0 w-auto',
                    disabled: false,
                    defaultContainerStyles: '',
                    containerStyles: '',
                    defaultValue: null,
                    buttonPreIcon: prevButtonIcon,
                    btnText: 'buttons.previous',
                    buttonDisabled: () => {
                        return false;
                    },
                },
                submitButton: {
                    buttonName: 'submit',
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    inputStyles: 'btn-submit btn-primary d-flex align-items-center',
                    hostClass: 'mb-0 align-items-end w-auto',
                    defaultContainerStyles: '',
                    containerStyles: '',
                    buttonPreIcon: nextButtonIcon,
                    defaultValue: null,
                    btnText: 'buttons.save',
                    buttonDisabled: (mappedOutputValue: any, isFormValid: boolean) => {
                        return !isFormValid;
                    },
                },
            },
        },
    ],
});
