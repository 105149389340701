import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelSubscription} from 'jobhunter-common-web';
import {IModelSubscriptionDefinition} from 'jobhunter-common-web';

export interface ISetSubscriptionPlansList {
    subscriptionsPlansList: typeof IModelSubscriptionDefinition[] | null;
}

export interface IChangeIsPlansLoading {
    isPlansListLoading: boolean;
}

export interface IChangeIsPlansInitialized {
    isPlansListInitialized: boolean;
}

export interface IChangeBillingPageError {
    billingPageError: string | null;
}

export interface IChangeIsPlanPurchased {
    isPlanPurchased: boolean;
}

export interface IChangeBillingsLoading {
    isBillingsLoading: boolean;
}
export interface ISubscriptionsList {
    current: typeof IModelSubscription | null;
    purchased: typeof IModelSubscription | null;
}

export interface ISetSubscriptionsList {
    subscriptions: ISubscriptionsList | null;
}
export interface IBillingPageState {
    subscriptionsPlansList: typeof IModelSubscriptionDefinition[] | null;
    isPlansListLoading: boolean;
    isPlansListInitialized: boolean;
    billingPageError: string | null;
    isPlanPurchased: boolean;
    isBillingsLoading: boolean;
    subscriptions: ISubscriptionsList | null;
}

const initialState: IBillingPageState = {
    subscriptionsPlansList: null,
    isPlanPurchased: false,
    isPlansListInitialized: false,
    isPlansListLoading: false,
    billingPageError: null,
    isBillingsLoading: false,
    subscriptions: null,
};

const billingPageSlice = createSlice({
    name: 'billingPage',
    initialState: initialState,
    reducers: {
        setSubscriptions: {
            reducer: (state: IBillingPageState, action: PayloadAction<ISetSubscriptionsList>) => {
                return {
                    ...state,
                    subscriptions: action.payload.subscriptions,
                };
            },
            prepare: (subscriptions: ISubscriptionsList | null) => {
                return {
                    payload: {
                        subscriptions: subscriptions,
                    },
                };
            },
        },
        changeIsBillingLoading: {
            reducer: (state: IBillingPageState, action: PayloadAction<IChangeBillingsLoading>) => {
                return {
                    ...state,
                    isBillingsLoading: action.payload.isBillingsLoading,
                };
            },
            prepare: (isBillingsLoading: boolean) => {
                return {
                    payload: {
                        isBillingsLoading: isBillingsLoading,
                    },
                };
            },
        },
        fetchSubscriptions: (state: IBillingPageState) => {
            return {
                ...state,
                isBillingsLoading: true,
            };
        },
        changeIsPlanPurchased: {
            reducer: (state: IBillingPageState, action: PayloadAction<IChangeIsPlanPurchased>) => {
                return {
                    ...state,
                    isPlanPurchased: action.payload.isPlanPurchased,
                };
            },
            prepare: (isPlanPurchased: boolean) => {
                return {
                    payload: {
                        isPlanPurchased: isPlanPurchased,
                    },
                };
            },
        },
        fetchSubscriptionPlansList: (state: IBillingPageState) => {
            return {
                ...state,
                isPlansListLoading: true,
            };
        },
        setSubscriptionPlansList: {
            reducer: (state: IBillingPageState, action: PayloadAction<ISetSubscriptionPlansList>) => {
                return {
                    ...state,
                    subscriptionsPlansList: action.payload.subscriptionsPlansList,
                };
            },
            prepare: (subscriptionsPlansList: typeof IModelSubscriptionDefinition[] | null) => {
                return {
                    payload: {
                        subscriptionsPlansList: subscriptionsPlansList,
                    },
                };
            },
        },
        changeIsPlansListLoading: {
            reducer: (state: IBillingPageState, action: PayloadAction<IChangeIsPlansLoading>) => {
                return {
                    ...state,
                    isPlansListLoading: action.payload.isPlansListLoading,
                };
            },
            prepare: (isPlansListLoading: boolean) => {
                return {
                    payload: {
                        isPlansListLoading: isPlansListLoading,
                    },
                };
            },
        },
        changeIsPlansListInitialized: {
            reducer: (state: IBillingPageState, action: PayloadAction<IChangeIsPlansInitialized>) => {
                return {
                    ...state,
                    isPlansListInitialized: action.payload.isPlansListInitialized,
                };
            },
            prepare: (isPlansListInitialized: boolean) => {
                return {
                    payload: {
                        isPlansListInitialized: isPlansListInitialized,
                    },
                };
            },
        },
        changeBillingPageError: {
            reducer: (state: IBillingPageState, action: PayloadAction<IChangeBillingPageError>) => {
                return {
                    ...state,
                    billingPageError: action.payload.billingPageError,
                };
            },
            prepare: (billingPageError: string | null) => {
                return {
                    payload: {
                        billingPageError: billingPageError,
                    },
                };
            },
        },
        resetToInitialBillingPageState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    fetchSubscriptionPlansList,
    setSubscriptionPlansList,
    changeBillingPageError,
    changeIsPlanPurchased,
    changeIsPlansListInitialized,
    changeIsPlansListLoading,
    resetToInitialBillingPageState,
    fetchSubscriptions,
    changeIsBillingLoading,
    setSubscriptions,
} = billingPageSlice.actions;

export default billingPageSlice.reducer;
