import {FormButtonType, FormControlType, IFormConfig, InputType, ValidationRules} from 'jobhunter-common-web';
import {IModelDictionaryDatum} from '../../../../../model/dictionaryDatum';

export const addVerificationDocumentFormConfig: typeof IFormConfig = (verificationFileTypes: IModelDictionaryDatum[], value?: any) => ({
    controlType: 'form',
    class: 'default-form',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'verificationType',
            controlType: 'group',
            class: 'row',
            controls: {
                documentType: {
                    hostClass: 'form-control',
                    controlType: 'control',
                    defaultValue: value && value.documentType ? value.documentType : null,
                    formControlType: FormControlType.RADIO,
                    options: mapToRadioOptions(verificationFileTypes),
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: '',
                    label: 'employer.verification.addDocument.form.labels.documentType',
                    type: InputType.RADIO,
                    wrapperStyles: 'checkbox-radio',
                    isDisplayValueTranslated: true,
                },
            },
        },
        {
            key: 'document',
            controlType: 'group',
            class: 'row',
            controls: {
                document: {
                    hostClass: 'form-control col-xl-12',
                    additionalStyles: 'add-verification-document-input',
                    controlType: 'control',
                    formControlType: FormControlType.FILE_UPLOAD,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: '',
                    label: 'employer.verification.addDocument.form.labels.addDocument',
                    isLabelHidden: false,
                    isFileRemovable: true,
                    acceptedFileExtension: '.pdf',
                    iconSize: 22,
                },
            },
        },
        {
            controlType: 'group',
            key: 'add_document_submit',
            class: 'row',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    inputStyles: 'btn btn-primary',
                    hostClass: 'col-xl-12 mb-0',
                    defaultContainerStyles: '',
                    containerStyles: 'button-container mt-2',
                    defaultValue: null,
                    btnText: 'buttons.add',
                },
            },
        },
    ],
});

export const mapToRadioOptions = (verificationFileTypes: IModelDictionaryDatum[] | []): {value: string; displayValue: string}[] => {
    const options: {value: string; displayValue: string}[] = [];
    if (verificationFileTypes === null) {
        return options;
    }

    verificationFileTypes.map((item: IModelDictionaryDatum) => {
        return options.push({value: item.id, displayValue: item.name});
    });

    return options;
};
