import React from 'react';
import {
    DateComponent,
    Form,
    FormControlChangeType,
    HtmlParserComponent,
    IFormConfig,
    Tooltip,
    Translation,
    WithNavigate,
    withNavigation,
} from 'jobhunter-common-web';
import {calendarFormConfig} from './calendarFormConfig';
import moment from 'moment';
import {BehaviorSubject, Subscription} from 'rxjs';
import {distinctUntilChanged, filter, tap} from 'rxjs/operators';
import classnames from 'classnames';
import {AlertCircle, AlertTriangle, Calendar, Eye, Trash2, Video} from 'react-feather';
import {Button} from 'reactstrap';
import {
    CalendarEventMetaType,
    CalendarIconType,
    ICalendarEvent,
    ICalendarEventName,
    ICalendarEventTag,
    IEventMetadataItem,
} from '../../../model/calendarEventModel';
import {WithTranslation, withTranslation} from 'react-i18next';
import EventCalendarDetailsModal from './EventCalendarDetailsModal';
import RemoveCalendarEventModal from './RemoveCalendarEventModal';

export enum CalendarEventType {
    ONLINE_CONSULTATION = 'online_consultation',
    TASK = 'task',
    RELOCATION = 'relocation',
}

declare type WithNavigationProps = typeof WithNavigate;

interface IConnectedEventCalendarProps {}

interface IExternalEventCalendarProps {
    username: string;
    calendarEvents: ICalendarEvent[] | null;
}

interface IEventCalendarProps extends IConnectedEventCalendarProps, IExternalEventCalendarProps, WithNavigationProps, WithTranslation {}

interface IEventCalendarState {
    formConfig: typeof IFormConfig | null;
    value: any;
    isRemoveEventModalShown: boolean;
    isViewEventModalShown: boolean;
    selectedEvent: ICalendarEvent | null;
}

class EventCalendar extends React.Component<IEventCalendarProps, IEventCalendarState> {
    readonly subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(props: IEventCalendarProps) {
        super(props);
        this.state = {
            formConfig: null,
            value: null,
            isRemoveEventModalShown: false,
            isViewEventModalShown: false,
            selectedEvent: null,
        };
    }

    componentDidMount() {
        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    distinctUntilChanged(),
                    tap((data: any) => this.setState({value: data.value}))
                )
                .subscribe()
        );
        if (null !== this.props.calendarEvents) {
            this.setFormConfig(this.props.calendarEvents);
        }
    }

    componentDidUpdate(prevProps: Readonly<IEventCalendarProps>) {
        if (this.props.calendarEvents !== prevProps.calendarEvents && this.props.calendarEvents) {
            this.setFormConfig(this.props.calendarEvents);
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col-xl-12">
                        <Form
                            config={this.state.formConfig}
                            controlName={'calendarForm'}
                            onValueStateChange={this.onValueStateChange}
                            value={this.state.value}
                        />
                    </div>

                    <div className="col-xl-12">{this.renderEventDetails()}</div>
                </div>

                {this.state.isViewEventModalShown ? (
                    <EventCalendarDetailsModal
                        toggleModal={this.toggleViewEventModal}
                        event={this.state.selectedEvent}
                        getTitle={this.getEventTitle}
                        getDescription={this.getDescription}
                        getIcon={this.getIconType}
                        isModalOpen={this.state.isViewEventModalShown}
                    />
                ) : null}
                {this.state.isRemoveEventModalShown ? (
                    <RemoveCalendarEventModal
                        toggleModal={this.toggleRemoveEventModal}
                        selectedEvent={this.state.selectedEvent}
                        isModalOpen={this.state.isRemoveEventModalShown}
                    />
                ) : null}
            </>
        );
    }

    private renderEventDetails = () => {
        if (!this.state.value || this.props.calendarEvents === null) {
            return;
        }

        let events = this.getSelectedEvents(this.state.value.date.value, this.props.calendarEvents);

        events = events.sort((a: ICalendarEvent, b: ICalendarEvent) => {
            return new Date(a.startsAt).getTime() > new Date(b.startsAt).getTime() ? 1 : -1;
        });

        const {t} = this.props;

        return (
            <div className="event-container">
                <p className="event-title">
                    <Translation text="dashboard.calendar.eventCalendar.title" />
                </p>
                {!events || !events.length ? (
                    <p className="no-events">
                        <Translation text="dashboard.calendar.eventCalendar.noEvents" />
                    </p>
                ) : (
                    events.map((item: ICalendarEvent, index: number) => {
                        const eventName = item?.name?.eventNames.filter(
                                (event: ICalendarEventName) => event.email !== this.props.username
                            )[0],
                            eventIcon = this.getIconType(item),
                            description = this.getDescription(item);
                        return (
                            <div className="event-details" key={index}>
                                <div
                                    className={classnames(
                                        'event-icon',
                                        {consultation: item.type === CalendarEventType.ONLINE_CONSULTATION},
                                        {task: item.type === CalendarEventType.TASK},
                                        {warning: eventIcon.style === CalendarIconType.WARNING},
                                        {alert: eventIcon.style === CalendarIconType.ALERT}
                                    )}>
                                    {item.type === CalendarEventType.ONLINE_CONSULTATION ? <Calendar /> : <AlertCircle />}
                                </div>
                                <div className="details">
                                    <div>
                                        <p className="title">
                                            {item.type === CalendarEventType.ONLINE_CONSULTATION
                                                ? eventName?.name
                                                : this.getEventTitle(item)}
                                        </p>
                                        <p>
                                            {item.type === CalendarEventType.ONLINE_CONSULTATION ? (
                                                <DateComponent date={item.startsAt} format="HH:mm, DD.MM.YYYY" />
                                            ) : (
                                                <HtmlParserComponent
                                                    htmlContent={description.length > 50 ? description.substr(0, 50) + '...' : description}
                                                />
                                            )}
                                        </p>
                                    </div>
                                    {item.type === CalendarEventType.ONLINE_CONSULTATION ? (
                                        <Button
                                            color="primary"
                                            className="join-consultation-button"
                                            onClick={() => this.redirectToConsultation(item)}>
                                            <Video size={14} />
                                            <Translation text="buttons.join" />
                                        </Button>
                                    ) : null}
                                    {item.type === CalendarEventType.RELOCATION ? (
                                        <div className="btn-wrapper">
                                            <Button
                                                color="flat-primary"
                                                className="remove-event-button"
                                                onClick={() => this.toggleRemoveEventModal(item)}>
                                                <Trash2 id={`delete_${item.id}`} size={20} />
                                                <Tooltip
                                                    target={`delete_${item.id}`}
                                                    tooltipText={t('relocationView.calendar.tooltips.delete')}
                                                />
                                            </Button>
                                            <Button
                                                color="flat-primary"
                                                className="view-event-button"
                                                onClick={() => this.toggleViewEventModal(item)}>
                                                <Eye id={`view_${item.id}`} size={20} />
                                                <Tooltip
                                                    target={`view_${item.id}`}
                                                    tooltipText={t('relocationView.calendar.tooltips.view')}
                                                />
                                            </Button>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        );
                    })
                )}
            </div>
        );
    };

    private getSelectedEvents(selectedDate: Date, eventsList: ICalendarEvent[]) {
        const recommendationDate = moment(selectedDate).format('YYYY-MM-DD');
        return eventsList.filter((event) => {
            const date = moment(event.startsAt).utcOffset(0).format('YYYY-MM-DD');

            return date === recommendationDate;
        });
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private setFormConfig = (calendarEvents: any[]) => {
        const eventDates = this.getRecommendationOccurrence(calendarEvents);
        this.setState({
            formConfig: calendarFormConfig(eventDates),
        });
    };

    private getRecommendationOccurrence(data: any) {
        if (!data || !data.length) {
            return null;
        }

        const map = data.reduce(
            (acc: any, e: any) =>
                acc.set(e.startsAt.split('T')[0].replace(/-0/g, '-'), (acc.get(e.startsAt.split('T')[0].replace(/-0/g, '-')) || 0) + 1),
            new Map()
        );
        return [...map.entries()];
    }

    private redirectToConsultation = (item: ICalendarEvent | null) => {
        const consultationTags = item?.tags?.tags.filter((tag: ICalendarEventTag) => tag.tagName.startsWith('online_consultation:')),
            consultationId = consultationTags?.length ? consultationTags[0]?.tagName.replace('online_consultation:', '') : null;

        this.props.navigate(`/panel/consultations/${consultationId}`);
    };

    private toggleRemoveEventModal = (event?: ICalendarEvent | null) => {
        this.setState({
            isRemoveEventModalShown: !this.state.isRemoveEventModalShown,
            selectedEvent: event ? event : null,
        });
    };

    private toggleViewEventModal = (event?: ICalendarEvent | null) => {
        this.setState({
            isViewEventModalShown: !this.state.isViewEventModalShown,
            selectedEvent: event ? event : null,
        });
    };

    private getIconType = (item: ICalendarEvent | null) => {
        let iconItem = {
            style: '',
            element: <AlertCircle />,
        };

        if (item === null) {
            return iconItem;
        }

        item.eventMeta.eventMetadata.map((metadata: IEventMetadataItem) => {
            if (metadata.key === CalendarEventMetaType.ICON_TYPE) {
                if (metadata.value === CalendarIconType.WARNING) {
                    iconItem = {
                        style: CalendarIconType.WARNING,
                        element: <AlertCircle color="#EA5455" />,
                    };
                }

                if (metadata.value === CalendarIconType.ALERT) {
                    iconItem = {
                        style: CalendarIconType.ALERT,
                        element: <AlertTriangle color="#FF9F43" />,
                    };
                }
            }
            return iconItem;
        });

        return iconItem;
    };

    private getEventTitle = (item: ICalendarEvent): string => {
        let title = '---';
        item.eventMeta.eventMetadata.map((metadata: IEventMetadataItem) => {
            if (metadata.key === CalendarEventMetaType.TITLE) {
                title = metadata.value;
            }
            return title;
        });
        return title;
    };

    private getDescription = (item: ICalendarEvent): string => {
        let description = '---';
        item.eventMeta.eventMetadata.map((metadata: IEventMetadataItem) => {
            if (metadata.key === CalendarEventMetaType.DESCRIPTION) {
                description = metadata.value;
            }
            return description;
        });

        return description;
    };
}

export default withNavigation(withTranslation()(EventCalendar));
