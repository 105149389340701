import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {catchError, switchMap} from 'rxjs/operators';
import {of, Observable} from 'rxjs';
import {RootState} from '../reducers';
import {
    addAlert,
    AlertType,
    authTokenSelector,
    getErrorMessage,
    RestQueryParams,
    flattenObj,
    deepCloneObject,
    getMetadataDetails,
} from 'jobhunter-common-web';
import {
    addPosition,
    applyPositionFilters,
    changeIsPositionStatusUpdated,
    changePositionAdded,
    changePositionRemoved,
    changePositionsPageError,
    changePositionsPageLoading,
    changePositionsPagination,
    changePositionStatus,
    deletePosition,
    fetchPositionsList,
    IAddPosition,
    IChangePositionStatus,
    IDeletePosition,
    IFetchPositionList,
    setPositionsList,
    setPositionsMetadata,
} from '../reducers/positionsPageSlice';
import {getPositionsAPI} from '../../api/getPositionsAPI';
import {PayloadAction} from '@reduxjs/toolkit';
import {addPositionAPI} from '../../api/addPositionApi';
import {deletePositionAPI} from '../../api/deletePositionAPI';
import {closePositionAPI} from '../../api/closePositionAPI';
import {openPositionAPI} from '../../api/openPositionAPI';
import {PositionStatus} from '../../model/positionsDataModel';
import {positionFiltersSelector, positionsPaginationSelector} from '../selectors/positionSelectors';

const fetchPositionsListEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getPositions(action$, state$, fetchPositionsList);
};

const applyPositionFiltersEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getPositions(action$, state$, applyPositionFilters);
};

const changePositionsPaginationEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getPositions(action$, state$, changePositionsPagination);
};

const getPositions = (action$: Observable<any>, state$: StateObservable<any>, actionType: any) => {
    return action$.pipe(
        ofType(actionType.type),
        switchMap((action: PayloadAction<IFetchPositionList>): any => {
            const authToken = authTokenSelector(state$.value),
                paginationParams = positionsPaginationSelector(state$.value),
                filters = deepCloneObject(positionFiltersSelector(state$.value)),
                filterObj = {
                    ...filters,
                    ...paginationParams,
                },
                flattened = flattenObj(filterObj);
            let queryParams = new RestQueryParams(flattened).add('order[createdAt]', 'desc');
            if (action.payload && action.payload.params) {
                const params = action.payload.params;
                Object.keys(params).forEach((key) => {
                    return (queryParams = queryParams.add(String(key), String(params[key])));
                });
            }

            return getPositionsAPI(authToken, queryParams).pipe(
                switchMap((resp: any) => {
                    const metadata = getMetadataDetails(resp['hydra:view']),
                        actions = successActions([setPositionsList(resp[`hydra:member`]), setPositionsMetadata(metadata)]);
                    return of(...actions);
                }),
                catchError((error) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

const addNewPositionEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(addPosition.type),
        switchMap((action: PayloadAction<IAddPosition>): any => {
            const authToken = authTokenSelector(state$.value);
            return addPositionAPI(authToken, action.payload.position).pipe(
                switchMap(() => {
                    const message = 'employer.positions.addPosition.positionAdded',
                        actions = successActions([addAlert({message: message}), changePositionAdded(true), fetchPositionsList(null)]);
                    return of(...actions);
                }),
                catchError((error) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

const removePositionEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(deletePosition.type),
        switchMap((action: PayloadAction<IDeletePosition>): any => {
            const authToken = authTokenSelector(state$.value);
            return deletePositionAPI(authToken, action.payload.positionId).pipe(
                switchMap(() => {
                    const actions = successActions([changePositionRemoved(true), fetchPositionsList(null)]);
                    return of(...actions);
                }),
                catchError((error) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

const changePositionStatusEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(changePositionStatus.type),
        switchMap((action: PayloadAction<IChangePositionStatus>): any => {
            const authToken = authTokenSelector(state$.value),
                positionId = action.payload.positionId,
                positionStatus = action.payload.positionStatus;

            if (positionStatus === PositionStatus.ACTIVE) {
                return closePositionAPI(authToken, positionId).pipe(
                    switchMap(() => of(...updatePositionSuccessActions('employer.positions.closePosition.positionClosed'))),
                    catchError((error) => of(...updatePositionErrorActions(error)))
                );
            } else {
                return openPositionAPI(authToken, positionId).pipe(
                    switchMap(() => of(...updatePositionSuccessActions('employer.positions.openPosition.positionOpened'))),
                    catchError((error) => of(...updatePositionErrorActions(error)))
                );
            }
        }),
        catchError((error) => of(...updatePositionErrorActions(error)))
    );
};

const successActions = (changeSliceList: any[]): any[] => {
    const actions = [changePositionsPageLoading(false)];
    return [...actions, ...changeSliceList];
};

const errorActions = (error: any): any[] => {
    return [
        changePositionsPageLoading(false),
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
        changePositionsPageError(getErrorMessage(error)),
    ];
};

const updatePositionSuccessActions = (successMessage: string) => {
    return [changeIsPositionStatusUpdated(true), addAlert({message: successMessage}), fetchPositionsList(null)];
};

const updatePositionErrorActions = (error: any) => {
    return [
        changeIsPositionStatusUpdated(true),
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
        changePositionsPageError(getErrorMessage(error)),
    ];
};

const positionsEpic = combineEpics(
    fetchPositionsListEpic,
    addNewPositionEpic,
    removePositionEpic,
    changePositionStatusEpic,
    applyPositionFiltersEpic,
    changePositionsPaginationEpic
);

export default positionsEpic;
