import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IPositionsListState} from '../reducers/positionsPageSlice';

export const selectPositionsPage = (state: RootState): IPositionsListState => {
    return state.positionsPage;
};

export const positionsListSelector = createSelector([selectPositionsPage], (state: IPositionsListState) => state.positionsList);

export const positionsMetadataSelector = createSelector([selectPositionsPage], (state: IPositionsListState) => state.positionsMetadata);

export const positionsPaginationSelector = createSelector([selectPositionsPage], (state: IPositionsListState) => state.pagination);

export const isPositionsPageLoading = createSelector([selectPositionsPage], (state: IPositionsListState) => state.isPositionsListLoading);

export const isPositionsPageInitialized = createSelector(
    [selectPositionsPage],
    (state: IPositionsListState) => state.isPositionsListInitialized
);

export const isPositionAddedSelector = createSelector([selectPositionsPage], (state: IPositionsListState) => state.isPositionAdded);

export const positionsPageError = createSelector([selectPositionsPage], (state: IPositionsListState) => state.positionsListError);

export const isPositionStatusUpdatedSelector = createSelector(
    [selectPositionsPage],
    (state: IPositionsListState) => state.isPositionStatusUpdated
);
export const positionFiltersSelector = createSelector([selectPositionsPage], (state: IPositionsListState) => state.positionFilters);
