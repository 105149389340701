import React, {Component} from 'react';
import {
    citiesSelector,
    companyTypesSelector,
    contractTypesSelector,
    convertPriceToInputValue,
    convertPriceToPayloadValue,
    convertSeniorityToRangeValues,
    convertToMultiselectLabels,
    DateComponent,
    employmentTypesSelector,
    Form,
    FormControlChangeType,
    IFormConfig,
    IModelCity,
    IModelDictionaryDatum,
    IModelSeniority,
    industriesSelector,
    Loader,
    LoaderType,
    organizationSizesSelector,
    senioritySelector,
    technologiesSelector,
    technologyToolsSelector,
    withLocation,
    WithLocation,
    workTypesSelector,
    preferenceTagsSelector,
    sortMultiselectLabels,
} from 'jobhunter-common-web';
import {BehaviorSubject, Subscription} from 'rxjs';
import {debounceTime, filter, tap} from 'rxjs/operators';
import {RootState} from '../../../../store/reducers';
import {connect} from 'react-redux';
import {WithTranslation, withTranslation} from 'react-i18next';
import {editOfferFormConfig} from './editOfferFormConfig';
import {
    fetchOfferDetails,
    IOfferDetails,
    resetToInitialOfferViewPageState,
    updateOffer,
} from '../../../../store/reducers/offerViewPageSlice';
import {isOfferViewLoadingSelector, offerDetailsSelector} from '../../../../store/selectors/offerViewPageSelectors';
import LayoutWrapper from '../../../LayoutWrapper';
import {Card, CardBody} from 'reactstrap';
import {IAddOfferPayload} from '../../../../api/addOfferAPI';
import {generateRequestedJobBoards, JobBoardsOptions} from '../AddOffer';

declare type WithLocationProps = typeof WithLocation;

interface IConnectedEditOfferProps {
    readonly offerDetails: IOfferDetails | null;
    readonly isOfferDetailsLoading: boolean;
    readonly cities: typeof IModelCity[] | null;
    readonly companyTypes: typeof IModelDictionaryDatum[] | null;
    readonly industries: typeof IModelDictionaryDatum[] | null;
    readonly contractTypes: typeof IModelDictionaryDatum[] | null;
    readonly workTypes: typeof IModelDictionaryDatum[] | null;
    readonly employmentTypes: typeof IModelDictionaryDatum[] | null;
    readonly seniorityLevels: typeof IModelSeniority[] | null;
    readonly organizationSizes: typeof IModelDictionaryDatum[] | null;
    readonly technologies: typeof IModelDictionaryDatum[] | null;
    readonly technologyTools: typeof IModelDictionaryDatum[] | null;
    readonly preferenceTags: typeof IModelDictionaryDatum[] | null;
    readonly fetchOfferDetails: typeof fetchOfferDetails;
    readonly updateOffer: typeof updateOffer;
    readonly resetToInitialOfferViewPageState: typeof resetToInitialOfferViewPageState;
}

export interface IEditOfferProps extends IConnectedEditOfferProps, WithLocationProps, WithTranslation {}

interface IEditOfferState {
    value: any;
    formConfig: typeof IFormConfig | null;
}

class EditOffer extends Component<IEditOfferProps, IEditOfferState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IEditOfferProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: null,
        };
    }

    componentDidMount(): void {
        if (this.props.location && this.props.location.pathname) {
            const offerId = this.props.location.pathname.split('/').pop();
            this.props.fetchOfferDetails(offerId);
        }

        if (this.props.offerDetails) {
            this.setFormValuesFromState();
        }

        this.setFormConfig();
        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    debounceTime(500),
                    tap((data: any) => this.changePersonalData(data.value))
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: Readonly<IEditOfferProps>) {
        if (this.props.offerDetails !== prevProps.offerDetails) {
            this.setFormValuesFromState();
        }

        if (
            this.props.technologies !== prevProps.technologies ||
            this.props.technologyTools !== prevProps.technologyTools ||
            this.props.cities !== prevProps.cities ||
            this.props.companyTypes !== prevProps.companyTypes ||
            this.props.industries !== prevProps.industries ||
            this.props.contractTypes !== prevProps.contractTypes ||
            this.props.workTypes !== prevProps.workTypes ||
            this.props.employmentTypes !== prevProps.employmentTypes ||
            this.props.preferenceTags !== prevProps.preferenceTags
        ) {
            this.setFormConfig();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        const {t} = this.props,
            offer = this.props.offerDetails?.offer,
            offerDetails = offer?.offer,
            offerCity = offerDetails?.city.name,
            offerCountry = offerDetails?.city.country.name,
            offerLocation = `${offerCity ? `${t(offerCity)},` : ''} ${offerCountry ? t(offerCountry) : ''}`;

        return (
            <LayoutWrapper>
                <div className="offer-view-container">
                    <div className="position-details offer-edit">
                        <div className="header flex-column">
                            <h3 className="job-title">
                                {offerDetails?.name ? <span>{offerDetails?.name}</span> : null} {offerLocation}
                            </h3>
                            <DateComponent date={offer?.offer.publishAt} format="DD/MM/YYYY" />
                        </div>
                    </div>

                    <Card className="edit-offer-card">
                        <CardBody>
                            <Form
                                config={this.state.formConfig}
                                onValueStateChange={this.onValueStateChange}
                                value={this.state.value}
                                controlName={'editOfferForm'}
                                submitForm={this.updateOffer}
                            />
                        </CardBody>
                    </Card>
                    <Loader type={LoaderType.Local} showLoader={this.props.isOfferDetailsLoading} />
                </div>
            </LayoutWrapper>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private changePersonalData = (data: {[key: string]: any}) => {
        this.setState({value: data});
    };

    private setFormConfig = () => {
        const {t} = this.props;
        const cities = this.props.cities ? sortMultiselectLabels(convertToMultiselectLabels(this.props.cities, t)) : [],
            companyTypes = this.props.companyTypes ? sortMultiselectLabels(convertToMultiselectLabels(this.props.companyTypes, t)) : [],
            industries = this.props.industries ? sortMultiselectLabels(convertToMultiselectLabels(this.props.industries, t)) : [],
            contractTypes = this.props.contractTypes ? sortMultiselectLabels(convertToMultiselectLabels(this.props.contractTypes, t)) : [],
            workTypes = this.props.workTypes ? sortMultiselectLabels(convertToMultiselectLabels(this.props.workTypes, t)) : [],
            employmentTypes = this.props.employmentTypes
                ? sortMultiselectLabels(convertToMultiselectLabels(this.props.employmentTypes, t, true))
                : [],
            seniorityLevels = convertSeniorityToRangeValues(this.props.seniorityLevels),
            organizationSize = this.props.organizationSizes
                ? sortMultiselectLabels(convertToMultiselectLabels(this.props.organizationSizes, t))
                : [],
            preferenceTags = this.props.preferenceTags
                ? sortMultiselectLabels(convertToMultiselectLabels(this.props.preferenceTags, t))
                : [],
            technologyTools = this.props.technologyTools,
            technologies = this.props.technologies;

        this.setState({
            formConfig: editOfferFormConfig(
                seniorityLevels,
                cities,
                companyTypes,
                industries,
                contractTypes,
                employmentTypes,
                workTypes,
                organizationSize,
                technologyTools,
                technologies,
                preferenceTags,
                () => this.updateOffer(true)
            ),
        });
    };

    private setFormValuesFromState = () => {
        const offer = this.props.offerDetails,
            offerDetails = offer?.offer.offer,
            offerTechnologies = offer?.technologies,
            offerTools = offer?.technologyTools,
            technologyValues = {
                technologies: offerTechnologies?.map((technology: any) => {
                    return {
                        technologyId: technology.technology.id,
                        seniorityId: technology.seniority.id,
                    };
                }),
                technologyTools: offerTools?.map((tool: {[key: string]: any}) => {
                    return {
                        technologyToolId: tool.technologyTool.id,
                        seniorityId: tool.seniority.id,
                    };
                }),
            },
            values = {
                companyType: offerDetails?.companyTypes.map((type) => type.id),
                contractType: offerDetails?.contractTypes.map((type) => type.id),
                description: offer?.offerDescription.description,
                employmentType: offerDetails?.employmentTypes.map((type) => type.id),
                industry: offerDetails?.industries.map((industry) => industry.id),
                location: offerDetails?.city.id,
                maximumSalary: convertPriceToInputValue(offerDetails?.maximumSalary),
                minimumSalary: convertPriceToInputValue(offerDetails?.minimumSalary),
                name: offerDetails?.name,
                onlyRelocation: offerDetails?.relocation,
                organizationSize: offerDetails?.organizationSize?.id,
                seniority: offerDetails?.seniority.id,
                workType: offerDetails?.workTypes.map((type) => type.id),
                technology: technologyValues,
                preferenceTags: offerDetails?.preferenceTags.map((tag) => tag.id),
                postJobFree: offer?.offer?.requestedJobBoards?.includes(JobBoardsOptions.POST_JOB_FREE),
                workUa: offer?.offer?.requestedJobBoards?.includes(JobBoardsOptions.WORK_UA),
                careerAge: offer?.offer?.requestedJobBoards?.includes(JobBoardsOptions.CAREER_AGE),
            };

        this.setState({value: values});
    };

    private updateOffer = (isDraft?: boolean) => {
        if (!this.props.offerDetails) {
            return;
        }

        const formData = this.state.value,
            offerPublishDate = this.props.offerDetails?.offer.offer.publishAt,
            requestedJobBoards = generateRequestedJobBoards(formData),
            payload: IAddOfferPayload = {
                soughtPositionId: '',
                name: formData.name,
                description: formData.description,
                seniorityId: formData.seniority,
                minimumSalary: convertPriceToPayloadValue(formData.minimumSalary),
                maximumSalary: convertPriceToPayloadValue(formData.maximumSalary),
                technologies: formData.technology.technologies,
                technologyTools: formData.technology.technologyTools,
                cityId: formData.location,
                companyTypes: formData.companyType,
                industries: formData.industry,
                contractTypes: formData.contractType,
                workTypes: formData.workType,
                employmentTypes: formData.employmentType,
                relocation: formData.onlyRelocation,
                organizationSizeId: formData.organizationSize,
                publishAt: isDraft === true ? null : offerPublishDate !== null ? offerPublishDate : new Date().toISOString(),
                preferenceTags: formData.preferenceTags ? formData.preferenceTags : [],
                requestedJobBoards: requestedJobBoards,
            };

        this.props.updateOffer(this.props.offerDetails?.offer.offer.id, payload);
    };
}

export default connect(
    (state: RootState) => ({
        offerDetails: offerDetailsSelector(state),
        isOfferDetailsLoading: isOfferViewLoadingSelector(state),
        technologies: technologiesSelector(state),
        cities: citiesSelector(state),
        companyTypes: companyTypesSelector(state),
        industries: industriesSelector(state),
        contractTypes: contractTypesSelector(state),
        workTypes: workTypesSelector(state),
        employmentTypes: employmentTypesSelector(state),
        seniorityLevels: senioritySelector(state),
        organizationSizes: organizationSizesSelector(state),
        technologyTools: technologyToolsSelector(state),
        preferenceTags: preferenceTagsSelector(state),
    }),
    {
        fetchOfferDetails,
        updateOffer,
        resetToInitialOfferViewPageState,
    }
)(withLocation(withTranslation()(EditOffer)));
