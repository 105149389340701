import React, {Component} from 'react';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {Form, FormControlChangeType, IFormConfig, Loader, LoaderType, Translation} from 'jobhunter-common-web';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {addEmployeeFormConfig} from './addEmployeeFormConfig';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {employeesPageErrorSelector, isEmployeeAddedSelector} from '../../../../store/selectors/employeesPageSelectors';
import {
    addEmployee,
    changeEmployeeRole,
    changeExternalEmployee,
    changeIsEmployeeAdded,
} from '../../../../store/reducers/employeesPageSlice';
import {IAddEmployeePayload} from '../../../../api/addEmployeeAPI';
import {EmployeeType} from '../../../../model/employeeDataModel';
import {editEmployeeFormConfig} from './editEmployeeFormConfig';
import {WithTranslation, withTranslation} from 'react-i18next';

interface IConnectedAddEmployeeProps {
    readonly isEmployeeAdded: boolean;
    readonly error: string | null;
    readonly changeIsEmployeeAdded: typeof changeIsEmployeeAdded;
    readonly changeEmployeeRole: typeof changeEmployeeRole;
    readonly changeExternalEmployee: typeof changeExternalEmployee;
    readonly addEmployee: typeof addEmployee;
}

interface IExternalAddEmployeeProps {
    isModalOpen: boolean;
    toggleModal: () => void;
    employee?: {[key: string]: any} | null;
}

interface IAddEmployeeProps extends IConnectedAddEmployeeProps, IExternalAddEmployeeProps, WithTranslation {}

interface IAddEmployeeState {
    value: any;
    formConfig: typeof IFormConfig | null;
    isLoading: boolean;
}

class AddEmployee extends Component<IAddEmployeeProps, IAddEmployeeState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IAddEmployeeProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: null,
            isLoading: false,
        };
    }

    componentDidMount(): void {
        let formConfig;

        if (this.props.employee && this.props.employee?.type === EmployeeType.ORGANIZATION_EMPLOYEE) {
            const roleList = this.roleList();
            formConfig = editEmployeeFormConfig(this.props.toggleModal, roleList);
        } else {
            formConfig = addEmployeeFormConfig(this.props.toggleModal, this.props.employee);
        }

        this.setState({formConfig});

        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    tap((data: any) => this.setState({value: data.value}))
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: IAddEmployeeProps): void {
        if (this.props.isEmployeeAdded !== prevProps.isEmployeeAdded && this.props.isEmployeeAdded) {
            this.props.toggleModal();
        }

        if (this.props.error !== prevProps.error && this.props.error) {
            this.setState({isLoading: false});
        }
    }

    componentWillUnmount() {
        this.props.changeIsEmployeeAdded(false);
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <Modal isOpen={this.props.isModalOpen} toggle={() => this.props.toggleModal()}>
                <ModalHeader toggle={() => this.props.toggleModal()}>
                    <Translation
                        text={this.props.employee ? 'humanResources.editEmployee.title' : 'humanResources.addEmployeeModal.title'}
                    />
                </ModalHeader>
                <ModalBody>
                    {this.state.formConfig && (
                        <Form
                            config={this.state.formConfig}
                            value={this.state.value}
                            controlName={this.props.employee ? 'changeEmployeeForm' : 'addEmployeeForm'}
                            submitForm={() => {
                                this.props.employee === null
                                    ? this.addEmployee()
                                    : this.props.employee?.type === EmployeeType.ORGANIZATION_EMPLOYEE
                                    ? this.editOrganizationEmployee()
                                    : this.editExternalEmployee();
                            }}
                            onValueStateChange={this.onValueStateChange}
                        />
                    )}
                </ModalBody>

                <Loader type={LoaderType.Local} showLoader={this.state.isLoading} />
            </Modal>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private roleList = () => {
        const {t} = this.props;
        return [
            {value: 'ROLE_STANDARD_EMPLOYEE', label: t('humanResources.editEmployee.roleEmployee')},
            {value: 'ROLE_HEADHUNTER', label: t('humanResources.editEmployee.roleHeadhunter')},
            {value: 'ROLE_ORGANIZATION_ADMIN', label: t('humanResources.editEmployee.roleAdmin')},
        ];
    };

    private addEmployee = () => {
        this.setState({isLoading: true});

        const payload: IAddEmployeePayload = {
            email: this.state.value.email,
            firstName: this.state.value.firstName,
            lastName: this.state.value.lastName,
        };
        this.props.addEmployee(payload);
    };

    private editOrganizationEmployee = () => {
        this.setState({isLoading: true});

        if (this.state.value === null) {
            return;
        }
        const role = this.state.value.role;
        this.props.changeEmployeeRole(this.props.employee?.organizationEmployeeId, role);
    };

    private editExternalEmployee = () => {
        this.setState({isLoading: true});

        if (!this.props.employee || !this.props.employee.id) {
            return;
        }

        const payload: IAddEmployeePayload = {
            email: this.state.value.email,
            firstName: this.state.value.firstName,
            lastName: this.state.value.lastName,
        };
        this.props.changeExternalEmployee(this.props.employee?.id, payload);
    };
}

export default connect(
    (state: RootState) => ({
        isEmployeeAdded: isEmployeeAddedSelector(state),
        error: employeesPageErrorSelector(state),
    }),
    {addEmployee, changeIsEmployeeAdded, changeEmployeeRole, changeExternalEmployee}
)(withTranslation()(AddEmployee));
