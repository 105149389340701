import {
    IModelApiResponseViewObject,
    IModelDictionaryDatum,
    IModelPayment,
    Loader,
    LoaderType,
    Pagination,
    Price,
    Tooltip,
    Translation,
} from 'jobhunter-common-web';
import React, {Component} from 'react';
import {Search} from 'react-feather';
import {WithTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Badge, Button, Card, CardBody, Table} from 'reactstrap';
import {IModelPurchasedService, IModelServiceListItem} from '../../../model/servicesDataModel';
import {RootState} from '../../../store/reducers';
import {
    changePurchasedServicesPagination,
    fetchPurchasedServicesList,
    resetToInitialPurchasedServicePageState,
} from '../../../store/reducers/purchasedServicesSlice';
import {
    isPurchasedServicesPageLoading,
    purchasedServicesListSelector,
    purchasedServicesMetadataSelector,
} from '../../../store/selectors/purchasedServicesSelectors';
import LayoutWrapper from '../../LayoutWrapper';
import ServiceView from './ServiceView';

interface IConnectedPurchasedServicesProps {
    readonly isLoading: boolean;
    readonly purchasedServicesList: IModelPurchasedService[] | null;
    readonly purchasedServicesMetadata: typeof IModelApiResponseViewObject | null;
    readonly fetchPurchasedServicesList: typeof fetchPurchasedServicesList;
    readonly resetToInitialPurchasedServicePageState: typeof resetToInitialPurchasedServicePageState;
    readonly changePurchasedServicesPagination: typeof changePurchasedServicesPagination;
}

interface IPurchasedServicesProps extends IConnectedPurchasedServicesProps, WithTranslation {}

interface IPurchasedServicesState {
    selectedServiceToView: IModelPurchasedService | null;
}

class PurchasedServices extends Component<IPurchasedServicesProps, IPurchasedServicesState> {
    constructor(props: IPurchasedServicesProps) {
        super(props);

        this.state = {
            selectedServiceToView: null,
        };
    }

    componentDidMount(): void {
        this.props.fetchPurchasedServicesList(null);
    }

    componentWillUnmount() {
        this.props.resetToInitialPurchasedServicePageState();
    }

    render() {
        return (
            <LayoutWrapper>
                <div className="d-flex justify-content-between align-items-center">
                    <h2 className="panel-section-header">
                        <Translation text="marketplace.purchasedServices.title" />
                    </h2>
                </div>

                <Card className="purchased-services-card">
                    <CardBody>{this.renderServiceListTable()}</CardBody>
                </Card>

                {this.state.selectedServiceToView && (
                    <ServiceView
                        toggleModal={this.toggleViewServiceModal}
                        service={this.state.selectedServiceToView.service}
                        payment={this.state.selectedServiceToView.payment}
                    />
                )}

                <Pagination
                    listMetadata={this.props.purchasedServicesMetadata}
                    itemsPerPage={10}
                    changePage={this.props.changePurchasedServicesPagination}
                />

                <Loader type={LoaderType.Local} showLoader={this.props.isLoading} />
            </LayoutWrapper>
        );
    }

    private renderServiceListTable = () => {
        return (
            <Table striped responsive className="purchased-services-table">
                <thead>
                    <tr>
                        <th>
                            <Translation text="marketplace.services.table.headers.service" />
                        </th>
                        <th>
                            <Translation text="marketplace.services.table.headers.type" />
                        </th>
                        <th>
                            <Translation text="marketplace.services.table.headers.description" />
                        </th>
                        <th>
                            <Translation text="marketplace.services.table.headers.price" />
                        </th>
                        {/*Status is hidden in current version*/}
                        {/*<th>*/}
                        {/*<Translation text="marketplace.services.table.headers.status" />*/}
                        {/*</th>*/}
                        <th>
                            <Translation text="marketplace.services.table.headers.paymentStatus" />
                        </th>
                        <th>
                            <Translation text="marketplace.services.table.headers.actions" />
                        </th>
                    </tr>
                </thead>
                <tbody>{this.renderServicesTableRows()}</tbody>
            </Table>
        );
    };

    private renderServicesTableRows() {
        const services: IModelPurchasedService[] | null = this.props.purchasedServicesList,
            {t} = this.props;

        if (!services || services.length === 0) {
            return (
                <tr key={'no-data'}>
                    <td colSpan={7}>
                        <p>
                            <Translation text="marketplace.purchasedServices.table.noData" />
                        </p>
                    </td>
                </tr>
            );
        }

        return services.map((purchasedService: IModelPurchasedService) => {
            const item: IModelServiceListItem = purchasedService.service,
                payment: typeof IModelPayment = purchasedService.payment;
            return (
                <tr key={`matches_${purchasedService.id}`}>
                    <td>
                        <p className="fw-bolder">{item.title}</p>
                    </td>
                    <td>{this.renderServiceTypes(item.types)}</td>
                    <td>{item.description}</td>
                    <td className="fw-bolder">
                        <Price price={{amount: item.grossPrice}} />
                    </td>
                    {/*Status is hidden in current version*/}
                    {/*<td>{this.renderServiceStatus(item.accepted)}</td>*/}
                    <td>{this.renderPaymentStatus(payment)}</td>
                    <td>
                        <div className="d-flex">
                            <Button
                                color="flat-primary"
                                className="view-service-button"
                                onClick={() => this.toggleViewServiceModal(purchasedService)}>
                                <Search id={`edit_${item.id}`} size={20} />
                                <Tooltip target={`edit_${item.id}`} tooltipText={t('marketplace.services.table.tooltips.view')} />
                            </Button>
                        </div>
                    </td>
                </tr>
            );
        });
    }

    // private renderServiceStatus = (status: boolean) => {
    //     const serviceStatusTranslationKey = status
    //         ? 'marketplace.services.serviceStatus.accepted'
    //         : 'marketplace.services.serviceStatus.pending';
    //     return (
    //         <Badge color={`${status ? 'light-primary' : 'light-warning'}`} pill>
    //             <Translation text={serviceStatusTranslationKey} />
    //         </Badge>
    //     );
    // };

    private renderPaymentStatus = (payment: typeof IModelPayment) => {
        const paymentStatusTranslationKey = payment.paidAt
            ? 'marketplace.services.serviceStatus.paid'
            : 'marketplace.services.serviceStatus.notPaid';
        return (
            <Badge color={`${payment.paidAt ? 'light-primary' : 'light-warning'}`} pill>
                <Translation text={paymentStatusTranslationKey} />
            </Badge>
        );
    };

    private renderServiceTypes = (types: typeof IModelDictionaryDatum) => {
        return types.map((item: typeof IModelDictionaryDatum) => {
            return (
                <Badge color="light-primary" pill className="mb-025" key={item.id}>
                    <span className="align-middle">
                        <Translation text={item.name} />
                    </span>
                </Badge>
            );
        });
    };

    private toggleViewServiceModal = (service: any) => {
        this.setState({
            selectedServiceToView: service ? service : null,
        });
    };
}

export default withTranslation()(
    connect(
        (state: RootState) => ({
            isLoading: isPurchasedServicesPageLoading(state),
            purchasedServicesList: purchasedServicesListSelector(state),
            purchasedServicesMetadata: purchasedServicesMetadataSelector(state),
        }),
        {
            fetchPurchasedServicesList,
            resetToInitialPurchasedServicePageState,
            changePurchasedServicesPagination,
        }
    )(PurchasedServices)
);
