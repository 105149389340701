import React, {Component} from 'react';
import LayoutWrapper from '../../../LayoutWrapper';
import {Card, CardBody} from 'reactstrap';
import {
    citiesSelector,
    companyTypesSelector,
    contractTypesSelector,
    convertPriceToInputValue,
    convertPriceToPayloadValue,
    convertSeniorityToRangeValues,
    convertToMultiselectLabels,
    employmentTypesSelector,
    Form,
    FormControlChangeType,
    IFormConfig,
    IModelCity,
    IModelDictionaryDatum,
    IModelSeniority,
    industriesSelector,
    Loader,
    LoaderType,
    organizationSizesSelector,
    senioritySelector,
    technologiesSelector,
    technologyToolsSelector,
    withLocation,
    WithLocation,
    workTypesSelector,
    preferenceTagsSelector,
    sortMultiselectLabels,
} from 'jobhunter-common-web';
import {BehaviorSubject, Subscription} from 'rxjs';
import {debounceTime, filter, tap} from 'rxjs/operators';
import {editOfferFormConfig} from '../EditOffer/editOfferFormConfig';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {withTranslation, WithTranslation} from 'react-i18next';
import {IAddOfferPayload} from '../../../../api/addOfferAPI';
import {addOffer, fetchOfferRelatedPosition} from '../../../../store/reducers/offerViewPageSlice';
import {isOfferViewLoadingSelector, offerRelatedPositionSelector} from '../../../../store/selectors/offerViewPageSelectors';
import {IModelRecruitment} from '../../../../model/recruitmentDataModel';

declare type WithLocationProps = typeof WithLocation;

interface IConnectedAddOfferProps {
    readonly isLoading: boolean;
    readonly cities: typeof IModelCity[] | null;
    readonly companyTypes: typeof IModelDictionaryDatum[] | null;
    readonly industries: typeof IModelDictionaryDatum[] | null;
    readonly contractTypes: typeof IModelDictionaryDatum[] | null;
    readonly workTypes: typeof IModelDictionaryDatum[] | null;
    readonly employmentTypes: typeof IModelDictionaryDatum[] | null;
    readonly seniorityLevels: typeof IModelSeniority[] | null;
    readonly organizationSizes: typeof IModelDictionaryDatum[] | null;
    readonly technologyTools: typeof IModelDictionaryDatum[] | null;
    readonly technologies: typeof IModelDictionaryDatum[] | null;
    readonly preferenceTags: typeof IModelDictionaryDatum[] | null;
    readonly offerRelatedPosition: IModelRecruitment | null;
    readonly addOffer: typeof addOffer;
    readonly fetchOfferRelatedPosition: typeof fetchOfferRelatedPosition;
}

interface IExternalAddOfferProps {}

interface IAddOfferProps extends IConnectedAddOfferProps, IExternalAddOfferProps, WithTranslation, WithLocationProps {}

export enum JobBoardsOptions {
    POST_JOB_FREE = 'postjobfree',
    WORK_UA = 'workua',
    CAREER_AGE = 'careerage',
}

export const generateRequestedJobBoards = (formData: {[key: string]: any}): string[] => {
    const requestedJobBoards = [];
    if (formData.postJobFree) {
        requestedJobBoards.push(JobBoardsOptions.POST_JOB_FREE);
    }

    if (formData.workUa) {
        requestedJobBoards.push(JobBoardsOptions.WORK_UA);
    }

    if (formData.careerAge) {
        requestedJobBoards.push(JobBoardsOptions.CAREER_AGE);
    }

    return requestedJobBoards;
};

interface IAddOfferState {
    value: any;
    formConfig: typeof IFormConfig | null;
    positionId: string | null | undefined;
}

class AddOffer extends Component<IAddOfferProps, IAddOfferState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IAddOfferProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: null,
            positionId: null,
        };
    }

    componentDidMount(): void {
        if (this.props.location.state && this.props.location.state.soughtPositionId) {
            this.setState({positionId: this.props.location.state.soughtPositionId});
            this.props.fetchOfferRelatedPosition(this.props.location.state.soughtPositionId);
        }

        if (this.props.offerRelatedPosition) {
            this.setFormValuesFromState();
        }

        this.setFormConfig();
        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    debounceTime(500),
                    tap((data: any) => this.setState({value: data.value}))
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: Readonly<IAddOfferProps>) {
        if (this.props.offerRelatedPosition !== prevProps.offerRelatedPosition) {
            this.setFormValuesFromState();
        }

        if (
            this.props.technologies !== prevProps.technologies ||
            this.props.technologyTools !== prevProps.technologyTools ||
            this.props.cities !== prevProps.cities ||
            this.props.companyTypes !== prevProps.companyTypes ||
            this.props.industries !== prevProps.industries ||
            this.props.contractTypes !== prevProps.contractTypes ||
            this.props.workTypes !== prevProps.workTypes ||
            this.props.employmentTypes !== prevProps.employmentTypes ||
            this.props.preferenceTags !== prevProps.preferenceTags
        ) {
            this.setFormConfig();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <LayoutWrapper>
                <div className="offer-view-container">
                    <div className="position-details offer-edit">
                        <div className="header">
                            <h3 className="job-title">
                                <span className="new-offer">New Offer</span>
                            </h3>
                        </div>
                    </div>

                    <Card className="add-offer-card">
                        <CardBody>
                            <Form
                                config={this.state.formConfig}
                                onValueStateChange={this.onValueStateChange}
                                value={this.state.value}
                                controlName={'addOfferForm'}
                                submitForm={this.addNewOffer}
                            />
                        </CardBody>
                    </Card>
                </div>

                <Loader type={LoaderType.Local} showLoader={this.props.isLoading} />
            </LayoutWrapper>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private setFormConfig = () => {
        const {t} = this.props;
        const cities = this.props.cities ? sortMultiselectLabels(convertToMultiselectLabels(this.props.cities, t)) : [],
            companyTypes = this.props.companyTypes ? sortMultiselectLabels(convertToMultiselectLabels(this.props.companyTypes, t)) : [],
            industries = this.props.industries ? sortMultiselectLabels(convertToMultiselectLabels(this.props.industries, t)) : [],
            contractTypes = this.props.contractTypes ? sortMultiselectLabels(convertToMultiselectLabels(this.props.contractTypes, t)) : [],
            workTypes = this.props.workTypes ? sortMultiselectLabels(convertToMultiselectLabels(this.props.workTypes, t)) : [],
            employmentTypes = this.props.employmentTypes
                ? sortMultiselectLabels(convertToMultiselectLabels(this.props.employmentTypes, t, true))
                : [],
            seniorityLevels = convertSeniorityToRangeValues(this.props.seniorityLevels),
            organizationSize = this.props.organizationSizes
                ? sortMultiselectLabels(convertToMultiselectLabels(this.props.organizationSizes, t))
                : [],
            preferenceTags = this.props.preferenceTags
                ? sortMultiselectLabels(convertToMultiselectLabels(this.props.preferenceTags, t))
                : [],
            technologyTools = this.props.technologyTools,
            technologies = this.props.technologies;

        this.setState({
            formConfig: editOfferFormConfig(
                seniorityLevels,
                cities,
                companyTypes,
                industries,
                contractTypes,
                employmentTypes,
                workTypes,
                organizationSize,
                technologyTools,
                technologies,
                preferenceTags,
                () => this.addNewOffer(true)
            ),
        });
    };

    private addNewOffer = (isDraft?: boolean) => {
        const formData = this.state.value,
            soughtPositionId = this.state.positionId ? this.state.positionId : null,
            isDefaultSeniority = formData.seniority === '' || formData.seniority === undefined,
            seniorityLevel: typeof IModelSeniority | undefined = this.props.seniorityLevels?.find((level) =>
                isDefaultSeniority ? level.level === 0 : level.id === formData.seniority
            ),
            requestedJobBoards = generateRequestedJobBoards(formData),
            payload: IAddOfferPayload = {
                soughtPositionId: soughtPositionId,
                name: formData.name,
                description: formData.description,
                seniorityId: seniorityLevel.id,
                minimumSalary: convertPriceToPayloadValue(formData.minimumSalary),
                maximumSalary: convertPriceToPayloadValue(formData.maximumSalary),
                technologies: formData.technology?.technologies ? formData.technology.technologies : [],
                technologyTools: formData.technology?.technologyTools ? formData.technology.technologyTools : [],
                cityId: formData.location,
                companyTypes: formData.companyType,
                industries: formData.industry,
                contractTypes: formData.contractType,
                workTypes: formData.workType,
                employmentTypes: formData.employmentType,
                relocation: formData.onlyRelocation === undefined ? false : formData.onlyRelocation,
                organizationSizeId: formData.organizationSize,
                publishAt: isDraft === true ? null : new Date().toISOString(),
                preferenceTags: formData.preferenceTags ? formData.preferenceTags : [],
                requestedJobBoards: requestedJobBoards,
            };

        this.props.addOffer(payload);
    };

    private setFormValuesFromState = () => {
        const soughtPosition = this.props.offerRelatedPosition,
            values = {
                description: soughtPosition?.description,
                maximumSalary: convertPriceToInputValue(soughtPosition?.maximumSalary),
                minimumSalary: convertPriceToInputValue(soughtPosition?.minimumSalary),
                name: soughtPosition?.name,
            };

        this.setState({value: values});
    };
}

export default connect(
    (state: RootState) => ({
        isLoading: isOfferViewLoadingSelector(state),
        technologies: technologiesSelector(state),
        cities: citiesSelector(state),
        companyTypes: companyTypesSelector(state),
        industries: industriesSelector(state),
        contractTypes: contractTypesSelector(state),
        workTypes: workTypesSelector(state),
        employmentTypes: employmentTypesSelector(state),
        seniorityLevels: senioritySelector(state),
        organizationSizes: organizationSizesSelector(state),
        technologyTools: technologyToolsSelector(state),
        preferenceTags: preferenceTagsSelector(state),
        offerRelatedPosition: offerRelatedPositionSelector(state),
    }),
    {addOffer, fetchOfferRelatedPosition}
)(withTranslation()(withLocation(AddOffer)));
