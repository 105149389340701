import React, {Component} from 'react';
import LayoutWrapper from '../../LayoutWrapper';
import SubscriptionPlan from './SubscriptionPlan';
import styles from './styles.module.scss';
import {Translation, IModelSubscriptionDefinition, Loader, LoaderType} from 'jobhunter-common-web';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {
    subscriptionsPlansListSelector,
    isPlansListLoadingSelector,
    subscriptonsSelector,
    isBillingsLoadingSelector,
} from '../../../store/selectors/billingPageSelectors';
import {
    fetchSubscriptions,
    fetchSubscriptionPlansList,
    ISubscriptionsList,
    resetToInitialBillingPageState,
} from '../../../store/reducers/billingPageSlice';
import PurchasedSubscriptions from './PurchasedSubscription';

interface IConnectedBillingsProps {
    readonly subscriptionPlansList: typeof IModelSubscriptionDefinition[] | null;
    readonly isLoading: boolean;
    readonly fetchSubscriptionPlansList: typeof fetchSubscriptionPlansList;
    readonly resetToInitialBillingPageState: typeof resetToInitialBillingPageState;
    readonly fetchSubscriptions: typeof fetchSubscriptions;
    readonly subscriptions: ISubscriptionsList | null;
    readonly isBillingsLoading: boolean;
}

interface IBillingsProps extends IConnectedBillingsProps {}

interface IBillingsState {}

class Billings extends Component<IBillingsProps, IBillingsState> {
    componentDidMount(): void {
        this.props.fetchSubscriptionPlansList();
        this.props.fetchSubscriptions();
    }
    componentWillUnmount(): void {
        this.props.resetToInitialBillingPageState();
    }
    render() {
        return (
            <LayoutWrapper>
                {/* Commented on 19.12.2022 - plans moved to jobhunter-web to improve - if neccessary then uncomment - PT */}
                {/* <div className="panel-section-header">
                    <Translation text="plans.title" />
                </div> */}

                {/* <div className={styles.plansContainer}>
                    {null === this.props.subscriptionPlansList ||
                        (this.props.subscriptionPlansList.length === 0 && <Translation text="billings.table.emptyPlansList" />)}
                    {this.props.subscriptionPlansList?.map((plan: any) => {
                        return <SubscriptionPlan subscriptionPlan={plan} key={plan.id} />;
                    })}
                    <Loader type={LoaderType.Local} showLoader={this.props.isLoading} />
                </div> */}
                <PurchasedSubscriptions billings={this.props.subscriptions} isLoading={this.props.isBillingsLoading} />
            </LayoutWrapper>
        );
    }
}

export default connect(
    (state: RootState) => ({
        subscriptionPlansList: subscriptionsPlansListSelector(state),
        isLoading: isPlansListLoadingSelector(state),
        subscriptions: subscriptonsSelector(state),
        isBillingsLoading: isBillingsLoadingSelector(state),
    }),
    {fetchSubscriptionPlansList, resetToInitialBillingPageState, fetchSubscriptions}
)(Billings);
