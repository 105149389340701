import {
    Form,
    FormControlChangeType,
    IFormConfig,
    Translation,
    convertToMultiselectLabels,
    Loader,
    LoaderType,
    countriesSelector,
    IModelDictionaryDatum,
    sortMultiselectLabels,
} from 'jobhunter-common-web';
import React, {Component} from 'react';
import {Card, CardBody, CardHeader} from 'reactstrap';
import {filter, tap} from 'rxjs/operators';
import {BehaviorSubject, Subscription} from 'rxjs';
import {billingInformationFormConfig} from './billingInformationFormConfig';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {IModelBillingInformation} from '../../../model/billingDataModel';
import {
    fetchBillingInformation,
    updateBillingInformation,
    resetToInitialBillingInformationState,
} from '../../../store/reducers/settingsPageSlice';
import {billingInformationSelector, isBillingInformationLoadingSelector} from '../../../store/selectors/settingsPageSelector';
import {withTranslation, WithTranslation} from 'react-i18next';
import {IUpdateBillingInformationPayload} from '../../../api/updateBillingInfoAPI';

interface IConnectedBillingInformationProps {
    readonly isLoading: boolean;
    readonly billingInfo: IModelBillingInformation | null;
    readonly fetchBillingInformation: typeof fetchBillingInformation;
    readonly resetToInitialBillingInformationState: typeof resetToInitialBillingInformationState;
    readonly updateBillingInformation: typeof updateBillingInformation;
    readonly countryList: typeof IModelDictionaryDatum[] | null;
}

interface IExternalBillingInformationProps {}

interface IBillingInformationProps extends IConnectedBillingInformationProps, IExternalBillingInformationProps, WithTranslation {}

interface IBillingInformationState {
    value: any;
    formConfig: typeof IFormConfig | null;
}

class BillingInformation extends Component<IBillingInformationProps, IBillingInformationState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IBillingInformationProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: null,
        };
    }

    componentDidMount(): void {
        this.props.fetchBillingInformation();
        this.setFormConfig();
        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    tap((data: any) => this.setState({value: data.value}))
                )
                .subscribe()
        );
    }
    componentDidUpdate(prevProps: Readonly<IBillingInformationProps>): void {
        if (this.props.billingInfo !== prevProps.billingInfo || this.props.countryList !== prevProps.countryList) {
            this.setFormConfig();
        }
    }

    componentWillUnmount() {
        this.props.resetToInitialBillingInformationState();
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <Card className="billing-information-card">
                <CardHeader>
                    <Translation text="settings.billingInformation.title" />
                </CardHeader>
                <CardBody>
                    {this.state.formConfig && (
                        <Form
                            config={this.state.formConfig}
                            onValueStateChange={this.onValueStateChange}
                            value={this.state.value}
                            submitForm={() => this.submitBillingInfoForm()}
                            controlName={'changeBillingDetailsForm'}
                        />
                    )}
                </CardBody>
                <Loader type={LoaderType.Local} showLoader={this.props.isLoading} />
            </Card>
        );
    }

    private submitBillingInfoForm() {
        const value = this.state.value;
        if (value === null) {
            return;
        }

        const payload: IUpdateBillingInformationPayload = {
            companyName: value.companyName,
            email: value.companyEmail,
            taxId: value.taxId,
            mobile: value.phone,
            address: value.address,
            state: value.state,
            city: value.city,
            zip: value.postCode,
            countryId: value.country,
        };
        this.props.updateBillingInformation(payload);
    }

    private setFormConfig = () => {
        const {t} = this.props,
            formValues = {
                address: this.props.billingInfo?.address,
                companyName: this.props.billingInfo?.companyName,
                companyEmail: this.props.billingInfo?.email,
                country: this.props.billingInfo?.country?.id,
                phone: this.props.billingInfo?.mobile,
                postCode: this.props.billingInfo?.zip,
                state: this.props.billingInfo?.state,
                city: this.props.billingInfo?.city,
                taxId: this.props.billingInfo?.taxId,
            },
            countries = sortMultiselectLabels(convertToMultiselectLabels(this.props.countryList, t)),
            formConfig = billingInformationFormConfig(countries);

        this.setState({formConfig, value: formValues});
    };

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };
}

export default connect(
    (state: RootState) => ({
        billingInfo: billingInformationSelector(state),
        isLoading: isBillingInformationLoadingSelector(state),
        countryList: countriesSelector(state),
    }),
    {fetchBillingInformation, updateBillingInformation, resetToInitialBillingInformationState}
)(withTranslation()(BillingInformation));
