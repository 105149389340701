import React, {Component} from 'react';
import LayoutWrapper from '../../../LayoutWrapper';
import {Button, Card, CardBody, CardHeader} from 'reactstrap';
import {
    Form,
    FormControlChangeType,
    IFormConfig,
    Translation,
    Tooltip,
    WithLocation,
    withLocation,
    IModelCity,
    senioritySelector,
    LoaderType,
    IModelSeniority,
    Loader,
    IRangePickerOption,
    convertSeniorityToRangeValues,
    convertToMultiselectLabels,
    citiesSelector,
    convertPriceToInputValue,
    convertPriceToPayloadValue,
    sortMultiselectLabels,
} from 'jobhunter-common-web';
import {Save} from 'react-feather';
import {WithTranslation, withTranslation} from 'react-i18next';
import styles from './styles.module.scss';
import {editPositionFormConfig} from './editPositionFormConfig';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {IModelPosition, IUpdatePositionPayload} from '../../../../model/positionsDataModel';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {positionDetailsSelector, isPositionDetailsLoadingSelector} from '../../../../store/selectors/positionDetailsSelectors';
import {
    fetchPositionDetails,
    resetToInitialPositionDetailsState,
    updatePosition,
} from '../../../../store/reducers/positionDetailsPageSlice';

declare type WithLocationProps = typeof WithLocation;

interface IConnectedEditPositionProps {
    readonly isLoading: boolean;
    readonly position: IModelPosition | null;
    readonly cityList: typeof IModelCity[] | null;
    readonly seniorityLevel: typeof IModelSeniority[];
    readonly fetchPositionDetails: typeof fetchPositionDetails;
    readonly resetToInitialPositionDetailsState: typeof resetToInitialPositionDetailsState;
    readonly updatePosition: typeof updatePosition;
}

interface IExternalEditPositionProps {}

interface IEditPositionProps extends WithTranslation, WithLocationProps, IConnectedEditPositionProps, IExternalEditPositionProps {}

interface IEditPositionState {
    value: any;
    formConfig: typeof IFormConfig | null;
}
class EditPosition extends Component<IEditPositionProps, IEditPositionState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IEditPositionProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: null,
        };
    }

    componentDidMount(): void {
        if (this.props.location && this.props.location.pathname) {
            const positionId = this.props.location.pathname.split('/').pop();
            this.props.fetchPositionDetails(positionId);
        }

        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    tap((data: any) => this.setState({value: data.value}))
                )
                .subscribe()
        );
        if (this.props.position) {
            this.setDefaultStateValues(this.props.position);
        }

        this.setFormConfig(this.props.cityList, this.props.seniorityLevel);
    }

    componentDidUpdate(prevProps: Readonly<IEditPositionProps>): void {
        if (this.props.position !== prevProps.position && this.props.position && this.props.position) {
            this.setDefaultStateValues(this.props.position);
        }

        if (this.props.cityList !== prevProps.cityList || this.props.seniorityLevel !== prevProps.seniorityLevel) {
            this.setFormConfig(this.props.cityList, this.props.seniorityLevel);
        }
    }

    componentWillUnmount() {
        this.props.resetToInitialPositionDetailsState();
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        const {t} = this.props;
        return (
            <>
                <LayoutWrapper>
                    <div className={styles.positionDetails}>
                        <div className="panel-section-header">
                            <Translation text="employer.positions.positionDetails.title" />
                        </div>
                        <Card>
                            <CardHeader>
                                <div className={styles.positionHeader}>
                                    <Button
                                        color="flat-primary"
                                        className="update-position-button"
                                        onClick={() => this.updateDescription()}>
                                        <Save id={`save`} size={20} />
                                        <Tooltip target={`save`} tooltipText={t('employer.positions.positionDetails.tooltips.save')} />
                                    </Button>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    {this.state.formConfig && (
                                        <Form
                                            config={this.state.formConfig}
                                            onValueStateChange={this.onValueStateChange}
                                            value={this.state.value}
                                            controlName={'editPositionForm'}
                                        />
                                    )}
                                </div>
                            </CardBody>
                            <Loader type={LoaderType.Local} showLoader={this.props.isLoading} />
                        </Card>
                    </div>
                </LayoutWrapper>
            </>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private updateDescription = () => {
        if (null === this.props.position) {
            return;
        }

        const positionId = this.props.position.id,
            value = this.state.value,
            payload: IUpdatePositionPayload = {
                name: value.name,
                description: value.description,
                minimumSalary: convertPriceToPayloadValue(value.minSalary),
                maximumSalary: convertPriceToPayloadValue(value.maxSalary),
                cityId: value.city,
                seniorityId: value.seniority,
            };

        this.props.updatePosition(positionId, payload);
    };

    private setDefaultStateValues = (position: IModelPosition | null) => {
        const value = {
            name: position?.name,
            description: position?.description,
            minSalary: convertPriceToInputValue(position?.minimumSalary),
            maxSalary: convertPriceToInputValue(position?.maximumSalary),
            city: position?.city.id,
            seniority: position?.seniority.id,
        };
        this.setState({value});
    };

    private setFormConfig = (cities: typeof IModelCity[] | null, seniority: typeof IModelSeniority[] | null) => {
        const {t} = this.props,
            cityList = sortMultiselectLabels(convertToMultiselectLabels(cities, t)),
            seniorityLevel: {[key: number]: typeof IRangePickerOption} = convertSeniorityToRangeValues(seniority),
            formConfig = editPositionFormConfig(cityList, seniorityLevel, this.state.value);
        this.setState({formConfig});
    };
}

export default connect(
    (state: RootState) => ({
        seniorityLevel: senioritySelector(state),
        position: positionDetailsSelector(state),
        isLoading: isPositionDetailsLoadingSelector(state),
        cityList: citiesSelector(state),
    }),
    {fetchPositionDetails, resetToInitialPositionDetailsState, updatePosition}
)(withTranslation()(withLocation(EditPosition)));
