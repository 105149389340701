import {Observable} from 'rxjs';
import {jobHunterAPI, RestQueryParams} from 'jobhunter-common-web';

export interface IAddEmployeePayload {
    email: string;
    firstName: string;
    lastName: string;
}

export function addEmployeeAPI(authToken: string | null, payload: IAddEmployeePayload): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return jobHunterAPI.post(`api/external_employees`, payload, new RestQueryParams(), headers);
}
