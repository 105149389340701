import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IBillingPageState} from '../reducers/billingPageSlice';

export const selectBillingPage = (state: RootState) => {
    return state.billingPage;
};

export const subscriptionsPlansListSelector = createSelector(
    [selectBillingPage],
    (state: IBillingPageState) => state.subscriptionsPlansList
);
export const isPlanPurchasedSelector = createSelector([selectBillingPage], (state: IBillingPageState) => state.isPlanPurchased);
export const isPlansListLoadingSelector = createSelector([selectBillingPage], (state: IBillingPageState) => state.isPlansListLoading);
export const isPlansListInitializedSelector = createSelector(
    [selectBillingPage],
    (state: IBillingPageState) => state.isPlansListInitialized
);
export const billingPageErrorSelector = createSelector([selectBillingPage], (state: IBillingPageState) => state.billingPageError);
export const isBillingsLoadingSelector = createSelector([selectBillingPage], (state: IBillingPageState) => state.isBillingsLoading);
export const subscriptonsSelector = createSelector([selectBillingPage], (state: IBillingPageState) => state.subscriptions);
