import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {Loader, LoaderType, Translation} from 'jobhunter-common-web';
import {changeIsRelocationEventRemoved, removeRelocationEvent} from '../../../../store/reducers/relocationDetailsPageSlice';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {isRelocationEventRemovedSelector, relocationDetailsErrorSelector} from '../../../../store/selectors/relocationDetailsPageSelectors';
import {ICalendarEvent} from '../../../../model/calendarEventModel';

interface IConnectedRemoveCalendarEventModalProps {
    readonly error: string | null;
    readonly isRelocationEventRemoved: boolean;
    readonly removeRelocationEvent: typeof removeRelocationEvent;
    readonly changeIsRelocationEventRemoved: typeof changeIsRelocationEventRemoved;
}

interface IExternalRemoveCalendarEventModalProps {
    isModalOpen: boolean;
    toggleModal: () => void;
    selectedEvent: ICalendarEvent | null;
}

interface IRemoveCalendarEventModalProps extends IConnectedRemoveCalendarEventModalProps, IExternalRemoveCalendarEventModalProps {}

interface IRemoveCalendarEventModalState {
    isLoading: boolean;
}

class RemoveCalendarEventModal extends Component<IRemoveCalendarEventModalProps, IRemoveCalendarEventModalState> {
    constructor(props: IRemoveCalendarEventModalProps) {
        super(props);

        this.state = {
            isLoading: false,
        };
    }

    componentDidUpdate(prevProps: IRemoveCalendarEventModalProps): void {
        if (this.props.isRelocationEventRemoved !== prevProps.isRelocationEventRemoved && this.props.isRelocationEventRemoved) {
            this.props.toggleModal();
        }

        if (this.props.error !== prevProps.error && this.props.error) {
            this.setState({isLoading: false});
        }
    }

    componentWillUnmount() {
        this.props.changeIsRelocationEventRemoved(false);
    }

    render() {
        return (
            <Modal isOpen={this.props.isModalOpen} toggle={() => this.props.toggleModal()}>
                <ModalHeader toggle={() => this.props.toggleModal()} />
                <ModalBody>
                    <p className="modal-title">
                        <Translation text="relocationView.calendar.removeEvent.title" />
                    </p>
                    <p className="modal-description">
                        <Translation text="relocationView.calendar.removeEvent.description" />
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" className="close-modal-button" outline onClick={() => this.props.toggleModal()}>
                        <Translation text="buttons.cancel" />
                    </Button>

                    <Button color="primary" onClick={() => this.removeEvent()}>
                        <Translation text="buttons.delete" />
                    </Button>
                </ModalFooter>

                <Loader type={LoaderType.Local} showLoader={this.state.isLoading} />
            </Modal>
        );
    }

    private removeEvent = () => {
        if (this.props.selectedEvent) {
            this.setState({isLoading: true});
            this.props.removeRelocationEvent(this.props.selectedEvent.id);
        }
    };
}

export default connect(
    (state: RootState) => ({
        error: relocationDetailsErrorSelector(state),
        isRelocationEventRemoved: isRelocationEventRemovedSelector(state),
    }),
    {
        removeRelocationEvent,
        changeIsRelocationEventRemoved,
    }
)(RemoveCalendarEventModal);
