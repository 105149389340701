import React, {Component} from 'react';
import LayoutWrapper from '../../../LayoutWrapper';
import {Badge, Card, CardBody} from 'reactstrap';
import {
    Avatar,
    AvatarColor,
    AvatarSize,
    IModelDictionaryDatum,
    isNotNullOrUndefined,
    Loader,
    LoaderType,
    Map,
    Price,
    RatingComponent,
    Translation,
    withLocation,
    WithLocation,
    HtmlParserComponent,
} from 'jobhunter-common-web';
import {Box, DollarSign, TrendingUp, User} from 'react-feather';
import {connect} from 'react-redux';
import {
    fetchOfferDetails,
    IOfferDetails,
    IOfferTechnologyTool,
    resetToInitialOfferViewPageState,
} from '../../../../store/reducers/offerViewPageSlice';
import {RootState} from '../../../../store/reducers';
import {isOfferViewLoadingSelector, offerDetailsSelector} from '../../../../store/selectors/offerViewPageSelectors';
import {WithTranslation, withTranslation} from 'react-i18next';
import {JobBoardsOptions} from '../AddOffer';

declare type WithLocationProps = typeof WithLocation;

interface IConnectedOfferViewProps {
    readonly offerDetails: IOfferDetails;
    readonly isOfferDetailsLoading: boolean;
    readonly fetchOfferDetails: typeof fetchOfferDetails;
    readonly resetToInitialOfferViewPageState: typeof resetToInitialOfferViewPageState;
}

interface IOfferViewProps extends IConnectedOfferViewProps, WithLocationProps, WithTranslation {}

interface IOfferViewState {
    offer: IOfferDetails | null;
}

class OfferView extends Component<IOfferViewProps, IOfferViewState> {
    constructor(props: IOfferViewProps) {
        super(props);
        this.state = {
            offer: null,
        };
    }

    componentDidMount() {
        if (this.props.location && this.props.location.pathname) {
            const offerId = this.props.location.pathname.split('/').pop();
            this.props.fetchOfferDetails(offerId);
        }
    }

    componentWillUnmount() {
        this.props.resetToInitialOfferViewPageState();
    }

    render() {
        if (this.props.offerDetails === null) {
            return (
                <LayoutWrapper>
                    <p>
                        <Translation text="offers.offerView.noData" />
                    </p>
                    <Loader showLoader={this.props.isOfferDetailsLoading} type={LoaderType.Local} />
                </LayoutWrapper>
            );
        }

        const {t} = this.props,
            offer = this.props.offerDetails.offer,
            offerDetails = offer.offer,
            offerCity = offerDetails.city.name,
            offerCountry = offerDetails.city.country.name,
            offerLocation = `${offerCity ? `${t(offerCity)},` : ''} ${offerCountry ? t(offerCountry) : ''}`,
            salary = `${offerDetails.minimumSalary}, ${offerDetails.maximumSalary}`;

        return (
            <LayoutWrapper>
                <Card className="offer-details-card">
                    <CardBody className="pe-0 ps-0 pt-0 pb-0">
                        <div className="offer-view-container">
                            <div className="map-wrapper">
                                <Map
                                    isMarkerShown
                                    position={{lat: -34.397, lng: 150.644}}
                                    // commented until valid Google Maps key is provided
                                    // googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                                    loadingElement={<div style={{height: `100%`}} />}
                                    containerElement={<div style={{height: `100%`}} />}
                                    mapElement={<div style={{height: `100%`, borderRadius: 6}} />}
                                />
                            </div>

                            <div className="position-details">
                                <div className="header">
                                    <h3 className="job-title">
                                        {offerDetails.name ? <span>{offerDetails.name}</span> : null} {offerLocation}
                                    </h3>
                                </div>

                                <div className="summary">
                                    {this.renderPositionDetails(
                                        AvatarColor.LIGHT_PRIMARY,
                                        TrendingUp,
                                        'seniority',
                                        offerDetails.seniority.name
                                    )}

                                    {this.renderPositionDetails(
                                        AvatarColor.LIGHT_INFO,
                                        User,
                                        'employees',
                                        offerDetails?.organizationSize?.name
                                    )}

                                    {this.renderPositionDetails(
                                        AvatarColor.LIGHT_DANGER,
                                        Box,
                                        'relocation',
                                        offerDetails.relocation ? t('buttons.yes') : t('buttons.no')
                                    )}
                                    {this.renderPositionDetails(AvatarColor.LIGHT_SUCCESS, DollarSign, 'salary', salary, true)}
                                </div>

                                <hr />

                                <div className="technology">
                                    <h5 className="subtitle">
                                        <Translation text="offers.offerView.description.technology" />
                                    </h5>
                                    <div className="technology-details">
                                        {this.props.offerDetails.technologyTools.map((tool: IOfferTechnologyTool) => {
                                            return this.renderTechnologyDetails(tool.technologyTool.name, Number(tool.seniority.level));
                                        })}
                                    </div>
                                </div>

                                <hr />

                                <div className="job-description">
                                    <h5 className="subtitle">
                                        <Translation text="offers.offerView.description.jobDescription" />
                                    </h5>
                                    <HtmlParserComponent htmlContent={this.props.offerDetails.offerDescription.description} />

                                    {/*<h5 className="subtitle">*/}
                                    {/*    <Translation text="offers.offerView.description.responsibilities" />*/}
                                    {/*</h5>*/}
                                    {/*<ul>*/}
                                    {/*    {offer.responsibilities.map((responsibility: string, index: number) => (*/}
                                    {/*        <li key={`responsibility_${index}`}>{responsibility}</li>*/}
                                    {/*    ))}*/}
                                    {/*</ul>*/}

                                    {/*<h5 className="subtitle">*/}
                                    {/*    <Translation text="offers.offerView.description.benefits" />*/}
                                    {/*</h5>*/}
                                    {/*<ul>*/}
                                    {/*    {offer.benefits.map((benefit: string, index: number) => (*/}
                                    {/*        <li key={`benefit_${index}`}>{benefit}</li>*/}
                                    {/*    ))}*/}
                                    {/*</ul>*/}
                                </div>

                                <hr />

                                <div className="additional-information">
                                    {this.renderOfferAdditionalInformation('industry', offerDetails.industries)}
                                    {this.renderOfferAdditionalInformation('companyType', offerDetails.companyTypes)}
                                    {this.renderOfferAdditionalInformation('contractType', offerDetails.contractTypes)}
                                    {this.renderOfferAdditionalInformation('employmentType', offerDetails.employmentTypes)}
                                    {this.renderOfferAdditionalInformation('jobType', offerDetails.workTypes)}
                                </div>

                                {offerDetails.preferenceTags && offerDetails.preferenceTags.length
                                    ? this.renderPreferenceTags(offerDetails.preferenceTags)
                                    : null}

                                {this.renderJobBoards(offer.requestedJobBoards, offer.jobBoards)}
                            </div>
                        </div>
                    </CardBody>
                </Card>
                <Loader showLoader={this.props.isOfferDetailsLoading} type={LoaderType.Local} />
            </LayoutWrapper>
        );
    }

    private renderPositionDetails = (color: typeof AvatarColor, Icon: any, label: string, description: string, isPrice?: boolean) => {
        return (
            <div className="summary-item">
                <Avatar color={color} size={AvatarSize.LG} icon={<Icon size={24} />} tag={'div'} />
                <div className="summary-details">
                    <p className="description">
                        {isNotNullOrUndefined(description) ? (
                            isPrice ? (
                                <>
                                    <Price price={{amount: description.split(',')[0]}} /> -{' '}
                                    <Price price={{amount: description.split(',')[1]}} />
                                </>
                            ) : (
                                <Translation text={description} />
                            )
                        ) : (
                            '---'
                        )}
                    </p>
                    <p className="label">
                        <Translation text={`offers.offerView.labels.${label}`} />
                    </p>
                </div>
            </div>
        );
    };

    private renderTechnologyDetails = (label: string, level: number) => {
        return (
            <div className="technology-item" key={label}>
                <p className="label">
                    <Translation text={label} />
                </p>
                <div className="level">
                    <RatingComponent
                        id={label}
                        initialRating={level / 10}
                        emptySymbol={<div className="empty-symbol" />}
                        fullSymbol={<div className="full-symbol" />}
                        readonly
                        stop={6}
                    />
                </div>
            </div>
        );
    };

    private renderOfferAdditionalInformation = (label: string, items: string[]) => {
        return (
            <div className="information-item">
                <div className="offer-information-label">
                    <p className="label">
                        <Translation text={`offers.offerView.labels.${label}`} />
                    </p>
                </div>
                <div className="offer-information-content">
                    {items.map((item: typeof IModelDictionaryDatum) => (
                        <Badge key={`additional_info_${item.id}`} color="primary" pill>
                            <Translation text={item.name.toLocaleLowerCase()} />
                        </Badge>
                    ))}
                </div>
            </div>
        );
    };

    private renderPreferenceTags = (tags: typeof IModelDictionaryDatum[]) => {
        return (
            <>
                <hr />

                <div className="job-description preference-tags">
                    <h5 className="subtitle">
                        <Translation text="offers.offerView.description.preferenceTags" />
                    </h5>

                    {tags.map((tag) => {
                        return (
                            <Badge key={tag.id} color="primary" className="me-1" pill>
                                <Translation text={tag.name} />
                            </Badge>
                        );
                    })}
                </div>
            </>
        );
    };

    private renderJobBoards = (requestedJobBoards: string[], jobBoards: {[key: string]: any}) => {
        if (undefined !== jobBoards && null !== jobBoards) {
            const mapped = Object.getOwnPropertyNames(jobBoards)
                .filter((name) => -1 !== requestedJobBoards.indexOf(name))
                .map((name) => ({
                    name,
                    data: jobBoards[name],
                }));

            return (
                <>
                    <hr />

                    <div className="additional-information">
                        <h5 className="subtitle">
                            <Translation text="offers.offerView.description.jobBoards" />
                        </h5>

                        {!Array.isArray(mapped) || !mapped.length ? (
                            <p>
                                <Translation text="offers.offerView.description.noJobBoardLinks" />
                            </p>
                        ) : (
                            mapped.map((elem: any, index: number) => {
                                return <React.Fragment key={index}>{this.getJobBoardName(elem.name, elem.data)}</React.Fragment>;
                            })
                        )}
                    </div>
                </>
            );
        }
    };

    private getJobBoardName = (name: string, data: any) => {
        const isPostJobFreeLabel = name.includes(JobBoardsOptions.POST_JOB_FREE),
            isWorkUaLabel = name.includes(JobBoardsOptions.WORK_UA),
            label = isPostJobFreeLabel ? 'postJobFreeLabel' : isWorkUaLabel ? 'workUaLabel' : 'careerAgeLabel';

        return (
            <div className="information-item">
                <div className="offer-information-label">
                    <p className="label">
                        <a className="" href={data.url} target="_blank">
                            <Translation text={`offers.offerView.description.${label}`} />
                        </a>
                    </p>
                </div>
            </div>
        );
    };
}

export default connect(
    (state: RootState) => ({
        offerDetails: offerDetailsSelector(state),
        isOfferDetailsLoading: isOfferViewLoadingSelector(state),
    }),
    {
        fetchOfferDetails,
        resetToInitialOfferViewPageState,
    }
)(withTranslation()(withLocation(OfferView)));
