import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {ICandidateViewState} from '../reducers/candidateViewPageSlice';

export const selectCandidateViewPage = (state: RootState): ICandidateViewState => {
    return state.candidateViewPage;
};

export const candidateDetailsSelector = createSelector([selectCandidateViewPage], (state: ICandidateViewState) => state.candidateDetails);

export const isCandidateViewLoadingSelector = createSelector(
    [selectCandidateViewPage],
    (state: ICandidateViewState) => state.isCandidateViewLoading
);

export const isCandidateViewInitializedSelector = createSelector(
    [selectCandidateViewPage],
    (state: ICandidateViewState) => state.isCandidateViewInitialized
);

export const candidateViewErrorSelector = createSelector(
    [selectCandidateViewPage],
    (state: ICandidateViewState) => state.candidateViewError
);
